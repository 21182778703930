import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { P2REnvironmentService } from '@p2r/env';
import { P2RApiService, P2R_CENTER_DETT } from '@p2r/platform-api';
import { P2RDialogComponent, P2RDialogService, P2RLocalStorageService, P2RTranslateService } from '@p2r/shared';
import { EMPTY, forkJoin, Observable, of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { CONFIGURATION_SOURCE, CONFIGURATION_TYPE, GROUP_TYPE, SERVICE_TYPE } from './configuration.model';
import { ConfigurationService } from './configuration.service';
import { GlobalVariable } from '../global';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { TranslateService } from "@ngx-translate/core";
import { ConfigurationAlertComponent } from './alert/alert.component';
import { assetUrl } from 'src/single-spa/asset-url';
@Injectable({
  providedIn: 'root',
})
export class ConfigurationResolver implements Resolve<any> {

  private _assetsPath;

  constructor(
    private configurationService: ConfigurationService,
    private p2rLocalStorageService: P2RLocalStorageService,
    private apiService: P2RApiService,
    private envService: P2REnvironmentService,
    private p2rDialogService: P2RDialogService,
    private p2rEnvironmentService: P2REnvironmentService,
    private ngxService: NgxUiLoaderService
  ) {
    this.p2rEnvironmentService.getEnvironmentStream().subscribe(env => {
      this._assetsPath = `${env.BASE_CONFIG_URL}`;
    });
  }
  resolve(route: any): any {
    const group = route.data && route.data.group;

    const selectedPv = this.p2rLocalStorageService.getFromLocalStorage(
      GlobalVariable.SELECTED_PV_KEY_STORAGE,
      []
    );

    console.log('CONFIGURATION RESOLVE: group ' + group);

    switch (group) {
      case GROUP_TYPE.OPERATOR:
      case GROUP_TYPE.HALLS_TABLES:
      case GROUP_TYPE.OFFLINE_WORK:
      case GROUP_TYPE.DOCUMENTS:
      case GROUP_TYPE.CLOSURE:
      case GROUP_TYPE.ORDER_PRINTERS:
      case GROUP_TYPE.TAKEAWAY:
      case GROUP_TYPE.PAYMENTS:
      case GROUP_TYPE.LANGUAGE_CURRENCY:
      case GROUP_TYPE.ARTICLES:
      case GROUP_TYPE.ORDERS:
        const items$ = new Observable((observer) => {
          this.configurationService.groups$().subscribe((groups: any[]) => {
            this.p2rLocalStorageService.addToLocalStorage(GlobalVariable.N_GET_GROUP_PROP_STORE_REQUEST, [], 0);
            const grp = groups.find((g) => g.cod === group);
            const items = (grp && grp.items) || [];
            observer.next(items);
            observer.complete();
          });
        });
        return forkJoin([
          items$,
          selectedPv
            ? this.configurationService.getValues(group, selectedPv.codice)
            : this.apiService
              .get(
                `${this.apiService.apiPath}/configuration/store/getStoreInfo`
              )
              .pipe(
                mergeMap((data: any) => {
                  this.envService.storeData = data;
                  this.p2rLocalStorageService.addToLocalStorage(
                    GlobalVariable.SELECTED_PV_KEY_STORAGE,
                    [],
                    data.pv[0]
                  );
                  return this.configurationService.getValues(
                    group,
                    data.pv[0].codice
                  );
                })
              ),
        ]);
      default:
        //return this.configurationService.getGroups();
        let nOfRequest = this.p2rLocalStorageService.getFromLocalStorage(GlobalVariable.N_GET_GROUP_PROP_STORE_REQUEST, []);
        if (nOfRequest == undefined) {
          nOfRequest = 0;
        }
        if (nOfRequest < GlobalVariable.N_GET_GROUP_PROP_STORE_MAX_RETRY) {
          this.p2rLocalStorageService.addToLocalStorage(GlobalVariable.N_GET_GROUP_PROP_STORE_REQUEST, [], nOfRequest + 1);
          if (nOfRequest > 0) {
            this.waitOneSecond();
          }
          return this.configurationService.getGroups();
        } else {
          console.error("*** TOO MUCH REQUEST /group-prop-store ***");
          this.p2rLocalStorageService.addToLocalStorage(GlobalVariable.N_GET_GROUP_PROP_STORE_REQUEST, [], 0);
          this.showGetGroupPropStoreErrorMessage();
          return EMPTY;
        }
    }
  }

  async waitOneSecond() {
    await new Promise(f => setTimeout(f, GlobalVariable.N_GET_GROUP_PROP_STORE_THREESHOLD));
  }

  getImg(imgSrc) {
    if (typeof imgSrc === 'function') {
      imgSrc = imgSrc();
    }
    return assetUrl(imgSrc, this._assetsPath);
  }

  showGetGroupPropStoreErrorMessage() {
    let alertTitle = "Warning";
    let msg1 = "Page does not respond.";
    let msg2 = "Try to reload.";
    const lang = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;
    if (lang.includes("it") || lang.includes("IT")) {
      alertTitle = "Attenzione";
      msg1 = "La pagina non risponde.";
      msg2 = "Provare a ricaricare la pagina.";
    }
    this.p2rDialogService.open(P2RDialogComponent, {
      id: 'group-prop-store-error-alert',
      body: ConfigurationAlertComponent,
      hasHead: false,
      data: {
        title: alertTitle,
        messages: [msg1, msg2]
      },
      actions: []
    });
    this.ngxService.stopAll();
  }
}

@Injectable({
  providedIn: 'root',
})
export class ServicesResolver implements Resolve<any> {
  constructor(
    private configurationService: ConfigurationService,
    private p2rLocalStorageService: P2RLocalStorageService,
    private apiService: P2RApiService,
    private envService: P2REnvironmentService
  ) { }
  resolve(route: any): any {
    const group = route.data && route.data.group;

    const selectedPv = this.p2rLocalStorageService.getFromLocalStorage(
      GlobalVariable.SELECTED_PV_KEY_STORAGE,
      []
    );

    console.log('SERVICE RESOLVE: group ' + group);

    switch (group) {
      case SERVICE_TYPE.FCIC:
      case SERVICE_TYPE.MYCUSTOM:
      case SERVICE_TYPE.CUSTOM4U:
        const items$ = new Observable((observer) => {
          this.configurationService.groups$().subscribe((groups: any[]) => {
            const grp = groups.find((g) => g.cod === group);
            const items = (grp && grp.items) || [];
            observer.next(items);
            observer.complete();
          });
        });
        return forkJoin([
          items$,
          selectedPv
            ? this.configurationService.getValues(group, selectedPv.codice)
            : this.apiService
              .get(
                `${this.apiService.apiPath}/configuration/store/getStoreInfo`
              )
              .pipe(
                mergeMap((data: any) => {
                  this.envService.storeData = data;
                  this.p2rLocalStorageService.addToLocalStorage(
                    GlobalVariable.SELECTED_PV_KEY_STORAGE,
                    [],
                    data.pv[0]
                  );
                  return this.configurationService.getValues(
                    group,
                    data.pv[0].codice
                  );
                })
              ),
        ]);
      default:
        return this.configurationService.getGroups({ type: CONFIGURATION_TYPE.SERVICE });
    }
  }
}

/**
 * Custom configuration resolver used only by "LAVORO OFFLINE" section.
 */
@Injectable({
  providedIn: 'root',
})
export class ConfigurationResolverOfflineWork implements Resolve<any> {
  constructor(
    private configurationService: ConfigurationService,
    private p2rLocalStorageService: P2RLocalStorageService,
    private apiService: P2RApiService,
    private envService: P2REnvironmentService,
    private ngxService: NgxUiLoaderService,
    private p2rTranslateService: P2RTranslateService,
    private translateService: TranslateService,
  ) { }
  resolve(route: any): any {
    const group = route.data && route.data.group;

    const selectedPv = this.p2rLocalStorageService.getFromLocalStorage(
      GlobalVariable.SELECTED_PV_KEY_STORAGE,
      []
    );

    const cashTrans = this.p2rTranslateService.get(this.translateService.instant('cashdesk'));

    console.log('CONFIGURATION OFFLINE_WORK RESOLVE: group ' + group);

    const items$ = new Observable((observer) => {
      this.configurationService.groups$().subscribe((groups: any[]) => {
        const grp = groups.find((g) => g.cod === group);
        const items = (grp && grp.items) || [];
        observer.next(items);
        observer.complete();
      });
    });
    return forkJoin([
      items$,
      selectedPv
        ? this.getValues(group, selectedPv.codice)
        : this.apiService
          .get(`${this.apiService.apiPath}/configuration/store/getStoreInfo`)
          .pipe(
            mergeMap((data: any) => {
              this.envService.storeData = data;
              this.p2rLocalStorageService.addToLocalStorage(
                GlobalVariable.SELECTED_PV_KEY_STORAGE,
                [],
                data.pv[0]
              );
              return this.getValues(group, data.pv[0].codice);
            })
          ),
      this.apiService.get(`${this.apiService.apiPath}/settings/SFFOOD`,
        {
          center_dett: P2R_CENTER_DETT.VISUALIZZA,
          source: CONFIGURATION_SOURCE.SFFOOD,
          offset: `all`
        },
        {
          microservice: 'mssettings'
        }
      ).pipe(
        map((sffoods: any[]) => sffoods
          .map(sffood => ({
            ...sffood,
            key: sffood.XSF04,
            label: sffood.XSFFOOD05 || cashTrans + ' ' + sffood.XSF04
          }))
          .reverse()
        )
      )
    ]);
  }

  public getValues(groupType: GROUP_TYPE, store) {
    this.ngxService.start();
    const groupKey = this.configurationService.allGroups.find(
      (g) => g.cod === groupType
    ).key;
    return this.apiService
      .get(this.apiService.apiCorePath + '/tmpstore/operatore/findByStore', {
        Store: store,
        Group: groupKey,
        Extend: 1,
      })
      .pipe(
        catchError((error) => of(error)),
        map((v: any) => {
          this.ngxService.stop();
          if (v.status !== undefined && v.status !== 200) {
            return v.error;
          } else {
            return v;
          }
        })
      );
  }
}

/**
 * Custom configuration resolver used only by "DOCUMENTI" section.
 */
@Injectable({
  providedIn: 'root',
})
export class ConfigurationResolverDocuments implements Resolve<any> {
  constructor(
    private configurationService: ConfigurationService,
    private p2rLocalStorageService: P2RLocalStorageService,
    private apiService: P2RApiService,
    private envService: P2REnvironmentService,
    private ngxService: NgxUiLoaderService
  ) { }
  resolve(route: any): any {
    const group = route.data && route.data.group;

    const selectedPv = this.p2rLocalStorageService.getFromLocalStorage(
      GlobalVariable.SELECTED_PV_KEY_STORAGE,
      []
    );

    console.log('CONFIGURATION DOCUMENTS RESOLVE: group ' + group);

    const items$ = new Observable((observer) => {
      this.configurationService.groups$().subscribe((groups: any[]) => {
        const grp = groups.find((g) => g.cod === group);
        const items = (grp && grp.items) || [];
        observer.next(items);
        observer.complete();
      });
    });
    return forkJoin([
      items$,
      selectedPv
        ? this.getValues(group, selectedPv.codice)
        : this.apiService
          .get(`${this.apiService.apiPath}/configuration/store/getStoreInfo`)
          .pipe(
            mergeMap((data: any) => {
              this.envService.storeData = data;
              this.p2rLocalStorageService.addToLocalStorage(
                GlobalVariable.SELECTED_PV_KEY_STORAGE,
                [],
                data.pv[0]
              );
              return this.getValues(group, data.pv[0].codice);
            })
          ),
    ]);
  }

  public getValues(groupType: GROUP_TYPE, store) {
    this.ngxService.start();
    const groupKey = this.configurationService.allGroups.find(
      (g) => g.cod === groupType
    ).key;
    return this.apiService
      .get(this.apiService.apiCorePath + '/tmpstore/operatore/findByStore', {
        Store: store,
        Group: groupKey,
        Extend: 1,
      })
      .pipe(
        catchError((error) => of(error)),
        map((v: any) => {
          this.ngxService.stop();
          let apiValues = {} as any;
          apiValues.tmpl_store = {};
          if (v.status !== undefined && v.status !== 200) {
            const apiValues = {} as any;
            apiValues.tmpl_store = {};
            apiValues.tmpl_store.jsontemplate = JSON.stringify(
              this.configurationService.apiValues[groupType].values
            );
            apiValues.tmpl_store.codice = 0;
            apiValues.tmpl_store.key_group = this.configurationService.apiValues[groupType].key_group;
            if (v.error.fatture !== undefined) {
              apiValues.fatture = v.error.fatture;
            }
            return apiValues;
          } else {

            const values = JSON.parse(v.tmpl_store.jsontemplate);
            const defaultValues = this.configurationService.apiValues[groupType].values

            const merged = {
              ...defaultValues,
              ...values
            }

            v.tmpl_store.jsontemplate = JSON.stringify(merged)
            apiValues = v;
            return apiValues;
          }
        })
      );
  }
}

/**
 * Custom configuration resolver used only by "SERVICES" section.
 */
@Injectable({
  providedIn: 'root',
})
export class ConfigurationResolverServices implements Resolve<any> {
  constructor(
    private configurationService: ConfigurationService,
    private p2rLocalStorageService: P2RLocalStorageService,
    private apiService: P2RApiService,
    private envService: P2REnvironmentService,
    private ngxService: NgxUiLoaderService
  ) { }
  resolve(route: any): any {
    const group = route.data && route.data.group;

    const selectedPv = this.p2rLocalStorageService.getFromLocalStorage(
      GlobalVariable.SELECTED_PV_KEY_STORAGE,
      []
    );

    console.log('CONFIGURATION SERVICES RESOLVE: group ' + group);

    const items$ = new Observable((observer) => {
      this.configurationService.groups$().subscribe((groups: any[]) => {
        const grp = groups.find((g) => g.cod === group);
        const items = (grp && grp.items) || [];
        observer.next(items);
        observer.complete();
      });
    });
    return forkJoin([
      items$,
      selectedPv
        ? this.getValues(selectedPv.codice)
        : this.apiService
          .get(`${this.apiService.apiPath}/configuration/store/getStoreInfo`)
          .pipe(
            mergeMap((data: any) => {
              this.envService.storeData = data;
              this.p2rLocalStorageService.addToLocalStorage(
                GlobalVariable.SELECTED_PV_KEY_STORAGE,
                [],
                data.pv[0]
              );
              return this.getValues(data.pv[0].codice);
            })
          ),
    ]);
  }

  public getValues(store) {
    return this.apiService
      .get(this.apiService.apiCorePath + '/fatture/getcredenziali', {
        Store: store,
      })
      .pipe(
        map((v: any) => {
          return v;
        })
      );
  }
}

/**
 * Custom configuration resolver used only by "Generali" section.
 */

@Injectable({
  providedIn: 'root',
})
export class ConfigurationResolverGenerali implements Resolve<any> {
  constructor(
    private configurationService: ConfigurationService,
    private p2rLocalStorageService: P2RLocalStorageService,
    private apiService: P2RApiService,
    private envService: P2REnvironmentService,
    private ngxService: NgxUiLoaderService
  ) { }
  resolve(route: any): any {
    const group = route.data && route.data.group;

    const selectedPv = this.p2rLocalStorageService.getFromLocalStorage(
      GlobalVariable.SELECTED_PV_KEY_STORAGE,
      []
    );

    console.log('CONFIGURATION DOCUMENTS RESOLVE: group ' + group);

    const items$ = new Observable((observer) => {
      this.configurationService.groups$().subscribe((groups: any[]) => {
        this.p2rLocalStorageService.addToLocalStorage(GlobalVariable.N_GET_GROUP_PROP_STORE_REQUEST, [], 0);
        const grp = groups.find((g) => g.cod === group);
        const items = (grp && grp.items) || [];
        observer.next(items);
        observer.complete();
      });
    });

    const listini$ = this.apiService.get(`${this.apiService.apiPath}/settings/LSTPERIODO`,
      {
        center_dett: P2R_CENTER_DETT.VISUALIZZA,
        source: CONFIGURATION_SOURCE.ART,
        offset: `all`
      },
      {
        microservice: 'mssettings'
      }
    ).pipe(
      map((lsts: any[]) => lsts
        .map(lst => ({
          ...lst,
          key: lst.XLSTPERIODO04,
          label: lst.XLSTPERIODO03
        }))
        .reverse()
      )
    )

    return forkJoin([
      items$,
      selectedPv
        ? this.getValues(group, selectedPv.codice)
        : this.apiService
          .get(`${this.apiService.apiPath}/configuration/store/getStoreInfo`)
          .pipe(
            mergeMap((data: any) => {
              this.envService.storeData = data;
              this.p2rLocalStorageService.addToLocalStorage(
                GlobalVariable.SELECTED_PV_KEY_STORAGE,
                [],
                data.pv[0]
              );
              return this.getValues(group, data.pv[0].codice);
            })
          ),
      listini$
    ]);
  }

  public getValues(groupType: GROUP_TYPE, store) {
    this.ngxService.start();
    const groupKey = this.configurationService.allGroups.find(
      (g) => g.cod === groupType
    ).key;
    return this.apiService
      .get(this.apiService.apiCorePath + '/tmpstore/operatore/findByStore', {
        Store: store,
        Group: groupKey,
        Extend: 1,
      })
      .pipe(
        catchError((error) => of(error)),
        map((v: any) => {
          this.ngxService.stop();
          const apiValues = {} as any;
          apiValues.tmpl_store = {};
          if (v.status !== undefined && v.status !== 200) {
            const apiValues = {} as any;
            apiValues.tmpl_store = {};
            apiValues.tmpl_store.jsontemplate = JSON.stringify(
              this.configurationService.apiValues[groupType].values
            );
            apiValues.tmpl_store.codice = 0;
            apiValues.tmpl_store.key_group =
              this.configurationService.apiValues[groupType].key_group;
            return apiValues;
          } else {

            const values = JSON.parse(v.jsontemplate);
            const defaultValues = this.configurationService.apiValues[groupType].values

            const merged = {
              ...defaultValues,
              ...values
            }

            v.jsontemplate = JSON.stringify(merged)

            apiValues.tmpl_store = v;
            return apiValues;
          }
        })
      );
  }
}

@Injectable({
  providedIn: 'root',
})
export class ConfigurationResolverClosure implements Resolve<any> {
  constructor(
    private configurationService: ConfigurationService,
    private p2rLocalStorageService: P2RLocalStorageService,
    private apiService: P2RApiService,
    private envService: P2REnvironmentService,
    private p2rTranslateService: P2RTranslateService,
    private translateService: TranslateService,
  ) { }
  resolve(route: any): any {
    const group = route.data && route.data.group;

    const selectedPv = this.p2rLocalStorageService.getFromLocalStorage(
      GlobalVariable.SELECTED_PV_KEY_STORAGE,
      []
    );

    const cashTrans = this.p2rTranslateService.get(this.translateService.instant('cashdesk'));

    const items$ = new Observable((observer) => {
      this.configurationService.groups$().subscribe((groups: any[]) => {
        const grp = groups.find((g) => g.cod === group);
        const items = (grp && grp.items) || [];
        observer.next(items);
        observer.complete();
      });
    });
    return forkJoin([
      items$,
      selectedPv
        ? this.configurationService.getValues(group, selectedPv.codice)
        : this.apiService
          .get(
            `${this.apiService.apiPath}/configuration/store/getStoreInfo`
          )
          .pipe(
            mergeMap((data: any) => {
              this.envService.storeData = data;
              this.p2rLocalStorageService.addToLocalStorage(
                GlobalVariable.SELECTED_PV_KEY_STORAGE,
                [],
                data.pv[0]
              );
              return this.configurationService.getValues(
                group,
                data.pv[0].codice
              );
            })
          ),
      this.apiService.get(`${this.apiService.apiPath}/settings/SFFOOD`,
        {
          center_dett: P2R_CENTER_DETT.VISUALIZZA,
          source: CONFIGURATION_SOURCE.SFFOOD,
          offset: `all`
        },
        {
          microservice: 'mssettings'
        }
      ).pipe(
        map((sffoods: any[]) => sffoods
          .map(sffood => ({
            ...sffood,
            key: sffood.XSF04,
            label: sffood.XSFFOOD05 || cashTrans + ' ' + sffood.XSF04
          }))
          .reverse()
        )
      )
    ]);
  }
}
@Injectable({
  providedIn: 'root',
})
export class ConfigurationResolverTakeaway implements Resolve<any> {
  constructor(
    private configurationService: ConfigurationService,
    private p2rLocalStorageService: P2RLocalStorageService,
    private apiService: P2RApiService,
    private envService: P2REnvironmentService,
    private p2rTranslateService: P2RTranslateService,
    private translateService: TranslateService,
  ) { }
  resolve(route: any): any {
    const group = route.data && route.data.group;

    const selectedPv = this.p2rLocalStorageService.getFromLocalStorage(
      GlobalVariable.SELECTED_PV_KEY_STORAGE,
      []
    );

    const cashTrans = this.p2rTranslateService.get(this.translateService.instant('cashdesk'));

    const items$ = new Observable((observer) => {
      this.configurationService.groups$().subscribe((groups: any[]) => {
        const grp = groups.find((g) => g.cod === group);
        const items = (grp && grp.items) || [];
        observer.next(items);
        observer.complete();
      });
    });
    return forkJoin([
      items$,
      selectedPv
        ? this.configurationService.getValues(group, selectedPv.codice)
        : this.apiService
          .get(
            `${this.apiService.apiPath}/configuration/store/getStoreInfo`
          )
          .pipe(
            mergeMap((data: any) => {
              this.envService.storeData = data;
              this.p2rLocalStorageService.addToLocalStorage(
                GlobalVariable.SELECTED_PV_KEY_STORAGE,
                [],
                data.pv[0]
              );
              return this.configurationService.getValues(
                group,
                data.pv[0].codice
              );
            })
          ),
      this.apiService.get(`${this.apiService.apiPath}/settings/SFFOOD`,
        {
          center_dett: P2R_CENTER_DETT.VISUALIZZA,
          source: CONFIGURATION_SOURCE.SFFOOD,
          offset: `all`
        },
        {
          microservice: 'mssettings'
        }
      ).pipe(
        map((sffoods: any[]) => sffoods
          .map(sffood => ({
            ...sffood,
            key: sffood.XSF04,
            label: sffood.XSFFOOD05 || cashTrans + ' ' + sffood.XSF04
          }))
          .reverse()
        )
      )
    ]);
  }
}
@Injectable({
  providedIn: 'root',
})
export class ConfigurationResolverPayments implements Resolve<any> {
  constructor(
    private configurationService: ConfigurationService,
    private p2rLocalStorageService: P2RLocalStorageService,
    private apiService: P2RApiService,
    private envService: P2REnvironmentService,
    private p2rTranslateService: P2RTranslateService,
    private translateService: TranslateService,
  ) { }
  resolve(route: any): any {
    const group = route.data && route.data.group;

    const selectedPv = this.p2rLocalStorageService.getFromLocalStorage(
      GlobalVariable.SELECTED_PV_KEY_STORAGE,
      []
    );

    const cashTrans = this.p2rTranslateService.get(this.translateService.instant('cashdesk'));

    const items$ = new Observable((observer) => {
      this.configurationService.groups$().subscribe((groups: any[]) => {
        const grp = groups.find((g) => g.cod === group);
        const items = (grp && grp.items) || [];
        observer.next(items);
        observer.complete();
      });
    });
    return forkJoin([
      items$,
      selectedPv
        ? this.configurationService.getValues(group, selectedPv.codice)
        : this.apiService
          .get(
            `${this.apiService.apiPath}/configuration/store/getStoreInfo`
          )
          .pipe(
            mergeMap((data: any) => {
              this.envService.storeData = data;
              this.p2rLocalStorageService.addToLocalStorage(
                GlobalVariable.SELECTED_PV_KEY_STORAGE,
                [],
                data.pv[0]
              );
              return this.configurationService.getValues(
                group,
                data.pv[0].codice
              );
            })
          ),
      this.apiService.get(`${this.apiService.apiPath}/configuration/CCARDFOOD`,
        {
          center_dett: P2R_CENTER_DETT.ALL_VIEWS,
          source: CONFIGURATION_SOURCE.PREFERENCES,
          offset: `all`,
          // $filter: `XCCARD16 eq 1`
        },
        // {
        //   microservice: 'mssettings'
        // }
      ).pipe(
        map((ccards: any[]) => {
          ccards.reverse()
          return ccards
        })
      ),
      this.apiService.get(`${this.apiService.apiPath}/settings/SFFOOD`,
        {
          center_dett: P2R_CENTER_DETT.VISUALIZZA,
          source: CONFIGURATION_SOURCE.SFFOOD,
          offset: `all`
        },
        {
          microservice: 'mssettings'
        }
      ).pipe(
        map((sffoods: any[]) => sffoods
          .map(sffood => ({
            ...sffood,
            key: sffood.XSF04,
            label: sffood.XSFFOOD05 || cashTrans + ' ' + sffood.XSF04
          }))
          .reverse()
        )
      )
    ]);
  }
}
@Injectable({
  providedIn: 'root',
})
export class ImportExportResolver implements Resolve<any> {
  constructor(
    private configurationService: ConfigurationService
  ) { }
  resolve(route: any): any {
    return this.configurationService.getGroups({ type: CONFIGURATION_TYPE.IMPORT_EXPORT });
  }
}
@Injectable({
  providedIn: 'root',
})
export class ConfigurationResolverLanguageAndCurrency implements Resolve<any> {
  constructor(
    private configurationService: ConfigurationService,
    private p2rLocalStorageService: P2RLocalStorageService,
    private apiService: P2RApiService,
    private envService: P2REnvironmentService,
  ) { }
  resolve(route: any): any {
    const group = route.data && route.data.group;

    const selectedPv = this.p2rLocalStorageService.getFromLocalStorage(
      GlobalVariable.SELECTED_PV_KEY_STORAGE,
      []
    );

    const items$ = new Observable((observer) => {
      this.configurationService.groups$().subscribe((groups: any[]) => {
        const grp = groups.find((g) => g.cod === group);
        const items = (grp && grp.items) || [];
        observer.next(items);
        observer.complete();
      });
    });
    return forkJoin([
      items$,
      selectedPv
        ? this.configurationService.getValues(group, selectedPv.codice)
        : this.apiService
          .get(
            `${this.apiService.apiPath}/configuration/store/getStoreInfo`
          )
          .pipe(
            mergeMap((data: any) => {
              this.envService.storeData = data;
              this.p2rLocalStorageService.addToLocalStorage(
                GlobalVariable.SELECTED_PV_KEY_STORAGE,
                [],
                data.pv[0]
              );
              return this.configurationService.getValues(
                group,
                data.pv[0].codice
              );
            })
          )
    ]);
  }
}
