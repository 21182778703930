<div class="d-flex flex-grow-1 flex-column h-100">
    <configuration-header [header]="header"></configuration-header>
    <div
        class="d-flex flex-grow-1 flex-column h-100"
        style="overflow: hidden;"
    >
        <ng-container *ngIf="showSearch">
            <div
                class="d-flex background-grey p-2"
                style="position: relative;"
            >
                <input
                    class="aspetto-menu-input d-flex flex-grow-1 font-r_r fs20 lh25 pl-3 w-100"
                    placeholder="{{ 'search' | translate }}"
                    #searchInput
                    type="text"
                    customVirtualKeyboard
                    [(ngModel)]="search"
                    (ngModelChange)="onSearch()"
                >
                <ng-container *ngIf="search.length">
                    <img
                        [src]="configurationService.getImg(images.clean)"
                        class="icon configuration-search-append-icon"
                        width="40"
                        height="40"
                        (click)="onClean()"
                    >
                </ng-container>
                <ng-container *ngIf="!search.length">
                    <img
                        [src]="configurationService.getImg(images.search)"
                        class="icon configuration-search-append-icon"
                        width="40"
                        height="40"
                        (click)="onSearch()"
                    >
                </ng-container>
            </div>
        </ng-container>
        <div
            class="d-flex flex-grow-1"
            style="height: calc(100% - 112px);"
        >
            <div
                class="left-half position-relative"
                style="overflow: auto;"
                [ngStyle]="{'background-color': (isStoreListExpanded) ? '#EFEFEF' : ''}"
            >
                <div
                    class="h-100"
                    style="overflow: auto;"
                >
                    <div
                        class="w-100"
                        style="position: relative;"
                        *ngIf="isMultiStore && ![CONFIGURATION_TYPE.SERVICE].includes(configurationService.CONFIGURATION_TYPE)"
                    >
                        <!-- Pannello per la selezione del negozio -->
                        <configuration-store-panel (expandEvent)="expandedStoreList($event)"></configuration-store-panel>
                    </div>
                    <ul
                        class="properties"
                        [class.d-none]="isStoreListExpanded"
                    >
                        <li
                            #properties
                            *ngFor="let property of properties"
                            [ngStyle]="{'display': (property.label != undefined) ? 'visible' : 'none'}"
                            [class.selected]="property.isSelected"
                            (click)="onSelectProperty(property)"
                        >
                            <div class="position-relative">
                                <div
                                    *ngIf="property.isModified || (property.cod == 'PAYMENTS' && (paymentsService.paymentLocalList.length || paymentsService.paymentDeletedList.length))"
                                    class="group-modified"
                                ></div>
                                <div class="label-uppercase">{{property.label}}</div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div>
                    <div
                        class="position-absolute w-100"
                        style="bottom: 0;"
                    >
                        <!-- Pannello con i pulsanti Salva e Ripristina -->
                        <configuration-save-panel></configuration-save-panel>
                    </div>
                </div>
            </div>
            <div
                class="right-half"
                style="overflow: auto;"
            >
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>
