import { Component, OnInit } from '@angular/core';
import { ConfigurationService } from '../../configuration.service';

@Component({
  selector: 'configuration-exportAspect',
  templateUrl: './exportAspect.component.html',
  styleUrls: ['./exportAspect.component.scss']
})
export class ExportAspectComponent implements OnInit {

  constructor(
    public configurationService: ConfigurationService
  ) { }

  ngOnInit() {
  }

}
