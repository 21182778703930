import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { P2RDialogComponent, P2RDialogService, P2RLocalStorageService, P2RTranslateService, P2R_FIELD_TYPE } from '@p2r/shared';
import { TranslateService } from '@ngx-translate/core';
import { CUSTOMIZE_TYPE } from './customize-dialog.model';
import { ConfigurationService } from '../configuration/configuration.service';
import { GlobalVariable } from '../global';
import { P2REnvironmentService } from '@p2r/env';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConfigurationAlertComponent } from '../configuration/alert/alert.component';
import { PaymentsService } from '../configuration/payments/payments.service';

@Component({
  selector: 'configuration-customize-dialog',
  templateUrl: './customize-dialog.component.html',
  styleUrls: ['./customize-dialog.component.scss']
})
export class CustomizeDialogComponent implements OnInit {

  public _data: any;
  private _opt: any;
  private _items: any[];
  private _printerLanguage: any[];
  private _defaultCurrency: any[];
  private _origItems: any[];
  private _apiValues: any;
  private _fieldKey: any;
  public _hasSearch: boolean;
  private _hasSelectAll: boolean;
  public customizeType: CUSTOMIZE_TYPE;
  public CUSTOMIZE_TYPE = CUSTOMIZE_TYPE;
  public P2R_FIELD_TYPE = P2R_FIELD_TYPE;

  public itemName: string;
  public itemPlaceholder: string;
  public actions: any[];
  public selectAllAction: any[];

  constructor(
    private p2rTranslateService: P2RTranslateService,
    private translateService: TranslateService,
    private p2rDialogService: P2RDialogService,
    public configurationService: ConfigurationService,
    private p2rLocalStorageService: P2RLocalStorageService,
    private p2rEnvironmentService: P2REnvironmentService,
    private loaderService: NgxUiLoaderService,
    private cdRef: ChangeDetectorRef,
    public paymentsService: PaymentsService
  ) {
    this.itemName = '';
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    if(this.customizeType == CUSTOMIZE_TYPE.ARTICLE_MOBILE_SECOND_ROW_ON_BUTTON) {
      // Lo faccio in questo modo così che venga gestito il tag <br> presente nella stringa KU_SECOND_ROW_ON_BUTTON.STOCK.NOTE
      document.getElementById('stock_note_id').innerHTML = this.p2rTranslateService.get(this.translateService.instant("KU_SECOND_ROW_ON_BUTTON.STOCK.NOTE"));
      document.getElementById('stock_note_id').className = 'font-r_r fs16 lh16 c10 itm-note txt-color-grey';
      this.setSecondRowPreviewButton(this._data.secondRowSelected);
    }
  }

  @Input() set data(data: any) {
    this._data = data;
    this._hasSearch = data.hasSearch;
    this._hasSelectAll = data.hasSelectAll ? data.hasSelectAll : false;
    this.customizeType = data.customizeType;
    this._items = this._origItems = data.items;
    this._printerLanguage = data.items;
    this._defaultCurrency = data.items;

    this._opt = data.opt || {};

    this.data.modifiedKeys = this.data.modifiedKeys || [];

    /*
        const customActions = this.customizeXItemService.getCustomActions({
          fieldKey: this._fieldKey,
          items: this._items,
          form: this.form,
          apiValues: data.apiValues,
          dimension: data.multistoreDim
        });*/

    switch (this.customizeType) {
      case CUSTOMIZE_TYPE.SELECT_STORE:
        this.itemPlaceholder = this.p2rTranslateService.get(this.translateService.instant('search_store'));
        break;
      case CUSTOMIZE_TYPE.SELECT_CASHDESK:
        this.itemPlaceholder = this.p2rTranslateService.get(this.translateService.instant('search_cashdesk'));
        break;
      case CUSTOMIZE_TYPE.LINK_DEP_FCIC:
        this.itemPlaceholder = this.p2rTranslateService.get(this.translateService.instant('search_dep'));
        break;
      case CUSTOMIZE_TYPE.LINK_CAT_FCIC:
        this.itemPlaceholder = this.p2rTranslateService.get(this.translateService.instant('search_cat_FCIC'));
        this._items = this._items.sort((a, b) => a.value ? -1 : 1)
        break;
      case CUSTOMIZE_TYPE.PRINT_STATISTICS:
        this.itemPlaceholder = this.p2rTranslateService.get(this.translateService.instant('search_statistic'));
        break;
      case CUSTOMIZE_TYPE.ASK_PASSWORD_FOR_RESERVED_OPERATIONS:
        this.itemPlaceholder = this.p2rTranslateService.get(this.translateService.instant('search_operations'));
        break;
      case CUSTOMIZE_TYPE.CUSTOMIZED_PAYMENT:
        this.itemPlaceholder = this.p2rTranslateService.get(this.translateService.instant('customized_payment'));
        break;
      case CUSTOMIZE_TYPE.METHOD_PAYMENT:
        this.itemPlaceholder = this.p2rTranslateService.get(this.translateService.instant('method_payment'));
        break;
      case CUSTOMIZE_TYPE.PRINTER_LANGUAGE:
        this.itemPlaceholder = this.p2rTranslateService.get(this.translateService.instant('method_payment'));
        break;
      case CUSTOMIZE_TYPE.DEFAULT_CURRENCY:
        this.itemPlaceholder = this.p2rTranslateService.get(this.translateService.instant('method_payment'));
        break;
        case CUSTOMIZE_TYPE.ARTICLE_MOBILE_SECOND_ROW_ON_BUTTON:
        this.itemPlaceholder = '';//this.p2rTranslateService.get(this.translateService.instant('method_payment'));
        break;
      case CUSTOMIZE_TYPE.CASSE_VASSOIO:
        this.itemPlaceholder = this.p2rTranslateService.get(this.translateService.instant('search_cashdesk'));
        break;
      default:
        this.itemPlaceholder = this.p2rTranslateService.get(this.translateService.instant('search_store'));
        break;
    }

    this._apiValues = data.apiValues;

    const customActions = [];

    this.actions = [
      ...customActions,
      {
        label: this.p2rTranslateService.get(this.translateService.instant('close')),
        click: this.data.close,
        shown: () => [
          CUSTOMIZE_TYPE.LICENSE_DETAIL,
          CUSTOMIZE_TYPE.LISTINO_PERIOD,
          CUSTOMIZE_TYPE.METHOD_PAYMENT,
          CUSTOMIZE_TYPE.CUSTOMIZED_PAYMENT,
          CUSTOMIZE_TYPE.PRINTER_LANGUAGE,
          CUSTOMIZE_TYPE.DEFAULT_CURRENCY,
          CUSTOMIZE_TYPE.ARTICLE_MOBILE_SECOND_ROW_ON_BUTTON
        ].includes(this.customizeType) ? false : true
      }
    ];

    this.selectAllAction = [
      {
        label: (this.customizeType == CUSTOMIZE_TYPE.CASSE_VASSOIO ? this.p2rTranslateService.get(this.translateService.instant('deactivate_all_casse')) : this.p2rTranslateService.get(this.translateService.instant('deactivate_all'))),
        click: () => {
          this.data.items.forEach(item => {
            if (item.flag) {
              if(this.customizeType == CUSTOMIZE_TYPE.CASSE_VASSOIO) {
                this.selectCassaVassoio(item);
              } else {
                this.selectStatistic(item);
              }
            }
          });
        },
        shown: () => {
          return this.checkIfItemsAllSelected(this.data.items);
        }
      },
      {
        label: (this.customizeType == CUSTOMIZE_TYPE.CASSE_VASSOIO ? this.p2rTranslateService.get(this.translateService.instant('activate_all_casse')) : this.p2rTranslateService.get(this.translateService.instant('activate_all'))),
        click: () => {
          this.data.items.forEach(item => {
            if (!item.flag) {
              if(this.customizeType == CUSTOMIZE_TYPE.CASSE_VASSOIO) {
                this.selectCassaVassoio(item);
              } else {
                this.selectStatistic(item);
              }
            }
          });
        },
        shown: () => {
          return !this.checkIfItemsAllSelected(this.data.items);
        }
      }
    ];
  }

  get data(): any {
    return this._data;
  }

  get items(): any[] {
    return this._items;
  }
  get hasSearch(): boolean {
    return this._hasSearch;
  }

  get hasSelectAll(): boolean {
    return this._hasSelectAll;
  }

  private checkIfItemsAllSelected(list) {
    for (let i = 0; i < list.length; i++) {
      const app = list[i];
      if (!app.flag) {
        return false;
      }
    }
    return true;
  }

  public itemSearch(event?: Event) {
    //this._items = this._origItems.filter(f => this.itemName && this.itemName.length ? f.label.toLocaleLowerCase().startsWith(this.itemName.toLocaleLowerCase()) : true);
    let itemsTemp = Array();
    this._origItems.forEach(oItem => {
      const splittedStr = oItem.label.split(" ");
      splittedStr.forEach(str => {
        if (this.itemName && (this.itemName.length > 0) && str.toLocaleLowerCase().startsWith(this.itemName)) {
          itemsTemp.push(oItem);
          return true;
        }
      });
    });
    if (itemsTemp.length > 0) {
      this._items = itemsTemp;
    } else {
      this._items = this._origItems;
    }
  }

  public clearItemSearch() {
    this.itemName = '';
    this.itemSearch();
  }


  public ngOnDestroy() {
  }

  public selectStore(code) {
    this._items = this._items.map(s => ({
      ...s,
      value: code === s.key ? 1 : 0
    }))

    const selectedPV = (this.p2rEnvironmentService.storeData.pv || []).find(p => p.codice === code);
    this.p2rLocalStorageService.addToLocalStorage(GlobalVariable.SELECTED_PV_KEY_STORAGE, [], selectedPV);
    this.configurationService.changeStoreCompleteSubject.next(true);
  }

  public selectCash(code) {
    this._items = this._items.map(s => ({
      ...s,
      value: code === s.key ? 1 : 0
    }))

    this.data.close(code);
  }

  public linkDepFCIC(item) {

    this.data.close();

    // tslint:disable-next-line: triple-equals
    const linkedDep = this._opt.linkDEPCAT.find(ldc => ldc.XLINKDEPCATFCICFOOD04 == item.key);

    const dialog = this.p2rDialogService.open(P2RDialogComponent, {
      id: 'customize-dialog',
      body: CustomizeDialogComponent,
      hideCloseButton: true,
      title: `${this.p2rTranslateService.get(this.translateService.instant('choose_category_by_department'))}: ${item.label}`,
      titleClasses: 'text-uppercase font-r_r fs24 lh21 c11 mt-2',
      size: 'lg',
      /*      options: {
        id: 'customize-for-store-opt',
        titleClasses: 'customize-for-store-height'
      },*/
      data: {
        hasSearch: true,
        customizeType: CUSTOMIZE_TYPE.LINK_CAT_FCIC,
        items: (this._opt.categorieFCIC || []).map(r => ({
          ...r,
          key: r.id,
          label: r.descrizione,
          // tslint:disable-next-line: triple-equals
          value: this._opt.linkDEPCAT.find(ldc => ldc.XLINKDEPCATFCICFOOD04 == item.key && ldc.XLINKDEPCATFCICFOOD05 == r.id) ? 1 : 0,
          data: {
            sigla: r.sigla
          }
        })),
        opt: {
          selectedDep: item,
          linkedDep
        },
        close: () => {
          dialog.dismiss();
          this.configurationService.associateDEPCATFCIC();
        },
      }
    });
  }

  public unlinkDepFCIC(item) {
    // tslint:disable-next-line: triple-equals
    const unlink = () => {
      const linkDepCat = this._opt.linkDEPCAT.find(ld => ld.XLINKDEPCATFCICFOOD04 == item.key)

      this.configurationService.unlinkDepCatFCIC({
        linkDEPCAT: linkDepCat
      }).subscribe(d => {
        // this.data.close();
        this._items = this._items.map(s => ({
          ...s,
          value: item.key === s.key ? 0 : s.value
        }))

        const linkDepCatIndex = this._opt.linkDEPCAT.findIndex(ld => ld.XLINKDEPCATFCICFOOD04 == item.key)
        this._opt.linkDEPCAT.splice(linkDepCatIndex, 1);

        if (!this.cdRef[`destroyed`]) {
          this.cdRef.detectChanges();
        }
      })
    }

    const alert = this.p2rDialogService.open(P2RDialogComponent, {
      id: 'unlink-dep-fcic-dialog',
      body: ConfigurationAlertComponent,
      hasHead: false,
      data: {
        imgSrc: this.configurationService.getImg('icons/attenzione.svg'),
        title: this.p2rTranslateService.get(this.translateService.instant('attention')),
        messages: [
          this.p2rTranslateService.get(this.translateService.instant('if_you_deactivate_the_association_between_this_department_and_the_FCIC_categories_the_articles_belonging_to_the_department_will_no_longer_be_synchronized')),
          this.p2rTranslateService.get(this.translateService.instant('are_you_sure_you_want_to_proceed_or_do_you_want_to_cancel_the_operation'))
        ]
      },
      actions: [
        {
          label: this.p2rTranslateService.get(this.translateService.instant('proceed')),
          click: () => {
            unlink();
          },
          classes: 'btn rounded-pill text-uppercase new-alert-button',
          data: {
            remove: true
          },
          params: {
            close: true
          }
        },
        {
          label: this.p2rTranslateService.get(this.translateService.instant('dialog_cancel')),
          click: () => {
            alert.dismiss();
          },
          classes: 'btn rounded-pill text-uppercase new-alert-button',
          params: {
          }
        }
      ]
    });
  }

  public linkCatFCIC(item) {

    this._items = this._items.map(s => ({
      ...s,
      value: item.key === s.key ? 1 : 0
    }))

    this.configurationService.linkDepCatFCIC({
      rifDEP: this._opt.selectedDep.XDEPFOOD03,
      objCATFCIC: item,
      linkDEPCAT: this._opt.linkedDep
    }).subscribe(d => {
      this.data.close();
    })
  }

  public getLinkedCATDescrizione(item) {
    // tslint:disable-next-line: triple-equals
    const found = this._opt.categorieFCIC.find(c => (this._opt.linkDEPCAT.find(ra => ra.XLINKDEPCATFCICFOOD04 == item.key) || {}).XLINKDEPCATFCICFOOD05 == c.id);

    return found && found.descrizione || "";
  }

  public customizeListinoPeriodo(item) {
    if ((item.key || '').startsWith('XLST')) {
      this._opt.listinoPeriod.codListino = item.key
      this._items = this._items.map(s => ({
        ...s,
        value: item.key === s.key
          ? 1
          : (s.key || '').startsWith('XLST')
            ? 0
            : s.value
      }))
    } else {
      switch (item.key) {
        case 'enabled':
          this._opt.listinoPeriod.enabled = !item.value
          break;
        case 'priorityOnRoom':
          this._opt.listinoPeriod.priorityOnRoom = !item.value
          break;
        default:
          break;
      }

      const foundIndex = this.items.findIndex(itm => itm.key == item.key);
      if (foundIndex >= 0) {
        this.items[foundIndex].value = !item.value
      }
    }
  }

  public selectStatistic(item) {
    item.flag = !item.flag;
    this.data.updateItems(this._data.items);
  }

  public getCustomItemClass(item) {
    switch (this.customizeType) {
      case CUSTOMIZE_TYPE.LISTINO_PERIOD:
        return item.fieldType && item.fieldType === P2R_FIELD_TYPE.RADIO ? 'ml-4' : ''
      default:
        return ''
    }
  }

  // Customized payment 
  public enableCashdeskBtn(paymentSelected, key) {
    if (paymentSelected.XCCARDFOOD07) {
      const alert = this.p2rDialogService.open(P2RDialogComponent, {
        id: 'disable-cashdeskBtn-alert',
        body: ConfigurationAlertComponent,
        hasHead: false,
        data: {
          imgSrc: this.configurationService.getImg('/icons/attenzione.svg'),
          title: this.p2rTranslateService.get(this.translateService.instant('attention')),
          messages: [
            this.p2rTranslateService.get(this.translateService.instant('disable_cashdeskBtn_alert_message_1')),
            { class: 'mt-3', text: ' ' },
            this.p2rTranslateService.get(this.translateService.instant('disable_cashdeskBtn_alert_message_2')),
            { class: 'mt-3', text: ' ' },
            this.p2rTranslateService.get(this.translateService.instant('disable_cashdeskBtn_alert_message_3'))
          ]
        },
        actions: [
          {
            label: this.p2rTranslateService.get(this.translateService.instant('annulla')),
            click: () => {
              alert.dismiss();
              // resolve(true);
            },
            classes: 'btn rounded-pill text-uppercase new-alert-button',
            data: {
              remove: true
            },
            params: {
            }
          },
          {
            label: this.p2rTranslateService.get(this.translateService.instant('continua')),
            click: () => {
              alert.dismiss();
              paymentSelected.XCCARDFOOD07 = false;
              this.cdRef.detectChanges();
            },
            classes: 'btn rounded-pill text-uppercase new-alert-button',
            params: {
            }
          }
        ]
      });
    } else {
      this._data.paymentSelected.XCCARDFOOD07 = !this._data.paymentSelected.XCCARDFOOD07;
    }
  }

  public getPaymentDescription(event) {
    const payment_description = event.target.value.trim();
    // const regex = /[a-zA-Z0-9]/;
    // const doesItHaveLetter = regex.test(payment_description);
    if (payment_description.length) {
      this._data.paymentSelected.XCCARDFOOD05 = payment_description.toUpperCase();
    }
  }

  // Method payments list
  public openMethodPaymentListDialog(paymentSelected) {

    const dialogData = {
      hasSearch: false,
      customizeType: CUSTOMIZE_TYPE.METHOD_PAYMENT,
      paymentSelected: paymentSelected,
      opt: {

      },
      items:
        this.paymentsService.METHOD_PAYMENT_TYPE
    }

    document.getElementById('methodPaymentLabel').innerHTML = this.paymentsService.getMethodLabelByCode(paymentSelected.XCCARDFOOD10);

    const dialog = this.p2rDialogService.open(P2RDialogComponent, {
      id: 'lst-periodo-dialog',
      body: CustomizeDialogComponent,
      // hideCloseButton: true,
      title: this.p2rTranslateService.get(this.translateService.instant('method_payment_title')),
      titleClasses: 'text-uppercase font-r_r fs24 lh21 c11 mt-2',
      size: 'lg',
      keyboard: false,
      closeOnOutsideClick: false,
      closeButton: {
        label: this.p2rTranslateService.get(this.translateService.instant('close')),
        buttonClass: 'btn btn-primary rounded-pill lp-bottom-btn',
        labelClass: 'text-uppercase font-r_l fs18 lh21',
        click: () => {
          const p = dialogData.paymentSelected;
          this.paymentsService.changesManager(p, false);
          const modifiedPayment = this.paymentsService.paymentLocalList;
          this.cdRef.detectChanges();
        }
      },
      data: dialogData

    });
  }

  public chooseMethodPayment(methodCode) {
    this._data.paymentSelected.XCCARDFOOD11 = 0
    this._data.paymentSelected.XCCARDFOOD12 = 0
    this._data.paymentSelected.XCCARDFOOD13 = 0
    this._data.paymentSelected.XCCARDFOOD14 = 0
    this._data.paymentSelected.XCCARDFOOD15 = 0

    switch (methodCode) {
      case 1:
        this._data.paymentSelected.XCCARDFOOD12 = 1;
        break;
      case 2:
        this._data.paymentSelected.XCCARDFOOD11 = 1;
        break;
      case 3:
        this._data.paymentSelected.XCCARDFOOD09 = 1;
        break;
      case 4:
        this._data.paymentSelected.XCCARDFOOD13 = 1;
        break;
      case 5:
        this._data.paymentSelected.XCCARDFOOD13 = 1;
        break;
      case 6:
        this._data.paymentSelected.XCCARDFOOD09 = 1;
    }

    this._data.paymentSelected.XCCARDFOOD10 = methodCode;
  }

  // Lingua di stampa
  public chooseItemKey(selectedKey) {
    this._printerLanguage.reduce((acc, curr) => {
      curr.selected = false
      curr.key == selectedKey ? curr.selected = true : false
      acc.push(acc)
      return acc
    }, [])

    this.cdRef.detectChanges();
  }

  // Second row on button (mobile)
  public chooseSecondRowOnButton(selectedValue) {
    this._data.secondRowSelected = selectedValue;
    this.setSecondRowPreviewButton(this._data.secondRowSelected);
  }

  private setSecondRowPreviewButton(selectedValue) {
    switch (selectedValue) {
      case 1:
        document.getElementsByClassName("mobile_preview_btn_text")[0].classList.add("txtEllipsis");
        document.getElementsByClassName("mobile_preview_btn_text")[0].classList.remove("mobile_art_multiple_lines");
        document.getElementsByClassName("mobile_preview_btn_price")[0].classList.remove("d-none");
        document.getElementsByClassName("mobile_preview_btn_stock")[0].classList.add("d-none");
        break;
      case 2:
        document.getElementsByClassName("mobile_preview_btn_text")[0].classList.add("txtEllipsis");
        document.getElementsByClassName("mobile_preview_btn_text")[0].classList.remove("mobile_art_multiple_lines");
        document.getElementsByClassName("mobile_preview_btn_price")[0].classList.add("d-none");
        document.getElementsByClassName("mobile_preview_btn_stock")[0].classList.remove("d-none");
        break;
      case 3:
        document.getElementsByClassName("mobile_preview_btn_text")[0].classList.remove("txtEllipsis");
        document.getElementsByClassName("mobile_preview_btn_text")[0].classList.add("mobile_art_multiple_lines");
        document.getElementsByClassName("mobile_preview_btn_price")[0].classList.add("d-none");
        document.getElementsByClassName("mobile_preview_btn_stock")[0].classList.add("d-none");
        break;
      default:
        break;
    }
  }

  public selectCassaVassoio(item) {
    item.flag = !item.flag;
    this.data.updateItems(this._data.items);
  }

}
