// Controlla se ci sono dei cambiamenti tra orignalData e formData
// Esempio:
// orignalData = "{"KA_OPERATOR_TIME_SLOT":[],"KA_OPERATOR_TYPE":2}" --> Dato preso dal server
// formData = "{"KA_OPERATOR_TIME_SLOT":[],"KA_OPERATOR_TYPE":1}" --> Dato modicato nel form

import { isArray } from 'util';
import * as _ from 'lodash';

// changedKeys = "["KA_OPERATOR_TYPE"]" --> La chiave diversa
const getChangedKeys = (originalData, formData) => {
  const formDataKeys = Object.keys(formData);
  return formDataKeys.filter(key => {
    if (!isNaN(Number(formData[key])) && !isNaN(Number(originalData[key]))) {
      return Number(formData[key]) !== Number(originalData[key]);
    } else if ((typeof formData[key] === 'string') && (typeof originalData[key] === 'string')) {
      return (formData[key] as string).toLocaleLowerCase() !== (originalData[key] as string).toLocaleLowerCase();
    }
    if (isArray(formData[key]) && isArray(originalData[key])) {
      return _.differenceWith(originalData[key], formData[key], _.isEqual).length + _.differenceWith(formData[key], originalData[key], _.isEqual).length;
    }

    return formData[key] !== originalData[key];
  });
};

/**
 * Controlla se una chiave è presente all'interno di un array.
 * Questa funzione viene utilizzata per fare in modo che nell'html
 * non dobbiamo fare un ciclo for ma possiamo semplicemente controllare
 * che una specifica chiave sia presente nell'array. In questo modo
 * abbiamo l'html disegnato come il progetto grafico.
 * @param array Array di partenza
 * @param key Chiave da trovare
 * @returns true se la chiave viene trovata,
 *  false se la chiave non viene trovata
 */
const checkIfKeyIsPresent = (array, key) => {
  const t = Object.keys(array);
  if (t.includes(key)) {
    return true;
  } else {
    return false;
  }
};

export {
  getChangedKeys,
  checkIfKeyIsPresent
};
