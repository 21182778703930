import { Component, OnInit } from '@angular/core';
import { DECIMAL_SEPARATOR, EXPORT_SALES_DATA_ERROR } from '../../configuration.model';
import { ConfigurationService } from '../../configuration.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { P2RApiService } from "@p2r/platform-api";
import { P2RDialogComponent, P2RDialogService, P2RTranslateService } from '@p2r/shared';
import { catchError, tap } from "rxjs/operators";
import { of } from "rxjs";
import { ImportExportService } from '../importExport.service';
import { ConfigurationAlertComponent } from '../../alert/alert.component';
import { UtilsShared } from 'src/app/shared/utilsshared';
@Component({
  selector: 'configuration-export-sale-data',
  templateUrl: './export-sale-data.component.html',
  styleUrls: ['./export-sale-data.component.scss']
})
export class ExportSaleDataComponent implements OnInit {

  public storeList: any = [];
  public storeSelected: any;
  public storeSelectedConfirmClicked = false;

  public dateTimeSrcFrom;
  public dateTimeSrcTo;
  public dateRangeConfirmClicked = false;


  public decimalSeparatorList: any = [];
  public decimalSeparatorSelected: any;

  constructor(public configurationService: ConfigurationService, private translateService: TranslateService, 
    private p2rTranslateService: P2RTranslateService, private ngxLoader: NgxUiLoaderService, private p2rApiService: P2RApiService,
    private importExportService: ImportExportService, private p2rDialogService: P2RDialogService) {
      var d = new Date();
      d.setUTCHours(0,0,0,0);
      this.dateTimeSrcFrom = d.toISOString().slice(0, 16);
      d.setUTCHours(23,59,0,0);
      this.dateTimeSrcTo = d.toISOString().slice(0, 16);

      this.loadStore();

      this.decimalSeparatorList = [
        {
          key: DECIMAL_SEPARATOR.COMMA,
          label: this.p2rTranslateService.get(this.translateService.instant(DECIMAL_SEPARATOR.COMMA))
        },
        {
          key: DECIMAL_SEPARATOR.DOT,
          label: this.p2rTranslateService.get(this.translateService.instant(DECIMAL_SEPARATOR.DOT))
        }
        ];
      this.decimalSeparatorSelected = DECIMAL_SEPARATOR.COMMA;
   }

  ngOnInit() {
  }

  private loadStore() {
    this.ngxLoader.start();
    this.p2rApiService
      .get(this.p2rApiService.apiPath + "/configuration/store/getStoreInfo")
      .pipe(
        catchError((error) => of(error)),
        tap((v: any) => { })
      )
      .subscribe((pvList: any) => {
        if (pvList !== undefined) {
          this.storeList = pvList.pv;
          if(this.storeList.length > 1) {
            this.storeList.unshift({codice: 'all', nome: this.p2rTranslateService.get(this.translateService.instant("all_stores"))});
          }
          this.storeSelected = this.storeList[0].codice;
          console.log(this.storeList);
          this.ngxLoader.stopAll();
        } else {
          console.error("There was an error!", pvList);
          this.ngxLoader.stopAll();
        }
      });
  }

  isDateToAfterDateFrom() {
    if(this.dateTimeSrcFrom == undefined || this.dateTimeSrcTo == undefined) {
      return false;
    } else {
      return (new Date(this.dateTimeSrcTo)) > (new Date(this.dateTimeSrcFrom));
    }
  }

  dateTimeSrcChanged() {
    if(!this.isDateToAfterDateFrom()) {
      this.dateRangeConfirmClicked = false;
    }
  }

  confirmTimeRange() {
    this.dateRangeConfirmClicked = true;
  }

  confirmStore() {
    this.storeSelectedConfirmClicked = true;
  }

  exportSalesData() {
    try{
      this.ngxLoader.start();
      this.importExportService.exportSalesData(
        this.storeSelected, 
        UtilsShared.convertDateInCustomFormat(new Date(this.dateTimeSrcFrom)), 
        UtilsShared.convertDateInCustomFormat(new Date(this.dateTimeSrcTo)), 
        this.decimalSeparatorSelected, 
        (result, errorType, maxDays) => {
          this.ngxLoader.stopAll();
          if(result) {
            console.log("Export Sales Data SUCCESS");
          } else {
            let consoleErrorMessage = "Export Sales Data ERROR: " + errorType;
            if(errorType == EXPORT_SALES_DATA_ERROR.MAX_DAYS) {
              consoleErrorMessage += " -- maxDays: " + maxDays;
              maxDays = maxDays.replace("d", "");
            } 
            console.log(consoleErrorMessage);
            this.showExportError(errorType, maxDays);
          }
        });
    } catch(error) {
      console.error(error);
      this.ngxLoader.stopAll();
    }
  }

  private showExportError(errorType, maxDays) {
    let message = this.p2rTranslateService.get(this.translateService.instant('export_sales_data_fail'));
    if(errorType == EXPORT_SALES_DATA_ERROR.MAX_DAYS) {
      message = this.p2rTranslateService.get(this.translateService.instant('export_sales_data_fail_max_days')).replace("###", maxDays);
    }
    const alert = this.p2rDialogService.open(P2RDialogComponent, {
      id: 'export-sales-data-error-alert',
      body: ConfigurationAlertComponent,
      hasHead: false,
      data: {
        imgSrc: this.configurationService.getImg('icons/attenzione.svg'),
        title: this.p2rTranslateService.get(this.translateService.instant('attention')),
        messages: [message],
      },
      actions: [
        {
          label: this.p2rTranslateService.get(this.translateService.instant('close')),
          click: () => {
            alert.dismiss();
          },
          classes: 'btn btn-block rounded-pill text-uppercase new-alert-button font-r_m fs14 lh22',
          params: {
          }
        }
      ]
    });
  }

}
