import { Component, NgZone, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { P2RTranslateService } from '@p2r/shared';
import { ConfigurationService } from './configuration/configuration.service';

@Component({
  selector: 'configuration-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'configurationapp';

  // necessario per settare la lingua
  constructor(
    private p2rTranslateService: P2RTranslateService,
    private translateService: TranslateService,
    private configurationService: ConfigurationService,
    private readonly ngZone: NgZone
  ) {

    const css = `
      .import-export .ng-select .ng-arrow-wrapper .ng-arrow {
        display: none;
      }
      .import-export .ng-select .ng-arrow-wrapper {
        height: 36px;
        width: 36px;
        background-image: url('${this.configurationService.getImg("/icons/select_arrow.svg")}');
        background-repeat: no-repeat;
        margin-right: 10px;
        margin-left: 12px;
        margin-top: 23px;
      }
      .import-export .ng-select.ng-select-opened .ng-arrow-wrapper {
        transform: rotate(180deg);
        margin-top: -20px;
      }
      .import-export .ng-option {
        text-align: initial
      }

      .export-sale-data .ng-select .ng-select-container {
        height: 40px;
      }
      .export-sale-data .ng-select .ng-arrow-wrapper .ng-arrow {
        display: none;
      }
      .export-sale-data .ng-select .ng-arrow-wrapper {
        height: 36px;
        width: 36px;
        background-image: url('${this.configurationService.getImg("/icons/select_arrow.svg")}');
        background-repeat: no-repeat;
        margin-right: 10px;
        margin-left: 12px;
        margin-top: 23px;
      }
      .export-sale-data .ng-select.ng-select-opened .ng-arrow-wrapper {
        transform: rotate(180deg);
        margin-top: -20px;
      }
      .export-sale-data .ng-option {
        text-align: initial
      }
      .export-sale-data .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked { 
        background-color: white;
      }
      .export-sale-data .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
        background-color: white;
      }

      @font-face {
        font-family: Roboto-Bold;
        src: url(${this.configurationService.getImg(
      "/fonts/ROBOTO-BOLD.TTF"
    )});
      }

      @font-face {
        font-family: Roboto-Light;
        src: url(${this.configurationService.getImg(
      "/fonts/ROBOTO-LIGHT.TTF"
    )});
      }

      @font-face {
          font-family: Roboto-Medium;
          src: url(${this.configurationService.getImg(
      "/fonts/ROBOTO-MEDIUM.TTF"
    )});
      }

      @font-face {
        font-family: Roboto-Regular;
        src: url(${this.configurationService.getImg(
      "/fonts/ROBOTO-REGULAR.TTF"
    )});
      }
    `;

    const head = document.getElementsByTagName("head")[0];
    const style = document.createElement("style");
    style.type = "text/css";
    style.appendChild(document.createTextNode(css));
    head.appendChild(style);

    this.ngZone.runOutsideAngular(() => {
      if (document.getElementById('strangleramd')) {
        document.getElementById('strangleramd').setAttribute('src', '/node_modules/systemjs/dist/extras/amd.min.js');
      }
    });

  }

  closeMenu() {
    document.dispatchEvent(new CustomEvent('SETTINGS:CLOSE_MENU'));
  }
}
