import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { CLOSURE_FIELD_TYPES, GROUP_TYPE } from '../configuration/configuration.model';
import { ActivatedRoute } from '@angular/router';
import { ConfigurationService } from '../configuration/configuration.service';
import { checkIfKeyIsPresent } from '../configuration/configuration.utils';
import { TranslateService } from '@ngx-translate/core';
import { P2RLocalStorageService, P2RDialogComponent, P2RDialogService, P2RTranslateService } from '@p2r/shared';
import { CustomizeDialogComponent } from 'src/app/customize-dialog/customize-dialog.component';
// import { SetEmailDialogComponent } from 'src/app/set-email-dialog/set-email-dialog.component';
import { CUSTOMIZE_TYPE } from 'src/app/customize-dialog/customize-dialog.model';
import { GlobalVariable } from '../global';
import { SIZE } from '@p2r/shared/lib/shared/p2r-dialog/p2r-dialog.interface';
import { SetEmailDialogComponent } from '../configuration/set-email-dialog/set-email-dialog.component';
@Component({
  selector: 'configuration-ku-closure',
  templateUrl: './ku-closure.component.html',
  styleUrls: ['./ku-closure.component.scss']
})
export class KuClosureComponent implements OnInit {

  // {"STAMPA_REPORT_FINANZIARIO_IN_CHIUSURA":false,"KETCHAPP_APERTURA_CHIUSURA_VELOCE":true,"KU_ASK_FISCAL_CLOSURE":true}
  public values: any;

  /*{
    "ASK_FISCAL_CLOSURE":{
      "TITLE":{},
      "DESCRIPTION":{}
    },
    "APERTURA_CHIUSURA_VELOCE":{
      "TITLE":{},
      "DESCRIPTION":{}
    },
    "REPORTO_FINANZIARIO":{
      "TITLE":{},
      "DESCRIPTION":{}
    },
    "KU_STATS_CLOSING_PRINTER":{
      "TITLE":{},
      "DESCRIPTION":{}
    }
  }
  */
  public items: any;

  // STAMPA_REPORT_FINANZIARIO_IN_CHIUSURA
  public kuReportInChiusura: any;

  // KETCHAPP_APERTURA_CHIUSURA_VELOCE
  public kuAperturaChiusuraVeloce: any;

  // KU_ASK_FISCAL_CLOSURE
  public kuAskFiscalClosure: any;

  // KU_SCORECARDS_CLOSURE_EMAIL_ADDRESS
  public kuScorecardsClosureEmailAddress

  // KU_STATS_CLOSING_PRINTER
  public kuStatsPrinter: string;

  // Contatore statistiche
  public kuStatisticsKeyList: string[];
  public kuStatisticsCounter: string;

  casseAll: any[]; // Contiene le casse di tutti gli store
  casse: any[]; // Contiene le casse dello store attualmente selezionato

  // KEEPUP LISTA FLAG CHIUSURA CASSA
  public KuReportChiusuraFlagList: any[] = Array();

  constructor(
    public configurationService: ConfigurationService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private p2rTranslateService: P2RTranslateService,
    private translateService: TranslateService,
    private p2rDialogService: P2RDialogService,
    private p2rLocalStorageService: P2RLocalStorageService
  ) { }

  ngOnInit() {

    this.configurationService.selectedGroup = GROUP_TYPE.CLOSURE;

    this.route.data.subscribe(({ data }) => {
      const [items, values, casse] = data;

      this.items = items;
      this.values = values;
      this.casseAll = casse;
      this.filterCasseByStore();

      // console.log('CLOSURE');
      // console.log('ITEMS => ' + JSON.stringify(this.items));
      // console.log('VALUES =>\n' + JSON.stringify(this.values));

      this.kuReportInChiusura = values[CLOSURE_FIELD_TYPES.STAMPA_REPORT_FINANZIARIO_IN_CHIUSURA];
      this.kuAperturaChiusuraVeloce = !values[CLOSURE_FIELD_TYPES.KETCHAPP_APERTURA_CHIUSURA_VELOCE];
      this.kuAskFiscalClosure = values[CLOSURE_FIELD_TYPES.KU_ASK_FISCAL_CLOSURE];
      this.kuStatsPrinter = values[CLOSURE_FIELD_TYPES.KU_STATS_CLOSING_PRINTER];
      this.kuScorecardsClosureEmailAddress = values[CLOSURE_FIELD_TYPES.KU_SCORECARDS_CLOSURE_EMAIL_ADDRESS];


      const group: any = {};

      Object.keys(values).forEach(key => {
        group[key] = new FormControl(values[key], {
          validators: [],
          asyncValidators: [],
          updateOn: 'blur'
        });
      });

      this.configurationService.formGroup = this.formBuilder.group(group);

      /*  aggiungo le chiavi della stampa statistiche */
      this.setStatisticClosure();
      this.filterReportChiusuraKeys(false, null);
      this.setStatisticsLabel();
    });

    this.configurationService.groups$().subscribe(groups => {
      const found = groups.find(g => g.cod === GROUP_TYPE.CLOSURE);
      this.items = found && found.items || [];
    });

    this.configurationService.values$().subscribe(values => {
      values = values[GROUP_TYPE.CLOSURE];
      if (values !== undefined) {
        if (this.configurationService._selectedGroup === GROUP_TYPE.CLOSURE) {
          // this.configurationService.formGroup.setValue(values);

          this.kuReportInChiusura = values[CLOSURE_FIELD_TYPES.STAMPA_REPORT_FINANZIARIO_IN_CHIUSURA];
          this.kuAperturaChiusuraVeloce = !values[CLOSURE_FIELD_TYPES.KETCHAPP_APERTURA_CHIUSURA_VELOCE];
          this.kuAskFiscalClosure = values[CLOSURE_FIELD_TYPES.KU_ASK_FISCAL_CLOSURE];
          this.kuStatsPrinter = values[CLOSURE_FIELD_TYPES.KU_STATS_CLOSING_PRINTER];
          this.kuScorecardsClosureEmailAddress = values[CLOSURE_FIELD_TYPES.KU_SCORECARDS_CLOSURE_EMAIL_ADDRESS];

          /*  Avendo ripristinato le modifiche, ho bisogno di ricaricare i dati inerenti ai reporto di chiusura di cassa. */
          this.filterReportChiusuraKeys(true, values);
          this.setStatisticsLabel();

          // Prendo le casse dello store attuale
          this.filterCasseByStore();
        }
      }
    });
  }

  public checkIfKeyIsPresent(array, key) {
    const v = checkIfKeyIsPresent(array, key);
    return v;
  }

  /**
   * Filtra la lista casseAll creando una nuova lista con le sole casse dello
   * store corrente.
   */
  public filterCasseByStore() {
    const selectedPv = this.p2rLocalStorageService.getFromLocalStorage(
      GlobalVariable.SELECTED_PV_KEY_STORAGE,
      []
    );
    let casseTemp = [];
    this.casseAll.forEach(cassa => {
      if (cassa.XSF03 == selectedPv.codice) {
        casseTemp.push(cassa);
      }
    });
    this.casse = casseTemp;
  }

  public getPrinterStatsLabel() {
    if (this.kuStatsPrinter == undefined || this.kuStatsPrinter.length == 0) {
      return this.p2rTranslateService.get(this.translateService.instant('ku_closure_stats_not_printed'))
    } else {
      return this.casse.find(c => c.key === this.kuStatsPrinter).label;
    }
  }

  private setStatisticClosure(): void {
    this.kuStatisticsKeyList = Array();
    this.kuStatisticsKeyList.push(CLOSURE_FIELD_TYPES.KU_PRINT_STATS_GIACENZA_CASSA);
    this.kuStatisticsKeyList.push(CLOSURE_FIELD_TYPES.KU_PRINT_STATS_RISCOSSO);
    this.kuStatisticsKeyList.push(CLOSURE_FIELD_TYPES.KU_PRINT_STATS_VENDUTO_DA_RISCUOTERE);
    this.kuStatisticsKeyList.push(CLOSURE_FIELD_TYPES.KU_PRINT_STATS_SCONTI);
    this.kuStatisticsKeyList.push(CLOSURE_FIELD_TYPES.KU_PRINT_STATS_MAGGIORAZIONI);
    this.kuStatisticsKeyList.push(CLOSURE_FIELD_TYPES.KU_PRINT_STATS_VENDUTO_CLIENTE);
    this.kuStatisticsKeyList.push(CLOSURE_FIELD_TYPES.KU_PRINT_STATS_VENDUTO_PER_REPARTO);
    this.kuStatisticsKeyList.push(CLOSURE_FIELD_TYPES.KU_PRINT_STATS_VENDUTO_PER_CASSIERE);
    this.kuStatisticsKeyList.push(CLOSURE_FIELD_TYPES.KU_PRINT_STATS_VENDUTO_TOTALE_DOCUMENTI);
    this.kuStatisticsKeyList.push(CLOSURE_FIELD_TYPES.KU_PRINT_STATS_VENDUTO_PER_ARTICOLO);
  }

  private filterReportChiusuraKeys(forceReload, newValues) {
    let labelKeyTranslation = Array();
    const keyList = Object.keys(this.items);

    if ((this.KuReportChiusuraFlagList.length > 0) && !forceReload) {
      return this.KuReportChiusuraFlagList;
    }
    this.kuStatisticsKeyList.forEach(key => {
      const keyStat = keyList.find(item => key.includes(item));
      /*
        Costruisco il mio oggetto che passerò al dialog
      */
      const keyTraslation = 'CLOSURE.' + keyStat + '.TITLE';
      const valuesList = (newValues == null) ? this.values : newValues;
      labelKeyTranslation.push({
        key: key,
        label: this.p2rTranslateService.get(this.translateService.instant(keyTraslation)),
        flag: valuesList[key]
      });
    });
    this.KuReportChiusuraFlagList = labelKeyTranslation;
  }

  public openPopupReportChiusura() {
    const dialog = this.p2rDialogService.open(P2RDialogComponent, {
      id: 'customize-dialog',
      body: CustomizeDialogComponent,
      hideCloseButton: true,
      title: this.p2rTranslateService.get(this.translateService.instant('CLOSURE.REPORTO_FINANZIARIO.TITLE')),
      titleClasses: 'text-uppercase font-r_r fs24 lh21 c11 mt-2',
      size: 'lg',
      data: {
        hasSearch: true,
        hasSelectAll: true,
        customizeType: CUSTOMIZE_TYPE.PRINT_STATISTICS,
        items: this.KuReportChiusuraFlagList,
        close: () => {
          this.setStatisticsLabel();
          dialog.dismiss();
        },
        updateItems: (itemList) => {
          this.KuReportChiusuraFlagList = itemList;
          this.KuReportChiusuraFlagList.forEach(elem => {
            /*this.values[elem.key] = elem.flag*/
            this.configurationService.formGroup.get(elem.key).setValue(elem.flag);
          });
        }
      }
    });
  }

  public chooseCassa() {
    const dialog = this.p2rDialogService.open(P2RDialogComponent, {
      id: 'customize-dialog',
      body: CustomizeDialogComponent,
      hideCloseButton: true,
      title: this.p2rTranslateService.get(this.translateService.instant('CLOSURE.KU_STATS_CLOSING_PRINTER.TITLE')),
      titleClasses: 'text-uppercase font-r_r fs24 lh21 c11 mt-2',
      size: 'lg',
      data: {
        hasSearch: true,
        customizeType: CUSTOMIZE_TYPE.SELECT_CASHDESK,
        items: this.casse.map(s => ({
          ...s,
          value: this.kuStatsPrinter === s.key ? 1 : 0
        })),
        close: (key) => {
          if (key) {
            if (this.kuStatsPrinter == key) {
              // Ho riselezionato la stessa cassa, quindi la voglio disabilitare
              this.kuStatsPrinter = '';
            } else {
              this.kuStatsPrinter = key;
            }
            this.configurationService.formGroup.get(CLOSURE_FIELD_TYPES.KU_STATS_CLOSING_PRINTER).setValue(this.kuStatsPrinter);
          }
          dialog.dismiss();
        },
      }
    });
  }

  public setUpMail() {
    // Logica per settare la mail
    let dataToDialog = {
      email: this.kuScorecardsClosureEmailAddress,
      input_title: this.p2rTranslateService.get(this.translateService.instant("email_address")),
      email_placeholder: this.p2rTranslateService.get(this.translateService.instant("type_email_address")),
      email_invalid: false
    };
    console.log(dataToDialog.email);
    const alert = this.p2rDialogService.open(P2RDialogComponent, {
      id: 'set-email-dialog',
      hideCloseButton: true,
      title: this.p2rTranslateService.get(this.translateService.instant('CLOSURE.KU_SCORECARDS_CLOSURE_EMAIL_ADDRESS.TITLE')),
      titleClasses: "font-r_r fs24 lh-39 c11",
      body: SetEmailDialogComponent,
      data: dataToDialog,
      size: SIZE.MEDIUM,
      actions: [
        {
          label: this.p2rTranslateService.get(
            this.translateService.instant("dialog_cancel")
          ),
          click: () => {
            alert.dismiss();
          },
          classes: "btn rounded-pill text-uppercase new-alert-button font-r_l fs18",
          data: {
            remove: true,
          },
          params: {},
        },
        {
          label: this.p2rTranslateService.get(
            this.translateService.instant("dialog_confirm")
          ),
          click: (data) => {
            console.log("stats email = " + JSON.stringify(data));
            // check if email
            if (this.checkIfEmail(dataToDialog.email)) {
              this.kuScorecardsClosureEmailAddress = dataToDialog.email;
              this.configurationService.formGroup.get(CLOSURE_FIELD_TYPES.KU_SCORECARDS_CLOSURE_EMAIL_ADDRESS).setValue(this.kuScorecardsClosureEmailAddress); // Serve per non avere problemi con apostrofo
              alert.dismiss();
            } else {
              dataToDialog.email_invalid = true
            }
          },
          data: dataToDialog,
          classes: "btn rounded-pill text-uppercase new-alert-button font-r_l fs18",
          params: {
            submit: true,
          },
        },
      ],
    });
  }

  private checkIfEmail(text: string): boolean {
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (text.length === 0 || text.match(validRegex)) {
      return true;
    } else {
      return false;
    }
  }

  public setEmailFieldMessage(): string {
    if (this.kuScorecardsClosureEmailAddress.length == 0) {
      return this.p2rTranslateService.get(this.translateService.instant('no_email_address_message'));
    } else {
      return this.kuScorecardsClosureEmailAddress;
    }
  }

  public setStatisticsLabel() {
    let counter: number = 0;
    let labelToPrint: string = "";

    this.kuStatisticsKeyList.forEach(key => {
      const item = this.KuReportChiusuraFlagList.find(elem => elem.key == key);
      if (item.flag) {
        counter++;
      }
    })
    if (counter == 0) {
      labelToPrint = this.p2rTranslateService.get(this.translateService.instant('no_statistics'));
    } else if (counter == this.kuStatisticsKeyList.length) {
      labelToPrint = this.p2rTranslateService.get(this.translateService.instant('all_statistics'));
    } else {
      labelToPrint = counter.toString() + " " + this.p2rTranslateService.get(this.translateService.instant('statistics')).toLowerCase();
    }
    this.kuStatisticsCounter = `${labelToPrint}`;
  }

  onClickEnableReportInChiusura() {
    this.kuReportInChiusura = !this.kuReportInChiusura;
    this.configurationService.formGroup.get(CLOSURE_FIELD_TYPES.STAMPA_REPORT_FINANZIARIO_IN_CHIUSURA).setValue(this.kuReportInChiusura);
  }

  onClickEnableAperturaChiusuraVeloce() {
    this.kuAperturaChiusuraVeloce = !this.kuAperturaChiusuraVeloce;
    this.configurationService.formGroup.get(CLOSURE_FIELD_TYPES.KETCHAPP_APERTURA_CHIUSURA_VELOCE).setValue(!this.kuAperturaChiusuraVeloce);
  }

  onClinkEnableFiscalClosure() {
    this.kuAskFiscalClosure = !this.kuAskFiscalClosure;
    this.configurationService.formGroup.get(CLOSURE_FIELD_TYPES.KU_ASK_FISCAL_CLOSURE).setValue(this.kuAskFiscalClosure);
  }

}
