import { ReplaySubject } from 'rxjs';
import { AppProps } from 'single-spa';
import { Injectable } from '@angular/core';

@Injectable()
export class SingleSpaPropsSubject {
    public static singleSpaPropsSubject: ReplaySubject<any> = new ReplaySubject<any>(1);
    public static getSingleSpaPropsSubject() {
        return this.singleSpaPropsSubject;
    }
    public getClasses(): (typeof SingleSpaPropsSubject)[] {
        return [
            SingleSpaPropsSubject,
        ];
    }

}
// Add any custom single-spa props you have to this type def
// https://single-spa.js.org/docs/building-applications.html#custom-props
export type SingleSpaProps = AppProps & {
    communicationBridge
};
