<div class="d-flex flex-grow-1 h-100 w-100 steps text-center">
    <div
        style="min-width: 300px;"
        class="mt-3 w-33 border-right"
    >
        <div class="d-flex flex-column flex-grow-1 h-100 justify-content-between">
            <div>
                <div
                    style="margin-top: 40px;"
                    class="disabled-step"
                >
                    <span class="numstep">1</span>
                </div>
                <div class="m-5 p-2">
                    <span class="fs24 lh32 font_r-r c11">
                        {{'if_you_already_have_a_file_exported_from_keep_up_go_to_step_4'| translate}}
                    </span>
                </div>
                <div class="m-5 p-2">
                    <span class="fs24 lh32 font_r-r c11">{{'or'| translate}}</span>
                </div>
                <div class="m-5 p-2">
                    <span class="fs24 lh32 font_r-r c11">
                        {{'download_the_desired_blank_template'| translate}}
                    </span>
                </div>
            </div>
            <div class="mb-5">
                <img [src]="configurationService.getImg('/icons/export.svg')">
            </div>
        </div>
    </div>
    <div
        style="min-width: 300px;"
        class="mt-3 w-33 border-right"
    >
        <div class="d-flex flex-column flex-grow-1 h-100 justify-content-between">
            <div>
                <div
                    style="margin-top: 40px;"
                    class="disabled-step"
                >
                    <span class="numstep">2</span>
                </div>
                <div class="m-5 p-2">
                    <span class="fs24 lh32 font_r-r c11">
                        {{'fill_in_the_blank_template_with_your_details'| translate}}
                    </span>
                </div>
                <div class="m-5 p-2">
                    <span class="fs24 lh32 font_r-r c11">{{'or'| translate}}</span>
                </div>
                <div class="m-5 p-2">
                    <span class="fs24 lh32 font_r-r c11">
                        {{'use_it_as_a_track_to_edit_the_file_in_your_possession'| translate}}
                    </span>
                </div>
            </div>
            <div class="mb-5">
                <img [src]="configurationService.getImg('/icons/export_edit.svg')">
            </div>
        </div>
    </div>
    <div
        style="min-width: 300px;"
        class="mt-3 w-33"
    >
        <div class="d-flex flex-column flex-grow-1 h-100 justify-content-between">
            <div>
                <div
                    style="margin-top: 40px;"
                    class="disabled-step"
                >
                    <span class="numstep">3</span>
                </div>
                <div class="m-5">
                    <span class="fs24 lh32 font_r-r c11">
                        {{'save_the_template_filled_in_with_your_details'| translate}}
                    </span>
                </div>
                <!-- <div style="margin-top: 6.3rem">
                    <button
                        (click)="attivaLicenza()"
                        class="btn btn-custom pl-5 pr-5"
                        [disabled]="!idAzienda.length"
                    >
                        {{'activate' | translate | uppercase}}
                    </button>
                </div> -->
            </div>
            <div class="mb-5">
                <img [src]="configurationService.getImg('/icons/export_save.svg')">
            </div>
        </div>
    </div>
    <div
        style="min-width: 300px;"
        class="mt-3 w-33"
    >
        <div class="d-flex flex-column flex-grow-1 h-100 justify-content-between">
            <div>
                <div
                    style="margin-top: 40px;"
                    class="disabled-step"
                >
                    <span class="numstep">4</span>
                </div>
                <div class="m-5">
                    <span class="fs24 lh32 font_r-r c11">
                        {{'save_the_template_filled_in_with_your_details'| translate}}
                    </span>
                </div>
                <!-- <div style="margin-top: 6.3rem">
                    <button
                        (click)="attivaLicenza()"
                        class="btn btn-custom pl-5 pr-5"
                        [disabled]="!idAzienda.length"
                    >
                        {{'activate' | translate | uppercase}}
                    </button>
                </div> -->
            </div>
            <div class="mb-5">
                <img [src]="configurationService.getImg('/icons/import.svg')">
            </div>
        </div>
    </div>
</div>
