/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./configuration-empty-route.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./configuration-empty-route.component";
var styles_ConfigurationEmptyRouteComponent = [i0.styles];
var RenderType_ConfigurationEmptyRouteComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfigurationEmptyRouteComponent, data: {} });
export { RenderType_ConfigurationEmptyRouteComponent as RenderType_ConfigurationEmptyRouteComponent };
export function View_ConfigurationEmptyRouteComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(1, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_ConfigurationEmptyRouteComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "configuration-empty-route", [], null, null, null, View_ConfigurationEmptyRouteComponent_0, RenderType_ConfigurationEmptyRouteComponent)), i1.ɵdid(1, 49152, null, 0, i3.ConfigurationEmptyRouteComponent, [], null, null)], null, null); }
var ConfigurationEmptyRouteComponentNgFactory = i1.ɵccf("configuration-empty-route", i3.ConfigurationEmptyRouteComponent, View_ConfigurationEmptyRouteComponent_Host_0, {}, {}, []);
export { ConfigurationEmptyRouteComponentNgFactory as ConfigurationEmptyRouteComponentNgFactory };
