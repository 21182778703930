<div
    class="w-100 h-100 bootstrap-stylesheet"
    style="overflow-x: hidden;"
>
    <div
        class="d-flex justify-content-between align-items-center pb-3 pl-3 pr-3 pt-1"
        [ngClass]="{'border-bottom': !data.message}"
    >
        <div>
            <div
                *ngIf="hasSearch"
                class="d-flex"
                style="position: relative;"
            >
                <input
                    type="text"
                    [(ngModel)]="itemName"
                    #searchInput
                    customVirtualKeyboard
                    [placeholder]="itemPlaceholder"
                    class="w-100 input-research font-r_l fs18 c11 lh21"
                    (ngModelChange)="itemSearch($event)"
                >
                <ng-container *ngIf="itemName.length">
                    <img
                        [src]="configurationService.getImg('icons/close_black.svg')"
                        class="left-panel-icon icon search-append-icon"
                        width="40"
                        height="40"
                        (click)="clearItemSearch()"
                    >
                </ng-container>
                <ng-container *ngIf="itemName.length === 0">
                    <img
                        [src]="configurationService.getImg('icons/search.svg')"
                        class="left-panel-icon icon search-append-icon"
                        width="40"
                        height="40"
                        (click)="itemSearch($event)"
                    >
                </ng-container>
            </div>
            <div *ngIf="data.hasHeaderLabel">
                <span class="font-r_m fs24 lh21 c11">{{data.headerLabel}}</span>
            </div>
        </div>
        <div class="d-flex">
            <div *ngIf="hasSelectAll">
                <div *ngFor="let ac of selectAllAction">
                    <div
                        *ngIf="ac.shown(items)"
                        class="ml-2"
                    >
                        <button
                            class="btn btn-primary rounded-pill lp-bottom-btn"
                            style="height: 40px;"
                            (click)="ac.click(items);"
                        >
                            <span class="text-uppercase font-r_l fs18 lh21">{{ac.label}}</span>
                        </button>
                    </div>
                </div>
            </div>
            <div *ngFor="let action of actions">
                <div
                    *ngIf="action.shown(items)"
                    class="ml-2"
                >
                    <button
                        class="btn btn-primary rounded-pill lp-bottom-btn"
                        style="height: 40px;"
                        (click)="action.click()"
                    >
                        <span class="text-uppercase font-r_l fs18 lh21">{{action.label}}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div
        *ngIf="data.message"
        class="d-flex justify-content-between align-items-center border-bottom pb-3 pl-2 pr-3 pt-3"
        style="font-weight: 600;"
    >
        <div class="col-11 fs16">
            {{ 'cassa_master_dialog_message' | translate }}
        </div>
        <div class="lh21">
            <img
                style="width: 40px; height: 40px; margin-right: 2px;"
                [src]="configurationService.getImg('/icons/warning_2.svg')"
            >
        </div>
    </div>
    <div class="w-100 stores-container d-flex flex-column flex-grow-1">
        <drag-scroll
            drag-scroll-x-disabled="true"
            class="w-100 position-relative h-100"
            style="max-width: 800px;"
        >
            <div
                *ngFor="let item of items"
                class="row border-bottom pl-4 pr-3 pt-3 pb-3"
                [ngClass]="{'item-disabled': item.isDisabled}"
            >
                <div class="align-items-center col d-flex">
                    <div
                        class="mb-1 position-relative"
                        [ngClass]="getCustomItemClass(item)"
                    >
                        <div [ngClass]="{'store-field-modified': item.isModified}"></div>
                        <span class="font-r_r fs20 lh25 c11 itm-label">{{ item.label | translate }}</span>
                        <div
                            *ngIf="item.note"
                            id="{{item.noteId}}"
                            class="mt-1"
                        >
                            <span
                                class="font-r_r fs16 lh16 c10 itm-note"
                                style="color: #8a8d8f;"
                            >
                                {{item.note}}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="align-items-center col-1 d-flex">
                    <div class="w-100">
                        <ng-container [ngSwitch]="customizeType">
                            <ng-container *ngSwitchCase="CUSTOMIZE_TYPE.SELECT_STORE">
                                <ng-container *ngTemplateOutlet="SELECT_STORE; context {item: item}"></ng-container>
                            </ng-container>
                            <ng-container *ngSwitchCase="CUSTOMIZE_TYPE.SELECT_CASHDESK">
                                <ng-container *ngTemplateOutlet="SELECT_CASHDESK; context {item: item}"></ng-container>
                            </ng-container>
                            <ng-container *ngSwitchCase="CUSTOMIZE_TYPE.LINK_DEP_FCIC">
                                <ng-container *ngTemplateOutlet="LINK_DEP_FCIC; context {item: item}"></ng-container>
                            </ng-container>
                            <ng-container *ngSwitchCase="CUSTOMIZE_TYPE.LINK_CAT_FCIC">
                                <ng-container *ngTemplateOutlet="LINK_CAT_FCIC; context {item: item}"></ng-container>
                            </ng-container>
                            <ng-container *ngSwitchCase="CUSTOMIZE_TYPE.LICENSE_DETAIL">
                                <ng-container *ngTemplateOutlet="LICENSE_DETAIL; context {item: item}"></ng-container>
                            </ng-container>
                            <ng-container *ngSwitchCase="CUSTOMIZE_TYPE.LISTINO_PERIOD">
                                <ng-container *ngTemplateOutlet="LISTINO_PERIOD; context {item: item}"></ng-container>
                            </ng-container>
                            <ng-container *ngSwitchCase="CUSTOMIZE_TYPE.PRINT_STATISTICS">
                                <ng-container *ngTemplateOutlet="PRINT_STATISTICS; context {item: item}"></ng-container>
                            </ng-container>
                            <ng-container *ngSwitchCase="CUSTOMIZE_TYPE.ASK_PASSWORD_FOR_RESERVED_OPERATIONS">
                                <ng-container *ngTemplateOutlet="ASK_PASSWORD_FOR_RESERVED_OPERATIONS; context {item: item}"></ng-container>
                            </ng-container>
                            <ng-container *ngSwitchCase="CUSTOMIZE_TYPE.CUSTOMIZED_PAYMENT">
                                <ng-container *ngTemplateOutlet="CUSTOMIZED_PAYMENT; context {item: item}"></ng-container>
                            </ng-container>
                            <ng-container *ngSwitchCase="CUSTOMIZE_TYPE.METHOD_PAYMENT">
                                <ng-container *ngTemplateOutlet="METHOD_PAYMENT; context {item: item}"></ng-container>
                            </ng-container>
                            <ng-container *ngSwitchCase="CUSTOMIZE_TYPE.PRINTER_LANGUAGE">
                                <ng-container *ngTemplateOutlet="PRINTER_LANGUAGE; context {item: item}"></ng-container>
                            </ng-container>
                            <ng-container *ngSwitchCase="CUSTOMIZE_TYPE.DEFAULT_CURRENCY">
                                <ng-container *ngTemplateOutlet="DEFAULT_CURRENCY; context {item: item}"></ng-container>
                            </ng-container>
                            <ng-container *ngSwitchCase="CUSTOMIZE_TYPE.ARTICLE_MOBILE_SECOND_ROW_ON_BUTTON">
                                <ng-container *ngTemplateOutlet="ARTICLE_MOBILE_SECOND_ROW_ON_BUTTON; context {item: item}"></ng-container>
                            </ng-container>
                            <ng-container *ngSwitchCase="CUSTOMIZE_TYPE.CASSE_VASSOIO">
                                <ng-container *ngTemplateOutlet="CASSE_VASSOIO; context {item: item}"></ng-container>
                            </ng-container>
                            <ng-container *ngSwitchDefault></ng-container>
                        </ng-container>
                    </div>
                </div>
            </div>
        </drag-scroll>
    </div>
</div>
<ng-template
    #SELECT_STORE
    let-item="item"
>
    <div class="d-flex justify-content-end align-items-center SELECT_STORE">
        <div class="sub-section-checkbox">
            <img
                style="width: 40px; height: 40px"
                [src]="item.value === 1 ? configurationService.getImg('/icons/checkbox-selected.svg') : configurationService.getImg('/icons/checkbox-not_selected.svg')"
                (click)="selectStore(item.key)"
            >
        </div>
    </div>
</ng-template>
<ng-template
    #SELECT_CASHDESK
    let-item="item"
>
    <div class="d-flex justify-content-end align-items-center SELECT_STORE SELECT_CASHDESK">
        <div class="sub-section-checkbox">
            <img
                style="width: 40px; height: 40px"
                [src]="item.value === 1 ? configurationService.getImg('/icons/checkbox-selected.svg') : configurationService.getImg('/icons/checkbox-not_selected.svg')"
                (click)="selectCash(item.key)"
            >
        </div>
    </div>
</ng-template>
<ng-template
    #LINK_DEP_FCIC
    let-item="item"
>
    <div class="d-flex justify-content-end align-items-center">
        <ng-container *ngIf="item.value === 1">
            <div class="d-flex align-items-center">
                <span
                    class="font-r_r fs20 lh16 c11 mr-2 text-right"
                    style="white-space: nowrap;"
                >
                    {{ getLinkedCATDescrizione(item) }}
                </span>
                <img
                    [src]="configurationService.getImg('icons/options_img.svg')"
                    style="cursor: pointer"
                    width="40"
                    height="40"
                    (click)="linkDepFCIC(item)"
                >
            </div>
        </ng-container>
        <div class="ml-2 pull-right">
            <ng-container *ngIf="item.value === 1; else unchecked">
                <img
                    [src]="configurationService.getImg('icons/checkbox_grey_filled.svg')"
                    style="cursor: pointer"
                    width="40"
                    height="40"
                    (click)="unlinkDepFCIC(item)"
                >
            </ng-container>
            <ng-template #unchecked>
                <img
                    [src]="configurationService.getImg('icons/checkbox_grey_empty.svg')"
                    style="cursor: pointer"
                    width="40"
                    height="40"
                    (click)="linkDepFCIC(item)"
                >
            </ng-template>
        </div>
    </div>
</ng-template>
<ng-template
    #LINK_CAT_FCIC
    let-item="item"
>
    <div class="d-flex justify-content-end align-items-center">
        <div class="ml-2 pull-right">
            <ng-container *ngIf="item.value === 1; else unchecked">
                <img
                    [src]="configurationService.getImg('icons/checkbox_grey_filled.svg')"
                    style="cursor: pointer"
                    width="40"
                    height="40"
                    (click)="linkCatFCIC(item)"
                >
            </ng-container>
            <ng-template #unchecked>
                <img
                    [src]="configurationService.getImg('icons/checkbox_grey_empty.svg')"
                    style="cursor: pointer"
                    width="40"
                    height="40"
                    (click)="linkCatFCIC(item)"
                >
            </ng-template>
        </div>
    </div>
</ng-template>
<ng-template
    #LISTINO_PERIOD
    let-item="item"
>
    <ng-container [ngSwitch]="item.fieldType">
        <ng-container *ngSwitchCase="P2R_FIELD_TYPE.CHECK">
            <div class="d-flex justify-content-end align-items-center">
                <div class="ml-2 pull-right">
                    <div class="sub-section-checkbox-2">
                        <img
                            [src]="item.value ? configurationService.getImg('/icons/switch-on.svg') :
          configurationService.getImg('/icons/switch-off.svg')"
                            (click)="customizeListinoPeriodo(item)"
                        >
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="P2R_FIELD_TYPE.RADIO">
            <div class="d-flex justify-content-end align-items-center">
                <div class="ml-2 pull-right">
                    <ng-container *ngIf="item.value === 1; else unchecked">
                        <img
                            [src]="configurationService.getImg('icons/checkbox-selected.svg')"
                            style="cursor: pointer"
                            width="40"
                            height="40"
                            (click)="customizeListinoPeriodo(item)"
                        >
                    </ng-container>
                    <ng-template #unchecked>
                        <img
                            [src]="configurationService.getImg('icons/checkbox-not_selected.svg')"
                            style="cursor: pointer"
                            width="40"
                            height="40"
                            (click)="customizeListinoPeriodo(item)"
                        >
                    </ng-template>
                </div>
            </div>
        </ng-container>
        <ng-container *ngSwitchDefault></ng-container>
    </ng-container>
</ng-template>
<ng-template
    #PRINT_STATISTICS
    let-item="item"
>
    <div class="d-flex justify-content-end align-items-center SELECT_STORE SELECT_CASHDESK SELECT_PRINT_STATISTICS">
        <div class="sub-section-checkbox">
            <img
                style="width: 40px; height: 40px"
                [src]="item.flag === true ? configurationService.getImg('/icons/checkbox-selected.svg') : configurationService.getImg('/icons/checkbox-not_selected.svg')"
                (click)="selectStatistic(item)"
            >
        </div>
    </div>
</ng-template>
<ng-template
    #ASK_PASSWORD_FOR_RESERVED_OPERATIONS
    let-item="item"
>
    <div class="d-flex justify-content-end align-items-center SELECT_STORE SELECT_CASHDESK SELECT_PRINT_STATISTICS">
        <div class="sub-section-checkbox">
            <img
                style="width: 40px; height: 40px"
                [src]="item.flag === true ? configurationService.getImg('/icons/checkbox-selected.svg') : configurationService.getImg('/icons/checkbox-not_selected.svg')"
                (click)="selectStatistic(item)"
            >
        </div>
    </div>
</ng-template>
<ng-template
    #CUSTOMIZED_PAYMENT
    let-item="item"
>
    <!--  -->
    <ng-container [ngSwitch]="item.key">
        <!-- Abilita tasto in cassa -->
        <ng-container *ngSwitchCase="'enabled'">
            <div class="d-flex justify-content-end align-items-center CUSTOMIZED_PAYMENT">
                <div class="sub-section-checkbox pr-4">
                    <img
                        class="sub-section-checkbox-2"
                        [src]="_data.paymentSelected.XCCARDFOOD07 ? configurationService.getImg('/icons/switch-on.svg') : configurationService.getImg('/icons/switch-off.svg')"
                        (click)="enableCashdeskBtn(_data.paymentSelected, item.key)"
                    >
                </div>
            </div>
        </ng-container>
        <!-- Descrizione tasto -->
        <ng-container *ngSwitchCase="'description'">
            <div class="d-flex justify-content-end align-items-center CUSTOMIZED_PAYMENT">
                <div class="sub-section-checkbox pr-4">
                    <input
                        type="text"
                        class="fs24 c11 text-uppercase font-r_r"
                        maxlength="30"
                        style="border: none; text-align: right; background: none; font-family: Roboto-Regular"
                        value="{{ _data.paymentSelected.XCCARDFOOD05 }}"
                        (keyup)="getPaymentDescription($event)"
                        placeholder="{{ 'PAYMENT' | translate }}"
                    >
                </div>
            </div>
        </ng-container>
        <!-- Metodo di pagamento -->
        <ng-container *ngSwitchCase="'method'">
            <div class="d-flex justify-content-end align-items-center CUSTOMIZED_PAYMENT">
                <div class="sub-section-checkbox pr-4">
                    <span
                        class="fs24 mr-2 font-r_r"
                        *ngIf="_data.paymentSelected.XCCARDFOOD10"
                    >
                        {{ paymentsService.getMethodLabelByCode(_data.paymentSelected.XCCARDFOOD10) | translate }}
                    </span>
                    <span
                        class="fs24 mr-2 font-r_r"
                        *ngIf="!_data.paymentSelected.XCCARDFOOD10"
                    >
                        {{ paymentsService.getMethodLabelByCode(1) | translate }}
                    </span>
                    <button
                        class="btn btn-primary rounded-pill lp-bottom-btn"
                        (click)="openMethodPaymentListDialog(_data.paymentSelected)"
                    >
                        <span class="text-uppercase font-r_l fs18 lh21">{{ 'choose' | translate }}</span>
                    </button>
                </div>
            </div>
        </ng-container>
        <ng-container *ngSwitchDefault></ng-container>
    </ng-container>
    <!--  -->
</ng-template>
<ng-template
    #METHOD_PAYMENT
    let-item="item"
>
    <div class="d-flex justify-content-end align-items-center CUSTOMIZED_PAYMENT">
        <div class="sub-section-checkbox">
            <img
                style="width: 40px; height: 40px"
                [src]="_data.paymentSelected.XCCARDFOOD10 === item.value ? configurationService.getImg('/icons/checkbox-selected.svg') : configurationService.getImg('/icons/checkbox-not_selected.svg')"
                (click)="chooseMethodPayment(item.value)"
            >
        </div>
    </div>
</ng-template>
<!-- LINGUA DI STAMPA -->
<ng-template
    #PRINTER_LANGUAGE
    let-item="item"
>
    <div class="d-flex justify-content-end align-items-center PRINTER_LANGUAGE">
        <div class="sub-section-checkbox">
            <img
                style="width: 40px; height: 40px"
                [src]="item.selected ? configurationService.getImg('/icons/checkbox-selected.svg') : configurationService.getImg('/icons/checkbox-not_selected.svg')"
                (click)="chooseItemKey(item.key)"
            >
        </div>
    </div>
</ng-template>
<!-- VALUTA -->
<ng-template
    #DEFAULT_CURRENCY
    let-item="item"
>
    <div class="d-flex justify-content-end align-items-center DEFAULT_CURRENCY">
        <div class="sub-section-checkbox">
            <img
                style="width: 40px; height: 40px"
                [src]="item.selected ? configurationService.getImg('/icons/checkbox-selected.svg') : configurationService.getImg('/icons/checkbox-not_selected.svg')"
                (click)="chooseItemKey(item.key)"
            >
        </div>
    </div>
</ng-template>
<ng-template
    #LICENSE_DETAIL
    let-item="item"
>
    <div class="align-items-center d-flex justify-content-end">
        <div class="ml-2 pull-right">
            <span class="font_r-r fs24 lh16 c11">{{item.value}}</span>
        </div>
    </div>
</ng-template>
<!-- SECOND ROW ON ARTICLE BUTTON-->
<ng-template
    #ARTICLE_MOBILE_SECOND_ROW_ON_BUTTON
    let-item="item"
>
    <div class="d-flex justify-content-end align-items-center CUSTOMIZED_PAYMENT">
        <div class="sub-section-checkbox">
            <img
                style="width: 40px; height: 40px"
                [src]="_data.secondRowSelected === item.value ? configurationService.getImg('/icons/checkbox-selected.svg') : configurationService.getImg('/icons/checkbox-not_selected.svg')"
                (click)="chooseSecondRowOnButton(item.value)"
            >
        </div>
    </div>
</ng-template>
<ng-template
    #CASSE_VASSOIO
    let-item="item"
>
    <div class="d-flex justify-content-end align-items-center SELECT_STORE SELECT_CASHDESK SELECT_PRINT_STATISTICS">
        <div class="sub-section-checkbox">
            <img
                style="width: 40px; height: 40px"
                [src]="item.flag === true ? configurationService.getImg('/icons/checkbox-selected.svg') : configurationService.getImg('/icons/checkbox-not_selected.svg')"
                (click)="selectCassaVassoio(item)"
            >
        </div>
    </div>
</ng-template>