/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./exportAspect.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./exportAspect.component";
import * as i4 from "../../configuration.service";
var styles_ExportAspectComponent = [i0.styles];
var RenderType_ExportAspectComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ExportAspectComponent, data: {} });
export { RenderType_ExportAspectComponent as RenderType_ExportAspectComponent };
export function View_ExportAspectComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "d-flex flex-grow-1 h-100 justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "d-flex flex-column justify-content-between"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "m-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "span", [["class", "fs24 lh32 font_r-r c11"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "mb-5 d-flex justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("choose_the_section_to_export")); _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.configurationService.getImg("/icons/export.svg"); _ck(_v, 8, 0, currVal_1); }); }
export function View_ExportAspectComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "configuration-exportAspect", [], null, null, null, View_ExportAspectComponent_0, RenderType_ExportAspectComponent)), i1.ɵdid(1, 114688, null, 0, i3.ExportAspectComponent, [i4.ConfigurationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ExportAspectComponentNgFactory = i1.ɵccf("configuration-exportAspect", i3.ExportAspectComponent, View_ExportAspectComponent_Host_0, {}, {}, []);
export { ExportAspectComponentNgFactory as ExportAspectComponentNgFactory };
