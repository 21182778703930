import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { GROUP_TYPE, HALLS_TABLES_FIELD_TYPES, PAYMENTS_FIELD_TYPES } from '../configuration/configuration.model';
import { ActivatedRoute } from '@angular/router';
import { ConfigurationService } from '../configuration/configuration.service';
import { checkIfKeyIsPresent } from '../configuration/configuration.utils';
import { P2RDialogComponent, P2RDialogService, P2RTranslateService } from '@p2r/shared';
import { ChangeServicePercentageDialogComponent } from '../configuration/change-service-percentage-dialog/change-service-percentage-dialog.component';
import { SIZE } from '@p2r/shared/lib/shared/p2r-dialog/p2r-dialog.interface';
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'configuration-halls-tables',
  templateUrl: './halls-tables.component.html',
  styleUrls: ['./halls-tables.component.scss']
})
export class HallsTablesComponent implements OnInit {

  // {"KETCHAPP_ABILITA_COPERTI":true,"KETCHAPP_FORCE_FIRST_PAGE_MENU":true,"RETURN2HOME_AFTER_RSC":true}
  public values: any;

  /* {"HALLS": {
        "RETURN_TO_HOME":{"TITLE":{},"DESCRIPTION":{}},
        "SHOW_LAST_FOLDER":{"TITLE":{},"DESCRIPTION":{}}
      },
     "TABLES":{
        "COVERED":{"TITLE":{},"DESCRIPTION":{}}}
      }}
  */
  public items: any;

  public kuAbilitaCoperti: any;
  public kuForceFirstPageMenu: any;
  public kuReturnToHomeAfterRsc: any;
  // KU-674
  public kuVisualizzaTotaleTavolo: any;
  //
  public kuabilitavoceservizio: any;
  public kuvoceserviziopercentage: number;

  constructor(
    public configurationService: ConfigurationService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private p2rDialogService: P2RDialogService,
    private p2rTranslateService: P2RTranslateService,
    private translateService: TranslateService
  ) {
  }

  ngOnInit() {

    this.configurationService.selectedGroup = GROUP_TYPE.HALLS_TABLES;

    this.route.data.subscribe(({ data }) => {
      const [items, values] = data;

      this.items = items;
      this.values = values;

      // console.log('HALLS TABLES');
      // console.log('ITEMS => ' + JSON.stringify(this.items));
      // console.log('VALUES =>\n' + JSON.stringify(this.values));

      this.kuAbilitaCoperti = values[HALLS_TABLES_FIELD_TYPES.KETCHAPP_ABILITA_COPERTI];
      this.kuForceFirstPageMenu = !values[HALLS_TABLES_FIELD_TYPES.KETCHAPP_FORCE_FIRST_PAGE_MENU];
      this.kuReturnToHomeAfterRsc = !values[HALLS_TABLES_FIELD_TYPES.RETURN2HOME_AFTER_RSC];
      // KU-674
      this.kuVisualizzaTotaleTavolo = values[HALLS_TABLES_FIELD_TYPES.KU_VISUALIZZA_TOTALE_TAVOLI];

      const group: any = {};

      Object.keys(values).forEach(key => {
        group[key] = new FormControl(values[key], {
          validators: [],
          asyncValidators: [],
          updateOn: 'blur'
        });
      });

      this.configurationService.formGroup = this.formBuilder.group(group);
    });

    this.configurationService.groups$().subscribe(groups => {
      const found = groups.find(g => g.cod === GROUP_TYPE.HALLS_TABLES);
      this.items = found && found.items || [];
    });

    this.configurationService.values$().subscribe(values => {
      values = values[GROUP_TYPE.HALLS_TABLES];
      if (values !== undefined) {
        if (this.configurationService._selectedGroup === GROUP_TYPE.HALLS_TABLES) {
          // this.configurationService.formGroup.setValue(values);

          this.kuAbilitaCoperti = values[HALLS_TABLES_FIELD_TYPES.KETCHAPP_ABILITA_COPERTI];
          this.kuForceFirstPageMenu = !values[HALLS_TABLES_FIELD_TYPES.KETCHAPP_FORCE_FIRST_PAGE_MENU];
          this.kuReturnToHomeAfterRsc = !values[HALLS_TABLES_FIELD_TYPES.RETURN2HOME_AFTER_RSC];
          // KU-674
          this.kuVisualizzaTotaleTavolo = values[HALLS_TABLES_FIELD_TYPES.KU_VISUALIZZA_TOTALE_TAVOLI];
        }
      }
    });
  }

  public checkIfKeyIsPresent(array, key) {
    const v = checkIfKeyIsPresent(array, key);
    return v;
  }

  onClickReturn2Home() {
    this.kuReturnToHomeAfterRsc = !this.kuReturnToHomeAfterRsc;
    this.configurationService.formGroup.get(HALLS_TABLES_FIELD_TYPES.RETURN2HOME_AFTER_RSC).setValue(!this.kuReturnToHomeAfterRsc);
  }

  onClickForceFirstPageMenu() {
    this.kuForceFirstPageMenu = !this.kuForceFirstPageMenu;
    this.configurationService.formGroup.get(HALLS_TABLES_FIELD_TYPES.KETCHAPP_FORCE_FIRST_PAGE_MENU).setValue(!this.kuForceFirstPageMenu);
  }

  onClickAbilitaCoperti() {
    this.kuAbilitaCoperti = !this.kuAbilitaCoperti;
    this.configurationService.formGroup.get(HALLS_TABLES_FIELD_TYPES.KETCHAPP_ABILITA_COPERTI).setValue(this.kuAbilitaCoperti);
  }

  onClickAbilitaVisualizzaTotaleTavolo() {
    this.kuVisualizzaTotaleTavolo = !this.kuVisualizzaTotaleTavolo;
    this.configurationService.formGroup.get(HALLS_TABLES_FIELD_TYPES.KU_VISUALIZZA_TOTALE_TAVOLI).setValue(this.kuVisualizzaTotaleTavolo);
  }

}
