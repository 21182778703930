import { P2R_FIELD_TYPE } from '@p2r/shared';
import * as i0 from "@angular/core";
import * as i1 from "@p2r/shared";
import * as i2 from "@ngx-translate/core";
export class CustomArticlesService {
    constructor(p2rTranslateService, translateService) {
        this.p2rTranslateService = p2rTranslateService;
        this.translateService = translateService;
        this.MOBILE_SECOND_ROW_TYPE = [
            {
                key: 'price',
                label: this.p2rTranslateService.get(this.translateService.instant('Price')),
                value: 1,
                fieldType: P2R_FIELD_TYPE.CHECK
            },
            {
                key: 'stock',
                label: this.p2rTranslateService.get(this.translateService.instant('Stock')),
                value: 2,
                note: 'fake',
                noteId: "stock_note_id",
                fieldType: P2R_FIELD_TYPE.CHECK
            },
            {
                key: 'description',
                label: this.p2rTranslateService.get(this.translateService.instant('Description')),
                note: this.p2rTranslateService.get(this.translateService.instant('KU_SECOND_ROW_ON_BUTTON.DESCRIPTION.NOTE')),
                value: 3,
                fieldType: P2R_FIELD_TYPE.CHECK
            }
        ];
    }
    getSecondRowOnButtonCurrentSelected(kuMobileShowPriceOnButton, kuMobileShowStockOnButton) {
        if (kuMobileShowPriceOnButton == false && kuMobileShowStockOnButton == false) {
            return 3;
        }
        else if (kuMobileShowPriceOnButton) {
            return 1;
        }
        else if (kuMobileShowStockOnButton) {
            return 2;
        }
    }
}
CustomArticlesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CustomArticlesService_Factory() { return new CustomArticlesService(i0.ɵɵinject(i1.P2RTranslateService), i0.ɵɵinject(i2.TranslateService)); }, token: CustomArticlesService, providedIn: "root" });
