import { Injectable } from '@angular/core';
import { P2RTranslateService, P2R_FIELD_TYPE } from '@p2r/shared';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class CustomArticlesService {

  public MOBILE_SECOND_ROW_TYPE: any;

  constructor(private p2rTranslateService: P2RTranslateService, private translateService: TranslateService) {

    this.MOBILE_SECOND_ROW_TYPE = [
      {
        key: 'price',
        label: this.p2rTranslateService.get(this.translateService.instant('Price')),
        value: 1,
        fieldType: P2R_FIELD_TYPE.CHECK
      },
      {
        key: 'stock',
        label: this.p2rTranslateService.get(this.translateService.instant('Stock')),
        value: 2,
        note: 'fake', // in questo modo mi crea la riga della nota visto che fa il controllo (item.note)
        noteId: "stock_note_id",
        fieldType: P2R_FIELD_TYPE.CHECK
      },
      {
        key: 'description',
        label: this.p2rTranslateService.get(this.translateService.instant('Description')),
        note: this.p2rTranslateService.get(this.translateService.instant('KU_SECOND_ROW_ON_BUTTON.DESCRIPTION.NOTE')),
        value: 3,
        fieldType: P2R_FIELD_TYPE.CHECK
      }
    ];

  }

  public getSecondRowOnButtonCurrentSelected(kuMobileShowPriceOnButton, kuMobileShowStockOnButton) {
    if(kuMobileShowPriceOnButton == false && kuMobileShowStockOnButton == false) {
      return 3;
    } else if(kuMobileShowPriceOnButton) {
      return 1;
    } else if(kuMobileShowStockOnButton) {
      return 2;
    }
  }
}
