<div>
    <div
        *ngIf="this.fattureApiValues !== undefined"
        [class.disabled]="this.fattureApiValues.readonly === true"
    >
        <div>
            <div class="sub-section-container">
                <div>
                    <p
                        class="section-title"
                        style="text-transform: uppercase;"
                    >
                        {{'DOCUMENTS.SEZIONALE_FATTURA.TITLE' | translate}}
                    </p>
                    <p class="sub-section-description-2 font-r_r fs16 lh16 c10">
                        {{'DOCUMENTS.SEZIONALE_FATTURA.DESCRIPTION' | translate}}
                    </p>
                </div>
                <div
                    class="sub-section-variable"
                    style="padding-top: 16px;"
                    (click)="changeSezionale()"
                >
                    <div *ngIf="this.kuSezionaleFattura; else noSezionaleFattura">{{kuSezionaleFattura}}</div>
                    <ng-template #noSezionaleFattura>-</ng-template>
                </div>
            </div>
            <hr>
        </div>
        <p class="section-title">
            {{'DOCUMENTS.NUMERAZIONE_DOCUMENTI.TITLE' | translate}}
        </p>
        <p class="section-description font-r_r fs16 lh16 c10">
            {{'DOCUMENTS.NUMERAZIONE_DOCUMENTI.DESCRIPTION' | translate}}
        </p>
        <hr>
        <div>
            <div class="sub-section-container">
                <div>
                    <p class="sub-section-title">
                        {{'DOCUMENTS.NUMERAZIONE_DOCUMENTI.PROGRESSIVO_FATTURE.TITLE' | translate}}
                    </p>

                </div>
                <div
                    class="sub-section-variable"
                    (click)="openNumberDialog()"
                >
                    <div *ngIf="this.kuProgressivoFattura; else noProgressivo">{{kuProgressivoFattura}}</div>
                    <ng-template #noProgressivo>-</ng-template>
                </div>
            </div>
            <hr>
        </div>
    </div>
    <div *ngFor="let item of currentStoreJsonTemplate | keyvalue">
        <div *ngIf="item.key==='KA_reprint_carte'">
            <div *ngFor="let k of items | keyvalue">
                <div *ngIf="k.key==='STAMPA_COPIA_DOCUMENTO'">
                    <p class="section-title">
                        {{'DOCUMENTS.STAMPA_COPIA_DOCUMENTO.TITLE' | translate}}
                    </p>
                    <p class="section-description font-r_r fs16 lh16 c10">
                        {{'DOCUMENTS.STAMPA_COPIA_DOCUMENTO.DESCRIPTION' | translate}}
                    </p>
                    <hr>
                    <div *ngIf="items.STAMPA_COPIA_DOCUMENTO.hasOwnProperty('PAG_ELETTRONICO')">
                        <div class="sub-section-container">
                            <div>
                                <p class="sub-section-title">
                                    {{'DOCUMENTS.STAMPA_COPIA_DOCUMENTO.PAG_ELETTRONICO.TITLE' | translate}}
                                </p>
                                <p class="sub-section-description font-r_r fs16 lh16 c10">
                                    {{'DOCUMENTS.STAMPA_COPIA_DOCUMENTO.PAG_ELETTRONICO.DESCRIPTION' | translate}}
                                </p>
                            </div>
                            <div class="sub-section-checkbox">
                                <img
                                    [src]="kuPreprintWithCard ? configurationService.getImg('/icons/switch-on.svg') : configurationService.getImg('/icons/switch-off.svg')"
                                    (click)="onClickEnableReprintCart()"
                                >
                            </div>
                        </div>
                        <hr>
                    </div>
                    <div *ngIf="items.STAMPA_COPIA_DOCUMENTO.hasOwnProperty('PAG_A_CREDITO')">
                        <div class="sub-section-container">
                            <div>
                                <p
                                    class="sub-section-title"
                                    style="margin-top: 8px;"
                                >
                                    {{'DOCUMENTS.STAMPA_COPIA_DOCUMENTO.PAG_A_CREDITO.TITLE' | translate}}
                                </p>
                            </div>
                            <div class="sub-section-checkbox">
                                <img
                                    [src]="kuReprintCredit ? configurationService.getImg('/icons/switch-on.svg') : configurationService.getImg('/icons/switch-off.svg')"
                                    (click)="onClickEnableReprintCredit()"
                                >
                            </div>
                        </div>
                        <hr>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="item.key==='KU_INTESTAZIONE_FATTURA'">
            <div *ngFor="let k of items | keyvalue">
                <div *ngIf="k.key==='STAMPA_INTESTAZIONE_DOCUMENTO'">
                    <p class="section-title">
                        {{'DOCUMENTS.STAMPA_INTESTAZIONE_DOCUMENTO.TITLE' | translate}}
                    </p>
                    <p class="section-description font-r_r fs16 lh16 c10">
                        {{'DOCUMENTS.STAMPA_INTESTAZIONE_DOCUMENTO.DESCRIPTION' | translate}}
                    </p>
                    <hr>
                    <div *ngIf="items.STAMPA_INTESTAZIONE_DOCUMENTO.hasOwnProperty('FATTURA')">
                        <div class="sub-section-container">
                            <div>
                                <p
                                    class="sub-section-title"
                                    style="margin-top: 8px;"
                                >
                                    {{'DOCUMENTS.STAMPA_INTESTAZIONE_DOCUMENTO.FATTURA.TITLE' | translate}}
                                </p>
                            </div>
                            <div class="sub-section-checkbox">
                                <img
                                    [src]="kuIntestazioneFattura ? configurationService.getImg('/icons/switch-on.svg') : configurationService.getImg('/icons/switch-off.svg')"
                                    (click)="onClinkEnableIntestazioneFattura()"
                                >
                            </div>
                        </div>
                        <hr>
                    </div>
                    <div *ngIf="items.STAMPA_INTESTAZIONE_DOCUMENTO.hasOwnProperty('PROFORMA')">
                        <div class="sub-section-container">
                            <div>
                                <p
                                    class="sub-section-title"
                                    style="margin-top: 8px;"
                                >
                                    {{'DOCUMENTS.STAMPA_INTESTAZIONE_DOCUMENTO.PROFORMA.TITLE' | translate}}
                                </p>
                            </div>
                            <div class="sub-section-checkbox">
                                <img
                                    [src]="kuIntestazioneProforma ? configurationService.getImg('/icons/switch-on.svg') : configurationService.getImg('/icons/switch-off.svg')"
                                    (click)="onClinkEnableIntestazioneProforma()"
                                >
                            </div>
                        </div>
                        <hr>
                    </div>
                    <div *ngIf="items.STAMPA_INTESTAZIONE_DOCUMENTO.hasOwnProperty('RESOCONTO')">
                        <div class="sub-section-container">
                            <div>
                                <p
                                    class="sub-section-title"
                                    style="margin-top: 8px;"
                                >
                                    {{'DOCUMENTS.STAMPA_INTESTAZIONE_DOCUMENTO.RESOCONTO.TITLE' | translate}}
                                </p>
                            </div>
                            <div class="sub-section-checkbox">
                                <img
                                    [src]="kuIntestazioneResoconto ? configurationService.getImg('/icons/switch-on.svg') : configurationService.getImg('/icons/switch-off.svg')"
                                    (click)="onClinkEnableIntestazioneResoconto()"
                                >
                            </div>
                        </div>
                        <hr>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="item.key==='KU_PRINT_FATTURA_SU_STAMPANTE_CONTI'">
            <div *ngFor="let k of items | keyvalue">
                <div *ngIf="k.key==='STAMPA_SU_STAMPANTE_CONTI'">
                    <p class="section-title">
                        {{'DOCUMENTS.STAMPA_SU_STAMPANTE_CONTI.TITLE' | translate}}
                    </p>
                    <p class="section-description font-r_r fs16 lh16 c10">
                        {{'DOCUMENTS.STAMPA_SU_STAMPANTE_CONTI.DESCRIPTION' | translate}}
                    </p>
                    <hr>
                    <div *ngIf="items.STAMPA_SU_STAMPANTE_CONTI.hasOwnProperty('FATTURA')">
                        <div class="sub-section-container">
                            <div>
                                <p
                                    class="sub-section-title"
                                    style="margin-top: 8px;"
                                >
                                    {{'DOCUMENTS.STAMPA_SU_STAMPANTE_CONTI.FATTURA.TITLE' | translate}}
                                </p>
                            </div>
                            <div class="sub-section-checkbox">
                                <img
                                    [src]="kuAbilitaStampaFatturaSuConti ? configurationService.getImg('/icons/switch-on.svg') : configurationService.getImg('/icons/switch-off.svg')"
                                    (click)="onClickEnablePrintFatturaSuConti()"
                                >
                            </div>
                        </div>
                        <hr>
                    </div>
                    <div *ngIf="items.STAMPA_SU_STAMPANTE_CONTI.hasOwnProperty('PROFORMA')">
                        <div class="sub-section-container">
                            <div>
                                <p
                                    class="sub-section-title"
                                    style="margin-top: 8px;"
                                >
                                    {{'DOCUMENTS.STAMPA_SU_STAMPANTE_CONTI.PROFORMA.TITLE' | translate}}
                                </p>
                            </div>
                            <div class="sub-section-checkbox">
                                <img
                                    [src]="kuAbilitaStampaProformaSuConti ? configurationService.getImg('/icons/switch-on.svg') : configurationService.getImg('/icons/switch-off.svg')"
                                    (click)="onClickEnablePrintProformaSuConti()"
                                >
                            </div>
                        </div>
                        <hr>
                    </div>
                    <div *ngIf="items.STAMPA_SU_STAMPANTE_CONTI.hasOwnProperty('RESOCONTO')">
                        <div class="sub-section-container">
                            <div>
                                <p
                                    class="sub-section-title"
                                    style="margin-top: 8px;"
                                >
                                    {{'DOCUMENTS.STAMPA_SU_STAMPANTE_CONTI.RESOCONTO.TITLE' | translate}}
                                </p>
                            </div>
                            <div class="sub-section-checkbox">
                                <img
                                    [src]="kuAbilitaStampaPrecontoSuConti ? configurationService.getImg('/icons/switch-on.svg') : configurationService.getImg('/icons/switch-off.svg')"
                                    (click)="onClickEnablePrintPrecontoSuConti()"
                                >
                            </div>
                        </div>
                        <hr>
                    </div>
                    <div *ngIf="items.STAMPA_SU_STAMPANTE_CONTI.hasOwnProperty('STATISTICHE_DI_CHIUSURA_CASSA')">
                        <div class="sub-section-container">
                            <div>
                                <p
                                    class="sub-section-title"
                                    style="margin-top: 8px;"
                                >
                                    {{'DOCUMENTS.STAMPA_SU_STAMPANTE_CONTI.STATISTICHE_DI_CHIUSURA_CASSA.TITLE' | translate}}
                                </p>
                            </div>
                            <div class="sub-section-checkbox">
                                <img
                                    [src]="kuAbilitaStampaStatisticheDiChiusuraCassaSuConti ? configurationService.getImg('/icons/switch-on.svg') : configurationService.getImg('/icons/switch-off.svg')"
                                    (click)="onClickEnablePrintStatisticheDiChiusuraCassaSuConti()"
                                >
                            </div>
                        </div>
                        <hr>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="item.key==='KU_PRINT_LOGO_SU_PRECONTO'">
            <div
                class="sub-section-container"
                style="margin-top: 10px;"
            >
                <div>
                    <p
                        class="section-title-2"
                        style="text-transform: uppercase;"
                    >
                        {{'DOCUMENTS.KU_PRINT_LOGO_SU_PRECONTO.TITLE' | translate}}
                    </p>
                    <p class="sub-section-description-2 font-r_r fs16 lh16 c10">
                        {{'DOCUMENTS.KU_PRINT_LOGO_SU_PRECONTO.DESCRIPTION' | translate}}
                    </p>
                </div>
                <div class="sub-section-checkbox-2">
                    <img
                        [src]="kuAbilitaStampaLogoSuPreconto ? configurationService.getImg('/icons/switch-on.svg') : configurationService.getImg('/icons/switch-off.svg')"
                        (click)="onClickEnablePrintLogoOnProforma()"
                    >
                </div>
            </div>
            <hr>
        </div>
        <div *ngIf="item.key==='KU_RESOCONTO_PRE_ROMANA'">
            <div
                class="sub-section-container"
                style="margin-top: 10px;"
            >
                <div>
                    <p
                        class="section-title-2"
                        style="text-transform: uppercase;"
                    >
                        {{'DOCUMENTS.KU_RESOCONTO_PRE_ROMANA.TITLE' | translate}}
                    </p>
                    <p class="sub-section-description-2 font-r_r fs16 lh16 c10">
                        {{'DOCUMENTS.KU_RESOCONTO_PRE_ROMANA.DESCRIPTION' | translate}}
                    </p>
                </div>
                <div class="sub-section-checkbox-2">
                    <img
                        [src]="kuAbilitaResocontoPreRomana ? configurationService.getImg('/icons/switch-on.svg') : configurationService.getImg('/icons/switch-off.svg')"
                        (click)="onClickEnableResocontoPreRomana()"
                    >
                </div>
            </div>
            <hr>
        </div>
    </div>
</div>
