/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../node_modules/ngx-ui-loader/ngx-ui-loader.ngfactory";
import * as i3 from "ngx-ui-loader";
import * as i4 from "@angular/platform-browser";
import * as i5 from "@angular/router";
import * as i6 from "./app.component";
import * as i7 from "@p2r/shared";
import * as i8 from "@ngx-translate/core";
import * as i9 from "./configuration/configuration.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "bootstrap-stylesheet core-ui-stylesheet d-flex flex-column flex-grow-1 h-100"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeMenu() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "ngx-ui-loader", [], null, null, null, i2.View_ɵb_0, i2.RenderType_ɵb)), i1.ɵdid(2, 770048, null, 0, i3.ɵb, [i4.DomSanitizer, i1.ChangeDetectorRef, i3.NgxUiLoaderService], { loaderId: [0, "loaderId"] }, null), (_l()(), i1.ɵeld(3, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(4, 212992, null, 0, i5.RouterOutlet, [i5.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var currVal_0 = "configuration-loader"; _ck(_v, 2, 0, currVal_0); _ck(_v, 4, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "configuration-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 49152, null, 0, i6.AppComponent, [i7.P2RTranslateService, i8.TranslateService, i9.ConfigurationService, i1.NgZone], null, null)], null, null); }
var AppComponentNgFactory = i1.ɵccf("configuration-root", i6.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
