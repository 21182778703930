/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./mycustom.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./mycustom.component";
import * as i5 from "../configuration.service";
import * as i6 from "@angular/router";
import * as i7 from "ngx-ui-loader";
import * as i8 from "@p2r/shared";
import * as i9 from "@angular/forms";
import * as i10 from "@p2r/platform-api";
import * as i11 from "../configuration.resolve";
import * as i12 from "@p2r/env";
var styles_MycustomComponent = [i0.styles];
var RenderType_MycustomComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MycustomComponent, data: {} });
export { RenderType_MycustomComponent as RenderType_MycustomComponent };
function View_MycustomComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 13, "div", [["class", "align-items-center d-flex justify-content-between ml-3 mr-4 mt-4 mb-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "span", [["class", "section-title margin-auto"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 8, "div", [["class", "d-flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["class", "sub-section-variable section-title margin-auto mr-3"], ["style", "white-space: nowrap;"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, [" ", " "])), (_l()(), i1.ɵeld(9, 0, null, null, 5, "button", [["class", "btn rounded-pill btn-block btn-primary text-uppercase pl-3 pr-3"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectStore() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 4, "span", [["class", "font-r_r fs18 c11 lh21"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(14, 1), (_l()(), i1.ɵeld(15, 0, null, null, 0, "hr", [], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("selected_store")); _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.selectedPv.nome.toUpperCase(); _ck(_v, 8, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 12, 0, _ck(_v, 14, 0, i1.ɵnov(_v.parent.parent, 0), i1.ɵunv(_v, 12, 0, i1.ɵnov(_v, 13).transform("scegli_negozio")))); _ck(_v, 12, 0, currVal_2); }); }
function View_MycustomComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "mb-4"]], null, null, null, null, null))], null, null); }
function View_MycustomComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.kuServicesUsername; _ck(_v, 1, 0, currVal_0); }); }
function View_MycustomComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["-"]))], null, null); }
function View_MycustomComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.kuServicesPwd; _ck(_v, 1, 0, currVal_0); }); }
function View_MycustomComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["-"]))], null, null); }
function View_MycustomComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.kuServicesUrl; _ck(_v, 1, 0, currVal_0); }); }
function View_MycustomComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["-"]))], null, null); }
function View_MycustomComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 44, "div", [], [[2, "disabled", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MycustomComponent_2)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MycustomComponent_3)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 38, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 11, "div", [["class", "sub-section-container mt-4 mb-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 5, "p", [], null, null, null, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(10, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵted(11, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(13, 1), (_l()(), i1.ɵeld(14, 0, null, null, 3, "div", [["class", "pl-5 sub-section-variable"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeUsername() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MycustomComponent_4)), i1.ɵdid(16, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["noUsername", 2]], null, 0, null, View_MycustomComponent_5)), (_l()(), i1.ɵeld(18, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 11, "div", [["class", "sub-section-container mt-4 mb-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 5, "p", [], null, null, null, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(23, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵted(24, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(26, 1), (_l()(), i1.ɵeld(27, 0, null, null, 3, "div", [["class", "pl-5 sub-section-variable"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changePwd() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MycustomComponent_6)), i1.ɵdid(29, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["noPwd", 2]], null, 0, null, View_MycustomComponent_7)), (_l()(), i1.ɵeld(31, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(32, 0, null, null, 11, "div", [["class", "sub-section-container mt-4 mb-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(33, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(34, 0, null, null, 5, "p", [], null, null, null, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(36, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵted(37, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(39, 1), (_l()(), i1.ɵeld(40, 0, null, null, 3, "div", [["class", "pl-5 sub-section-variable"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeUrl() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MycustomComponent_8)), i1.ɵdid(42, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["noUrl", 2]], null, 0, null, View_MycustomComponent_9)), (_l()(), i1.ɵeld(44, 0, null, null, 0, "hr", [], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.isMultiStore; _ck(_v, 2, 0, currVal_1); var currVal_2 = !_co.isMultiStore; _ck(_v, 4, 0, currVal_2); var currVal_3 = (_co.isMultiStore ? "sub-section-title" : "sub-section-title ml-3"); _ck(_v, 10, 0, currVal_3); var currVal_5 = _co.kuServicesUsername; var currVal_6 = i1.ɵnov(_v, 17); _ck(_v, 16, 0, currVal_5, currVal_6); var currVal_7 = (_co.isMultiStore ? "sub-section-title" : "sub-section-title ml-3"); _ck(_v, 23, 0, currVal_7); var currVal_9 = _co.kuServicesPwd; var currVal_10 = i1.ɵnov(_v, 30); _ck(_v, 29, 0, currVal_9, currVal_10); var currVal_11 = (_co.isMultiStore ? "sub-section-title" : "sub-section-title ml-3"); _ck(_v, 36, 0, currVal_11); var currVal_13 = _co.kuServicesUrl; var currVal_14 = i1.ɵnov(_v, 43); _ck(_v, 42, 0, currVal_13, currVal_14); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.currentServicesValues.readonly === true); _ck(_v, 0, 0, currVal_0); var currVal_4 = i1.ɵunv(_v, 11, 0, _ck(_v, 13, 0, i1.ɵnov(_v.parent, 0), i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 12).transform("SERVIZI.MY_CUSTOM.USERNAME")))); _ck(_v, 11, 0, currVal_4); var currVal_8 = i1.ɵunv(_v, 24, 0, _ck(_v, 26, 0, i1.ɵnov(_v.parent, 0), i1.ɵunv(_v, 24, 0, i1.ɵnov(_v, 25).transform("SERVIZI.MY_CUSTOM.PWD")))); _ck(_v, 24, 0, currVal_8); var currVal_12 = i1.ɵunv(_v, 37, 0, _ck(_v, 39, 0, i1.ɵnov(_v.parent, 0), i1.ɵunv(_v, 37, 0, i1.ɵnov(_v, 38).transform("SERVIZI.MY_CUSTOM.URL")))); _ck(_v, 37, 0, currVal_12); }); }
export function View_MycustomComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.UpperCasePipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MycustomComponent_1)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.currentServicesValues !== undefined); _ck(_v, 3, 0, currVal_0); }, null); }
export function View_MycustomComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "configuration-mycustom", [], null, null, null, View_MycustomComponent_0, RenderType_MycustomComponent)), i1.ɵdid(1, 245760, null, 0, i4.MycustomComponent, [i5.ConfigurationService, i6.ActivatedRoute, i7.NgxUiLoaderService, i8.P2RLocalStorageService, i9.FormBuilder, i8.P2RDialogService, i8.P2RTranslateService, i2.TranslateService, i10.P2RApiService, i11.ConfigurationResolverServices, i12.P2REnvironmentService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MycustomComponentNgFactory = i1.ɵccf("configuration-mycustom", i4.MycustomComponent, View_MycustomComponent_Host_0, {}, {}, []);
export { MycustomComponentNgFactory as MycustomComponentNgFactory };
