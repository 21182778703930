<div>
    <div *ngIf="checkIfKeyIsPresent(templateStoreApiValues, 'KU_ABILITA_STOCK')">
        <div class="sub-section-container">
            <div>
                <p
                    class="section-title"
                    style="text-transform: uppercase;"
                >
                    {{'ARTICLES.KU_ABILITA_STOCK.TITLE' | translate}}
                </p>
                <p class="sub-section-description-2 font-r_r fs16 lh16 c10">
                    {{'ARTICLES.KU_ABILITA_STOCK.SUBTITLE' | translate}}
                </p>
            </div>
            <div class="sub-section-checkbox-2">
                <img
                    [src]="kuabilitastock ? configurationService.getImg('/icons/switch-on.svg') :
            configurationService.getImg('/icons/switch-off.svg')"
                    (click)="onClickAbilitaStock()"
                >
            </div>
        </div>
        <hr>
    </div>
    <div *ngIf="checkIfKeyIsPresent(templateStoreApiValues, 'KU_BARCODE_ENABLED')">
        <div class="sub-section-container">
            <div>
                <p
                    class="section-title-2"
                    style="text-transform: uppercase;"
                >
                    {{'ARTICLES.KU_BARCODE_ENABLED.TITLE' | translate}}
                </p>
                <p class="sub-section-description-2 font-r_r fs16 lh16 c10">
                    {{'ARTICLES.KU_BARCODE_ENABLED.SUBTITLE' | translate}}
                </p>
            </div>
            <div class="sub-section-checkbox">
                <img
                    [src]="kuabilitavenditacodice ? configurationService.getImg('/icons/switch-on.svg') :
            configurationService.getImg('/icons/switch-off.svg')"
                    (click)="onClickAbilitaVenditaCodice()"
                >
            </div>
        </div>
        <hr>
    </div>
    <div class="sub-section-container" style="margin-top:30px;">
        <div class="d-flex">
            <p
                class="section-title-2"
                style="text-transform: uppercase;"
            >
                {{'GENERALI.KU_LISTINO_PERIODS' | translate}}
            </p>
            <ng-container *ngIf="canAdd">
                <img
                    [src]="configurationService.getImg(images.add)"
                    class="icon"
                    style="margin-left: 12px;"
                    width="40"
                    height="40"
                    (click)="addTimeSlot()"
                >
            </ng-container>
        </div>
    </div>
    <hr>
    <div class="align-items-center d-flex sub-section-container">
        <ng-container>
            <div>
                <p class="sub-section-title text-uppercase">
                    {{ 'GENERALI.KU_EXCLUDE_DESK_FROM_TIMESLOT_LISTINO.TITLE' | translate }}
                </p>
                <p class="sub-section-description font-r_r fs16 lh16 c10">
                    {{ 'GENERALI.KU_EXCLUDE_DESK_FROM_TIMESLOT_LISTINO.SUBTITLE' | translate }}
                </p>
            </div>
        </ng-container>
        <ng-container>
            <div class="sub-section-checkbox">
                <img
                    [src]="!kuexcludedesk ? configurationService.getImg('/icons/switch-on.svg') :
                            configurationService.getImg('/icons/switch-off.svg')"
                    (click)="onClickExcludeDesk()"
                >
            </div>
        </ng-container>
    </div>
    <hr>
    <div *ngFor="let listinoPeriod of listinoPeriods; index as i">
        <div class="align-items-center d-flex sub-section-container">
            <ng-container [ngSwitch]="i">
                <ng-container *ngSwitchCase="0">
                    <div [class.disabled]="!listinoPeriod.enabled">
                        <p class="sub-section-title">
                            {{'GENERALI.TIME_SLOT_1.TITLE' | translate | uppercase}}
                        </p>
                        <p class="sub-section-description font-r_r fs16 lh16 c10">
                            {{'GENERALI.TIME_SLOT_1.SUBTITLE' | translate}}
                        </p>
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="1">
                    <div [class.disabled]="!listinoPeriod.enabled">
                        <p class="sub-section-title">
                            {{'GENERALI.TIME_SLOT_2.TITLE' | translate | uppercase}}
                        </p>
                        <p class="sub-section-description font-r_r fs16 lh16 c10">
                            {{'GENERALI.TIME_SLOT_2.SUBTITLE' | translate}}
                        </p>
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="2">
                    <div [class.disabled]="!listinoPeriod.enabled">
                        <p class="sub-section-title">
                            {{'GENERALI.TIME_SLOT_3.TITLE' | translate | uppercase}}
                        </p>
                        <p class="sub-section-description font-r_r fs16 lh16 c10">
                            {{'GENERALI.TIME_SLOT_3.SUBTITLE' | translate}}
                        </p>
                    </div>
                </ng-container>
            </ng-container>
            <div class="sub-section-number align-items-center d-flex">
                <div
                    (click)="openChangeTimeSlot(i)"
                    [class.disabled]="!listinoPeriod.enabled"
                >
                    <div *ngIf="listinoPeriod.startTime + listinoPeriod.endTime; else noBrokerIp">
                        <span
                            style="white-space: nowrap;"
                            class="mr-1"
                        >
                            {{convertMinutesToHourMinutes(listinoPeriod.startTime)}} - {{convertMinutesToHourMinutes(listinoPeriod.endTime)}}
                        </span>
                    </div>
                    <ng-template #noBrokerIp>00:00 - 23:59</ng-template>
                </div>
                <img
                    [src]="configurationService.getImg('icons/options_img.svg')"
                    class="icon"
                    style="margin-left: 12px;"
                    width="40"
                    height="40"
                    (click)="configTimeSlot(i)"
                >
                <ng-container *ngIf="i >= 1; else noImg">
                    <img
                        [src]="configurationService.getImg(images.remove)"
                        class="icon"
                        style="margin-left: 12px;"
                        width="40"
                        height="40"
                        (click)="removeTimeSlot(i)"
                    >
                </ng-container>
                <ng-template #noImg>
                    <div *ngIf="listinoPeriods.length > 1">
                        <div style="width: 40px; height: 40px; margin-left: 12px;"></div>
                    </div>
                </ng-template>
            </div>
        </div>
        <hr>
    </div>
</div>
