<div class="d-flex flex-column">
    <div>
        <!-- <div
            id="title-container"
            class="title-container"
        >
            {{'change_description_dialog_title' | translate}}
        </div> -->
        <!-- <hr> -->
        <div class="input-container">
            <label class="label-description">{{'ip_host' | translate}}</label>
            <input
                class="input-description"
                name="ip_host"
                [placeholder]='digitIpHostPlaceholder'
                [(ngModel)]="data.ip"
                required
                customVirtualKeyboard
            >
        </div>
    </div>
</div>
