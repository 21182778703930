<div>
    <div *ngIf="checkIfKeyIsPresent(values, 'KU_ABILITA_STOCK')">
        <div *ngIf="checkIfKeyIsPresent(items, 'KU_ABILITA_STOCK')">
            <div
                class="align-items-center d-flex justify-content-between sub-section-container" style="margin-top:30px;"
            >
                <div>
                    <p
                    class="section-title"
                    style="text-transform: uppercase;"
                    >
                    {{'ARTICLES.KU_ABILITA_STOCK.TITLE' | translate}}
                    </p>
                    <p class="sub-section-description font-r_r fs16 lh16 c10">
                        {{'ARTICLES.KU_ABILITA_STOCK.SUBTITLE' | translate}}
                    </p>
                    <p class="sub-section-description font-r_r fs14 lh16 c10 mt-2" style="font-weight: bold;">
                        {{'ARTICLES.KU_ABILITA_STOCK.NOTE' | translate}}
                    </p>
                </div>
                <div class="sub-section-checkbox">
                    <img
                        [src]="kuabilitastock ? configurationService.getImg('/icons/switch-on.svg') :
                configurationService.getImg('/icons/switch-off.svg')"
                        (click)="onClickAbilitaStock()"
                    >
                </div>
            </div>
            <hr>
        </div>
    </div>
    <div *ngIf="checkIfKeyIsPresent(values, 'KU_MOBILE_SHOW_PRICE_ON_BUTTONS')">
        <div *ngIf="checkIfKeyIsPresent(items, 'KU_SECOND_ROW_ON_BUTTON')">
            <div
                class="align-items-center d-flex justify-content-between sub-section-container"
                style="margin-right: 32px;"
            >
                <div>
                    <div style="display: flex;">
                        <p
                        class="section-title"
                        style="text-transform: uppercase;"
                        >
                            {{'ARTICLES.KU_SECOND_ROW_ON_BUTTON.TITLE' | translate}}
                        </p>
                        <div class="label_mobile">
                            MOBILE
                        </div>
                    </div>
                    <p class="sub-section-description font-r_r fs16 lh16 c10">
                        {{'ARTICLES.KU_SECOND_ROW_ON_BUTTON.SUBTITLE' | translate}}
                    </p>
                </div>
                <div>
                    <div class="d-flex align-items-center">
                        <span class="sub-section-value mr-3">{{getSecondRowOnButtonCurrentLabel()}}</span>
                        <button
                            class="btn rounded-pill btn-block btn-primary text-uppercase pl-3 pr-3 text-center"
                            style="height: 40px; width: 120px;"
                            (click)="chooseSecondRowOnButton()"
                        >
                            <span class="font-r_r fs18 c13 lh21">
                                <span>{{'choose' | translate | uppercase}}</span>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
            <hr>
        </div>
    </div>
    <div *ngIf="checkIfKeyIsPresent(values, 'KU_BARCODE_ENABLED')">
        <div *ngIf="checkIfKeyIsPresent(items, 'KU_BARCODE_ENABLED')">
            <div 
                class="align-items-center d-flex justify-content-between sub-section-container"
            >
                <div>
                    <p
                        class="section-title"
                        style="text-transform: uppercase;"
                    >
                        {{'ARTICLES.KU_BARCODE_ENABLED.TITLE' | translate}}
                    </p>
                    <p class="sub-section-description font-r_r fs16 lh16 c10">
                        {{'ARTICLES.KU_BARCODE_ENABLED.SUBTITLE' | translate}}
                    </p>
                </div>
                <div class="sub-section-checkbox">
                    <img
                        [src]="kuBarcodeEnabled ? configurationService.getImg('/icons/switch-on.svg') :
                configurationService.getImg('/icons/switch-off.svg')"
                        (click)="onClickAbilitaVenditaCodice()"
                    >
                </div>
            </div>
        </div>
        <hr>
    </div>
    <div *ngIf="checkIfKeyIsPresent(values, 'KU_LABEL_DEFAULT_MOD_VARIANT')">
        <div *ngIf="checkIfKeyIsPresent(items, 'KU_DEFAULT_LABEL_MODIFICATORE')">
            <div class="sub-section-container-label">
                <div>
                    <p
                        class="section-title"
                        style="text-transform: uppercase;"
                    >
                        {{'ARTICLES.KU_DEFAULT_LABEL_MODIFICATORE.TITLE' | translate}}
                    </p>
                    <p class="sub-section-description font-r_r fs16 lh16 c10">
                        {{'ARTICLES.KU_DEFAULT_LABEL_MODIFICATORE.SUBTITLE' | translate}}
                    </p>
                </div>
                <div
                    class="sub-section-label"
                    (click)="openChangePrefixDialog()"
                >
                    <div *ngIf="(kuLabelDefaultModVariant && kuLabelDefaultModVariant.length > 0); else elseBlock">
                        <td class="sub-section-label-value">{{kuLabelDefaultModVariant}}</td>
                        <td class="sub-section-container-label-variant">[{{'variant' | translate}}]</td>
                    </div>
                    <ng-template #elseBlock>
                        <td class="sub-section-label-value">{{'no_prefix' | translate}}</td>
                    </ng-template>
                </div>
            </div>
            <hr>
        </div>
    </div>
</div>
