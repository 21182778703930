import { Component, OnInit } from '@angular/core';
import { P2REnvironmentService } from '@p2r/env';
import { P2RLocalStorageService, P2RTranslateService } from '@p2r/shared';
import { ConfigurationService } from '../../configuration.service';
import { ITemplateImport, TEMPLATE_TYPE } from '../importExport.model';
import { ImportExportService } from '../importExport.service';
import { TranslateService } from '@ngx-translate/core';
import { GlobalVariable } from 'src/app/global';

@Component({
  selector: 'configuration-exportArchives',
  templateUrl: './exportArchives.component.html',
  styleUrls: ['./exportArchives.component.scss']
})
export class ExportArchivesComponent implements OnInit {

  templateList: ITemplateImport[] = [];
  templateSelected: ITemplateImport;
  isMultiStore: boolean;
  selectedPv: any;
  storeList: any[];
  TEMPLATE_TYPE = TEMPLATE_TYPE;

  constructor(
    public configurationService: ConfigurationService,
    private p2rTranslateService: P2RTranslateService,
    private translateService: TranslateService,
    private importExportService: ImportExportService,
    private p2rEnvironmentService: P2REnvironmentService,
    private p2rLocalStorageService: P2RLocalStorageService,
  ) { }

  ngOnInit() {
    setTimeout(() => {
      this.templateList = [
        {
          templateType: TEMPLATE_TYPE.ARTICLES,
          name: this.p2rTranslateService.get(this.translateService.instant('articles')).toLocaleUpperCase(),
          isSelected: false
        },
        {
          templateType: TEMPLATE_TYPE.HALLS_TABLES,
          name: this.p2rTranslateService.get(this.translateService.instant('halls_and_tables')).toLocaleUpperCase(),
          isSelected: false
        },
        {
          templateType: TEMPLATE_TYPE.CLIENTS,
          name: this.p2rTranslateService.get(this.translateService.instant('clients')).toLocaleUpperCase(),
          isSelected: false
        },
      ]

      this.templateList[0].isSelected = true;
      this.templateSelected = this.templateList[0];

      if (this.p2rEnvironmentService.storeData !== undefined) {
        this.isMultiStore = this.p2rEnvironmentService.storeData.multistore;
        this.storeList = this.p2rEnvironmentService.storeData.pv;
      } else {
        this.isMultiStore = false;
      }
    }, 200);

    this.selectedPv = this.p2rLocalStorageService.getFromLocalStorage(GlobalVariable.SELECTED_PV_KEY_STORAGE, []);
  }

  get exportEnabled() {
    return this.templateSelected && this.templateSelected.templateType === TEMPLATE_TYPE.HALLS_TABLES
      ? this.isMultiStore
        ? this.selectedPv && this.templateSelected
        : true
      : this.templateSelected
  }

  public storeCmp = (a, b) => a.codice === b.codice;

  public export() {
    console.log(this.templateSelected)

    switch (this.templateSelected.templateType) {
      case TEMPLATE_TYPE.ARTICLES:
        this.importExportService.exportArt();
        break;
      case TEMPLATE_TYPE.HALLS_TABLES:
        this.importExportService.exportHallsTables(this.selectedPv.codice);
        break;
      case TEMPLATE_TYPE.CLIENTS:
        this.importExportService.exportClients();
        break;
      default:
        break;
    }
  }

}
