export enum ORDER_PRINTING_TYPE {
  ON_ORDER,
  BEFORE_DELIVERY
}

export interface TakeawayPeriod {
  START: number,
  END: number,
  DESCRIPTION: string
}
