/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./custom-order.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./custom-order.component";
import * as i5 from "../configuration/configuration.service";
import * as i6 from "@angular/router";
import * as i7 from "@angular/forms";
var styles_CustomOrderComponent = [i0.styles];
var RenderType_CustomOrderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CustomOrderComponent, data: {} });
export { RenderType_CustomOrderComponent as RenderType_CustomOrderComponent };
function View_CustomOrderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "align-items-center d-flex justify-content-between sub-section-container"], ["style", "margin-top:30px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "p", [["class", "section-title"], ["style", "text-transform: uppercase;"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 2, "p", [["class", "sub-section-description font-r_r fs16 lh16 c10"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(9, 0, null, null, 1, "div", [["class", "sub-section-checkbox"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "img", [], [[8, "src", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickAbilitaComandaPosticipata() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "hr", [], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("ORDERS.KU_ABILITA_COMANDA_POSTICIPATA.TITLE")); _ck(_v, 4, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("ORDERS.KU_ABILITA_COMANDA_POSTICIPATA.SUBTITLE")); _ck(_v, 7, 0, currVal_1); var currVal_2 = (_co.kuabilitaComandaPosticipata ? _co.configurationService.getImg("/icons/switch-on.svg") : _co.configurationService.getImg("/icons/switch-off.svg")); _ck(_v, 10, 0, currVal_2); }); }
function View_CustomOrderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomOrderComponent_2)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.checkIfKeyIsPresent(_co.items, "KU_ABILITA_COMANDA_POSTICIPATA"); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_CustomOrderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomOrderComponent_1)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.checkIfKeyIsPresent(_co.values, "KU_ABILITA_COMANDA_POSTICIPATA"); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_CustomOrderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "configuration-custom-order", [], null, null, null, View_CustomOrderComponent_0, RenderType_CustomOrderComponent)), i1.ɵdid(1, 114688, null, 0, i4.CustomOrderComponent, [i5.ConfigurationService, i6.ActivatedRoute, i7.FormBuilder], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomOrderComponentNgFactory = i1.ɵccf("configuration-custom-order", i4.CustomOrderComponent, View_CustomOrderComponent_Host_0, {}, {}, []);
export { CustomOrderComponentNgFactory as CustomOrderComponentNgFactory };
