<div class="d-flex flex-grow-1 w-100 steps text-center import-export">
    <div
        style="min-width: 300px;"
        class="mt-3 w-33 border-right"
    >
        <div class="d-flex flex-column flex-grow-1 h-100 justify-content-between">
            <div>
                <div
                    style="margin-top: 40px;"
                    class="disabled-step"
                >
                    <span class="numstep">1</span>
                </div>
                <div class="ml-3 mr-3 mt-5 p-2">
                    <span class="fs24 lh32 font_r-r c11">
                        {{'if_you_already_have_a_file_exported_from_keep_up_go_to_step_4'| translate}}
                    </span>
                </div>
                <div class="m-4 p-2">
                    <span class="fs24 lh32 font_r-r c11">{{'or'| translate}}</span>
                </div>
                <div class="m-5 p-2">
                    <span class="fs24 lh32 font_r-r c11">
                        {{'download_the_desired_blank_template'| translate}}
                    </span>
                </div>
                <div class="mb-3 ml-4 mr-4">
                    <ng-select
                        [(ngModel)]="templateSelected"
                        name="template"
                        [clearable]="false"
                        [searchable]="false"
                    >
                        <ng-option
                            *ngFor="let template of templateList"
                            [value]="template"
                        >
                            <span class="font_r-r fs18 lh22 c11">{{template.name}}</span>
                        </ng-option>
                    </ng-select>
                </div>
                <div>
                    <button
                        (click)="download()"
                        class="btn btn-custom pl-5 pr-5"
                        [disabled]="!templateSelected"
                        style="width: 230px;"
                    >
                        {{'download' | translate | uppercase}}
                    </button>
                </div>
            </div>
            <div class="mb-5 mt-4">
                <img [src]="configurationService.getImg('/icons/export.svg')">
            </div>
        </div>
    </div>
    <div
        style="min-width: 300px;"
        class="mt-3 w-33 border-right"
    >
        <div class="d-flex flex-column flex-grow-1 h-100 justify-content-between">
            <div>
                <div
                    style="margin-top: 40px;"
                    class="disabled-step"
                >
                    <span class="numstep">2</span>
                </div>
                <div class="ml-5 mr-5 mt-5 p-2">
                    <span class="fs24 lh32 font_r-r c11">
                        {{'fill_in_the_blank_template_with_your_details'| translate}}
                    </span>
                </div>
                <div class="m-4 p-2">
                    <span class="fs24 lh32 font_r-r c11">{{'or'| translate}}</span>
                </div>
                <div class="m-5 p-2">
                    <span class="fs24 lh32 font_r-r c11">
                        {{'use_it_as_a_track_to_edit_the_file_in_your_possession'| translate}}
                    </span>
                </div>
            </div>
            <div class="mb-5 mt-4">
                <img [src]="configurationService.getImg('/icons/export_edit.svg')">
            </div>
        </div>
    </div>
    <div
        style="min-width: 300px;"
        class="mt-3 w-33 border-right"
    >
        <div class="d-flex flex-column flex-grow-1 h-100 justify-content-between">
            <div>
                <div
                    style="margin-top: 40px;"
                    class="disabled-step"
                >
                    <span class="numstep">3</span>
                </div>
                <div class="ml-5 mr-5 mt-5 p-2">
                    <span class="fs24 lh32 font_r-r c11">
                        {{'save_the_template_filled_in_with_your_details'| translate}}
                    </span>
                </div>
            </div>
            <div class="mb-5 mt-4">
                <img [src]="configurationService.getImg('/icons/export_save.svg')">
            </div>
        </div>
    </div>
    <div
        style="min-width: 300px;"
        class="mt-3 w-33"
    >
        <div class="d-flex flex-column flex-grow-1 h-100 justify-content-between">
            <div>
                <div
                    style="margin-top: 40px;"
                    class="disabled-step"
                >
                    <span class="numstep">4</span>
                </div>
                <div class="ml-5 mr-5 mt-5 p-2 mb-5">
                    <span class="fs24 lh32 font_r-r c11">
                        {{'choose_the_type_of_archive_to_import'| translate}}
                    </span>
                </div>
                <div class="mb-3 ml-4 mr-4">
                    <ng-select
                        [(ngModel)]="importTemplateSelected"
                        name="template"
                        [clearable]="false"
                        [searchable]="false"
                        (ngModelChange)="clearFileToImport()"
                    >
                        <ng-option
                            *ngFor="let template of templateList"
                            [value]="template"
                        >
                            <span class="font_r-r fs18 lh22 c11">{{template.name}}</span>
                        </ng-option>
                    </ng-select>
                </div>
                <div class="ml-5 mr-5 mt-5 p-2 mb-5">
                    <span class="fs24 lh32 font_r-r c11">
                        {{'choose_the_file_and_import_the_data'| translate}}
                    </span>
                </div>
                <div>
                    <button
                        (click)="file.click()"
                        class="btn btn-custom pl-5 pr-5"
                        style="width: 230px;"
                    >
                        {{'choose_the_file' | translate | uppercase}}
                    </button>
                    <input
                        #file
                        accept=".csv"
                        type="file"
                        (change)="onFileChange($event)"
                        (click)="$event.target.value=null"
                        hidden
                    >
                    <div class="mt-1">
                        <ng-container *ngIf="choosedFileName; else nochoosedFileName">
                            <span class="font_r-r fs18 lh24">{{choosedFileName}}</span>
                        </ng-container>
                        <ng-template #nochoosedFileName>
                            <span
                                class="font_r-r fs18 lh24"
                                style="color:#8A8D8F"
                            >
                                {{'no_file_chosen'| translate}}
                            </span>
                        </ng-template>
                    </div>
                </div>
                <ng-container *ngIf="choosedFile && importTemplateSelected && importTemplateSelected.templateType === TEMPLATE_TYPE.HALLS_TABLES && isMultiStore">
                    <div class="m-4">
                        <ng-select
                            [(ngModel)]="selectedPv"
                            name="store"
                            [clearable]="false"
                            [searchable]="false"
                            [compareWith]="storeCmp"
                        >
                            <ng-option
                                *ngFor="let store of storeList"
                                [value]="store"
                            >
                                <span class="font_r-r fs18 lh22 c11">{{store.nome}}</span>
                            </ng-option>
                        </ng-select>
                    </div>
                </ng-container>
                <div class="mt-5">
                    <button
                        (click)="import()"
                        class="btn btn-custom pl-5 pr-5"
                        style="width: 230px;"
                        [disabled]="!importEnabled"
                    >
                        {{'import' | translate | uppercase}}
                    </button>
                </div>
            </div>
            <div class="mb-5 mt-4">
                <img [src]="configurationService.getImg('/icons/import.svg')">
            </div>
        </div>
    </div>
</div>
