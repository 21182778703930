import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'configuration-custom4u',
  templateUrl: './custom4u.component.html',
  styleUrls: ['./custom4u.component.scss']
})
export class Custom4uComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
