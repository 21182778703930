import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { ConfigurationService } from '../configuration/configuration.service';
import { ActivatedRoute } from '@angular/router';
import { ARTICLES_FIELD_TYPES, GROUP_TYPE } from '../configuration/configuration.model';
import { checkIfKeyIsPresent } from '../configuration/configuration.utils';
import { P2RDialogComponent, P2RTranslateService, P2RDialogService, P2R_FIELD_TYPE } from '@p2r/shared';
import { TranslateService } from '@ngx-translate/core';
import { CUSTOMIZE_TYPE } from '../customize-dialog/customize-dialog.model';
import { CustomizeDialogComponent } from 'src/app/customize-dialog/customize-dialog.component';
import { CustomArticlesService } from './custom-articles.service';
import { ChangeTextDialogComponent } from '../configuration/change-text-dialog/change-text-dialog.component';
import { SIZE } from "@p2r/shared/lib/shared/p2r-dialog/p2r-dialog.interface";
@Component({
  selector: 'configuration-custom-articles',
  templateUrl: './custom-articles.component.html',
  styleUrls: ['./custom-articles.component.scss']
})
export class CustomArticlesComponent implements OnInit {

    /*
  {
    "KU_ABILITA_STOCK":true,
    "KU_MOBILE_SHOW_PRICE_ON_BUTTONS":true,
    "KU_MOBILE_SHOW_STOCK_ON_BUTTONS":"false"
  }
  */
  public values: any;

  /*
  {
    "KU_SECOND_ROW_ON_BUTTON":{
      "TITLE":{},
      "DESCRIPTION":{}
    }
  }
  */
  public items: any;

  public kuabilitastock: any;
  public kuBarcodeEnabled: any;
  public kuMobileShowPriceOnButton: any;
  public kuMobileShowStockOnButton: any;
  public kuLabelDefaultModVariant: string;

  constructor(public configurationService: ConfigurationService, private route: ActivatedRoute,
    private p2rTranslateService: P2RTranslateService, private translateService: TranslateService,
    private p2rDialogService: P2RDialogService, private customArticleService: CustomArticlesService,
    private cdRef: ChangeDetectorRef, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.configurationService.selectedGroup = GROUP_TYPE.ARTICLES;

    this.route.data.subscribe(({ data }) => {
      const [items, values] = data;

      this.items = items;
      this.values = values;

      // console.log(this.items);
      console.log(this.values);

      this.kuabilitastock = values[ARTICLES_FIELD_TYPES.KU_ABILITA_STOCK];
      this.kuBarcodeEnabled = values[ARTICLES_FIELD_TYPES.KU_BARCODE_ENABLED];
      this.kuMobileShowPriceOnButton = values[ARTICLES_FIELD_TYPES.KU_MOBILE_SHOW_PRICE_ON_BUTTONS];
      this.kuMobileShowStockOnButton = values[ARTICLES_FIELD_TYPES.KU_MOBILE_SHOW_STOCK_ON_BUTTONS];
      this.kuLabelDefaultModVariant = values[ARTICLES_FIELD_TYPES.KU_LABEL_DEFAULT_MOD_VARIANT];
      this.kuLabelDefaultModVariant = this.replaceAll(this.kuLabelDefaultModVariant, "\`", "\'"); // Solo per visualizzare meglio apostrofo

      const group: any = {};

      Object.keys(values).forEach(key => {
        group[key] = new FormControl(values[key], {
          validators: [],
          asyncValidators: [],
          updateOn: 'blur'
        });
      });

      this.configurationService.formGroup = this.formBuilder.group(group);
    });

    this.configurationService.groups$().subscribe(groups => {
      const found = groups.find(g => g.cod === GROUP_TYPE.ARTICLES);
      this.items = found && found.items || [];
    });

    this.configurationService.values$().subscribe(values => {
      values = values[GROUP_TYPE.ARTICLES];
      if (values !== undefined) {
        if (this.configurationService._selectedGroup === GROUP_TYPE.ARTICLES) {
          // this.configurationService.formGroup.setValue(values);
          this.kuabilitastock = values[ARTICLES_FIELD_TYPES.KU_ABILITA_STOCK];
          this.kuBarcodeEnabled = values[ARTICLES_FIELD_TYPES.KU_BARCODE_ENABLED];
          this.kuMobileShowPriceOnButton = values[ARTICLES_FIELD_TYPES.KU_MOBILE_SHOW_PRICE_ON_BUTTONS];
          this.kuMobileShowStockOnButton = values[ARTICLES_FIELD_TYPES.KU_MOBILE_SHOW_STOCK_ON_BUTTONS];
          this.kuLabelDefaultModVariant = values[ARTICLES_FIELD_TYPES.KU_LABEL_DEFAULT_MOD_VARIANT];
          this.kuLabelDefaultModVariant = this.replaceAll(this.kuLabelDefaultModVariant, "\`", "\'"); // Solo per visualizzare meglio apostrofo
        }
      }
    });

  }

  /**
   * Se la stringa non è nulla, sostituisce tutte le occorrenze
   * della variabile search con replace.
   * @param string
   * @param search
   * @param replace
   * @returns
   */
  replaceAll(string, search, replace) {
    if (string != undefined && string.length > 0) {
      return string.split(search).join(replace);
    } else {
      return string;
    }
  }

  public checkIfKeyIsPresent(array, key) {
    const v = checkIfKeyIsPresent(array, key);
    return v;
  }

  public getSecondRowOnButtonCurrentLabel() {
    if(this.kuMobileShowPriceOnButton == false && this.kuMobileShowStockOnButton == false) {
      return this.p2rTranslateService.get(this.translateService.instant('Description'));
    } else if(this.kuMobileShowPriceOnButton) {
      return this.p2rTranslateService.get(this.translateService.instant('Price'));
    } else if(this.kuMobileShowStockOnButton) {
      return this.p2rTranslateService.get(this.translateService.instant('Stock'));
    }
  }

  public chooseSecondRowOnButton() {
    const dialogData = {
      hasSearch: false,
      customizeType: CUSTOMIZE_TYPE.ARTICLE_MOBILE_SECOND_ROW_ON_BUTTON,
      secondRowSelected: this.customArticleService.getSecondRowOnButtonCurrentSelected(this.kuMobileShowPriceOnButton, this.kuMobileShowStockOnButton),
      opt: {},
      items: this.customArticleService.MOBILE_SECOND_ROW_TYPE
    }

    const dialog = this.p2rDialogService.open(P2RDialogComponent, {
      id: 'lst-second_row_on_button-dialog',
      body: CustomizeDialogComponent,
      titleHTML: this.createSecondRowMobileTitle(),
      size: 'lg',
      keyboard: false,
      closeOnOutsideClick: false,
      closeButton: {
        label: this.p2rTranslateService.get(this.translateService.instant('close')),
        buttonClass: 'btn btn-primary rounded-pill lp-bottom-btn ku-margin-bottom-auto',
        labelClass: 'text-uppercase font-r_l fs18 lh21',
        click: () => {
          const p = dialogData.secondRowSelected;
          switch (p) {
            case 1:
              this.onSecondRowPriceSelected();
              break;
            case 2:
              this.onSecondRowStockSelected();
              break;
            case 3:
              this.onSecondRowDescriptionSelected();
            default:
              break;
          }
          this.cdRef.detectChanges();
          this.setSecondRowOnMobileButtonValue();
        }
      },
      data: dialogData
    });

  }

  private createSecondRowMobileTitle() {
    let s = "<div>";
          s += "<div class='d-flex'>";
            s += "<p class='text-uppercase font-r_r fs24 lh21 c11 mt-2 ku-font-weight-500'>";
              s += this.p2rTranslateService.get(this.translateService.instant('ARTICLES.KU_SECOND_ROW_ON_BUTTON.TITLE'));
            s += "</p>";
            s += "<div class='label_mobile ml-2 mb-20'>MOBILE</div>";
          s += "</div>";
          s += "<div class='d-flex mt-2 ku-font-weight-normal'>";
            s += "<p class='txt-color-grey ml-1 font-r_r fs18 mt-auto'>";
              s +=  this.p2rTranslateService.get(this.translateService.instant('KU_SECOND_ROW_ON_BUTTON.PREVIEW'));
            s += "<p>";
            s += "<div class='d-flex p-0 mobile_preview_btn ml-5'>";
              s += "<div class='tag'></div>";
              s += "<div class='txt16px label'>";
                s += "<div class='pl-2 pr-1 txtEllipsis mobile_preview_btn_font mobile_preview_btn_text'>";
                  s += this.p2rTranslateService.get(this.translateService.instant('KU_SECOND_ROW_ON_BUTTON.PREVIEW.TITLE'));
                s += "</div>";
                s += "<div class='pr-2 pt-2 text-right mobile_preview_price_stock_font mobile_preview_btn_price'>8,00 €</div>";
                s += "<div class='pr-2 pt-2 text-right mobile_preview_price_stock_font mobile_preview_btn_stock'>(10p)</div>";
              s += "</div>";
            s += "</div>";
          s += "</div>";
        s += "</div>";
    return s;
  }

  private onSecondRowPriceSelected() {
    this.kuMobileShowPriceOnButton = true;
    this.kuMobileShowStockOnButton = false;
  }

  private onSecondRowStockSelected() {
    this.kuMobileShowPriceOnButton = false;
    this.kuMobileShowStockOnButton = true;
  }

  private onSecondRowDescriptionSelected() {
    this.kuMobileShowPriceOnButton = false;
    this.kuMobileShowStockOnButton = false;
  }

  private setSecondRowOnMobileButtonValue() {
    this.configurationService.formGroup.get(ARTICLES_FIELD_TYPES.KU_MOBILE_SHOW_PRICE_ON_BUTTONS).setValue(this.kuMobileShowPriceOnButton);
    this.configurationService.formGroup.get(ARTICLES_FIELD_TYPES.KU_MOBILE_SHOW_STOCK_ON_BUTTONS).setValue(this.kuMobileShowStockOnButton);
  }

  public onClickAbilitaStock() {
    this.kuabilitastock = !this.kuabilitastock;
    this.configurationService.formGroup.get(ARTICLES_FIELD_TYPES.KU_ABILITA_STOCK).setValue(this.kuabilitastock);
  }

  public onClickAbilitaVenditaCodice() {
    this.kuBarcodeEnabled = !this.kuBarcodeEnabled;
    this.configurationService.formGroup.get(ARTICLES_FIELD_TYPES.KU_BARCODE_ENABLED).setValue(this.kuBarcodeEnabled);
  }

  public openChangePrefixDialog() {
    console.log("openChangePrefixDialog");
    const dataToDialog = {
      text: this.kuLabelDefaultModVariant,
      input_title: this.p2rTranslateService.get(
        this.translateService.instant("prefix")
      ),
      rightFixedPlaceholder: this.p2rTranslateService.get(
        this.translateService.instant("variant")
      ),
      note: this.p2rTranslateService.get(this.translateService.instant("ku_default_label_modificatore_note"))
    };
    const alert = this.p2rDialogService.open(P2RDialogComponent, {
      id: 'change-text-dialog',
      hideCloseButton: true,
      title: this.p2rTranslateService.get(
        this.translateService.instant("ARTICLES.KU_DEFAULT_LABEL_MODIFICATORE.TITLE")
      ),
      titleClasses: "font-r_r fs24 lh-39 c11 text-uppercase",
      body: ChangeTextDialogComponent,
      data: dataToDialog,
      size: SIZE.MEDIUM,
      actions: [
        {
          label: this.p2rTranslateService.get(
            this.translateService.instant("dialog_cancel")
          ),
          click: () => {
            alert.dismiss();
          },
          classes: "btn rounded-pill text-uppercase new-alert-button font-r_l fs18",
          data: {
            remove: true,
          },
          params: {},
        },
        {
          label: this.p2rTranslateService.get(
            this.translateService.instant("dialog_confirm")
          ),
          click: (data) => {
            console.log("GENERALI variant prefix = " + JSON.stringify(data));
            this.kuLabelDefaultModVariant = dataToDialog.text;
            this.configurationService.formGroup.get(ARTICLES_FIELD_TYPES.KU_LABEL_DEFAULT_MOD_VARIANT).setValue(this.replaceAll(this.kuLabelDefaultModVariant, "\'", "\`")); // Serve per non avere problemi con apostrofo
            alert.dismiss();
          },
          data: dataToDialog,
          classes: "btn rounded-pill text-uppercase new-alert-button font-r_l fs18",
          params: {
            submit: true,
          },
        },
      ],
    });
  }
}
