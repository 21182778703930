import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'configuration-set-email-dialog',
  templateUrl: './set-email-dialog.component.html',
  styleUrls: ['../change-text-dialog/change-text-dialog.component.scss']
})
export class SetEmailDialogComponent implements OnInit {
  public _data: any;

  constructor() { }

  ngOnInit() {
  }

  @Input() set data(data: any) {
    this._data = data;
  }

  public dismiss() {

  }

  public emailChanged(event: string) {
    this._data.email_invalid = false;
    console.log(event);
    console.log(this._data);
  }

}
