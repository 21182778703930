<div class="d-flex flex-column">
    <div>
        <div class="input-container">
            <label
                class="label-description"
                style="text-transform: inherit;"
            >
                {{this._data.input_title}}
            </label>
            <div class="input-container-2">
                <input
                    class="input-description"
                    name="text"
                    [(ngModel)]="this._data.email"
                    (change)="emailChanged($event)"
                    placeholder="{{ this._data.email_placeholder }}"
                    type="text"
                    required
                    customVirtualKeyboard
                >
            </div>
            <div
                *ngIf="this._data.email_invalid"
                class="unit text-danger"
            >
                E-mail non valida
            </div>
        </div>
    </div>
</div>
