import { InjectionToken } from "@angular/core";
import { Routes } from "@angular/router";
import { ConfigurationComponent } from "./configuration/configuration.component";
import { OperatorComponent } from "./configuration/operator/operator.component";
import { ConfigurationEmptyRouteComponent } from "./empty-route/configuration-empty-route.component";
import { ConfigurationResolver, ConfigurationResolverClosure, ConfigurationResolverDocuments, ConfigurationResolverGenerali, ConfigurationResolverOfflineWork, ConfigurationResolverPayments, ConfigurationResolverServices, ConfigurationResolverTakeaway, ConfigurationResolverLanguageAndCurrency, ImportExportResolver, ServicesResolver, } from "./configuration/configuration.resolve";
import { CONFIGURATION_TYPE, GROUP_TYPE, IMPORT_EXPORT_TYPE, SERVICE_TYPE } from "./configuration/configuration.model";
import { HallsTablesComponent } from "./halls-tables/halls-tables.component";
import { KuClosureComponent } from "./ku-closure/ku-closure.component";
import { KuOrderPrintersComponent } from "./ku-order-printers/ku-order-printers.component";
import { CustomKuOfflineWorkComponent } from "./configuration/custom-ku-offline-work/custom-ku-offline-work.component";
import { CustomKuDocumentsComponent } from "./configuration/custom-ku-documents/custom-ku-documents.component";
import { CustomGeneraliComponent } from "./configuration/custom-generali/custom-generali.component";
import { FcicComponent } from "./configuration/fcic/fcic.component";
import { MycustomComponent } from "./configuration/mycustom/mycustom.component";
import { Custom4uComponent } from "./configuration/custom4u/custom4u.component";
import { TakeawayComponent } from "./configuration/takeaway/takeaway.component";
import { ImportArchivesComponent } from "./configuration/ImportExport/importArchives/importArchives.component";
import { ExportArchivesComponent } from "./configuration/ImportExport/exportArchives/exportArchives.component";
import { ImportAspectComponent } from "./configuration/ImportExport/importAspect/importAspect.component";
import { ExportAspectComponent } from "./configuration/ImportExport/exportAspect/exportAspect.component";
import { PaymentsComponent } from "./configuration/payments/payments.component";
import { LanguageCurrencyComponent } from "./configuration/language-currency/language-currency.component";
import { CustomArticlesComponent } from "./custom-articles/custom-articles.component";
import { CustomOrderComponent } from "./custom-order/custom-order.component";
import { ExportSaleDataComponent } from "./configuration/ImportExport/export-sale-data/export-sale-data.component";
export const BASE_HREF = new InjectionToken("");
const ɵ0 = ServicesResolver, ɵ1 = {
    header: {
        label: 'services'
    },
    type: CONFIGURATION_TYPE.SERVICE
}, ɵ2 = {
    group: SERVICE_TYPE.FCIC,
}, ɵ3 = ServicesResolver, ɵ4 = {
    group: SERVICE_TYPE.MYCUSTOM,
}, ɵ5 = ConfigurationResolverServices, ɵ6 = {
    group: SERVICE_TYPE.CUSTOM4U,
}, ɵ7 = ServicesResolver, ɵ8 = ImportExportResolver, ɵ9 = {
    header: {
        label: 'import_export'
    },
    type: CONFIGURATION_TYPE.IMPORT_EXPORT
}, ɵ10 = {
    group: IMPORT_EXPORT_TYPE.IMPORT_ARCHIVES,
}, ɵ11 = {
    group: IMPORT_EXPORT_TYPE.EXPORT_ARCHIVES,
}, ɵ12 = {
    group: IMPORT_EXPORT_TYPE.IMPORT_ASPECT,
}, ɵ13 = {
    group: IMPORT_EXPORT_TYPE.EXPORT_ASPECT,
}, ɵ14 = {
    group: IMPORT_EXPORT_TYPE.EXPORT_SALE_DATA,
}, ɵ15 = {
    header: {
        label: 'preferences'
    },
    type: CONFIGURATION_TYPE.CONFIGURATION
}, ɵ16 = ConfigurationResolver, ɵ17 = {
    group: GROUP_TYPE.OPERATOR,
}, ɵ18 = ConfigurationResolver, ɵ19 = {
    group: GROUP_TYPE.HALLS_TABLES,
}, ɵ20 = ConfigurationResolver, ɵ21 = {
    group: GROUP_TYPE.OFFLINE_WORK,
}, ɵ22 = ConfigurationResolverOfflineWork, ɵ23 = {
    group: GROUP_TYPE.DOCUMENTS,
}, ɵ24 = ConfigurationResolverDocuments, ɵ25 = {
    group: GROUP_TYPE.CLOSURE,
}, ɵ26 = ConfigurationResolverClosure, ɵ27 = {
    group: GROUP_TYPE.ORDER_PRINTERS,
}, ɵ28 = ConfigurationResolver, ɵ29 = {
    group: GROUP_TYPE.GENERALI,
}, ɵ30 = ConfigurationResolverGenerali, ɵ31 = {
    group: GROUP_TYPE.TAKEAWAY,
}, ɵ32 = ConfigurationResolverTakeaway, ɵ33 = {
    group: GROUP_TYPE.LANGUAGE_CURRENCY,
}, ɵ34 = ConfigurationResolverLanguageAndCurrency, ɵ35 = {
    group: GROUP_TYPE.PAYMENTS,
}, ɵ36 = ConfigurationResolverPayments, ɵ37 = {
    group: GROUP_TYPE.ARTICLES,
}, ɵ38 = ConfigurationResolver, ɵ39 = {
    group: GROUP_TYPE.ORDERS,
}, ɵ40 = ConfigurationResolver;
const routes = [
    {
        path: "services",
        component: ConfigurationComponent,
        resolve: {
            data: ɵ0,
        },
        data: ɵ1,
        children: [
            {
                path: "fcic",
                component: FcicComponent,
                data: ɵ2,
                resolve: {
                    data: ɵ3,
                },
            },
            {
                path: "mycustom",
                component: MycustomComponent,
                data: ɵ4,
                resolve: {
                    data: ɵ5,
                },
            },
            {
                path: "custom4u",
                component: Custom4uComponent,
                data: ɵ6,
                resolve: {
                    data: ɵ7,
                },
            },
        ],
    },
    {
        path: "importexport",
        component: ConfigurationComponent,
        resolve: {
            data: ɵ8,
        },
        data: ɵ9,
        children: [
            {
                path: "archives-import",
                component: ImportArchivesComponent,
                data: ɵ10,
            },
            {
                path: "archives-export",
                component: ExportArchivesComponent,
                data: ɵ11,
            },
            {
                path: "aspect-import",
                component: ImportAspectComponent,
                data: ɵ12,
            },
            {
                path: "aspect-export",
                component: ExportAspectComponent,
                data: ɵ13,
            },
            {
                path: "export-sale-data",
                component: ExportSaleDataComponent,
                data: ɵ14,
            },
        ],
    },
    {
        path: "",
        component: ConfigurationComponent,
        data: ɵ15,
        resolve: {
            data: ɵ16,
        },
        children: [
            {
                path: "operators",
                component: OperatorComponent,
                data: ɵ17,
                resolve: {
                    data: ɵ18,
                },
            },
            {
                path: "halls-tables",
                component: HallsTablesComponent,
                data: ɵ19,
                resolve: {
                    data: ɵ20,
                },
            },
            {
                path: "offline-work",
                component: CustomKuOfflineWorkComponent,
                data: ɵ21,
                resolve: {
                    data: ɵ22,
                },
            },
            {
                path: "ku-documents",
                component: CustomKuDocumentsComponent,
                data: ɵ23,
                resolve: {
                    data: ɵ24,
                },
            },
            // {
            //   path: "services",
            //   component: CustomKuServicesComponent,
            //   data: {
            //     group: GROUP_TYPE.SERVIZI,
            //   },
            //   resolve: {
            //     data: ConfigurationResolverServices,
            //   },
            // },
            {
                path: "ku-closure",
                component: KuClosureComponent,
                data: ɵ25,
                resolve: {
                    data: ɵ26,
                },
            },
            {
                path: "ku-order-printers",
                component: KuOrderPrintersComponent,
                data: ɵ27,
                resolve: {
                    data: ɵ28,
                },
            },
            {
                path: "generali",
                component: CustomGeneraliComponent,
                data: ɵ29,
                resolve: {
                    data: ɵ30,
                },
            },
            {
                path: "takeaway",
                component: TakeawayComponent,
                data: ɵ31,
                resolve: {
                    data: ɵ32,
                },
            },
            {
                path: "language-currency",
                component: LanguageCurrencyComponent,
                data: ɵ33,
                resolve: {
                    data: ɵ34,
                },
            },
            {
                path: "payments",
                component: PaymentsComponent,
                data: ɵ35,
                resolve: {
                    data: ɵ36,
                },
            },
            {
                path: "articles",
                component: CustomArticlesComponent,
                data: ɵ37,
                resolve: {
                    data: ɵ38,
                },
            },
            {
                path: "orders",
                component: CustomOrderComponent,
                data: ɵ39,
                resolve: {
                    data: ɵ40,
                },
            },
        ],
    },
    { path: "**", component: ConfigurationEmptyRouteComponent },
];
const ɵ41 = (() => `${window[`app_base`] ? window[`app_base`] : ""}/configuration`)();
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17, ɵ18, ɵ19, ɵ20, ɵ21, ɵ22, ɵ23, ɵ24, ɵ25, ɵ26, ɵ27, ɵ28, ɵ29, ɵ30, ɵ31, ɵ32, ɵ33, ɵ34, ɵ35, ɵ36, ɵ37, ɵ38, ɵ39, ɵ40, ɵ41 };
