import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { GROUP_TYPE, OPERATOR_FIELD_TYPES } from '../configuration.model';
import { ConfigurationService } from '../configuration.service';
import { TranslateService } from '@ngx-translate/core';
import { P2RDialogComponent, P2RDialogService, P2RTranslateService } from '@p2r/shared';
import { TimeSlotAlertComponent } from '../time-slot-alert/time-slot-alert.component';
import { ActivatedRoute } from '@angular/router';
import { ChangeDescriptionDialogComponent } from '../change-description-dialog/change-description-dialog.component';
import { SIZE } from '@p2r/shared/lib/shared/p2r-dialog/p2r-dialog.interface';
import { CustomizeDialogComponent } from 'src/app/customize-dialog/customize-dialog.component';
import { CUSTOMIZE_TYPE } from 'src/app/customize-dialog/customize-dialog.model';
import { GlobalVariable } from 'src/app/global';

@Component({
  selector: 'configuration-operator',
  templateUrl: './operator.component.html',
  styleUrls: ['./operator.component.scss']
})
export class OperatorComponent implements OnInit {

  public images: {
    add: string,
    remove: string
  };

  // [{"key":"KA_OPERATOR_TIME_SLOT","default":[]},{"key":"KA_OPERATOR_TYPE","default":0}]
  public values: any;

  /* {"MANAGEMENT": {
        "manually":{"TITLE":{},"DESCRIPTION":{}},
        "ON_ACCOUNT":{"TITLE":{},"DESCRIPTION":{}},
        "AT_TIME_SLOTS":{"TITLE":{},"DESCRIPTION":{}}
      },
     "TIME_SLOTS":{}}
  */
  public items: any;

  // public form: FormGroup;

  /* {"codice":"13",
      "store":"99999",
      "key_group":"13",
      "jsontemplate":{"KA_OPERATOR_TIME_SLOT":[],"KA_OPERATOR_TYPE":0}
  } */
  public currentValues: any;

  /*
  [ "10:00", "14:00"]
  */
  public currentTimeSlots: any = [];
  // public currentTimeSlots: { [id: string]: string; } = {};

  /*
  [ "DESC 1", "DESC 2"]
  */
  public currentTimeSlotDescriptions: any = [];

  public kuAskPwdOperatorKeyList: string[];
  public kuAskPwdOperatorCounter: string;
  public KuAskPasswordFlagList: any[] = Array();

  public operatorsManagement = {
    manually: false,
    on_account: false,
    at_time_slots: false
  };

  public user_grant_type: string;

  constructor(
    public configurationService: ConfigurationService,
    private p2rDialogService: P2RDialogService,
    private translateService: TranslateService,
    private p2rTranslateService: P2RTranslateService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute
  ) {
    this.images = {
      add: this.configurationService.ICONS.ADD,
      remove: this.configurationService.ICONS.REMOVE
    };
  }

  ngOnInit() {

    this.user_grant_type = this.configurationService.user_grant_type;

    this.configurationService.selectedGroup = GROUP_TYPE.OPERATOR;

    this.configurationService.openChangeTimeSlotSubject$().subscribe(({ }) => {
      this.openChangeTimeSlotAlert(undefined);
    });

    this.route.data.subscribe(({ data }) => {
      const [items, values] = data;

      this.items = items;
      this.values = values;

      console.log(typeof (values));

      const group: any = {};

      Object.keys(values).forEach(key => {
        group[key] = new FormControl(values[key], {
          validators: [],
          asyncValidators: [],
          updateOn: 'blur'
        });
      });

      this.currentTimeSlots = Object.assign([], values[OPERATOR_FIELD_TYPES.KA_OPERATOR_TIME_SLOT]);
      this.currentTimeSlotDescriptions = Object.assign([], values[OPERATOR_FIELD_TYPES.KA_OPERATOR_DESCRIPTIONS_TIME_SLOT]);

      this.configurationService.formGroup = this.formBuilder.group(group);

      this.setOperatorType(values[OPERATOR_FIELD_TYPES.KA_OPERATOR_TYPE]);

      /*  aggiungo le chiavi della stampa statistiche */
      this.setAskPasswordList();
      this.filterAskPasswordValue(false, null);
      this.setAskPasswordLabel();
    });

    this.configurationService.groups$().subscribe(groups => {
      const found = groups.find(g => g.cod === GROUP_TYPE.OPERATOR);
      this.items = found && found.items || [];
    });
    this.configurationService.values$().subscribe(values => {
      values = values[GROUP_TYPE.OPERATOR];
      if (values !== undefined) {
        if (this.configurationService._selectedGroup === GROUP_TYPE.OPERATOR) {
          // this.configurationService.formGroup.setValue(values);
          this.setOperatorType(values[OPERATOR_FIELD_TYPES.KA_OPERATOR_TYPE]);
          this.currentTimeSlots = Object.assign([], values[OPERATOR_FIELD_TYPES.KA_OPERATOR_TIME_SLOT]);
          this.currentTimeSlotDescriptions = Object.assign([], values[OPERATOR_FIELD_TYPES.KA_OPERATOR_DESCRIPTIONS_TIME_SLOT]);
          /*  Avendo ripristinato le modifiche, ho bisogno di ricaricare i dati inerenti alle password dispositivo. */
          this.filterAskPasswordValue(true, values);
          this.setAskPasswordLabel();
        }
      }
    });
  }

  changeOperatorType(key, value) {
    Object.keys(this.operatorsManagement).forEach(k => {
      this.operatorsManagement[k] = false;
    });
    this.operatorsManagement[key] = true;

    this.configurationService.formGroup.get(OPERATOR_FIELD_TYPES.KA_OPERATOR_TYPE).setValue(value);
  }

  setOperatorType(operatorType) {
    if (operatorType !== undefined) {
      // Per sicurezza metto a false tutti i tipi di operatore
      Object.keys(this.operatorsManagement).forEach(k => {
        this.operatorsManagement[k] = false;
      });
      switch (operatorType) {
        case 0:
          this.operatorsManagement.manually = true;
          break;
        case 1:
          this.operatorsManagement.on_account = true;
          break;
        case 2:
          this.operatorsManagement.at_time_slots = true;
          break;
      }
    }
  }

  public openChangeTimeSlotAlert(index: number) {
    let dataToDialog;
    if (index === undefined) {
      const d = new Date(); // for now
      const currentHour = this.pad(d.getHours());
      const currentMinutes = this.pad(d.getMinutes());
      dataToDialog = {
        hour: currentHour,
        minutes: currentMinutes
      };
    } else {
      const selectedTimeSlot = this.currentTimeSlots[index];
      console.log('click:\ncurrentTimeSlots[' + index + ']: ' + selectedTimeSlot);
      const splitted = selectedTimeSlot.split(':');
      if (splitted !== undefined && splitted.length === 2) {
        dataToDialog = {
          hour: this.pad(parseInt(splitted[0], 10)),
          minutes: this.pad(parseInt(splitted[1], 10))
        };
      }
    }
    const alert = this.p2rDialogService.open(P2RDialogComponent, {
      id: 'change-timeslot-dialog',
      hasHead: false,
      body: TimeSlotAlertComponent,
      data: dataToDialog,
      size: SIZE.MEDIUM,
      actions: [
        {
          label: this.p2rTranslateService.get(this.translateService.instant('dialog_cancel')),
          click: () => {
            alert.dismiss();
          },
          classes: 'btn rounded-pill text-uppercase new-alert-button font-r_l fs18',
          data: {
            remove: true
          },
          params: {
          }
        },
        {
          label: this.p2rTranslateService.get(this.translateService.instant('dialog_confirm')),
          click: (data) => {
            if (dataToDialog.hour == null
              || dataToDialog.hour < 0
              || dataToDialog.hour > 23) {
              this.configurationService.showErrorAlert([
                this.p2rTranslateService.get(this.translateService.instant('you_entered_an_incorrect_time_format')),
                this.p2rTranslateService.get(this.translateService.instant('the_hours_must_be_between_0_and_23')),
              ])
            } else if (dataToDialog.minutes == null
              || dataToDialog.minutes < 0
              || dataToDialog.minutes > 59) {
              this.configurationService.showErrorAlert([
                this.p2rTranslateService.get(this.translateService.instant('you_entered_an_incorrect_time_format')),
                this.p2rTranslateService.get(this.translateService.instant('the_minutes_must_be_between_0_and_59')),
              ])
            } else {
              alert.dismiss();
              if (index === undefined) {
                // Se index è undefined vuol dire che sto creando un nuovo time slot
                console.log('NUOVO SLOT:\n' + JSON.stringify(data));
                this.currentTimeSlots.push(String(this.pad(dataToDialog.hour)) + ':' + String(this.pad(dataToDialog.minutes)));

                this.currentTimeSlotDescriptions
                  .push(this.translateService.instant('OPERATOR.TIME_SLOTS.LABEL_CHANGE_TURN') + ' ' + this.currentTimeSlots.length);

                this.configurationService.formGroup.get(OPERATOR_FIELD_TYPES.KA_OPERATOR_TIME_SLOT).setValue(this.currentTimeSlots);

                this.configurationService.formGroup.get(OPERATOR_FIELD_TYPES.KA_OPERATOR_DESCRIPTIONS_TIME_SLOT)
                  .setValue(this.currentTimeSlotDescriptions);
              } else {
                // Mentre se è un numero vuol dire che sto modificando un time slot
                console.log('UPDATE SLOT:\n' + JSON.stringify(data));
                this.currentTimeSlots[index] = String(this.pad(dataToDialog.hour)) + ':' + String(this.pad(dataToDialog.minutes));
                this.configurationService.formGroup.get(OPERATOR_FIELD_TYPES.KA_OPERATOR_TIME_SLOT).setValue(this.currentTimeSlots);
              }
            }
          },
          data: dataToDialog,
          classes: 'btn rounded-pill text-uppercase new-alert-button font-r_l fs18',
          params: {
            submit: true
          }
        }
      ]
    });
  }

  public openChangeTimeSlotDescription(index: number) {
    const selectedTimeSlotDesc = this.currentTimeSlotDescriptions[index];
    console.log('click:\ncurrentTimeSlotDescriptions[' + index + ']: ' + selectedTimeSlotDesc);
    const dataToDialog = {
      description: selectedTimeSlotDesc
    };
    const alert = this.p2rDialogService.open(P2RDialogComponent, {
      id: 'change-timeslot-description-dialog',
      hideCloseButton: true,
      title: this.p2rTranslateService.get(this.translateService.instant('change_description_dialog_title')),
      titleClasses: 'font-r_r fs24 lh-39 c11 text-uppercase',
      body: ChangeDescriptionDialogComponent,
      data: dataToDialog,
      size: SIZE.MEDIUM,
      actions: [
        {
          label: this.p2rTranslateService.get(this.translateService.instant('dialog_cancel')),
          click: () => {
            alert.dismiss();
          },
          classes: 'btn rounded-pill text-uppercase new-alert-button font-r_l fs18',
          data: {
            remove: true
          },
          params: {
          }
        },
        {
          label: this.p2rTranslateService.get(this.translateService.instant('dialog_confirm')),
          click: (data) => {
            if (dataToDialog.description === undefined || dataToDialog.description.length === 0) {
              console.log('Invalid value:\n' + JSON.stringify(data));
            } else {
              alert.dismiss();
              console.log('UPDATE DESC:\n' + JSON.stringify(data));
              this.currentTimeSlotDescriptions[index] = dataToDialog.description;
              this.configurationService.formGroup
                .get(OPERATOR_FIELD_TYPES.KA_OPERATOR_DESCRIPTIONS_TIME_SLOT)
                .setValue(this.currentTimeSlotDescriptions);
            }
          },
          data: dataToDialog,
          classes: 'btn rounded-pill text-uppercase new-alert-button font-r_l fs18',
          params: {
            submit: true
          }
        }
      ]
    });
  }

  public removeTimeSlot(index: number) {
    console.log('Remove time slot (index=' + index + ')');
    if (index > -1) {
      this.currentTimeSlots.splice(index, 1);
      this.currentTimeSlotDescriptions.splice(index, 1);
      this.configurationService.formGroup.get(OPERATOR_FIELD_TYPES.KA_OPERATOR_TIME_SLOT).setValue(this.currentTimeSlots);
      this.configurationService.formGroup.get(OPERATOR_FIELD_TYPES.KA_OPERATOR_DESCRIPTIONS_TIME_SLOT)
        .setValue(this.currentTimeSlotDescriptions);
    }
  }

  private pad(value: number) {
    if (value === 0 && String(value).length === 1) {
      return '0' + value;
    } else {
      if (!String(value).startsWith('0')) {
        if (value < 10) {
          return '0' + value;
        } else {
          return value;
        }
      } else {
        return value;
      }
    }
  }

  private setAskPasswordList(): void {
    this.kuAskPwdOperatorKeyList = Array();
    this.kuAskPwdOperatorKeyList.push(OPERATOR_FIELD_TYPES.KU_ASK_PWD_STORNO_CANCELLA_ORDINE);
  }

  private filterAskPasswordValue(forceReload, newValues) {
    let labelKeyTranslation = Array();
    const keyList = Object.keys(this.items);

    if ((this.KuAskPasswordFlagList.length > 0) && !forceReload) {
      return this.KuAskPasswordFlagList;
    }
    this.kuAskPwdOperatorKeyList.forEach(key => {
      const keyStat = keyList.find(item => key.includes(item));
      /*
        Costruisco il mio oggetto che passerò al dialog
      */
      const keyTraslation = 'OPERATOR.' + keyStat + '.TITLE';
      const valuesList = (newValues == null) ? this.values : newValues;
      labelKeyTranslation.push({
        key: key,
        label: this.p2rTranslateService.get(this.translateService.instant(keyTraslation)),
        flag: valuesList[key]
      });
    });
    this.KuAskPasswordFlagList = labelKeyTranslation;
  }

  public setAskPasswordLabel() {
    let counter: number = 0;
    let labelToPrint: string = "";

    this.kuAskPwdOperatorKeyList.forEach(key => {
      const item = this.KuAskPasswordFlagList.find(elem => elem.key == key);
      if (item.flag) {
        counter++;
      }
    })
    if (counter == 0) {
      labelToPrint = this.p2rTranslateService.get(this.translateService.instant('OPERATOR.AS_PASSWORD_NO_OPERATIONS'));
    } else if (counter == this.kuAskPwdOperatorKeyList.length) {
      labelToPrint = this.p2rTranslateService.get(this.translateService.instant('OPERATOR.AS_PASSWORD_ALL_OPERATIONS'));
    } else {
      labelToPrint = counter.toString() + " " + this.p2rTranslateService.get(this.translateService.instant('OPERATOR.AS_PASSWORD_SOME_OPERATIONS')).toLowerCase();
    }
    this.kuAskPwdOperatorCounter = `${labelToPrint}`;
  }

  public openPopupChooseOperationsReserved() {
    const dialog = this.p2rDialogService.open(P2RDialogComponent, {
      id: 'customize-dialog',
      body: CustomizeDialogComponent,
      hideCloseButton: true,
      title: this.p2rTranslateService.get(this.translateService.instant('OPERATOR.ASK_PASSWORD.TITLE')),
      titleClasses: 'text-uppercase font-r_r fs24 lh21 c11 mt-2',
      size: 'lg',
      data: {
        hasSearch: true,
        hasSelectAll: true,
        customizeType: CUSTOMIZE_TYPE.ASK_PASSWORD_FOR_RESERVED_OPERATIONS,
        items: this.KuAskPasswordFlagList,
        close: () => {
          this.setAskPasswordLabel();
          dialog.dismiss();
        },
        updateItems: (itemList) => {
          this.KuAskPasswordFlagList = itemList;
          this.KuAskPasswordFlagList.forEach(elem => {
            /*this.values[elem.key] = elem.flag*/
            this.configurationService.formGroup.get(elem.key).setValue(elem.flag);
          });
        }
      }
    });
  }
}
