import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { P2RDialogComponent, P2RDialogService, P2RTranslateService, P2R_FIELD_TYPE, P2RLocalStorageService } from '@p2r/shared';
import { GlobalVariable } from "src/app/global";
import { ConfigurationService } from '../configuration.service';
import { TranslateService } from "@ngx-translate/core";
import { ChangeServicePercentageDialogComponent } from '../change-service-percentage-dialog/change-service-percentage-dialog.component';
import { SIZE } from '@p2r/shared/lib/shared/p2r-dialog/p2r-dialog.interface';
import { GROUP_TYPE, PAYMENTS_FIELD_TYPES } from '../configuration.model';
import { CUSTOMIZE_TYPE } from 'src/app/customize-dialog/customize-dialog.model';
import { CustomizeDialogComponent } from 'src/app/customize-dialog/customize-dialog.component';
import { PaymentsService } from './payments.service';
import { getChangedKeys } from '../configuration.utils';
import { Subscription } from 'rxjs';
import { ConfigurationAlertComponent } from '../alert/alert.component';
import { assetUrl } from 'src/single-spa/asset-url';
import { P2REnvironmentService } from '@p2r/env';
@Component({
  selector: 'configuration-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent implements OnInit, OnDestroy {
  public values: any;
  public items: any;
  public paymentsList: any[];
  //
  public kuabilitavoceservizio: any;
  public kuvoceserviziopercentage: number;
  typegrop: GROUP_TYPE;
  //
  public kuAbilitaVassoio: any;
  public kuCasseVassoio: any[];
  public kuCasseVassoioLabel: string;
  casseAll: any[]; // Contiene le casse di tutti gli store
  casse: any[]; // Contiene le casse dello store attualmente selezionato

  private configurationValuesSubscription: Subscription;

  private _assetsPath;

  constructor(
    private p2rEnvironmentService: P2REnvironmentService,
    public configurationService: ConfigurationService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private p2rDialogService: P2RDialogService,
    private p2rTranslateService: P2RTranslateService,
    private p2rLocalStorageService: P2RLocalStorageService,
    private translateService: TranslateService,
    public paymentsService: PaymentsService,
    private cdRef: ChangeDetectorRef,
  ) {
    this.typegrop = GROUP_TYPE.PAYMENTS;
    this.p2rEnvironmentService.getEnvironmentStream().subscribe(env => {
      this._assetsPath = `${env.BASE_CONFIG_URL}`;
    });
  }

  getImg(imgSrc) {
    if (typeof imgSrc === 'function') {
      imgSrc = imgSrc();
    }
    return assetUrl(imgSrc, this._assetsPath);
  }
  

  ngOnInit() {
    this.configurationService.selectedGroup = this.typegrop;

    this.route.data.subscribe(({ data }) => {
      const [items, values, ccards, casse] = data;

      this.items = items;
      this.values = values;
      this.casseAll = casse;
      this.filterCasseByStore();
      console.log(ccards);
      console.log(data);

      // localStorage.setItem('PAYMENT_LIST', JSON.stringify(ccards));

      // Payments methods
      this.paymentsService.paymentApiList = ccards;

      // Service percentage
      this.kuabilitavoceservizio = this.values.KU_VOCE_SERVIZIO_ENABLED;
      this.kuvoceserviziopercentage = this.values.KU_VOCE_SERVIZIO_PERCENTAGE;

      // Vassoio
      this.kuAbilitaVassoio = this.values.KU_VASSOIO_ENABLED;
      this.kuCasseVassoio = this.values.KU_VASSOIO_CASSE;
      if(this.kuCasseVassoio == undefined) {
        this.kuCasseVassoio = [];
      }
      this.setNumberOfCasseAbilitatePerVassoioLabel();

      const group: any = {};

      Object.keys(values).forEach(key => {
        group[key] = new FormControl(values[key], {
          validators: [],
          asyncValidators: [],
          updateOn: 'blur'
        });
      });

      this.configurationService.formGroup = this.formBuilder.group(group);
    });

    this.configurationService.groups$().subscribe(groups => {
      const found = groups.find(g => g.cod === GROUP_TYPE.PAYMENTS);
      this.items = found && found.items || [];
    });

    this.configurationValuesSubscription = this.configurationService.values$().subscribe(values => {
      values = values[GROUP_TYPE.PAYMENTS];
      if (values !== undefined) {

        this.values = values;
        if (this.configurationService._selectedGroup === GROUP_TYPE.PAYMENTS) {

          // Service percentage
          this.kuabilitavoceservizio = this.values.KU_VOCE_SERVIZIO_ENABLED;
          this.kuvoceserviziopercentage = this.values.KU_VOCE_SERVIZIO_PERCENTAGE;

          // Vassoio
          this.kuAbilitaVassoio = this.values.KU_VASSOIO_ENABLED;
          this.kuCasseVassoio = this.values.KU_VASSOIO_CASSE;
          if(this.kuCasseVassoio == undefined) {
            this.kuCasseVassoio = [];
          }
          this.setNumberOfCasseAbilitatePerVassoioLabel();
          this.filterCasseByStore();
        }
      }
    });

  }

  public onClickAbilitaVoceServizio() {
    this.kuabilitavoceservizio = !this.kuabilitavoceservizio;
    this.configurationService.formGroup.get(PAYMENTS_FIELD_TYPES.KU_VOCE_SERVIZIO_ENABLED).setValue(this.kuabilitavoceservizio);
  }

  public openPercentageDialog() {
    const dataToDialog = {
      percentage: this.kuvoceserviziopercentage,
      value_error: false
    };
    const alert = this.p2rDialogService.open(P2RDialogComponent, {
      id: 'change-service-percentage-dialog',
      hideCloseButton: true,
      title: this.p2rTranslateService.get(this.translateService.instant('change_percentage_dialog_title')),
      titleClasses: 'font-r_r fs24 lh-39 c11 text-uppercase',
      body: ChangeServicePercentageDialogComponent,
      data: dataToDialog,
      size: SIZE.MEDIUM,
      actions: [
        {
          label: this.p2rTranslateService.get(this.translateService.instant('dialog_cancel')),
          click: () => {
            alert.dismiss();
          },
          classes: 'btn rounded-pill text-uppercase new-alert-button font-r_l fs18',
          data: {
            remove: true
          },
          params: {
          }
        },
        {
          label: this.p2rTranslateService.get(this.translateService.instant('dialog_confirm')),
          click: () => {
            if (dataToDialog.percentage && dataToDialog.percentage !== 0) {
              this.kuvoceserviziopercentage = dataToDialog.percentage;
              this.configurationService.formGroup.get(PAYMENTS_FIELD_TYPES.KU_VOCE_SERVIZIO_PERCENTAGE).setValue(this.kuvoceserviziopercentage);
              alert.dismiss();
            } else {
              dataToDialog.value_error = true;
            }
          },
          data: dataToDialog,
          classes: 'btn rounded-pill text-uppercase new-alert-button font-r_l fs18',
          params: {
            submit: true
          }
        }
      ]
    });
  }

  public edit_customizedPayment(payment) {

    const dialogData = {
      hasSearch: false,
      customizeType: CUSTOMIZE_TYPE.CUSTOMIZED_PAYMENT,
      paymentSelected: payment,
      opt: {

      },
      items: [
        {
          key: 'enabled',
          label: this.p2rTranslateService.get(this.translateService.instant('CUSTOMIZED_PAYMENT.ENABLE_TITLE')),
          note: this.p2rTranslateService.get(this.translateService.instant('CUSTOMIZED_PAYMENT.ENABLE_SUBTITLE')),
          // value: ,
          fieldType: P2R_FIELD_TYPE.CHECK
        },
        {
          key: 'description',
          label: this.p2rTranslateService.get(this.translateService.instant('CUSTOMIZED_PAYMENT.DESCRIPTION_TITLE')),
          note: this.p2rTranslateService.get(this.translateService.instant('CUSTOMIZED_PAYMENT.DESCRIPTION_SUBTITLE')),
          // value: listinoPeriod.priorityOnRoom,
          fieldType: P2R_FIELD_TYPE.CHECK
        },
        {
          key: 'method',
          label: this.p2rTranslateService.get(this.translateService.instant('CUSTOMIZED_PAYMENT.METHOD_TITLE')),
          note: this.p2rTranslateService.get(this.translateService.instant('CUSTOMIZED_PAYMENT.METHOD_SUBTITLE')),
          // value: listinoPeriod.priorityOnRoom,
          fieldType: P2R_FIELD_TYPE.CHECK
        },
      ]
    }

    const dialog = this.p2rDialogService.open(P2RDialogComponent, {
      id: 'lst-periodo-dialog',
      body: CustomizeDialogComponent,
      // hideCloseButton: true,
      title: this.p2rTranslateService.get(this.translateService.instant('customized_payment')),
      titleClasses: 'text-uppercase font-r_r fs24 lh21 c11 mt-2',
      size: 'lg',
      keyboard: false,
      closeOnOutsideClick: false,
      closeButton: {
        label: this.p2rTranslateService.get(this.translateService.instant('close')),
        buttonClass: 'btn btn-primary rounded-pill lp-bottom-btn',
        labelClass: 'text-uppercase font-r_l fs18 lh21',
        click: () => {
          const p = dialogData.paymentSelected;
          this.paymentsService.changesManager(p, false);
          this.paymentsService.checkPaymentChanges();
        }
      },
      data: dialogData
    });
  }

  // Aggiunta nuovo pagamento personalizzato
  public addCustomizedPayment(list) {
    if (list.length < 10) {
      const refCodeList = list.map(el => parseInt(el.XCCARDFOOD04));

      const progressNum = Math.max(
        0,
        ...list
          .filter(f => f.CC_ID)
          .map(f => f.CC_ID)) + 1;

      let refCode = 600;
      for (let i = 1; i <= 10; i++) {
        refCode += i;
        if (!refCodeList.includes(refCode)) {
          break;
        }
      }

      var newCustomizedPayment = {
        CC_ID: progressNum,
        XCCARDFOOD10: 1,
        XCCARDFOOD04: refCode,
        XCCARDFOOD05: this.p2rTranslateService.get(this.translateService.instant('new_payment')) + ' ' + progressNum,
        XCCARDFOOD07: true,
        XCCARDFOOD11: 0,
        XCCARDFOOD12: 1,
        XCCARDFOOD13: 0,
        XCCARDFOOD14: 0,
        XCCARDFOOD15: 0
      }

      this.paymentsService.changesManager(newCustomizedPayment, false)
      this.paymentsService.checkPaymentChanges();
    }
  }

  // Delete
  public remove_customizedPayment(payment) {
    this.paymentsService.changesManager(payment, true)
    this.paymentsService.checkPaymentChanges();
  }

  /* *************************** */
  /*                             */
  /*          VASSOIO            */
  /*                             */
  /* *************************** */

  public onClickAbilitaVassoio() {
    if(this.kuAbilitaVassoio) {
      const alert = this.p2rDialogService.open(P2RDialogComponent, {
        id: 'pre-disable-vassoio-alert',
        body: ConfigurationAlertComponent,
        hasHead: false,
        data: {
          imgSrc: this.getImg('icons/attenzione.svg'),
          title: this.p2rTranslateService.get(this.translateService.instant('attention')),
          messages: [
            this.p2rTranslateService.get(this.translateService.instant('alert_disable_vassoio_1')),
            this.p2rTranslateService.get(this.translateService.instant('alert_disable_vassoio_2'))
          ]
        },
        actions: [
          {
            label: this.p2rTranslateService.get(this.translateService.instant('annulla')),
            click: () => {
              alert.dismiss();
            },
            classes: 'btn rounded-pill text-uppercase new-alert-button',
            data: {
              remove: true
            },
            params: {
            }
          },
          {
            label: this.p2rTranslateService.get(this.translateService.instant('continua')),
            click: () => {
              alert.dismiss();
              this.toggleKuAbilitaVassoio();
            },
            classes: 'btn rounded-pill text-uppercase new-alert-button',
            params: {
            }
          }
        ]
      });
    } else {
      this.toggleKuAbilitaVassoio();
    }
  }

  private toggleKuAbilitaVassoio() {
    this.kuAbilitaVassoio = !this.kuAbilitaVassoio;
    this.configurationService.formGroup.get(PAYMENTS_FIELD_TYPES.KU_VASSOIO_ENABLED).setValue(this.kuAbilitaVassoio);
  }

  public setNumberOfCasseAbilitatePerVassoioLabel() {
    if(this.kuCasseVassoio.length == 0) {
      this.kuCasseVassoioLabel = this.p2rTranslateService.get(this.translateService.instant('no_desk'));      
    } else {
      this.kuCasseVassoioLabel = this.kuCasseVassoio.length + this.p2rTranslateService.get(this.translateService.instant('n_desk'));
    }
  }

  public openScegliCassePerVassoio() {
    let _items = [];
    this.casse.forEach(e => {
      let item = e;
      if(this.kuCasseVassoio.includes(e.key)) {
        item.flag = true;
      } else {
        item.flag = false;
      }
      _items.push(item);
    })
    const dialog = this.p2rDialogService.open(P2RDialogComponent, {
      id: 'customize-dialog',
      body: CustomizeDialogComponent,
      hideCloseButton: true,
      title: this.p2rTranslateService.get(this.translateService.instant('cassa_per_inserimento_numero')),
      titleClasses: 'text-uppercase font-r_r fs24 lh21 c11 mt-2',
      size: 'lg',
      data: {
        hasSearch: true,
        hasSelectAll: true,
        customizeType: CUSTOMIZE_TYPE.CASSE_VASSOIO,
        items: _items,
        close: () => {
          this.setNumberOfCasseAbilitatePerVassoioLabel();
          dialog.dismiss();
        },
        updateItems: (itemList) => {
          this.kuCasseVassoio = [];
          itemList.forEach(elem => {
            if(elem.flag) {
              this.kuCasseVassoio.push(elem.key);
            }
          });
          this.configurationService.formGroup.get(PAYMENTS_FIELD_TYPES.KU_VASSOIO_CASSE).setValue(this.kuCasseVassoio);
        }
      }
    });
  }

  /**
   * Filtra la lista casseAll creando una nuova lista con le sole casse dello
   * store corrente.
  */
  public filterCasseByStore() {
    const selectedPv = this.p2rLocalStorageService.getFromLocalStorage(
      GlobalVariable.SELECTED_PV_KEY_STORAGE,
      []
    );
    let casseTemp = [];
    this.casseAll.forEach(cassa => {
      if (cassa.XSF03 == selectedPv.codice) {
        casseTemp.push(cassa);
      }
    });
    this.casse = casseTemp;
  }

  /* *************************** */
  /*                             */
  /*        END VASSOIO          */
  /*                             */
  /* *************************** */

  ngOnDestroy(): void {
    if (this.configurationValuesSubscription) {
      this.configurationValuesSubscription.unsubscribe()
    }
  }

}
