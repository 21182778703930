import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'configuration-change-text-dialog',
  templateUrl: './change-text-dialog.component.html',
  styleUrls: ['./change-text-dialog.component.scss']
})
export class ChangeTextDialogComponent implements OnInit {

  private _data: any;
  private _title: any;
  private _messages: any;

  public rightFixedPlaceholderWidth;

  constructor() { }

  ngOnInit() {
    this.calculateRightFixedPlaceholderWidth();
  }

  @Input() set data(data: any) {
    this._data = data;
  }

  get data(): any {
    return this._data;
  }

  @Input() set title(title: any) {
    this._title = title;
  }

  get title(): any {
    return this._title || this._data.title;
  }

  @Input() set messages(messages: any[]) {
    this._messages = messages;
  }

  get messages(): any[] {
    return this._messages || this._data.messages;
  }

  public onChangeInput(event) {
    console.log((event));
  }

  public dismiss() {

  }

  public calculateRightFixedPlaceholderWidth() {
    if (this.data.rightFixedPlaceholder && this.data.rightFixedPlaceholder.length > 0) {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      context.font = "normal normal normal 18px/22px Roboto-Regular";
      const {
        width
      } = context.measureText(this.data.text);
      this.rightFixedPlaceholderWidth = width + 16 + 8; // 16 è il padding left dell'input, mentre 8 è un padding aggiuntivo
      // console.log("calculateRightFixedPlaceholderWidth: " + this.variantWidth);
    } else {
      // console.log("calculateRightFixedPlaceholderWidth SKIPPED");
    }
  }

  public textChanged() {
    this.calculateRightFixedPlaceholderWidth();
  }
}
