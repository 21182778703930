<div>
    <div>
        <div *ngIf="checkIfKeyIsPresent(items, 'MQTT')">
            <div class="sub-section-container">
                <div>
                    <p
                        class="section-title"
                        style="text-transform: uppercase;"
                    >
                        {{'OFFLINE.MQTT.TITLE' | translate}}
                    </p>
                    <p class="sub-section-description-2 font-r_r fs16 lh16 c10">
                        {{'OFFLINE.MQTT.DESCRIPTION' | translate}}
                    </p>
                </div>
                <div class="sub-section-checkbox-2">
                    <img
                        [src]="kuOfflineWorkEnabled ? configurationService.getImg('/icons/switch-on.svg') : configurationService.getImg('/icons/switch-off.svg')"
                        (click)="onClickAbilitaMqtt()"
                    >
                </div>
            </div>
            <hr>
        </div>
    </div>
    <div>
        <div *ngIf="checkIfKeyIsPresent(items, 'TABLE_FATT')">
            <div
                class="align-items-center d-flex justify-content-between sub-section-container"
                [class.disabled]="kuOfflineWorkEnabled === false"
                style="margin-right: 32px;"
            >
                <div>
                    <p
                        class="section-title-2"
                        style="text-transform: uppercase;"
                    >
                        {{'OFFLINE.TABLE_FATT.TITLE' | translate}}
                    </p>
                    <p class="sub-section-description-2 font-r_r fs16 lh16 c10">
                        {{'OFFLINE.TABLE_FATT.DESCRIPTION' | translate}}
                    </p>
                </div>
                <div>
                    <div class="d-flex align-items-center">
                        <span class="sub-section-number mr-3">
                            {{getMasterCashLabel('KU_CASSA_FATTURE_OFFLINE')}}
                        </span>
                        <button
                            class="btn rounded-pill btn-block btn-primary text-uppercase pl-3 pr-3 text-center"
                            style="height: 40px; width: 120px;"
                            (click)="chooseCassaMaster('KU_CASSA_FATTURE_OFFLINE')"
                        >
                            <span class="font-r_r fs18 c13 lh21">
                                <span>{{'choose' | translate | uppercase}}</span>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
            <hr>
        </div>
    </div>
    <div *ngIf="this.brokerStoreApiValues !== undefined">
        <div
            class="sub-section-container"
            [class.disabled]="kuOfflineWorkEnabled === false"
        >
            <div>
                <p
                    class="section-title-2"
                    style="text-transform: uppercase;"
                >
                    {{'OFFLINE.BROKERS.TITLE' | translate}}
                </p>
                <p class="sub-section-description-2 font-r_r fs16 lh16 c10">
                    {{'OFFLINE.BROKERS.DESCRIPTION' | translate}}
                </p>
            </div>
            <div
                class="sub-section-number"
                (click)="changeBrokerHost()"
            >
                <div *ngIf="this.kuBrokerIpHost1; else noBrokerIp">{{kuBrokerIpHost1}}</div>
                <ng-template #noBrokerIp>-</ng-template>
            </div>
        </div>
        <hr>
    </div>
    <div>
        <div *ngIf="checkIfKeyIsPresent(items, 'TABLE_FATT')">
            <div
                [class.disabled]="kuOfflineWorkEnabled === false"
                class="align-items-center d-flex justify-content-between sub-section-container"
            >
                <div>
                    <p
                        class="section-title-2"
                        style="text-transform: uppercase;"
                    >
                        {{'OFFLINE.COMUNICAZIONE_TRAMITE_CASSA_MASTER.TITLE' | translate}}
                    </p>
                    <p class="sub-section-description-2 font-r_r fs16 lh16 c10">
                        {{'OFFLINE.COMUNICAZIONE_TRAMITE_CASSA_MASTER.DESCRIPTION' | translate}}
                    </p>
                </div>
                <div class="sub-section-checkbox">
                    <img
                        [src]="KuNewMqttEnabled ? configurationService.getImg('/icons/switch-on.svg') : configurationService.getImg('/icons/switch-off.svg')"
                        (click)="onClickEnableNewMqtt()"
                    >
                </div>
            </div>
            <hr>
        </div>
        <div>
            <div
                class="align-items-center d-flex justify-content-between sub-section-container"
                [class.disabled]="kuOfflineWorkEnabled === false"
                style="margin-right: 32px;"
            >
                <div>
                    <p
                        class="sub-section-title"
                        style="text-transform: uppercase;"
                    >
                        {{ 'OFFLINE.CASSA_MASTER.TITLE' | translate }}
                    </p>
                    <p class="sub-section-description font-r_r fs16 lh16 c10">
                        {{ 'OFFLINE.CASSA_MASTER.DESCRIPTION' | translate }}
                    </p>
                </div>
                <div>
                    <div class="d-flex align-items-center">
                        <span class="sub-section-number mr-3">
                            {{getMasterCashLabel('KU_NEW_MQTT_ELEGGI_NUOVO_MASTER_ENABLED')}}
                        </span>
                        <button
                            class="btn rounded-pill btn-block btn-primary text-uppercase pl-3 pr-3 text-center"
                            style="height: 40px; width: 120px;"
                            (click)="chooseCassaMaster('KU_NEW_MQTT_ELEGGI_NUOVO_MASTER_ENABLED')"
                        >
                            <span class="font-r_r fs18 c13 lh21">
                                <span>{{'choose' | translate | uppercase}}</span>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
            <hr>
            <div
                class="sub-section-container"
                [class.disabled]="kuOfflineWorkEnabled === false"
            >
                <div>
                    <p
                        class="sub-section-title text-uppercase"
                        style="margin: 8px 40px 0px;"
                    >
                        {{ 'OFFLINE.CAMBIO_CASSA_MASTER.TITLE' | translate }}
                    </p>
                    <p class="sub-section-description font-r_r fs16 lh16 c10">
                        {{ 'OFFLINE.CAMBIO_CASSA_MASTER.DESCRIPTION' | translate }}
                    </p>
                </div>
                <div class="sub-section-checkbox">
                    <img
                        [src]="KuEnableSwitchMaster ? configurationService.getImg('/icons/switch-on.svg') : configurationService.getImg('/icons/switch-off.svg')"
                        (click)="onClickEnableSwitchMaster()"
                    >
                </div>
            </div>
            <hr>
        </div>
    </div>
</div>
