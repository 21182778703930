export enum CUSTOMIZE_TYPE {
  SELECT_STORE = 'SELECT_STORE',
  SELECT_CASHDESK = 'SELECT_CASHDESK',
  LINK_DEP_FCIC = 'LINK_DEP_FCIC',
  LINK_CAT_FCIC = 'LINK_CAT_FCIC',
  LICENSE_DETAIL = 'LICENSE_DETAIL',
  LISTINO_PERIOD = 'LISTINO_PERIOD',
  PRINT_STATISTICS = 'PRINT_STATISTICS',
  CUSTOMIZED_PAYMENT = 'CUSTOMIZED_PAYMENT',
  METHOD_PAYMENT = 'METHOD_PAYMENT',
  PRINTER_LANGUAGE = "PRINTER_LANGUAGE",
  DEFAULT_CURRENCY = "DEFAULT_CURRENCY",
  ARTICLE_MOBILE_SECOND_ROW_ON_BUTTON = 'ARTICLE_MOBILE_SECOND_ROW_ON_BUTTON',
  CASSE_VASSOIO = 'CASSE_VASSOIO',
  ASK_PASSWORD_FOR_RESERVED_OPERATIONS = 'ASK_PASSWORD_FOR_RESERVED_OPERATIONS'
}
