import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { GROUP_TYPE, ORDER_PRINTERS_FIELD_TYPES } from '../configuration/configuration.model';
import { ActivatedRoute } from '@angular/router';
import { ConfigurationService } from '../configuration/configuration.service';
import { TranslateService } from '@ngx-translate/core';
import { P2RDialogComponent, P2RDialogService, P2RTranslateService } from '@p2r/shared';
import { ChangeHostIpDialogComponent } from '../change-host-ip-dialog/change-host-ip-dialog.component';
import { SIZE } from '@p2r/shared/lib/shared/p2r-dialog/p2r-dialog.interface';
import { checkIfKeyIsPresent } from '../configuration/configuration.utils';

@Component({
  selector: 'configuration-ku-order-printers',
  templateUrl: './ku-order-printers.component.html',
  styleUrls: ['./ku-order-printers.component.scss']
})
export class KuOrderPrintersComponent implements OnInit {

  /*
  {
    "KA_AVVISO_ACUSTICO_CASSETTO":true,
    "KA_BUZZER_COMANDE":true,
    "KA_PRINTER_COMANDE_SOCKET_HOST3":"",
    "KA_PRINTER_COMANDE_SOCKET_HOST2":"",
    "KA_PRINTER_COMANDE_SOCKET_HOST1":""
  }
  */
  public values: any;

  /*
  {
    "STAMPANTI_COMANDE_SENZA_WEB_SERVER":{
      "TITLE":{},
      "DESCRIPTION":{},
      "DISPOSITIVO_1":{
        "TITLE":{}
      },
      "DISPOSITIVO_2":{
        "TITLE":{}
      },
      "DISPOSITIVO_3":{
        "TITLE":{}
      }
    },
    "KA_BAZZER_COMANDE":{
      "TITLE":{},
      "DESCRIPTION":{}
    },
    "KA_AVVISO_ACUSTICO_ESTERNO":{
      "TITLE":{},
      "DESCRIPTION":{}
    }
  }
  */
  public items: any;

  public kuIpHost1: string;
  public kuIpHost2: string;
  public kuIpHost3: string;
  public kuBuzzerComandeEnable: any;
  public kuAvvisoSonoroEsternoEnable: any;


  constructor(
    public configurationService: ConfigurationService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private p2rDialogService: P2RDialogService,
    private p2rTranslateService: P2RTranslateService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.configurationService.selectedGroup = GROUP_TYPE.ORDER_PRINTERS;

    this.route.data.subscribe(({ data }) => {
      const [items, values] = data;

      this.items = items;
      this.values = values;

      // console.log('ORDER PRINTERS');
      // console.log('ITEMS => ' + JSON.stringify(this.items));
      // console.log('VALUES =>\n' + JSON.stringify(this.values));

      this.kuIpHost1 = values[ORDER_PRINTERS_FIELD_TYPES.KA_PRINTER_COMANDE_SOCKET_HOST1];
      this.kuIpHost2 = values[ORDER_PRINTERS_FIELD_TYPES.KA_PRINTER_COMANDE_SOCKET_HOST2];
      this.kuIpHost3 = values[ORDER_PRINTERS_FIELD_TYPES.KA_PRINTER_COMANDE_SOCKET_HOST3];
      this.kuBuzzerComandeEnable = values[ORDER_PRINTERS_FIELD_TYPES.KA_BUZZER_COMANDE];
      this.kuAvvisoSonoroEsternoEnable = values[ORDER_PRINTERS_FIELD_TYPES.KA_AVVISO_ACUSTICO_CASSETTO];


      const group: any = {};

      Object.keys(values).forEach(key => {
        group[key] = new FormControl(values[key], {
          validators: [],
          asyncValidators: [],
          updateOn: 'blur'
        });
      });

      this.configurationService.formGroup = this.formBuilder.group(group);
    });

    this.configurationService.groups$().subscribe(groups => {
      const found = groups.find(g => g.cod === GROUP_TYPE.ORDER_PRINTERS);
      this.items = found && found.items || [];
    });

    this.configurationService.values$().subscribe(values => {
      values = values[GROUP_TYPE.ORDER_PRINTERS];
      if (values !== undefined) {
        if (this.configurationService._selectedGroup === GROUP_TYPE.ORDER_PRINTERS) {
          // this.configurationService.formGroup.setValue(values);

          this.kuIpHost1 = values[ORDER_PRINTERS_FIELD_TYPES.KA_PRINTER_COMANDE_SOCKET_HOST1];
          this.kuIpHost2 = values[ORDER_PRINTERS_FIELD_TYPES.KA_PRINTER_COMANDE_SOCKET_HOST2];
          this.kuIpHost3 = values[ORDER_PRINTERS_FIELD_TYPES.KA_PRINTER_COMANDE_SOCKET_HOST3];
          this.kuBuzzerComandeEnable = values[ORDER_PRINTERS_FIELD_TYPES.KA_BUZZER_COMANDE];
          this.kuAvvisoSonoroEsternoEnable = values[ORDER_PRINTERS_FIELD_TYPES.KA_AVVISO_ACUSTICO_CASSETTO];
        }
      }
    });
  }

  openChangeHost(p) {
    console.log('openChangeHost: ' + p);
    let ipAdress;
    let dialogTitle = this.p2rTranslateService.get(this.translateService.instant('host_number'));
    dialogTitle += p;
    switch (p) {
      case '1':
        ipAdress = this.kuIpHost1;
        break;
      case '2':
        ipAdress = this.kuIpHost2;
        break;
      case '3':
        ipAdress = this.kuIpHost3;
        break;
      default:
        break;
    }
    const dataToDialog = {
      printer: p,
      ip: ipAdress
    };
    const alert = this.p2rDialogService.open(P2RDialogComponent, {
      id: 'open-change-host-dialog',
      hideCloseButton: true,
      title: dialogTitle,
      titleClasses: 'font-r_r fs24 lh-39 c11',
      body: ChangeHostIpDialogComponent,
      data: dataToDialog,
      size: SIZE.MEDIUM,
      actions: [
        {
          label: this.p2rTranslateService.get(this.translateService.instant('dialog_cancel')),
          click: () => {
            alert.dismiss();
          },
          classes: 'btn rounded-pill text-uppercase new-alert-button font-r_l fs18',
          data: {
            remove: true
          },
          params: {
          }
        },
        {
          label: this.p2rTranslateService.get(this.translateService.instant('dialog_confirm')),
          click: (data) => {
            alert.dismiss();
            console.log('openChangeHost return: ' + JSON.stringify(data));
            switch (dataToDialog.printer) {
              case '1':
                this.kuIpHost1 = (dataToDialog.ip !== undefined) ? dataToDialog.ip : '';
                this.configurationService.formGroup
                  .get(ORDER_PRINTERS_FIELD_TYPES.KA_PRINTER_COMANDE_SOCKET_HOST1)
                  .setValue(this.kuIpHost1);
                break;
              case '2':
                this.kuIpHost2 = (dataToDialog.ip !== undefined) ? dataToDialog.ip : '';
                this.configurationService.formGroup
                  .get(ORDER_PRINTERS_FIELD_TYPES.KA_PRINTER_COMANDE_SOCKET_HOST2)
                  .setValue(this.kuIpHost2);
                break;
              case '3':
                this.kuIpHost3 = (dataToDialog.ip !== undefined) ? dataToDialog.ip : '';
                this.configurationService.formGroup
                  .get(ORDER_PRINTERS_FIELD_TYPES.KA_PRINTER_COMANDE_SOCKET_HOST3)
                  .setValue(this.kuIpHost3);
                break;
              default:
                break;
            }
          },
          data: dataToDialog,
          classes: 'btn rounded-pill text-uppercase new-alert-button font-r_l fs18',
          params: {
            submit: true
          }
        }
      ]
    });
  }

  public checkIfKeyIsPresent(array, key) {
    const v = checkIfKeyIsPresent(array, key);
    return v;
  }

  onClickAbilitaBuzzerComande() {
    this.kuBuzzerComandeEnable = !this.kuBuzzerComandeEnable;
    this.configurationService.formGroup
      .get(ORDER_PRINTERS_FIELD_TYPES.KA_BUZZER_COMANDE)
      .setValue(this.kuBuzzerComandeEnable);
  }

  onClickAbilitaAvvisoAcustico() {
    this.kuAvvisoSonoroEsternoEnable = !this.kuAvvisoSonoroEsternoEnable;
    this.configurationService.formGroup
      .get(ORDER_PRINTERS_FIELD_TYPES.KA_AVVISO_ACUSTICO_CASSETTO)
      .setValue(this.kuAvvisoSonoroEsternoEnable);
  }
}
