<div class="header w-100 bg-dark">
    <div class="d-flex h-100 nav-bar">
        <div class="align-items-center d-flex flex-grow-1 justify-content-between">
            <div class="align-items-center d-flex">
                <div (click)="openMenu($event)">
                    <div id="back-btn">
                        <img [src]="configurationService.getImg('/icons/menu.svg')">
                    </div>
                </div>
                <div style="margin-left: 32px;">
                    <div>
                        <div id="save-btn">
                            <span class="header_label font-r_r fs20 c13 lh16">{{header.label | translate}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="align-items-center d-flex">
                <div>
                    <div *ngIf="!isFullScreen">
                        <img
                            [src]="configurationService.getImg(screenImg.fullscreen)"
                            (click)="openFullscreen()"
                        >
                    </div>
                    <div *ngIf="isFullScreen">
                        <img
                            [src]="configurationService.getImg(screenImg.windowscreen)"
                            (click)="closeFullscreen()"
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
