<div>
    <div *ngIf="checkIfKeyIsPresent(values, 'KA_PRINTER_COMANDE_SOCKET_HOST1')">
        <div *ngIf="checkIfKeyIsPresent(items,'STAMPANTI_COMANDE_SENZA_WEB_SERVER')">
            <p class="section-title">
                {{'ORDER_PRINTERS.STAMPANTI_COMANDE_SENZA_WEB_SERVER.TITLE' | translate}}
            </p>
            <p class="sub-section-description-2 font-r_r fs16 lh16 c10">
                {{'ORDER_PRINTERS.STAMPANTI_COMANDE_SENZA_WEB_SERVER.DESCRIPTION' | translate}}
            </p>
            <hr>
            <div *ngIf="items.STAMPANTI_COMANDE_SENZA_WEB_SERVER.hasOwnProperty('DISPOSITIVO_1')">
                <div class="sub-section-container-host_ip">
                    <div>
                        <p
                            class="sub-section-title"
                            style="margin-top: 6px;"
                        >
                            {{'ORDER_PRINTERS.STAMPANTI_COMANDE_SENZA_WEB_SERVER.DISPOSITIVO_1.TITLE' | translate}}
                        </p>
                    </div>
                    <div
                        class="sub-section-host"
                        (click)="openChangeHost('1')"
                    >
                        <div *ngIf="(kuIpHost1.length > 0); else elseBlock">{{kuIpHost1}}</div>
                        <ng-template #elseBlock>
                            <td style='color: #8A8D8F; font-size: 14px; padding-top: 2px;'>{{'digit_host_ip' | translate}}</td>
                        </ng-template>
                    </div>
                </div>
                <hr>
            </div>
            <div *ngIf="items.STAMPANTI_COMANDE_SENZA_WEB_SERVER.hasOwnProperty('DISPOSITIVO_2')">
                <div class="sub-section-container-host_ip ">
                    <div>
                        <p
                            class="sub-section-title"
                            style="margin-top: 6px;"
                        >
                            {{'ORDER_PRINTERS.STAMPANTI_COMANDE_SENZA_WEB_SERVER.DISPOSITIVO_2.TITLE' | translate}}
                        </p>
                    </div>
                    <div
                        class="sub-section-host"
                        (click)="openChangeHost('2')"
                    >
                        <div *ngIf="(kuIpHost2.length > 0); else elseBlock">{{kuIpHost2}}</div>
                        <ng-template #elseBlock>
                            <td style='color: #8A8D8F; font-size: 14px; padding-top: 2px;'>{{'digit_host_ip' | translate}}</td>
                        </ng-template>
                    </div>
                </div>
                <hr>
            </div>
            <div *ngIf="items.STAMPANTI_COMANDE_SENZA_WEB_SERVER.hasOwnProperty('DISPOSITIVO_3')">
                <div class="sub-section-container-host_ip ">
                    <div>
                        <p
                            class="sub-section-title"
                            style="margin-top: 6px;"
                        >
                            {{'ORDER_PRINTERS.STAMPANTI_COMANDE_SENZA_WEB_SERVER.DISPOSITIVO_3.TITLE' | translate}}
                        </p>
                    </div>
                    <div
                        class="sub-section-host"
                        style="text-align: right; float:left;"
                        (click)="openChangeHost('3')"
                    >
                        <div *ngIf="(kuIpHost3.length > 0); else elseBlock">{{kuIpHost3}}</div>
                        <ng-template #elseBlock>
                            <td style='color: #8A8D8F; font-size: 14px; padding-top: 2px;'>{{'digit_host_ip' | translate}}</td>
                        </ng-template>
                    </div>
                </div>
                <hr>
            </div>
        </div>
        <div *ngIf="checkIfKeyIsPresent(values, 'KA_BUZZER_COMANDE')">
            <div *ngIf="checkIfKeyIsPresent(items, 'KA_BAZZER_COMANDE')">
                <div
                    class="sub-section-container"
                    style="margin-right: 32px;"
                >
                    <div>
                        <p
                            class="section-title-2"
                            style="text-transform: uppercase;"
                        >
                            {{'ORDER_PRINTERS.KA_BAZZER_COMANDE.TITLE' | translate}}
                        </p>
                        <p class="sub-section-description-2 font-r_r fs16 lh16 c10">
                            {{'ORDER_PRINTERS.KA_BAZZER_COMANDE.DESCRIPTION' | translate}}
                        </p>
                    </div>
                    <div
                        class="sub-section-checkbox-2"
                        style="margin: auto;"
                    >
                        <img
                            [src]="kuBuzzerComandeEnable ? configurationService.getImg('/icons/switch-on.svg') : configurationService.getImg('/icons/switch-off.svg')"
                            (click)="onClickAbilitaBuzzerComande()"
                        >
                    </div>
                </div>
                <hr>
            </div>
        </div>
        <div *ngIf="checkIfKeyIsPresent(values, 'KA_AVVISO_ACUSTICO_CASSETTO')">
            <div *ngIf="checkIfKeyIsPresent(items, 'KA_AVVISO_ACUSTICO_ESTERNO')">
                <div
                    class="sub-section-container"
                    style="margin-right: 32px;"
                >
                    <div>
                        <p
                            class="section-title-2"
                            style="text-transform: uppercase;"
                        >
                            {{'ORDER_PRINTERS.KA_AVVISO_ACUSTICO_ESTERNO.TITLE' | translate}}
                        </p>
                        <p class="sub-section-description-2 font-r_r fs16 lh16 c10">
                            {{'ORDER_PRINTERS.KA_AVVISO_ACUSTICO_ESTERNO.DESCRIPTION' | translate}}
                        </p>
                    </div>
                    <div
                        class="sub-section-checkbox-2"
                        style="margin: auto;"
                    >
                        <img
                            [src]="kuAvvisoSonoroEsternoEnable ? configurationService.getImg('/icons/switch-on.svg') : configurationService.getImg('/icons/switch-off.svg')"
                            (click)="onClickAbilitaAvvisoAcustico()"
                        >
                    </div>
                </div>
                <hr>
            </div>
        </div>
    </div>
</div>
