<div
    [ngStyle]="{'background-color':(isStoreListExpanded) ? '#EFEFEF' : '#FFFFFF' }"
    [class.expanded]="isStoreListExpanded"
>
    <div
        class="section-container"
        style="padding-top: 15px; padding-bottom: 15px;"
    >
        <div
            class="store_title"
            style="
                white-space: nowrap; 
                overflow: hidden; 
                text-overflow: ellipsis;  
                width: 100%; 
                padding-right: 16px;
                "
            (click)="openStoreList()"
        >
            {{'selected_store' | translate}} {{this.selectedPv.nome.toUpperCase()}}
        </div>
        <div
            class="section-close-list"
            *ngIf="isStoreListExpanded"
        >
            <img
                [src]="configurationService.getImg('/icons/close_black.svg')"
                (click)="onCloseStoreList()"
            >
        </div>
    </div>
    <!-- <hr style="margin: 0;"> -->
    <ul
        *ngIf="isStoreListExpanded"
        class="store"
        style="padding-bottom: 2px;"
    >
        <li
            *ngFor="let pv of pvList"
            (click)="onSelectPv(pv)"
            [class.selected]="pv.codice==selectedPv.codice"
        >
            <div class="position-relative">
                <div style="text-transform: uppercase;">{{pv.nome}}</div>
            </div>
        </li>
    </ul>
</div>
