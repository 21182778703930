import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
export class HeaderComponent {
    constructor(configurationService, doc) {
        this.configurationService = configurationService;
        this.doc = doc;
        this._header = {
            label: 'preferences'
        };
        this.screenImg = {
            fullscreen: this.configurationService.ICONS.FULL_SCREEN,
            windowscreen: this.configurationService.ICONS.WINDOW_SCREEN
        };
        this.elem = doc.documentElement;
        this.isFullScreen = document.fullscreenElement ? true : false;
        doc.onfullscreenchange = () => {
            setTimeout(() => {
                this.isFullScreen = !this.isFullScreen;
            }, 0);
        };
    }
    ngOnInit() {
        document.dispatchEvent(new CustomEvent('SETTINGS:OPEN'));
    }
    openMenu(event) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            event.stopPropagation();
            event.preventDefault();
            document.dispatchEvent(new CustomEvent('SETTINGS:OPEN_MENU'));
        });
    }
    openFullscreen() {
        let isOpened;
        if (this.elem.requestFullscreen) {
            this.elem.requestFullscreen();
            isOpened = true;
        }
        else if (this.elem.mozRequestFullScreen) {
            /* Firefox */
            this.elem.mozRequestFullScreen();
            isOpened = true;
        }
        else if (this.elem.webkitRequestFullscreen) {
            /* Chrome, Safari and Opera */
            this.elem.webkitRequestFullscreen();
            isOpened = true;
        }
        else if (this.elem.msRequestFullscreen) {
            /* IE/Edge */
            this.elem.msRequestFullscreen();
            isOpened = true;
        }
        if (isOpened) {
            document.dispatchEvent(new CustomEvent('SETTINGS:OPEN_FULL_SCREEN'));
        }
    }
    /* Close fullscreen */
    closeFullscreen() {
        let isClosed;
        if (this.doc.exitFullscreen) {
            this.doc.exitFullscreen();
            isClosed = true;
        }
        else if (this.doc.mozCancelFullScreen) {
            /* Firefox */
            this.doc.mozCancelFullScreen();
            isClosed = true;
        }
        else if (this.doc.webkitExitFullscreen) {
            /* Chrome, Safari and Opera */
            this.doc.webkitExitFullscreen();
            isClosed = true;
        }
        else if (this.doc.msExitFullscreen) {
            /* IE/Edge */
            this.doc.msExitFullscreen();
            isClosed = true;
        }
        if (isClosed) {
            document.dispatchEvent(new CustomEvent('SETTINGS:CLOSE_FULL_SCREEN'));
        }
    }
    ngOnDestroy() {
    }
    set header(header) {
        if (header) {
            this._header = header;
        }
    }
    get header() {
        return this._header;
    }
}
