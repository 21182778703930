import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'configuration-change-service-percentage-dialog',
  templateUrl: './change-service-percentage-dialog.component.html',
  styleUrls: ['./change-service-percentage-dialog.component.scss']
})
export class ChangeServicePercentageDialogComponent implements OnInit {
  public servicePercentageForm: FormGroup;
  public _data: any;

  constructor(
  ) { }

  ngOnInit() {
  }

  @Input() set data(data: any) {
    this._data = data;
  }

  get data(): any {
    return this._data;
  }

  public checkLength(event, percentageVal) {
    this._data.value_error = false;
    if (parseInt(event.target.value + percentageVal) > 100 && event.keyCode !== 8 && event.keyCode !== 46 && event.keyCode !== 39 && event.keyCode !== 37) {
      return false;
    }
  }

}
