<div>
    <div *ngIf="checkIfKeyIsPresent(values, 'KU_ABILITA_COMANDA_POSTICIPATA')">
        <div *ngIf="checkIfKeyIsPresent(items, 'KU_ABILITA_COMANDA_POSTICIPATA')">
            <div
                class="align-items-center d-flex justify-content-between sub-section-container" style="margin-top:30px;"
            >
                <div>
                    <p
                    class="section-title"
                    style="text-transform: uppercase;"
                    >
                    {{'ORDERS.KU_ABILITA_COMANDA_POSTICIPATA.TITLE' | translate}}
                    </p>
                    <p class="sub-section-description font-r_r fs16 lh16 c10">
                        {{'ORDERS.KU_ABILITA_COMANDA_POSTICIPATA.SUBTITLE' | translate}}
                    </p>
                </div>
                <div class="sub-section-checkbox">
                    <img
                        [src]="kuabilitaComandaPosticipata ? configurationService.getImg('/icons/switch-on.svg') :
                configurationService.getImg('/icons/switch-off.svg')"
                        (click)="onClickAbilitaComandaPosticipata()"
                    >
                </div>
            </div>
            <hr>
        </div>
    </div>
</div>
