import { Component, OnInit } from '@angular/core';
import { GROUP_TYPE, OPERATOR_FIELD_TYPES, ORDERS_FIELD_TYPES } from '../configuration/configuration.model';
import { ConfigurationService } from '../configuration/configuration.service';
import { ActivatedRoute } from '@angular/router';
import { checkIfKeyIsPresent } from '../configuration/configuration.utils';
import { FormBuilder, FormControl } from '@angular/forms';

@Component({
  selector: 'configuration-custom-order',
  templateUrl: './custom-order.component.html',
  styleUrls: ['./custom-order.component.scss']
})
export class CustomOrderComponent implements OnInit {

  /*
  {
    "KU_ABILITA_COMANDA_POSTICIPATA":false
  }
  */
  public values: any;

  /*
  {
    "KU_ABILITA_COMANDA_POSTICIPATA":{
      "TITLE":{},
      "DESCRIPTION":{}
    }
  }
  */
  public items: any;

  public kuabilitaComandaPosticipata: any;

  constructor(public configurationService: ConfigurationService,  private route: ActivatedRoute,
    private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.configurationService.selectedGroup = GROUP_TYPE.ORDERS;

    this.route.data.subscribe(({ data }) => {
      const [items, values] = data;

      this.items = items;
      this.values = values;

      // console.log(this.items);
      console.log(this.values);

      this.kuabilitaComandaPosticipata = values[ORDERS_FIELD_TYPES.KU_ABILITA_COMANDA_POSTICIPATA];
      
      const group: any = {};

      Object.keys(values).forEach(key => {
        group[key] = new FormControl(values[key], {
          validators: [],
          asyncValidators: [],
          updateOn: 'blur'
        });
      });

      this.configurationService.formGroup = this.formBuilder.group(group);
    });

    this.configurationService.groups$().subscribe(groups => {
      const found = groups.find(g => g.cod === GROUP_TYPE.ORDERS);
      this.items = found && found.items || [];
    });

    this.configurationService.values$().subscribe(values => {
      values = values[GROUP_TYPE.ORDERS];
      if (values !== undefined) {
        if (this.configurationService._selectedGroup === GROUP_TYPE.ORDERS) {
          // this.configurationService.formGroup.setValue(values);
          this.kuabilitaComandaPosticipata = values[ORDERS_FIELD_TYPES.KU_ABILITA_COMANDA_POSTICIPATA];
        }
      }
    });
  }

  public checkIfKeyIsPresent(array, key) {
    const v = checkIfKeyIsPresent(array, key);
    return v;
  }

  onClickAbilitaComandaPosticipata(){
    this.kuabilitaComandaPosticipata = !this.kuabilitaComandaPosticipata;
    this.configurationService.formGroup.get(ORDERS_FIELD_TYPES.KU_ABILITA_COMANDA_POSTICIPATA).setValue(this.kuabilitaComandaPosticipata);
  }

}
