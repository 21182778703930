/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ku-closure.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./ku-closure.component";
import * as i5 from "../configuration/configuration.service";
import * as i6 from "@angular/forms";
import * as i7 from "@angular/router";
import * as i8 from "@p2r/shared";
var styles_KuClosureComponent = [i0.styles];
var RenderType_KuClosureComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_KuClosureComponent, data: {} });
export { RenderType_KuClosureComponent as RenderType_KuClosureComponent };
function View_KuClosureComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "sub-section-container"], ["style", "margin-top: 10px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "p", [["class", "section-title-2"], ["style", "text-transform: uppercase;"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 2, "p", [["class", "sub-section-description-2 font-r_r fs16 lh16 c10"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(9, 0, null, null, 1, "div", [["class", "sub-section-checkbox-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "img", [], [[8, "src", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClinkEnableFiscalClosure() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "hr", [], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("CLOSURE.ASK_FISCAL_CLOSURE.TITLE")); _ck(_v, 4, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("CLOSURE.ASK_FISCAL_CLOSURE.DESCRIPTION")); _ck(_v, 7, 0, currVal_1); var currVal_2 = (_co.kuAskFiscalClosure ? _co.configurationService.getImg("/icons/switch-on.svg") : _co.configurationService.getImg("/icons/switch-off.svg")); _ck(_v, 10, 0, currVal_2); }); }
function View_KuClosureComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_KuClosureComponent_2)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.checkIfKeyIsPresent(_co.items, "ASK_FISCAL_CLOSURE"); _ck(_v, 2, 0, currVal_0); }, null); }
function View_KuClosureComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "sub-section-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "p", [["class", "section-title-2"], ["style", "text-transform: uppercase;"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 2, "p", [["class", "sub-section-description-2 font-r_r fs16 lh16 c10"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(9, 0, null, null, 1, "div", [["class", "sub-section-checkbox-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "img", [], [[8, "src", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickEnableAperturaChiusuraVeloce() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "hr", [], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("CLOSURE.APERTURA_CHIUSURA_VELOCE.TITLE")); _ck(_v, 4, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("CLOSURE.APERTURA_CHIUSURA_VELOCE.DESCRIPTION")); _ck(_v, 7, 0, currVal_1); var currVal_2 = (_co.kuAperturaChiusuraVeloce ? _co.configurationService.getImg("/icons/switch-on.svg") : _co.configurationService.getImg("/icons/switch-off.svg")); _ck(_v, 10, 0, currVal_2); }); }
function View_KuClosureComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_KuClosureComponent_4)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.checkIfKeyIsPresent(_co.items, "APERTURA_CHIUSURA_VELOCE"); _ck(_v, 2, 0, currVal_0); }, null); }
function View_KuClosureComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 17, "div", [["class", "align-items-center d-flex justify-content-between sub-section-container"], ["style", "margin-right: 32px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "p", [["class", "section-title-2"], ["style", "text-transform: uppercase;"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 2, "p", [["class", "sub-section-description-2 font-r_r fs16 lh16 c10"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(9, 0, null, null, 9, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 8, "div", [["class", "d-flex align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "span", [["class", "sub-section-number mr-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, ["", ""])), (_l()(), i1.ɵeld(13, 0, null, null, 5, "button", [["class", "btn rounded-pill btn-block btn-primary text-uppercase pl-3 pr-3 text-center"], ["style", "height: 40px; width: 120px;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.chooseCassa() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 4, "span", [["class", "font-r_r fs18 c13 lh21"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(16, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(18, 1), (_l()(), i1.ɵeld(19, 0, null, null, 0, "hr", [], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("CLOSURE.KU_STATS_CLOSING_PRINTER.TITLE")); _ck(_v, 4, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("CLOSURE.KU_STATS_CLOSING_PRINTER.DESCRIPTION")); _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.getPrinterStatsLabel(); _ck(_v, 12, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 16, 0, _ck(_v, 18, 0, i1.ɵnov(_v.parent.parent, 0), i1.ɵunv(_v, 16, 0, i1.ɵnov(_v, 17).transform("choose")))); _ck(_v, 16, 0, currVal_3); }); }
function View_KuClosureComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_KuClosureComponent_6)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.checkIfKeyIsPresent(_co.items, "KU_STATS_CLOSING_PRINTER"); _ck(_v, 2, 0, currVal_0); }, null); }
function View_KuClosureComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 17, "div", [["class", "align-items-center d-flex justify-content-between sub-section-container"], ["style", "margin-right: 32px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "p", [["class", "section-title-2"], ["style", "text-transform: uppercase;"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 2, "p", [["class", "sub-section-description-2 font-r_r fs16 lh16 c10"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(9, 0, null, null, 9, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 8, "div", [["class", "d-flex align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "span", [["class", "sub-section-number mr-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, ["", ""])), (_l()(), i1.ɵeld(13, 0, null, null, 5, "button", [["class", "btn rounded-pill btn-block btn-primary text-uppercase pl-3 pr-3 text-center"], ["style", "height: 40px; width: 120px;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setUpMail() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 4, "span", [["class", "font-r_r fs18 c13 lh21"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(16, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(18, 1), (_l()(), i1.ɵeld(19, 0, null, null, 0, "hr", [], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("CLOSURE.KU_SCORECARDS_CLOSURE_EMAIL_ADDRESS.TITLE")); _ck(_v, 4, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("CLOSURE.KU_SCORECARDS_CLOSURE_EMAIL_ADDRESS.DESCRIPTION")); _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.setEmailFieldMessage(); _ck(_v, 12, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 16, 0, _ck(_v, 18, 0, i1.ɵnov(_v.parent.parent, 0), i1.ɵunv(_v, 16, 0, i1.ɵnov(_v, 17).transform("set_up")))); _ck(_v, 16, 0, currVal_3); }); }
function View_KuClosureComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "disabled": 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_KuClosureComponent_8)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, ((_co.kuStatsPrinter == undefined) || (_co.kuStatsPrinter.length == 0))); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.checkIfKeyIsPresent(_co.items, "KU_SCORECARDS_CLOSURE_EMAIL_ADDRESS"); _ck(_v, 5, 0, currVal_1); }, null); }
function View_KuClosureComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 22, "div", [], null, null, null, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "disabled": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 17, "div", [["class", "align-items-center d-flex justify-content-between sub-section-container"], ["style", "margin-right: 32px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "p", [["class", "section-title-2"], ["style", "text-transform: uppercase;"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(9, 0, null, null, 2, "p", [["class", "sub-section-description-2 font-r_r fs16 lh16 c10"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(12, 0, null, null, 9, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 8, "div", [["class", "d-flex align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "span", [["class", "sub-section-number mr-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(15, null, ["", ""])), (_l()(), i1.ɵeld(16, 0, null, null, 5, "button", [["class", "btn rounded-pill btn-block btn-primary text-uppercase pl-3 pr-3 text-center"], ["style", "height: 40px; width: 120px;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openPopupReportChiusura() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 4, "span", [["class", "font-r_r fs18 c13 lh21"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(19, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(21, 1), (_l()(), i1.ɵeld(22, 0, null, null, 0, "hr", [], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, ((_co.kuStatsPrinter == undefined) || (_co.kuStatsPrinter.length == 0))); _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("CLOSURE.REPORTO_FINANZIARIO.TITLE")); _ck(_v, 7, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform("CLOSURE.REPORTO_FINANZIARIO.DESCRIPTION")); _ck(_v, 10, 0, currVal_2); var currVal_3 = _co.kuStatisticsCounter; _ck(_v, 15, 0, currVal_3); var currVal_4 = i1.ɵunv(_v, 19, 0, _ck(_v, 21, 0, i1.ɵnov(_v.parent, 0), i1.ɵunv(_v, 19, 0, i1.ɵnov(_v, 20).transform("choose")))); _ck(_v, 19, 0, currVal_4); }); }
export function View_KuClosureComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.UpperCasePipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_KuClosureComponent_1)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_KuClosureComponent_3)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_KuClosureComponent_5)), i1.ɵdid(7, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_KuClosureComponent_7)), i1.ɵdid(9, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_KuClosureComponent_9)), i1.ɵdid(11, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.checkIfKeyIsPresent(_co.values, "KU_ASK_FISCAL_CLOSURE"); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.checkIfKeyIsPresent(_co.values, "KETCHAPP_APERTURA_CHIUSURA_VELOCE"); _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.checkIfKeyIsPresent(_co.values, "KU_STATS_CLOSING_PRINTER"); _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.checkIfKeyIsPresent(_co.values, "KU_SCORECARDS_CLOSURE_EMAIL_ADDRESS"); _ck(_v, 9, 0, currVal_3); var currVal_4 = _co.checkIfKeyIsPresent(_co.values, "STAMPA_REPORT_FINANZIARIO_IN_CHIUSURA"); _ck(_v, 11, 0, currVal_4); }, null); }
export function View_KuClosureComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "configuration-ku-closure", [], null, null, null, View_KuClosureComponent_0, RenderType_KuClosureComponent)), i1.ɵdid(1, 114688, null, 0, i4.KuClosureComponent, [i5.ConfigurationService, i6.FormBuilder, i7.ActivatedRoute, i8.P2RTranslateService, i2.TranslateService, i8.P2RDialogService, i8.P2RLocalStorageService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var KuClosureComponentNgFactory = i1.ɵccf("configuration-ku-closure", i4.KuClosureComponent, View_KuClosureComponent_Host_0, {}, {}, []);
export { KuClosureComponentNgFactory as KuClosureComponentNgFactory };
