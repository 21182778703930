/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./store-panel.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@ngx-translate/core";
import * as i4 from "./store-panel.component";
import * as i5 from "../configuration/configuration.service";
import * as i6 from "@p2r/env";
import * as i7 from "@p2r/shared";
var styles_StorePanelComponent = [i0.styles];
var RenderType_StorePanelComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StorePanelComponent, data: {} });
export { RenderType_StorePanelComponent as RenderType_StorePanelComponent };
function View_StorePanelComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "section-close-list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [], [[8, "src", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCloseStoreList() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.configurationService.getImg("/icons/close_black.svg"); _ck(_v, 1, 0, currVal_0); }); }
function View_StorePanelComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "li", [], [[2, "selected", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSelectPv(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "position-relative"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["style", "text-transform: uppercase;"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.$implicit.codice == _co.selectedPv.codice); _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit.nome; _ck(_v, 3, 0, currVal_1); }); }
function View_StorePanelComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ul", [["class", "store"], ["style", "padding-bottom: 2px;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StorePanelComponent_3)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.pvList; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_StorePanelComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [], [[2, "expanded", null]], null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(3, { "background-color": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 5, "div", [["class", "section-container"], ["style", "padding-top: 15px; padding-bottom: 15px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "store_title"], ["style", "\n                white-space: nowrap; \n                overflow: hidden; \n                text-overflow: ellipsis;  \n                width: 100%; \n                padding-right: 16px;\n                "]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openStoreList() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(6, null, [" ", " ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StorePanelComponent_1)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StorePanelComponent_2)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _ck(_v, 3, 0, (_co.isStoreListExpanded ? "#EFEFEF" : "#FFFFFF")); _ck(_v, 2, 0, currVal_1); var currVal_4 = _co.isStoreListExpanded; _ck(_v, 9, 0, currVal_4); var currVal_5 = _co.isStoreListExpanded; _ck(_v, 11, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isStoreListExpanded; _ck(_v, 0, 0, currVal_0); var currVal_2 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("selected_store")); var currVal_3 = _co.selectedPv.nome.toUpperCase(); _ck(_v, 6, 0, currVal_2, currVal_3); }); }
export function View_StorePanelComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "configuration-store-panel", [], null, null, null, View_StorePanelComponent_0, RenderType_StorePanelComponent)), i1.ɵdid(1, 114688, null, 0, i4.StorePanelComponent, [i5.ConfigurationService, i6.P2REnvironmentService, i7.P2RLocalStorageService, i7.P2RDialogService, i7.P2RTranslateService, i3.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StorePanelComponentNgFactory = i1.ɵccf("configuration-store-panel", i4.StorePanelComponent, View_StorePanelComponent_Host_0, {}, { expandEvent: "expandEvent" }, []);
export { StorePanelComponentNgFactory as StorePanelComponentNgFactory };
