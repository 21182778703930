/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./alert.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@ngx-translate/core";
import * as i4 from "./alert.component";
import * as i5 from "../configuration.service";
var styles_ConfigurationAlertComponent = [i0.styles];
var RenderType_ConfigurationAlertComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfigurationAlertComponent, data: {} });
export { RenderType_ConfigurationAlertComponent as RenderType_ConfigurationAlertComponent };
function View_ConfigurationAlertComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "image-container"], ["id", "image-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["height", "80px"], ["width", "80px"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "vertical-space"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.imgSrc; _ck(_v, 2, 0, currVal_0); }); }
function View_ConfigurationAlertComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "title-container fs34"], ["id", "title-container"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "vertical-space"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 2, 0, currVal_0); }); }
function View_ConfigurationAlertComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "progress-container m-4"], ["id", "progress-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "progress-bar"], ["id", "progress-bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "d-flex justify-content-between"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "font_r-r fs16 lh25 c11"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["0%"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "font_r-r fs16 lh25 c11"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["100%"])), (_l()(), i1.ɵeld(8, 0, null, null, 0, "div", [["class", "vertical-space"]], null, null, null, null, null))], null, null); }
function View_ConfigurationAlertComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "font-r_l fs18 lh21 c11"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""]))], function (_ck, _v) { var currVal_0 = ((_v.context.$implicit && _v.context.$implicit.class) ? _v.context.$implicit.class : ""); _ck(_v, 3, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = ((_v.context.$implicit && _v.context.$implicit.text) || _v.context.$implicit); _ck(_v, 5, 0, currVal_1); }); }
function View_ConfigurationAlertComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "message-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "font-r_r fs14 lh25 c11"], ["style", "white-space: pre"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 3, 0, currVal_0); }); }
function View_ConfigurationAlertComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showHideDetailMessages() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "span", [["class", "font-r_r fs16 lh25 c11 mr-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 3, "img", [], [[8, "src", 4]], null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(7, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(8, { "transform": 0 }), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "border-top border-bottom mt-2 p-3 text-left"], ["id", "detail-messages-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfigurationAlertComponent_7)), i1.ɵdid(11, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 8, 0, "rotate(90deg)"); _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.detailMessages; _ck(_v, 11, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("hide_details")); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.configurationService.getImg("icons/open-arrow-dark.svg"); _ck(_v, 5, 0, currVal_1); }); }
function View_ConfigurationAlertComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showHideDetailMessages() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "span", [["class", "font-r_r fs16 lh25 c11 mr-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 3, "img", [], [[8, "src", 4]], null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(7, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(8, { "transform": 0 })], function (_ck, _v) { var currVal_2 = _ck(_v, 8, 0, "rotate(90deg)"); _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("show_details")); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.configurationService.getImg("icons/open-arrow-dark.svg"); _ck(_v, 5, 0, currVal_1); }); }
function View_ConfigurationAlertComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "mt-4"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfigurationAlertComponent_6)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfigurationAlertComponent_8)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showDetailMessages; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.showDetailMessages; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_ConfigurationAlertComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "d-flex flex-column"], ["style", "overflow: hidden; margin: 16px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfigurationAlertComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfigurationAlertComponent_2)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfigurationAlertComponent_3)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "message-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfigurationAlertComponent_4)), i1.ɵdid(10, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfigurationAlertComponent_5)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.imgSrc; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.title; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.data.progressBar; _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.messages; _ck(_v, 10, 0, currVal_3); var currVal_4 = _co.detailMessages.length; _ck(_v, 12, 0, currVal_4); }, null); }
export function View_ConfigurationAlertComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "configuration-alert", [], null, null, null, View_ConfigurationAlertComponent_0, RenderType_ConfigurationAlertComponent)), i1.ɵdid(1, 114688, null, 0, i4.ConfigurationAlertComponent, [i5.ConfigurationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConfigurationAlertComponentNgFactory = i1.ɵccf("configuration-alert", i4.ConfigurationAlertComponent, View_ConfigurationAlertComponent_Host_0, { data: "data", imgSrc: "imgSrc", title: "title", messages: "messages", detailMessages: "detailMessages" }, {}, []);
export { ConfigurationAlertComponentNgFactory as ConfigurationAlertComponentNgFactory };
