import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImportArchivesComponent } from './importArchives/importArchives.component';
import { ImportAspectComponent } from './importAspect/importAspect.component';
import { ExportArchivesComponent } from './exportArchives/exportArchives.component';
import { ExportAspectComponent } from './exportAspect/exportAspect.component';
import { P2RTranslateModule } from '@p2r/shared';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ExportSaleDataComponent } from './export-sale-data/export-sale-data.component';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    P2RTranslateModule,
    NgSelectModule,
    FormsModule
  ],
  declarations: [
    ImportArchivesComponent,
    ImportAspectComponent,
    ExportArchivesComponent,
    ExportAspectComponent,
    ExportSaleDataComponent
  ]
})
export class ImportExportModule { }
