/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./change-host-ip-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/forms";
import * as i4 from "@p2r/shared";
import * as i5 from "./change-host-ip-dialog.component";
var styles_ChangeHostIpDialogComponent = [i0.styles];
var RenderType_ChangeHostIpDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChangeHostIpDialogComponent, data: {} });
export { RenderType_ChangeHostIpDialogComponent as RenderType_ChangeHostIpDialogComponent };
export function View_ChangeHostIpDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "d-flex flex-column"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 13, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 12, "div", [["class", "input-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "label", [["class", "label-description"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 8, "input", [["class", "input-description"], ["customVirtualKeyboard", ""], ["name", "ip_host"], ["required", ""]], [[8, "placeholder", 0], [1, "required", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"], [null, "focus"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 7).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 7)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 7)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("focus" === en)) {
        var pd_4 = (i1.ɵnov(_v, 14).onFocusEvent($event) !== false);
        ad = (pd_4 && ad);
    } if (("ngModelChange" === en)) {
        var pd_5 = ((_co.data.ip = $event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 16384, null, 0, i3.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i3.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵdid(8, 16384, null, 0, i3.RequiredValidator, [], { required: [0, "required"] }, null), i1.ɵprd(1024, null, i3.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i3.RequiredValidator]), i1.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.DefaultValueAccessor]), i1.ɵdid(11, 671744, null, 0, i3.NgModel, [[8, null], [6, i3.NG_VALIDATORS], [8, null], [6, i3.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i1.ɵdid(13, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), i1.ɵdid(14, 16384, null, 0, i4.CustomVirtualKeyboardDirective, [i1.ElementRef, i3.NgModel, i1.Renderer2], null, { blur: "blur" })], function (_ck, _v) { var _co = _v.component; var currVal_10 = ""; _ck(_v, 8, 0, currVal_10); var currVal_11 = "ip_host"; var currVal_12 = _co.data.ip; _ck(_v, 11, 0, currVal_11, currVal_12); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("ip_host")); _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.digitIpHostPlaceholder; var currVal_2 = (i1.ɵnov(_v, 8).required ? "" : null); var currVal_3 = i1.ɵnov(_v, 13).ngClassUntouched; var currVal_4 = i1.ɵnov(_v, 13).ngClassTouched; var currVal_5 = i1.ɵnov(_v, 13).ngClassPristine; var currVal_6 = i1.ɵnov(_v, 13).ngClassDirty; var currVal_7 = i1.ɵnov(_v, 13).ngClassValid; var currVal_8 = i1.ɵnov(_v, 13).ngClassInvalid; var currVal_9 = i1.ɵnov(_v, 13).ngClassPending; _ck(_v, 6, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }); }
export function View_ChangeHostIpDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "configuration-change-host-ip-dialog", [], null, null, null, View_ChangeHostIpDialogComponent_0, RenderType_ChangeHostIpDialogComponent)), i1.ɵdid(1, 114688, null, 0, i5.ChangeHostIpDialogComponent, [i2.TranslateService, i4.P2RTranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ChangeHostIpDialogComponentNgFactory = i1.ɵccf("configuration-change-host-ip-dialog", i5.ChangeHostIpDialogComponent, View_ChangeHostIpDialogComponent_Host_0, { data: "data", title: "title", messages: "messages" }, {}, []);
export { ChangeHostIpDialogComponentNgFactory as ChangeHostIpDialogComponentNgFactory };
