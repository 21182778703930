import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { P2RLocalStorageService, P2RTranslateService } from '@p2r/shared';
import { ConfigurationService } from '../../configuration.service';
import { ARTICLES_TEMPLATE_FIELDS_TYPE, CLIENT_TEMPLATE_FIELDS_TYPE, HALLS_TABLES_TEMPLATE_FIELDS_TYPE, ITemplateImport, TEMPLATE_TYPE } from '../importExport.model';
import { TranslateService } from '@ngx-translate/core';
import * as XLSX from 'xlsx';
import { ImportExportService } from '../importExport.service';
import { P2REnvironmentService } from '@p2r/env';
import { GlobalVariable } from 'src/app/global';

@Component({
  selector: 'configuration-importArchives',
  templateUrl: './importArchives.component.html',
  styleUrls: ['./importArchives.component.scss']
})
export class ImportArchivesComponent implements OnInit {

  @ViewChild('timeHourInput', { static: false }) timeHourInput: ElementRef;

  templateList: ITemplateImport[] = [];
  templateSelected: ITemplateImport
  importTemplateSelected: ITemplateImport
  choosedFileName: any;
  choosedFile: any;

  TEMPLATE_TYPE = TEMPLATE_TYPE;

  ARTICLES_TEMPLATE_FIELDS = [
    ARTICLES_TEMPLATE_FIELDS_TYPE.DESCRIZIONE,
    ARTICLES_TEMPLATE_FIELDS_TYPE.PREZZO,
    ARTICLES_TEMPLATE_FIELDS_TYPE.PREZZOINSET,
    ARTICLES_TEMPLATE_FIELDS_TYPE.REPARTO,
    ARTICLES_TEMPLATE_FIELDS_TYPE.VARIANTE,
    ARTICLES_TEMPLATE_FIELDS_TYPE.MENUCOMPOSTO
  ]

  CLIENTS_TEMPLATE_FIELDS = [
    CLIENT_TEMPLATE_FIELDS_TYPE.RAGIONE_SOCIALE,
    CLIENT_TEMPLATE_FIELDS_TYPE.PIVA,
    CLIENT_TEMPLATE_FIELDS_TYPE.CODICE_FISCALE,
    CLIENT_TEMPLATE_FIELDS_TYPE.COGNOME,
    CLIENT_TEMPLATE_FIELDS_TYPE.NOME,
    CLIENT_TEMPLATE_FIELDS_TYPE.CAP,
    CLIENT_TEMPLATE_FIELDS_TYPE.LOCALITA,
    CLIENT_TEMPLATE_FIELDS_TYPE.PROVINCIA,
    CLIENT_TEMPLATE_FIELDS_TYPE.INDIRIZZO,
    CLIENT_TEMPLATE_FIELDS_TYPE.SDI,
    CLIENT_TEMPLATE_FIELDS_TYPE.PA,
    CLIENT_TEMPLATE_FIELDS_TYPE.SPLIT_PAYMENT
  ]

  HALLS_TABLES_TEMPLATE_FIELDS = [
    HALLS_TABLES_TEMPLATE_FIELDS_TYPE.TAVOLO,
    HALLS_TABLES_TEMPLATE_FIELDS_TYPE.SALA,
    HALLS_TABLES_TEMPLATE_FIELDS_TYPE.POSTI
  ]

  isMultiStore: boolean;
  selectedPv: any;
  storeList: any[];
  isTemplateValid: boolean;

  constructor(
    public configurationService: ConfigurationService,
    private p2rTranslateService: P2RTranslateService,
    private translateService: TranslateService,
    private importExportService: ImportExportService,
    private p2rEnvironmentService: P2REnvironmentService,
    private p2rLocalStorageService: P2RLocalStorageService,
  ) {

  }

  ngOnInit() {
    setTimeout(() => {
      this.templateList = [
        {
          templateType: TEMPLATE_TYPE.ARTICLES,
          name: this.p2rTranslateService.get(this.translateService.instant('articles')).toLocaleUpperCase(),
          url: this.configurationService.getImg("/templates/import_articles.xlsx"),
          isSelected: false
        },
        {
          templateType: TEMPLATE_TYPE.HALLS_TABLES,
          name: this.p2rTranslateService.get(this.translateService.instant('halls_and_tables')).toLocaleUpperCase(),
          url: this.configurationService.getImg("/templates/import_halls_and_tables.xlsx"),
          isSelected: false
        },
        {
          templateType: TEMPLATE_TYPE.CLIENTS,
          name: this.p2rTranslateService.get(this.translateService.instant('clients')).toLocaleUpperCase(),
          url: this.configurationService.getImg("/templates/import_clients.xlsx"),
          isSelected: false
        },
      ]

      this.templateList[0].isSelected = true;
      this.templateSelected = this.templateList[0];
      this.importTemplateSelected = this.templateList[0];
    }, 200);

    this.selectedPv = this.p2rLocalStorageService.getFromLocalStorage(GlobalVariable.SELECTED_PV_KEY_STORAGE, []);
  }

  onFileChange(ev) {
    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = ev.target.files[0];
    this.choosedFile = file;
    this.choosedFileName = file.name;
    reader.onload = (event) => {
      const data = reader.result;
      workBook = XLSX.read(data, { type: 'binary' });
      jsonData = workBook.SheetNames.reduce((initial, name) => {
        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_json(sheet);
        return initial;
      }, {});
      this.checkTemplateType(jsonData);
    }
    reader.readAsBinaryString(file);
  }

  download() {
    let link = document.createElement("a");
    link.download = this.templateSelected.name;
    link.href = this.templateSelected.url;
    link.click();
  }

  import() {

    if (this.choosedFile) {
      switch (this.importTemplateSelected.templateType) {
        case TEMPLATE_TYPE.ARTICLES:
          this.importExportService.importArt(this.choosedFile)
          break;
        case TEMPLATE_TYPE.HALLS_TABLES:
          this.importExportService.importHallsTables(this.choosedFile, this.selectedPv.codice)
          break;
        case TEMPLATE_TYPE.CLIENTS:
          this.importExportService.importClients(this.choosedFile)
          break;
        default:
          break;
      }
    }
  }

  public checkTemplateType(jsonData) {

    this.isTemplateValid = true;

    const ARTICLES_TemplateCheck = (keyList): TEMPLATE_TYPE => keyList.every(key => this.ARTICLES_TEMPLATE_FIELDS.includes((key || '').toLocaleUpperCase())) ? TEMPLATE_TYPE.ARTICLES : undefined
    const HALLS_TABLES_TemplateCheck = (keyList): TEMPLATE_TYPE => keyList.every(key => this.HALLS_TABLES_TEMPLATE_FIELDS.includes((key || '').toLocaleUpperCase())) ? TEMPLATE_TYPE.HALLS_TABLES : undefined
    const CLIENTS_TemplateCheck = (keyList): TEMPLATE_TYPE => keyList.every(key => this.CLIENTS_TEMPLATE_FIELDS.includes((key || '').toLocaleUpperCase())) ? TEMPLATE_TYPE.CLIENTS : undefined

    const sheets = Object.keys(jsonData);

    const templateSheet = jsonData[sheets[0]];

    if (templateSheet && templateSheet.length) {
      const firstItem = templateSheet[0]
      const templateKeys = Object.keys(firstItem)

      switch (this.importTemplateSelected.templateType) {
        case TEMPLATE_TYPE.ARTICLES:
          if (!ARTICLES_TemplateCheck(templateKeys)) {
            this.configurationService.showErrorAlert([
              {
                // class: 'mb-2',
                text: this.p2rTranslateService.get(this.translateService.instant('the_selected_file_has_no_valid_template'))
              },
              {
                class: 'mb-2',
                text: `${this.p2rTranslateService.get(this.translateService.instant('the_template_must_include_the_following_columns'))}:`
              },
              this.ARTICLES_TEMPLATE_FIELDS.join(" ")
            ]);

            this.isTemplateValid = false;
          }
          break;
        case TEMPLATE_TYPE.HALLS_TABLES:
          if (!HALLS_TABLES_TemplateCheck(templateKeys)) {
            this.configurationService.showErrorAlert([
              {
                // class: 'mb-2',
                text: this.p2rTranslateService.get(this.translateService.instant('the_selected_file_has_no_valid_template'))
              },
              {
                class: 'mb-2',
                text: `${this.p2rTranslateService.get(this.translateService.instant('the_template_must_include_the_following_columns'))}:`
              },
              this.HALLS_TABLES_TEMPLATE_FIELDS.join(" ")
            ]);

            this.isTemplateValid = false;
          }
          break;
        case TEMPLATE_TYPE.CLIENTS:
          if (!CLIENTS_TemplateCheck(templateKeys)) {
            this.configurationService.showErrorAlert([
              {
                // class: 'mb-2',
                text: this.p2rTranslateService.get(this.translateService.instant('the_selected_file_has_no_valid_template'))
              },
              {
                class: 'mb-2',
                text: `${this.p2rTranslateService.get(this.translateService.instant('the_template_must_include_the_following_columns'))}:`
              },
              this.CLIENTS_TEMPLATE_FIELDS.join(" ")
            ]);

            this.isTemplateValid = false;
          }
          break;

        default:
          break;
      }

      if (this.importTemplateSelected.templateType === TEMPLATE_TYPE.HALLS_TABLES) {
        if (this.p2rEnvironmentService.storeData !== undefined) {
          this.isMultiStore = this.p2rEnvironmentService.storeData.multistore;
          this.storeList = this.p2rEnvironmentService.storeData.pv;
        } else {
          this.isMultiStore = false;
        }
      }

    } else {
      console.log("no elements")
    }
  }

  public clearFileToImport() {
    this.choosedFile = undefined
    this.choosedFileName = undefined
  }

  get importEnabled() {
    return this.isTemplateValid && this.importTemplateSelected && this.importTemplateSelected.templateType === TEMPLATE_TYPE.HALLS_TABLES
      ? this.isMultiStore
        ? this.selectedPv && this.choosedFile
        : this.choosedFile
      : this.choosedFile && this.isTemplateValid
  }

  public storeCmp = (a, b) => a.codice === b.codice

}
