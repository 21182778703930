import * as tslib_1 from "tslib";
import { ChangeDetectorRef, OnDestroy, OnInit, } from "@angular/core";
import { merge } from "rxjs";
import { map } from "rxjs/operators";
import { DO_CHECK_TYPES, GROUP_TYPE } from "../configuration.model";
export class SavePanelComponent {
    constructor(configurationService, p2rEnvironmentService, cdRef, ngxService, paymentsService) {
        this.configurationService = configurationService;
        this.p2rEnvironmentService = p2rEnvironmentService;
        this.cdRef = cdRef;
        this.ngxService = ngxService;
        this.paymentsService = paymentsService;
        this.p2rEnvironmentService.getEnvironmentStream().subscribe((env) => {
            this.assetsPath = `${env.BASE_CONFIG_URL}`;
            if (this.assetsPath !== "") {
                this.assetsPath = this.assetsPath;
            }
        });
    }
    ngOnInit() {
        // Sta in ascolto sul metodo doCheck
        // Se torna true vengono mostrati i pulsanti Salva e Ripristina
        // Se torna false vengono nascosti
        this._hasChangesSubscription = merge(this.configurationService.doCheck$().pipe(map(data => ({
            origin: DO_CHECK_TYPES.CONFIGURATIONS,
            value: data
        }))), this.paymentsService.doCheck$().pipe(map(data => ({
            origin: DO_CHECK_TYPES.PAYMENTS,
            value: data
        })))).subscribe((data) => {
            this.hasChanges = this.getHasChanges(data);
            if (!this.cdRef[`destroyed`]) {
                this.cdRef.detectChanges();
            }
        });
    }
    getHasChanges(data) {
        switch (data.origin) {
            case DO_CHECK_TYPES.CONFIGURATIONS:
                this._configurationChangesFlag = data.value;
                if (this._configurationChangesFlag) {
                    return this._configurationChangesFlag;
                }
                else {
                    return this._paymentsChangesFlag;
                }
            case DO_CHECK_TYPES.PAYMENTS:
                this._paymentsChangesFlag = data.value;
                if (this._paymentsChangesFlag) {
                    return this._paymentsChangesFlag;
                }
                else {
                    return this._configurationChangesFlag;
                }
                return data.value;
        }
    }
    set hasChanges(val) {
        this._hasChanges = val;
    }
    get hasChanges() {
        return this._hasChanges;
    }
    save() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const canSave = yield this.configurationService.preSave();
            if (canSave) {
                this._saveDataSubscription = this.configurationService
                    .saveLocalChanges()
                    .subscribe((resp) => {
                    this.successSaved = !resp.includes(false);
                    setTimeout(() => {
                        this.successSaved = false;
                    }, 6000);
                    if (this._saveDataSubscription) {
                        this._saveDataSubscription.unsubscribe();
                        this.configurationService.saveCompleteSubject.next(true);
                    }
                });
            }
            else {
                switch (this.configurationService.selectedGroup) {
                    case GROUP_TYPE.OPERATOR:
                        this.configurationService.setOpenChangeTimeSlotSubject();
                        break;
                    default:
                        break;
                }
            }
        });
    }
    restore() {
        const modifiedGroups = this.configurationService.modifiedGroups;
        this.configurationService.restoreLocalChanges();
        // this.ngxService.start();
        Object.keys(modifiedGroups).forEach((key) => {
            // Lo chiamiamo solo nel caso di DOCUMENTS, OFFLINE-WORK, SERVIZI
            // TODO: Se un domani se ne aggiungere un altro aggiungerlo a questo if.
            if (key === GROUP_TYPE.DOCUMENTS ||
                key === GROUP_TYPE.OFFLINE_WORK ||
                key === GROUP_TYPE.SERVIZI ||
                key == GROUP_TYPE.GENERALI // ||
            // key == GROUP_TYPE.PAYMENTS
            ) {
                this.configurationService.restoreCompleteSubject[key].next(true);
            }
        });
    }
    closeSavePanel() {
        this.successSaved = false;
    }
    ngOnDestroy() {
        if (this._hasChangesSubscription) {
            this._hasChangesSubscription.unsubscribe();
        }
        if (this._createOrEditSubscription) {
            this._createOrEditSubscription.unsubscribe();
        }
        if (this._saveDataSubscription) {
            this._saveDataSubscription.unsubscribe();
        }
    }
}
