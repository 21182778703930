<div class="d-flex flex-column">
    <div>
        <form>
            <div
                id="percetange-input"
                class="input-container"
            >
                <label class="label-description d-block">{{ 'percentage' | translate }}</label>
                <input
                    class="input-description"
                    [(ngModel)]="data.percentage"
                    name="percentage"
                    (keydown)="checkLength($event, data.percentage)"
                    type="number"
                    customVirtualKeyboard
                >
                <strong *ngIf="data.value_error">
                    {{ 'insert_value_to_proceed' | translate }}
                </strong>
            </div>
        </form>
    </div>
</div>
