<div>
    <div>
        <div>
            <div class="sub-section-container">
                <div>
                    <p
                        class="section-title"
                        style="text-transform: uppercase;"
                    >
                        {{'LANGUAGE_CURRENCY.USER_LANGUAGE.TITLE' | translate}}
                    </p>
                    <p class="sub-section-description-2 font-r_r fs16 lh16 c10">
                        {{'LANGUAGE_CURRENCY.USER_LANGUAGE.DESCRIPTION' | translate}}
                    </p>
                </div>
                <div
                    class="sub-section-number pt-4"
                    style="min-width: 200px; text-align: right;"
                >
                    {{ kuUserLanguage }}
                </div>
            </div>
            <hr>
            <div>
                <div
                    class="align-items-center d-flex justify-content-between sub-section-container"
                    style="margin-right: 32px;"
                >
                    <div>
                        <p class="section-title-2">
                            {{'LANGUAGE_CURRENCY.PRINTER_LANGUAGE.TITLE' | translate}}
                        </p>
                        <p class="sub-section-description-2 font-r_r fs16 lh16 c10">
                            {{'LANGUAGE_CURRENCY.PRINTER_LANGUAGE.DESCRIPTION' | translate}}
                        </p>
                    </div>
                    <div>
                        <div class="d-flex align-items-center">
                            <span class="sub-section-number mr-3">
                                {{ convertLabel(kuPrinterLanguage, true, false) }}
                            </span>
                            <button
                                class="btn rounded-pill btn-block btn-primary text-uppercase pl-3 pr-3 text-center"
                                style="height: 40px; width: 120px;"
                                (click)="openPrinterLanguageDialog()"
                            >
                                <span class="font-r_r fs18 c13 lh21">
                                    <span>{{'choose' | translate | uppercase}}</span>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <hr>
            <div>
                <div
                    class="align-items-center d-flex justify-content-between sub-section-container"
                    style="margin-right: 32px;"
                >
                    <div>
                        <p class="section-title-2">
                            {{'LANGUAGE_CURRENCY.DEFAULT_CURRENCY.TITLE' | translate}}
                        </p>
                    </div>
                    <div>
                        <div class="d-flex align-items-center">
                            <span class="sub-section-number mr-3">
                                {{ convertLabel(kuDefaultCurrency, false, false) }}
                            </span>
                            <button
                                class="btn rounded-pill btn-block btn-primary text-uppercase pl-3 pr-3 text-center"
                                style="height: 40px; width: 120px;"
                                (click)="openDefaultCurrencyDialog()"
                            >
                                <span class="font-r_r fs18 c13 lh21">
                                    <span>{{'choose' | translate | uppercase}}</span>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <hr>
        </div>
    </div>
</div>
