import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { P2RDialogComponent, P2RDialogService, P2RTranslateService } from '@p2r/shared';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CustomizeDialogComponent } from 'src/app/customize-dialog/customize-dialog.component';
import { TranslateService } from "@ngx-translate/core";
import { CUSTOMIZE_TYPE } from 'src/app/customize-dialog/customize-dialog.model';
import { ConfigurationService } from '../configuration.service';
import { Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ConfigurationAlertComponent } from '../alert/alert.component';
import { FCIC_CHECK_PIVA_ERRORS } from '../configuration.model';

@Component({
  selector: 'configuration-fcic',
  templateUrl: './fcic.component.html',
  styleUrls: ['./fcic.component.scss']
})
export class FcicComponent implements OnInit, OnDestroy {

  public credentials: any;

  public repartiAssociati: any[] = [];
  public reparti: any[] = [];
  public categorieFCIC: any[] = [];
  public linkDEPCAT: any[] = [];
  private linkRepartiCategorieSubscription: Subscription;

  public idAzienda: string = '';
  private repartiSubscription: Subscription;
  disableFcic: boolean;
  status: any;
  anomalie: number;

  constructor(
    private route: ActivatedRoute,
    private ngxService: NgxUiLoaderService,
    private p2rDialogService: P2RDialogService,
    private p2rTranslateService: P2RTranslateService,
    private translateService: TranslateService,
    public configurationService: ConfigurationService,
    private router: Router
  ) {
  }

  ngOnInit() {

    this.route.data.subscribe(({ data }) => {

      const [items, fcicData] = data;

      const { credenziali, articoli, linkART, linkDEPCAT, reparti, categorieFCIC, status } = fcicData;

      // tslint:disable-next-line: triple-equals
      this.repartiAssociati = (linkDEPCAT || []).filter(dc => reparti.find(r => r.XDEPFOOD03 == dc.XLINKDEPCATFCICFOOD04));
      this.reparti = reparti || [];
      this.categorieFCIC = categorieFCIC || [];
      this.linkDEPCAT = linkDEPCAT || [];

      this.credentials = credenziali;
      this.status = status;
      if(this.status != undefined) {
        this.anomalie = Object.keys(status).reduce((acc, curr) => {
          acc += status[curr] && status[curr].length
          return acc;
        }, 0);
      }

      if(this.credentials != undefined) {
        this.disableFcic = this.credentials.active === '1' ? false : true;
      }
      
      this.ngxService.stopAll();

    });

    this.linkRepartiCategorieSubscription = this.configurationService.getLinkRepartiCategorie2$().subscribe(data => {
      this.repartiAssociati = data.filter(dc => this.reparti.find(r => r.XDEPFOOD03 == dc.XLINKDEPCATFCICFOOD04));
    })

    this.repartiSubscription = this.configurationService.getReparti$().subscribe(data => {
      this.reparti = data;
    })
  }

  public associates() {
    this.configurationService.associateDEPCATFCIC()
  }

  public syncronize() {
    this.configurationService.syncronize();
  }

  public send() {
    this.configurationService.send();
  }

  public verify() {
    this.configurationService.verify();
  }

  public dettagli() {
    const dialog = this.p2rDialogService.open(P2RDialogComponent, {
      id: 'fcic-license-detail-dialog',
      body: CustomizeDialogComponent,
      // hideCloseButton: true,
      closeButton: {
        label: this.p2rTranslateService.get(this.translateService.instant('close')),
        buttonClass: 'btn btn-primary rounded-pill lp-bottom-btn',
        labelClass: 'text-uppercase font-r_l fs18 lh21',
      },
      title: this.p2rTranslateService.get(this.translateService.instant('license_details')),
      titleClasses: 'text-uppercase font-r_r fs24 lh21 c11 mt-2',
      size: 'lg',
      /*      options: {
        id: 'customize-for-store-opt',
        titleClasses: 'customize-for-store-height'
      },*/
      data: {
        // hasSearch: true,
        customizeType: CUSTOMIZE_TYPE.LICENSE_DETAIL,
        items: [
          {
            key: 'idAzienda',
            label: this.p2rTranslateService.get(this.translateService.instant('company_id')),
            value: this.credentials.idAzienda
          },
          {
            key: 'activation_date',
            label: this.p2rTranslateService.get(this.translateService.instant('activation_date')),
            value: this.credentials.activationDate
          }
        ],
        close: () => {
          dialog.dismiss();
        },
      }
    });
  }

  public attivaLicenza() {
    this.configurationService.activate({
      idAzienda: this.idAzienda
    }).subscribe(data => {
      if(data instanceof Array && data[0] instanceof HttpErrorResponse) {
        const error = data[0]['error'];
        let errorMessage = "";
        switch(error.ResponseBody) {
          case FCIC_CHECK_PIVA_ERRORS.NO_PIVA:
            errorMessage = this.p2rTranslateService.get(this.translateService.instant("error_fcic_check_piva_no_piva"));
            break;
          case FCIC_CHECK_PIVA_ERRORS.FCIC_DUPLICATO:
            errorMessage = this.p2rTranslateService.get(this.translateService.instant("error_fcic_check_piva_fcic_duplicato"));
            break;
          default:
            break;
        }
        this.alertDialog({
          imgSrc: "icons/attenzione.svg",
          title: "attention",
          messages: [
            {
              text: errorMessage,
              class: ''
            },
            {
             text: this.p2rTranslateService.get(this.translateService.instant("contact_your_dealer")),
             class: 'mt-2'
            }
          ],
        });
      } else if(data instanceof HttpErrorResponse) {
        this.alertDialog({
          imgSrc: "icons/attenzione.svg",
          title: "attention",
          messages: [
            {
              text: this.p2rTranslateService.get(this.translateService.instant("the_license_is_not_active")),
              class: ''
            },
            {
             text: this.p2rTranslateService.get(this.translateService.instant("contact_your_dealer")),
             class: 'mt-2'
            }
          ],
        });
      } else {
        if(data[0] instanceof Array && data[0].length == 3) { 
          this.credentials = data[0][2].credenziali;
          if(this.credentials != undefined) {
            this.disableFcic = this.credentials.active === '1' ? false : true;
          }
          this.alertDialog({
            imgSrc: "icons/confirm.svg",
            title: "activated_license",
            messages: [
              this.p2rTranslateService.get(
                this.translateService.instant("compliments")
              ),
              this.p2rTranslateService.get(
                this.translateService.instant("you_have_activated_your_license_and_can_start_using_the_service")
              )
            ],
          })
        }
      }
    })
  }

  deactivateLicense() {
    let dialog = this.p2rDialogService.open(P2RDialogComponent, {
      id: 'deactivate-license-dialog',
      body: ConfigurationAlertComponent,
      hasHead: false,
      data: {
        imgSrc: this.configurationService.getImg("icons/attenzione.svg"),
        title: this.p2rTranslateService.get(
          this.translateService.instant("attention")
        ),
        messages: [
          {
            text: this.p2rTranslateService.get(
              this.translateService.instant("fcic_deactivate_alert")
            ),
            class: ''
          },
          {
            text: this.p2rTranslateService.get(
              this.translateService.instant("fcic_deactivate_1")
            ),
            class: 'font-weight-bold mt-2'
          },
          {
            text: this.p2rTranslateService.get(
              this.translateService.instant("fcic_deactivate_2")
            ),
            class: 'font-weight-bold mt-2'
          },

        ],
      },
      actions: [
        {
          label: this.p2rTranslateService.get(
            this.translateService.instant("deactivate")
          ),
          click: () => {
            dialog.dismiss();
            this.doDeactivateLicense();
          },
          classes: "btn rounded-pill text-uppercase new-alert-button font-r_l fs18",
          data: {
            remove: true,
          },
          params: {
            position: "right",
          },
        },
        {
          label: this.p2rTranslateService.get(
            this.translateService.instant("dialog_cancel")
          ),
          click: () => {
            dialog.dismiss();
          },
          classes: "btn rounded-pill text-uppercase new-alert-button font-r_l fs18",
          data: {
            remove: true,
          },
          params: {
            position: "right",
          },
        },
      ],
    });
  }

  private doDeactivateLicense() {
    console.log("FCIC disattivazione in corso...");
    this.configurationService.deActivateFCICLicense().subscribe((result) => {
      // console.log("FCIC doDeactivateLicense result: " + result);
      if(result) {
        this.credentials = undefined;
        this.disableFcic = true;
        this.idAzienda = '';
      }
    })
  }

  activateSwitch() {
    let active;
    if (!this.credentials.active || this.credentials.active === '0') {
      active = '1';
    } else {
      active = '0';
    }

    const update = () => {
      this.configurationService.updateFCICSync({
        active,
        fcicfoodcredentials: this.credentials.fcicfoodcredentials
      }).subscribe(res => {
        this.credentials.active = active;
        this.disableFcic = this.credentials.active === '1' ? false : true;
        if (dialog) {
          dialog.dismiss();
        }
      })
    }

    let dialog;

    if (active == '0') {
      dialog = this.p2rDialogService.open(P2RDialogComponent, {
        id: 'deactivate-dialog',
        body: ConfigurationAlertComponent,
        hasHead: false,
        data: {
          imgSrc: this.configurationService.getImg("icons/attenzione.svg"),
          title: this.p2rTranslateService.get(
            this.translateService.instant("attention")
          ),
          messages: [
            {
              text: this.p2rTranslateService.get(
                this.translateService.instant("you_are_about_to_deactivate_the_communication_between_Keep_Up_PRO_and_the_FCIC_portal")
              ),
              class: ''
            },
            {
              text: this.p2rTranslateService.get(
                this.translateService.instant("from_this_moment_it_will_not_be_possible_to_synchronize_the_items_and_update_the_warehouse")
              ),
              class: 'font-weight-bold mt-2'
            },
            {
              text: this.p2rTranslateService.get(
                this.translateService.instant("do_you_want_to_deactivate_the_communication_or_cancel_the_operation")
              ),
              class: 'font-weight-bold mt-2'
            },

          ],
        },
        actions: [
          {
            label: this.p2rTranslateService.get(
              this.translateService.instant("deactivate")
            ),
            click: () => {
              update()
            },
            classes: "btn rounded-pill text-uppercase new-alert-button font-r_l fs18",
            data: {
              remove: true,
            },
            params: {
              position: "right",
            },
          },
          {
            label: this.p2rTranslateService.get(
              this.translateService.instant("dialog_cancel")
            ),
            click: () => {
              dialog.dismiss();
            },
            classes: "btn rounded-pill text-uppercase new-alert-button font-r_l fs18",
            data: {
              remove: true,
            },
            params: {
              position: "right",
            },
          },
        ],
      });
    } else {
      update()
    }
  }

  ngOnDestroy() {
    if (this.linkRepartiCategorieSubscription) {
      this.linkRepartiCategorieSubscription.unsubscribe();
    }
    if (this.repartiSubscription) {
      this.repartiSubscription.unsubscribe();
    }
  }

  alertDialog(opt: {
    imgSrc: string,
    title: string,
    messages
  }) {

    const { imgSrc, title, messages } = opt;

    const alert = this.p2rDialogService.open(P2RDialogComponent, {
      id: 'generic-alert-dialog',
      body: ConfigurationAlertComponent,
      hasHead: false,
      data: {
        imgSrc: this.configurationService.getImg(imgSrc),
        title: this.p2rTranslateService.get(
          this.translateService.instant(title)
        ),
        messages,
      },
      actions: [
        {
          label: this.p2rTranslateService.get(
            this.translateService.instant("close")
          ),
          click: () => {
            alert.dismiss();
          },
          classes: "btn rounded-pill text-uppercase new-alert-button",
          data: {
            remove: true,
          },
          params: {
            position: "right",
          },
        },
      ],
    });
  }

}
