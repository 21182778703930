<div class="d-flex flex-column">
    <div>
        <div class="input-container">
            <label class="label-description">{{data.input_title}}</label>
            <div class="input-container-2">
                <input
                    class="input-description"
                    name="text"
                    [(ngModel)]="data.text"
                    (ngModelChange)="textChanged()"
                    required
                    customVirtualKeyboard
                >
                <div
                    *ngIf="data.rightFixedPlaceholder && data.rightFixedPlaceholder.length > 0"
                    class="unit"
                    [style.left.px]="rightFixedPlaceholderWidth"
                >
                    [{{data.rightFixedPlaceholder}}]
                </div>
            </div>
            <div
                class="label-note"
                *ngIf="data.note && data.note.length > 0"
            >
                {{data.note}}
            </div>
        </div>
    </div>
</div>
