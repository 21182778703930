import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { P2RTranslateService } from '@p2r/shared';

@Component({
  selector: 'configuration-change-host-ip-dialog',
  templateUrl: './change-host-ip-dialog.component.html',
  styleUrls: ['./change-host-ip-dialog.component.scss']
})
export class ChangeHostIpDialogComponent implements OnInit {

  private _data: any;
  private _title: any;
  private _messages: any;

  public digitIpHostPlaceholder: string;

  constructor(
    private translateService: TranslateService,
    private p2rTranslateService: P2RTranslateService) {

  }

  ngOnInit() {
    this.digitIpHostPlaceholder = this.p2rTranslateService.get(this.translateService.instant('digit_host_ip'));
  }

  @Input() set data(data: any) {
    this._data = data;
  }

  get data(): any {
    return this._data;
  }

  @Input() set title(title: any) {
    this._title = title;
  }

  get title(): any {
    return this._title || this._data.title;
  }

  @Input() set messages(messages: any[]) {
    this._messages = messages;
  }

  get messages(): any[] {
    return this._messages || this._data.messages;
  }

  public onChangeInput(event) {
    console.log((event));
  }

  public dismiss() {

  }

}
