<div
    class="d-flex flex-column"
    style="overflow: hidden; margin: 16px;"
>
    <div class="text-center">
        <ng-container *ngIf="imgSrc">
            <div
                id="image-container"
                class="image-container"
            >
                <img
                    [src]="imgSrc"
                    height="80px"
                    width="80px"
                >
            </div>
            <div class="vertical-space"></div>
        </ng-container>
        <ng-container *ngIf="title">
            <div
                id="title-container"
                class="title-container fs34"
            >
                {{title}}
            </div>
            <div class="vertical-space"></div>
        </ng-container>
        <ng-container *ngIf="data.progressBar">
            <div
                id="progress-container"
                class="progress-container m-4"
            >
                <div
                    id="progress-bar"
                    class="progress-bar"
                >
                </div>
                <div class="d-flex justify-content-between">
                    <span class="font_r-r fs16 lh25 c11">0%</span>
                    <span class="font_r-r fs16 lh25 c11">100%</span>
                </div>
            </div>
            <div class="vertical-space"></div>
        </ng-container>
        <div class="message-container">
            <ng-container *ngFor="let message of messages">
                <div [ngClass]="message && message.class ? message.class : ''">
                    <span class="font-r_l fs18 lh21 c11">{{message && message.text || message}}</span>
                </div>
            </ng-container>
        </div>
        <div
            *ngIf="detailMessages.length"
            class="mt-4"
        >
            <ng-container *ngIf="showDetailMessages">
                <div (click)="showHideDetailMessages()">
                    <span class="font-r_r fs16 lh25 c11 mr-2">{{'hide_details' | translate}}</span>
                    <img
                        [src]="configurationService.getImg('icons/open-arrow-dark.svg')"
                        [ngStyle]="{'transform': 'rotate(90deg)'}"
                    >
                </div>
                <div
                    class="border-top border-bottom mt-2 p-3 text-left"
                    id="detail-messages-container"
                >
                    <ng-container *ngFor="let dMessage of detailMessages">
                        <div class="message-container">
                            <span
                                class="font-r_r fs14 lh25 c11"
                                style="white-space: pre"
                            >
                                {{dMessage}}
                            </span>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
            <ng-container *ngIf="!showDetailMessages">
                <div (click)="showHideDetailMessages()">
                    <span class="font-r_r fs16 lh25 c11 mr-2">{{'show_details' | translate}}</span>
                    <img
                        [src]="configurationService.getImg('icons/open-arrow-dark.svg')"
                        [ngStyle]="{'transform': 'rotate(90deg)'}"
                    >
                </div>
            </ng-container>
        </div>
    </div>
</div>
