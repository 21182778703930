<div>
    <div *ngFor="let item of values | keyvalue">
        <div *ngIf="item.key==='KA_OPERATOR_TIME_SLOT'">
            <div *ngFor="let k of items | keyvalue">
                <div *ngIf="k.key==='MANAGEMENT'">
                    <p class="section-title">{{'OPERATOR.MANAGEMENT' | translate}}</p>
                    <hr>
                    <div
                        *ngIf="items.MANAGEMENT.hasOwnProperty('MANUALLY')"
                        style="padding-bottom: 15px"
                    >
                        <div class="sub-section-container">
                            <div>
                                <p
                                    class="sub-section-title"
                                    style="text-transform: uppercase;"
                                >
                                    {{'OPERATOR.MANAGEMENT.MANUALLY.TITLE' | translate}}
                                </p>
                                <p class="sub-section-description font-r_r fs16 lh16 c10">
                                    {{'OPERATOR.MANAGEMENT.MANUALLY.DESCRIPTION' | translate}}
                                </p>
                            </div>
                            <div class="sub-section-checkbox">
                                <img
                                    style="width: 40px; height: 40px"
                                    [src]="operatorsManagement.manually ? configurationService.getImg('/icons/checkbox-selected.svg') : configurationService.getImg('/icons/checkbox-not_selected.svg')"
                                    (click)="changeOperatorType('manually',0)"
                                >
                            </div>
                        </div>
                    </div>
                    <div
                        *ngIf="items.MANAGEMENT.hasOwnProperty('ON_ACCOUNT')"
                        style="padding-top: 15px; padding-bottom: 15px"
                    >
                        <div class="sub-section-container">
                            <div>
                                <p
                                    class="sub-section-title"
                                    style="text-transform: uppercase;"
                                >
                                    {{'OPERATOR.MANAGEMENT.ON_ACCOUNT.TITLE' | translate}}
                                </p>
                                <p class="sub-section-description font-r_r fs16 lh16 c10">
                                    {{'OPERATOR.MANAGEMENT.ON_ACCOUNT.DESCRIPTION' | translate}}
                                </p>
                            </div>
                            <div class="sub-section-checkbox">
                                <img
                                    style="width: 40px; height: 40px"
                                    [src]="operatorsManagement.on_account ? configurationService.getImg('/icons/checkbox-selected.svg') : configurationService.getImg('/icons/checkbox-not_selected.svg')"
                                    (click)="changeOperatorType('on_account',1)"
                                >
                            </div>
                        </div>
                    </div>
                    <div
                        *ngIf="items.MANAGEMENT.hasOwnProperty('AT_TIME_SLOTS')"
                        style="padding-top: 15px; padding-bottom: 15px"
                    >
                        <div class="sub-section-container">
                            <div>
                                <p
                                    class="sub-section-title"
                                    style="text-transform: uppercase;"
                                >
                                    {{'OPERATOR.MANAGEMENT.AT_TIME_SLOTS.TITLE' | translate}}
                                </p>
                                <p class="sub-section-description font-r_r fs16 lh16 c10">
                                    {{'OPERATOR.MANAGEMENT.AT_TIME_SLOTS.DESCRIPTION' | translate}}
                                </p>
                            </div>
                            <div class="sub-section-checkbox">
                                <img
                                    style="width: 40px; height: 40px"
                                    [src]="operatorsManagement.at_time_slots ? configurationService.getImg('/icons/checkbox-selected.svg') : configurationService.getImg('/icons/checkbox-not_selected.svg')"
                                    (click)="changeOperatorType('at_time_slots',2)"
                                >
                            </div>
                        </div>
                        <hr>
                    </div>
                </div>
            </div>
        </div>
        <div
            *ngIf="item.key==='KA_OPERATOR_TYPE' && items.TIME_SLOTS"
            [class.disabled]="operatorsManagement.at_time_slots === false"
            class="mt-2"
        >
            <div style="height:auto; display:flex; width: 100%; margin-bottom: 10px">
                <p class="section-title-2">{{'OPERATOR.TIME_SLOTS' | translate}}</p>
                <img
                    [src]="configurationService.getImg(images.add)"
                    class="icon"
                    style="margin-left: 12px;"
                    width="40"
                    height="40"
                    (click)="openChangeTimeSlotAlert(undefined)"
                >
            </div>
            <hr>
            <div *ngFor="let item of currentTimeSlots; let i = index">
                <div class="sub-section-container">
                    <div (click)="openChangeTimeSlotDescription(i)">
                        <p
                            class="sub-section-title"
                            style="margin: 8px 40px 0px;"
                        >
                            {{currentTimeSlotDescriptions[i]}}
                        </p>
                    </div>
                    <div style="height:auto; display:flex; margin-left: auto">
                        <div
                            class="time-slot"
                            (click)="openChangeTimeSlotAlert(i)"
                        >
                            <p class="sub-section-title">{{item}}</p>
                        </div>
                        <div>
                            <img
                                [src]="configurationService.getImg(images.remove)"
                                class="icon"
                                style="margin-right: 32px;"
                                width="40"
                                height="40"
                                (click)="removeTimeSlot(i)"
                            >
                        </div>
                    </div>
                </div>
                <hr>
            </div>
        </div>
        <div
            *ngIf="item.key==='KU_ASK_PWD_STORNO_CANCELLA_ORDINE'"
            [class.disabled]="this.user_grant_type != '1' && this.user_grant_type != '3'"
        >
            <div
                class="align-items-center d-flex justify-content-between sub-section-container"
                style="margin-right: 32px;"
            >
                <div>
                    <p
                        class="section-title"
                        style="text-transform: uppercase; margin-top: 16px !important;"
                    >
                        {{'OPERATOR.ASK_PASSWORD.TITLE' | translate}}
                    </p>
                    <p class="sub-section-description font-r_r fs16 lh16 c10" style="margin-left: 16px !important;">
                        {{'OPERATOR.ASK_PASSWORD.SUBTITLE' | translate}}
                    </p>
                    <p class="sub-section-description font-r_r fs16 lh16 c10" style="margin-left: 16px !important;">
                        {{'OPERATOR.ASK_PASSWORD.SUBTITLE2' | translate}}
                    </p>
                </div>
                <div>
                    <div class="d-flex align-items-center">
                        <span class="sub-section-number mr-3">{{kuAskPwdOperatorCounter}}</span>
                        <button
                            class="btn rounded-pill btn-block btn-primary text-uppercase pl-3 pr-3 text-center"
                            style="height: 40px; width: 120px;"
                            (click)="openPopupChooseOperationsReserved()"
                        >
                            <span class="font-r_r fs18 c13 lh21">
                                <span>{{'choose' | translate | uppercase}}</span>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
            <hr>
        </div>
    </div>
</div>
