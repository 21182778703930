import { Component, OnInit } from '@angular/core';
import { ConfigurationService } from '../../configuration.service';

@Component({
  selector: 'configuration-importAspect',
  templateUrl: './importAspect.component.html',
  styleUrls: ['./importAspect.component.scss']
})
export class ImportAspectComponent implements OnInit {

  constructor(
    public configurationService: ConfigurationService
  ) { }

  ngOnInit() {
  }

}
