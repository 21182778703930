export interface ITemplateImport {
  name: string,
  isSelected: boolean,
  url?: string,
  templateType: TEMPLATE_TYPE
}

export enum TEMPLATE_TYPE {
  ARTICLES = 'ARTICLES',
  HALLS_TABLES = 'HALLS_TABLES',
  CLIENTS = 'CLIENTS',
}

export enum CLIENT_TEMPLATE_FIELDS_TYPE {
  RAGIONE_SOCIALE = 'RAGIONE SOCIALE',
  PIVA = 'PIVA',
  CODICE_FISCALE = 'CODICE FISCALE',
  COGNOME = 'COGNOME',
  NOME = 'NOME',
  CAP = 'CAP',
  LOCALITA = 'LOCALITA',
  PROVINCIA = 'PROVINCIA',
  INDIRIZZO = 'INDIRIZZO',
  SDI = 'SDI',
  PA = 'PA',
  SPLIT_PAYMENT = 'SPLIT PAYMENT',
}

export enum ARTICLES_TEMPLATE_FIELDS_TYPE {
  DESCRIZIONE = 'DESCRIZIONE',
  PREZZO = 'PREZZO',
  REPARTO = 'REPARTO',
  VARIANTE = 'VARIANTE',
  PREZZOINSET = 'PREZZOINSET',
  MENUCOMPOSTO = 'MENUCOMPOSTO'
}

export enum HALLS_TABLES_TEMPLATE_FIELDS_TYPE {
  TAVOLO = 'TAVOLO',
  SALA = 'SALA',
  POSTI = 'POSTI'
}
