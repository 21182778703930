<div class="d-flex flex-grow-1 h-100 justify-content-center">
    <div class="d-flex flex-column justify-content-between">
        <div>
            <div class="m-5">
                <span class="fs24 lh32 font_r-r c11">
                    {{'choose_the_section_to_export'| translate}}
                </span>
            </div>
        </div>
        <div class="mb-5 d-flex justify-content-center">
            <img [src]="configurationService.getImg('/icons/export.svg')">
        </div>
    </div>
</div>
