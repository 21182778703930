<ng-container *ngIf="credentials; else noCredentials">
    <div>
        <div>
            <div class="align-items-center d-flex justify-content-between ml-3 mr-4 mt-4 mb-4">
                <div>
                    <span class="section-title margin-auto">
                        {{'SERVIZI.FCIC.LICENSE' | translate | uppercase}}
                    </span>
                </div>
                <div class="align-items-center d-flex">
                    <!--
                    <div class="mr-2">
                        <span
                            class="font-r_r fs24 lh16"
                            style="white-space: nowrap;"
                        >
                            {{'activated_on'| translate}}
                        </span>
                    </div>
                    <div class="mr-4">
                        <span class="font-r_r fs24 lh16">{{credentials.activationDate}}</span>
                    </div>
                    -->
                    <div class="fcic-button-container">
                        <button
                            class="btn rounded-pill btn-block btn-primary text-uppercase pl-3 pr-3"
                            (click)="dettagli()"
                        >
                            <span class="font-r_r fs18 c13 lh21">
                                <span>{{'details' | translate | uppercase}}</span>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
            <hr>
            <div class="align-items-center d-flex justify-content-between ml-3 mr-4 mt-4 mb-4">
                <div>
                    <span class="section-title margin-auto">
                        {{'SERVIZI.FCIC.LICENSE_STATUS' | translate | uppercase}}
                    </span>
                </div>
                <div class="d-flex">
                    <div class="align-items-center d-flex font-r_r fs24 lh16">
                        <div class="mr-4">
                            <span
                                style="white-space: nowrap"
                            >
                                {{'SERVIZI.FCIC.LICENSE_STATUS_ACTIVE'| translate}}
                            </span>
                        </div>
                    </div>
                    <div class="sub-section-checkbox" style="width: 160px;">
                        <img
                            style="float: right;"
                            [src]="configurationService.getImg('/icons/switch-on.svg')"
                            (click)="deactivateLicense()"
                        >
                    </div>
                </div>
            </div>
            <hr>
            <div class="align-items-center d-flex justify-content-between ml-3 mr-4 mt-4 mb-4">
                <div>
                    <span class="section-title margin-auto">
                        {{'SERVIZI.FCIC.COMMUNICATION_WITH_FCIC' | translate}}
                    </span>
                </div>
                <div class="d-flex">
                    <div class="align-items-center d-flex font-r_r fs24 lh16">
                        <div class="mr-4">
                            <span
                                style="white-space: nowrap"
                                *ngIf="!credentials.active || credentials.active === '0'"
                            >
                                {{'SERVIZI.FCIC.NOT_ACTIVE_COMUNICATION'| translate}}
                            </span>
                            <span
                                style="white-space: nowrap"
                                *ngIf="credentials.active === '1'"
                            >
                                {{'SERVIZI.FCIC.ACTIVE_COMUNICATION'| translate}}
                            </span>
                        </div>
                    </div>
                    <div class="sub-section-checkbox" style="width: 160px;">
                        <img
                            style="float: right;"
                            [src]="credentials.active == '1' ? configurationService.getImg('/icons/switch-on.svg') : configurationService.getImg('/icons/switch-off.svg')"
                            (click)="activateSwitch()"
                        >
                    </div>
                </div>
            </div>
            <hr>
            <div class="align-items-center d-flex justify-content-between ml-3 mr-4 mt-4 mb-4">
                <div>
                    <span class="section-title margin-auto">
                        {{'SERVIZI.FCIC.LINK_DEP_CAT' | translate}}
                    </span>
                </div>
                <div class="d-flex">
                    <div class="align-items-center d-flex font-r_r fs24 lh16">
                        <div
                            class="mr-4"
                            *ngIf="repartiAssociati.length === 0"
                        >
                            <span style="white-space: nowrap">{{'no_linked_deps'| translate}}</span>
                        </div>
                        <div
                            class="mr-4"
                            *ngIf="repartiAssociati.length > 0 && reparti.length > repartiAssociati.length"
                        >
                            <span>
                                {{reparti.length - repartiAssociati.length}}
                            </span>
                            <span
                                class="ml-2"
                                style="white-space: nowrap;"
                            >
                                {{'non-associated_departments' | translate}}
                            </span>
                        </div>
                    </div>
                    <div class="fcic-button-container">
                        <button
                            class="btn rounded-pill btn-block btn-primary text-uppercase pl-3 pr-3"
                            (click)="associates()"
                        >
                            <span class="font-r_r fs18 c13 lh21">
                                <span>
                                    {{'associates' | translate | uppercase}}
                                </span>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
            <hr>
            <div class="align-items-center d-flex justify-content-between ml-3 mr-4 mt-4 mb-4" [class.disabled]="disableFcic">
                <div>
                    <span class="section-title margin-auto">{{'SERVIZI.FCIC.SYNC_ART' | translate}}</span>
                </div>
                <div class="d-flex">
                    <span
                        class="sub-section-variable section-title margin-auto mr-3"
                        style="white-space: nowrap;"
                    >
                        <!-- {{selectedPv.nome.toUpperCase()}} -->
                    </span>
                    <div class="fcic-button-container">
                        <button
                            class="btn rounded-pill btn-block btn-primary text-uppercase pl-3 pr-3"
                            (click)="syncronize()"
                            [disabled]="disableFcic"
                        >
                            <span class="font-r_r fs18 c13 lh21">
                                <span>
                                    {{'syncronize' | translate | uppercase}}
                                </span>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
            <hr>
            <div class="align-items-center d-flex justify-content-between ml-3 mr-4 mt-4 mb-4" [class.disabled]="disableFcic">
                <div>
                    <span class="section-title margin-auto">{{'update_warehouse_fcic' | translate}}</span>
                </div>
                <div class="d-flex">
                    <span
                        class="sub-section-variable section-title margin-auto mr-3"
                        style="white-space: nowrap;"
                    >
                        <!-- {{selectedPv.nome.toUpperCase()}} -->
                    </span>
                    <div class="fcic-button-container">
                        <button
                            class="btn rounded-pill btn-block btn-primary text-uppercase pl-3 pr-3"
                            (click)="send()"
                            [disabled]="disableFcic"
                        >
                            <span class="font-r_r fs18 c13 lh21">
                                <span>{{'update' | translate | uppercase}}</span>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
            <hr>
            <div class="align-items-center d-flex justify-content-between ml-3 mr-4 mt-4 mb-4" [class.disabled]="disableFcic">
                <div>
                    <span class="section-title margin-auto">
                        {{'SERVIZI.FCIC.SERVICE_STATUS' | translate}}
                    </span>
                </div>
                <div class="d-flex">
                    <span
                        class="sub-section-variable margin-auto mr-4"
                        style="white-space: nowrap;"
                    >
                        <span
                            class="ml-2"
                            style="white-space: nowrap;"
                        >
                            <ng-container *ngIf="anomalie; else no_anomaly">
                                {{anomalie}}&nbsp;{{'SERVIZI.FCIC.ANOMALIES' | translate}}
                            </ng-container>
                            <ng-template #no_anomaly>
                                {{'SERVIZI.FCIC.NO_ANOMALY'| translate}}
                            </ng-template>
                        </span>
                    </span>
                    <div class="fcic-button-container">
                        <button
                            class="btn rounded-pill btn-block btn-primary text-uppercase pl-3 pr-3"
                            (click)="verify()"
                            [disabled]="disableFcic"
                        >
                            <span class="font-r_r fs18 c13 lh21">
                                <span>
                                    {{'SERVIZI.FCIC.VERIFY' | translate | uppercase}}
                                </span>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <hr>
    </div>
</ng-container>
<ng-template #noCredentials>
    <div class="d-flex flex-grow-1 h-100 w-100 steps text-center">
        <div
            style="min-width: 400px;"
            class="mt-3 w-33 border-right"
        >
            <div class="d-flex flex-column flex-grow-1 h-100 justify-content-between">
                <div>
                    <div style="margin-top: 40px;">
                        <span class="numstep">1</span>
                    </div>
                    <div class="m-5 p-2">
                        <span class="fs24 lh32 font_r-r c11">
                            {{'the_requested_service_does_not_have_an_active_license'| translate}}
                        </span>
                    </div>
                    <div class="m-5 p-2">
                        <span class="fs24 lh32 font_r-r c11">
                            {{'contact_your_dealer_to_purchase_a_license'| translate}}
                        </span>
                    </div>
                </div>
                <div class="mb-5">
                    <img [src]="configurationService.getImg('/icons/licenza_sad.svg')">
                </div>
            </div>
        </div>
        <div
            style="min-width: 400px;"
            class="mt-3 w-33 border-right"
        >
            <div class="d-flex flex-column flex-grow-1 h-100 justify-content-between">
                <div>
                    <div style="margin-top: 40px;">
                        <span class="numstep">2</span>
                    </div>
                    <div class="m-5 p-2">
                        <span class="fs24 lh32 font_r-r c11">
                            {{'enter_the_credentials_received_after_the_purchase'| translate}}
                        </span>
                    </div>
                    <div class="m-5 p-2">
                        <input
                            class="custom-input font_r-r fs24 lh21 c11"
                            type="text"
                            [(ngModel)]="idAzienda"
                            placeholder="ID Azienda"
                        >
                    </div>
                </div>
                <div class="mb-5">
                    <img [src]="configurationService.getImg('/icons/licenza_key.svg')">
                </div>
            </div>
        </div>
        <div
            style="min-width: 400px;"
            class="mt-3 w-33"
        >
            <div class="d-flex flex-column flex-grow-1 h-100 justify-content-between">
                <div>
                    <div
                        style="margin-top: 40px;"
                        [ngClass]="{'disabled-step': !idAzienda.length}"
                    >
                        <span class="numstep">3</span>
                    </div>
                    <div class="m-5">
                        <span class="fs24 lh32 font_r-r c11">{{'activate_your_license'| translate}}</span>
                    </div>
                    <div style="margin-top: 6.3rem">
                        <button
                            (click)="attivaLicenza()"
                            class="btn btn-custom pl-5 pr-5"
                            [disabled]="!idAzienda.length"
                        >
                            {{'activate' | translate | uppercase}}
                        </button>
                    </div>
                </div>
                <div class="mb-5">
                    <img [src]="configurationService.getImg('/icons/licenza_smile.svg')">
                </div>
            </div>
        </div>
    </div>
</ng-template>
