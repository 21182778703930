import { InjectionToken, NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { APP_BASE_HREF } from "@angular/common";
import { ConfigurationComponent } from "./configuration/configuration.component";
import { OperatorComponent } from "./configuration/operator/operator.component";
import { ConfigurationEmptyRouteComponent } from "./empty-route/configuration-empty-route.component";
import {
  ConfigurationResolver,
  ConfigurationResolverClosure,
  ConfigurationResolverDocuments,
  ConfigurationResolverGenerali,
  ConfigurationResolverOfflineWork,
  ConfigurationResolverPayments,
  ConfigurationResolverServices,
  ConfigurationResolverTakeaway,
  ConfigurationResolverLanguageAndCurrency,
  ImportExportResolver,
  ServicesResolver,
} from "./configuration/configuration.resolve";
import { CONFIGURATION_TYPE, GROUP_TYPE, IMPORT_EXPORT_TYPE, SERVICE_TYPE } from "./configuration/configuration.model";
import { HallsTablesComponent } from "./halls-tables/halls-tables.component";
import { KuClosureComponent } from "./ku-closure/ku-closure.component";
import { KuOrderPrintersComponent } from "./ku-order-printers/ku-order-printers.component";
import { CustomKuOfflineWorkComponent } from "./configuration/custom-ku-offline-work/custom-ku-offline-work.component";
import { CustomKuDocumentsComponent } from "./configuration/custom-ku-documents/custom-ku-documents.component";
import { CustomKuServicesComponent } from "./configuration/custom-ku-services/custom-ku-services.component";
import { CustomGeneraliComponent } from "./configuration/custom-generali/custom-generali.component";
import { FcicComponent } from "./configuration/fcic/fcic.component";
import { MycustomComponent } from "./configuration/mycustom/mycustom.component";
import { Custom4uComponent } from "./configuration/custom4u/custom4u.component";
import { TakeawayComponent } from "./configuration/takeaway/takeaway.component";
import { ImportArchivesComponent } from "./configuration/ImportExport/importArchives/importArchives.component";
import { ExportArchivesComponent } from "./configuration/ImportExport/exportArchives/exportArchives.component";
import { ImportAspectComponent } from "./configuration/ImportExport/importAspect/importAspect.component";
import { ExportAspectComponent } from "./configuration/ImportExport/exportAspect/exportAspect.component";
import { PaymentsComponent } from "./configuration/payments/payments.component";
import { LanguageCurrencyComponent } from "./configuration/language-currency/language-currency.component";
import { CustomArticlesComponent } from "./custom-articles/custom-articles.component";
import { CustomOrderComponent } from "./custom-order/custom-order.component";
import { ExportSaleDataComponent } from "./configuration/ImportExport/export-sale-data/export-sale-data.component";

export const BASE_HREF: InjectionToken<string> = new InjectionToken<string>("");

const routes: Routes = [
  {
    path: "services",
    component: ConfigurationComponent,
    resolve: {
      data: ServicesResolver,
    },
    data: {
      header: {
        label: 'services'
      },
      type: CONFIGURATION_TYPE.SERVICE
    },
    children: [
      {
        path: "fcic",
        component: FcicComponent,
        data: {
          group: SERVICE_TYPE.FCIC,
        },
        resolve: {
          data: ServicesResolver,
        },
      },
      {
        path: "mycustom",
        component: MycustomComponent,
        data: {
          group: SERVICE_TYPE.MYCUSTOM,
        },
        resolve: {
          data: ConfigurationResolverServices,
        },
      },
      {
        path: "custom4u",
        component: Custom4uComponent,
        data: {
          group: SERVICE_TYPE.CUSTOM4U,
        },
        resolve: {
          data: ServicesResolver,
        },
      },
    ],
  },
  {
    path: "importexport",
    component: ConfigurationComponent,
    resolve: {
      data: ImportExportResolver,
    },
    data: {
      header: {
        label: 'import_export'
      },
      type: CONFIGURATION_TYPE.IMPORT_EXPORT
    },
    children: [
      {
        path: "archives-import",
        component: ImportArchivesComponent,
        data: {
          group: IMPORT_EXPORT_TYPE.IMPORT_ARCHIVES,
        },
        // resolve: {
        //   data: ImportExportResolver,
        // },
      },
      {
        path: "archives-export",
        component: ExportArchivesComponent,
        data: {
          group: IMPORT_EXPORT_TYPE.EXPORT_ARCHIVES,
        },
        // resolve: {
        //   data: ImportExportResolver,
        // },
      },
      {
        path: "aspect-import",
        component: ImportAspectComponent,
        data: {
          group: IMPORT_EXPORT_TYPE.IMPORT_ASPECT,
        },
        // resolve: {
        //   data: ImportExportResolver,
        // },
      },
      {
        path: "aspect-export",
        component: ExportAspectComponent,
        data: {
          group: IMPORT_EXPORT_TYPE.EXPORT_ASPECT,
        },
        // resolve: {
        //   data: ImportExportResolver,
        // },
      },
      {
        path: "export-sale-data",
        component: ExportSaleDataComponent,
        data: {
          group: IMPORT_EXPORT_TYPE.EXPORT_SALE_DATA,
        },
        // resolve: {
        //   data: ImportExportResolver,
        // },
      },
    ],
  },
  {
    path: "",
    component: ConfigurationComponent,
    data: {
      header: {
        label: 'preferences'
      },
      type: CONFIGURATION_TYPE.CONFIGURATION
    },
    resolve: {
      data: ConfigurationResolver,
    },
    children: [
      {
        path: "operators",
        component: OperatorComponent,
        data: {
          group: GROUP_TYPE.OPERATOR,
        },
        resolve: {
          data: ConfigurationResolver,
        },
      },
      {
        path: "halls-tables",
        component: HallsTablesComponent,
        data: {
          group: GROUP_TYPE.HALLS_TABLES,
        },
        resolve: {
          data: ConfigurationResolver,
        },
      },
      {
        path: "offline-work",
        component: CustomKuOfflineWorkComponent,
        data: {
          group: GROUP_TYPE.OFFLINE_WORK,
        },
        resolve: {
          data: ConfigurationResolverOfflineWork,
        },
      },
      {
        path: "ku-documents",
        component: CustomKuDocumentsComponent,
        data: {
          group: GROUP_TYPE.DOCUMENTS,
        },
        resolve: {
          data: ConfigurationResolverDocuments,
        },
      },
      // {
      //   path: "services",
      //   component: CustomKuServicesComponent,
      //   data: {
      //     group: GROUP_TYPE.SERVIZI,
      //   },
      //   resolve: {
      //     data: ConfigurationResolverServices,
      //   },
      // },
      {
        path: "ku-closure",
        component: KuClosureComponent,
        data: {
          group: GROUP_TYPE.CLOSURE,
        },
        resolve: {
          data: ConfigurationResolverClosure,
        },
      },
      {
        path: "ku-order-printers",
        component: KuOrderPrintersComponent,
        data: {
          group: GROUP_TYPE.ORDER_PRINTERS,
        },
        resolve: {
          data: ConfigurationResolver,
        },
      },
      {
        path: "generali",
        component: CustomGeneraliComponent,
        data: {
          group: GROUP_TYPE.GENERALI,
        },
        resolve: {
          data: ConfigurationResolverGenerali,
        },
      },
      {
        path: "takeaway",
        component: TakeawayComponent,
        data: {
          group: GROUP_TYPE.TAKEAWAY,
        },
        resolve: {
          data: ConfigurationResolverTakeaway,
        },
      },
      {
        path: "language-currency",
        component: LanguageCurrencyComponent,
        data: {
          group: GROUP_TYPE.LANGUAGE_CURRENCY,
        },
        resolve: {
          data: ConfigurationResolverLanguageAndCurrency,
        },
      },
      {
        path: "payments",
        component: PaymentsComponent,
        data: {
          group: GROUP_TYPE.PAYMENTS,
        },
        resolve: {
          data: ConfigurationResolverPayments,
        },
      },
      {
        path: "articles",
        component: CustomArticlesComponent,
        data: {
          group: GROUP_TYPE.ARTICLES,
        },
        resolve: {
          data: ConfigurationResolver,
        },
      },
      {
        path: "orders",
        component: CustomOrderComponent,
        data: {
          group: GROUP_TYPE.ORDERS,
        },
        resolve: {
          data: ConfigurationResolver,
        },
      },
    ],
  },
  { path: "**", component: ConfigurationEmptyRouteComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    {
      provide: APP_BASE_HREF,
      useValue: (() =>
        `${window[`app_base`] ? window[`app_base`] : ""}/configuration`)(),
    },
    { provide: BASE_HREF, useValue: "/configuration" },
  ],
})
export class AppRoutingModule { }
