<div
    *ngIf="hasChanges"
    class="w-100"
>
    <div class="d-flex flex-grow-1 p-2">
        <div
            class="mr-1 w-100"
            (click)="save()"
        >
            <button class="btn rounded-pill p-2 btn-block btn-primary text-uppercase pl-3 pr-3">
                <span class="font-r_r fs18 c13 lh21">
                    {{'save' | translate}}
                    <span *ngIf="changesNumber">({{changesNumber}})</span>
                </span>
            </button>
        </div>
        <div class="ml-1">
            <button
                class="btn rounded-pill p-2 btn-block btn-primary text-uppercase pl-3 pr-3"
                (click)="restore()"
            >
                <span class="font-r_r fs18 c13 lh21">{{'restore' | translate}}</span>
            </button>
        </div>
    </div>
</div>
<div
    *ngIf="successSaved && !hasChanges"
    class="w-100"
>
    <div class="d-flex flex-grow-1 p-2 position-relative">
        <button class="btn rounded-pill btn-block btn-primary text-uppercase pl-3 pr-3">
            <span class="font-r_r fs18 c13 lh21">
                <span>{{'configuration_saved' | translate }}</span>
            </span>
            <img
                [src]="configurationService.getImg('icons/close_white.svg')"
                width="28"
                height="28"
                style="position: absolute;right: 14px;width: 28px;height: 28px;top: 12px;"
                (click)="closeSavePanel()"
            >
        </button>
    </div>
</div>
