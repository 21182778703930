import { Component, OnDestroy, OnInit } from "@angular/core";
import { ConfigurationService } from "../configuration.service";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { DOCUMENT_FIELD_TYPES, GROUP_TYPE } from "../configuration.model";
import { ActivatedRoute } from "@angular/router";
import { NgxUiLoaderService } from "ngx-ui-loader";
import {
  P2RDialogComponent,
  P2RDialogService,
  P2RLocalStorageService,
  P2RTranslateService,
} from "@p2r/shared";
import { GlobalVariable } from "src/app/global";
import { P2RApiService } from "@p2r/platform-api";
import { ConfigurationResolverDocuments } from "../configuration.resolve";
import { SIZE } from "@p2r/shared/lib/shared/p2r-dialog/p2r-dialog.interface";
import { NumberDialogComponent } from "src/app/number-dialog/number-dialog.component";
import { TranslateService } from "@ngx-translate/core";
import { ChangeTextDialogComponent } from "../change-text-dialog/change-text-dialog.component";
import { ConfigurationAlertComponent } from "../alert/alert.component";
import { Subject, Subscription } from "rxjs";
import { UtilsShared } from "src/app/shared/utilsshared";

@Component({
  selector: "configuration-custom-ku-documents",
  templateUrl: "./custom-ku-documents.component.html",
  styleUrls: ["./custom-ku-documents.component.scss"],
})
export class CustomKuDocumentsComponent implements OnInit, OnDestroy {
  private TEMPLATE_STORE_LOCAL_STORAGE = "TEMPLATE_STORE";
  private FATTURE_LOCAL_STORAGE = "FATTURE";

  public items: any;
  private formGroup: FormGroup;

  private apiCode: number;
  private apiKeyGroup: number;
  private apiStore: string;

  private templateStoreApiValues: any; // { "codice": "19", "store": "99999", "key_group": "17", "jsontemplate": "{\"KU_PRINT_FATTURA_SU_STAMPANTE_CONTI\":false,\"KU_PRINT_RESOCONTO_SU_STAMPANTE_CONTI\":true,\"KU_PRINT_PROFORMA_SU_STAMPANTE_CONTI\":true,\"KU_INTESTAZIONE_RESOCONTO\":false,\"KU_INTESTAZIONE_PROFORMA\":true,\"KU_INTESTAZIONE_FATTURA\":false,\"KA_reprint_carte\":true,\"KA_reprint_credito\":false}" }
  private currentTemplateStoreValues: any;
  public currentStoreJsonTemplate: any;
  public kuPreprintWithCard: any; // KA_reprint_carte
  public kuReprintCredit: any; // KA_reprint_credito
  public kuIntestazioneFattura: any; // KU_INTESTAZIONE_FATTURA
  public kuIntestazioneProforma: any; // KU_INTESTAZIONE_PROFORMA
  public kuIntestazioneResoconto: any; // KU_INTESTAZIONE_RESOCONTO
  public kuAbilitaStampaFatturaSuConti: any; // KU_PRINT_FATTURA_SU_STAMPANTE_CONTI
  public kuAbilitaStampaPrecontoSuConti: any; // KU_PRINT_PRECONTO_SU_STAMPANTE_CONTI
  public kuAbilitaStampaProformaSuConti: any; // KU_PRINT_PROFORMA_SU_STAMPANTE_CONTI
  public kuAbilitaStampaStatisticheDiChiusuraCassaSuConti: any; // KU_PRINT_STATS_SU_STAMPANTE_CONTI
  public kuAbilitaStampaLogoSuPreconto: any; // KU_PRINT_LOGO_SU_PRECONTO
  public kuAbilitaResocontoPreRomana: any; // KU_RESOCONTO_PRE_ROMANA

  public fattureApiValues: any; // {"codice":"FATTURE","store":"99999","key_group":"17","sezionale":"","next_invoice":256,"readonly":false}
  private currentfattureValues: any;
  public kuSezionaleFattura: any;
  public kuProgressivoFattura: any;
  oldjson: any;
  oldfatture: any;
  private saveCompleteSubscription: Subscription;
  private _changeStoreSubscription: Subscription;

  constructor(
    public configurationService: ConfigurationService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private ngxService: NgxUiLoaderService,
    private p2rLocalStorageService: P2RLocalStorageService,
    private p2rApiService: P2RApiService,
    private resolver: ConfigurationResolverDocuments,
    private p2rDialogService: P2RDialogService,
    private p2rTranslateService: P2RTranslateService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.configurationService.selectedGroup = GROUP_TYPE.DOCUMENTS;
    this.configurationService.restoreCompleteSubject[GROUP_TYPE.DOCUMENTS] =
      new Subject<any>();

    this.route.data.subscribe(({ data }) => {
      this.ngxService.stopAll();

      const [items, apiValues] = data;
      this.items = items;

      if (
        apiValues !== undefined &&
        apiValues.tmpl_store !== undefined &&
        apiValues.tmpl_store.jsontemplate == "{}"
      ) {
        apiValues.tmpl_store.jsontemplate = JSON.stringify(
          this.configurationService.apiValues[GROUP_TYPE.DOCUMENTS].values
        );
      }
      console.log("DOCUMENTS apiValues = " + JSON.stringify(apiValues));

      this.templateStoreApiValues = apiValues.tmpl_store;
      this.fattureApiValues = apiValues.fatture;
      this.oldjson = JSON.parse(apiValues.tmpl_store.jsontemplate);
      this.oldfatture = apiValues.fatture;
      this.apiCode = apiValues.tmpl_store.codice * 1;
      this.apiKeyGroup = apiValues.tmpl_store.key_group;
      const currentPv = this.p2rLocalStorageService.getFromLocalStorage(
        GlobalVariable.SELECTED_PV_KEY_STORAGE,
        []
      );
      if (currentPv !== undefined && currentPv.codice !== undefined) {
        this.apiStore = currentPv.codice;
      }

      console.log(
        "DOCUMENTS tmp store = " + JSON.stringify(this.templateStoreApiValues)
      );
      console.log(
        "DOCUMENTS fatture = " + JSON.stringify(this.fattureApiValues)
      );
      console.log("DOCUMENTS codice = " + this.apiCode);
      console.log("DOCUMENTS key group = " + this.apiKeyGroup);
      console.log("DOCUMENTS store = " + this.apiStore);

      let [templateStoreLocaLStorage, fattureStoreLocalStorage] =
        this.getLocalStorage();
      if (
        templateStoreLocaLStorage === undefined &&
        fattureStoreLocalStorage === undefined
      ) {
        this.currentTemplateStoreValues = this.templateStoreApiValues;
        this.currentfattureValues = this.fattureApiValues;
      } else {
        this.currentTemplateStoreValues = templateStoreLocaLStorage;
        this.currentfattureValues = fattureStoreLocalStorage;
        this.enableSavePanel(true);
      }

      this.currentStoreJsonTemplate = JSON.parse(
        this.currentTemplateStoreValues.jsontemplate
      );
      this.setVariables(
        JSON.parse(this.currentTemplateStoreValues.jsontemplate),
        this.currentfattureValues
      );

      const group: any = {};
      Object.keys(this.currentStoreJsonTemplate).forEach((key) => {
        group[key] = new FormControl(this.currentStoreJsonTemplate[key], {
          validators: [],
          asyncValidators: [],
          updateOn: "blur",
        });
      });
      group["next_invoice"] = new FormControl(this.kuProgressivoFattura, {
        validators: [],
        asyncValidators: [],
        updateOn: "blur",
      });
      group["sezionale"] = new FormControl(this.kuSezionaleFattura, {
        validators: [],
        asyncValidators: [],
        updateOn: "blur",
      });
      this.formGroup = this.formBuilder.group(group);

      this.enableFormValuesChangeDetection();

      this.saveCompleteSubscription = this.configurationService.saveCompleteSubject.subscribe((res) => {
        console.log("DOCUMENTS save complete = " + res);
        if (res) {
          this.ngxService.stopAll();
          this.resolver
            .getValues(GROUP_TYPE.DOCUMENTS, this.apiStore)
            .subscribe((res) => {
              if (res) {
                this.currentStoreJsonTemplate = JSON.parse(
                  res.tmpl_store.jsontemplate
                );
                this.currentTemplateStoreValues = res.tmpl_store;
                this.fattureApiValues = res.fatture;

                this.oldjson = JSON.parse(res.tmpl_store.jsontemplate);
                this.oldfatture = res.fatture;
                this.setVariables(
                  JSON.parse(res.tmpl_store.jsontemplate),
                  res.fatture
                );
                // Svuoto local storage
                this.setAPI(false, undefined);
              }
            });
        }
      });

      this.configurationService.restoreCompleteSubject[
        GROUP_TYPE.DOCUMENTS
      ].subscribe((r) => {
        console.log("DOCUMENTS restore complete = " + r);
        if (r) {
          this.ngxService.start("documents-restore");
          this.resolver
            .getValues(GROUP_TYPE.DOCUMENTS, this.apiStore)
            .subscribe((res) => {
              if (res) {
                console.log(
                  "DOCUMENTS resolver values = " + JSON.stringify(res)
                );
                if (
                  res !== undefined &&
                  res.tmpl_store !== undefined &&
                  res.tmpl_store.jsontemplate == "{}"
                ) {
                  res.tmpl_store.jsontemplate = JSON.stringify(
                    this.configurationService.apiValues[GROUP_TYPE.DOCUMENTS]
                      .values
                  );
                }
                this.currentStoreJsonTemplate = JSON.parse(
                  res.tmpl_store.jsontemplate
                );
                this.currentTemplateStoreValues = res.tmpl_store;
                this.fattureApiValues = res.fatture;

                this.oldjson = JSON.parse(res.tmpl_store.jsontemplate);
                this.oldfatture = res.fatture;
                this.setVariables(
                  JSON.parse(res.tmpl_store.jsontemplate),
                  res.fatture
                );
                this.ngxService.stop("documents-restore");
                // Svuoto local storage
                this.setAPI(false, undefined);
              }
            });
        }
      });

      this._changeStoreSubscription = this.configurationService.changeStoreCompleteSubject.subscribe((r) => {
        console.log("DOCUMENTS change store complete = " + r);
        if (r) {
          if (
            this.configurationService.selectedGroup === GROUP_TYPE.DOCUMENTS
          ) {
            this.changeStoreComplete();
          } else {
            console.log("DOCUMENTS change store not visible");
          }
        }
      });
    });
  }

  onClickEnableReprintCart() {
    this.kuPreprintWithCard = !this.kuPreprintWithCard;
    this.formGroup
      .get(DOCUMENT_FIELD_TYPES.KU_REPRINNT_CART)
      .setValue(this.kuPreprintWithCard);
  }

  onClickEnableReprintCredit() {
    this.kuReprintCredit = !this.kuReprintCredit;
    this.formGroup
      .get(DOCUMENT_FIELD_TYPES.KU_REPRINT_CREDIT)
      .setValue(this.kuReprintCredit);
  }

  onClinkEnableIntestazioneFattura() {
    this.kuIntestazioneFattura = !this.kuIntestazioneFattura;
    this.formGroup
      .get(DOCUMENT_FIELD_TYPES.KU_INTESTAZIONE_FATTURA)
      .setValue(this.kuIntestazioneFattura);
  }

  onClinkEnableIntestazioneProforma() {
    this.kuIntestazioneProforma = !this.kuIntestazioneProforma;
    this.formGroup
      .get(DOCUMENT_FIELD_TYPES.KU_INTESTAZIONE_PROFORMA)
      .setValue(this.kuIntestazioneProforma);
  }

  onClinkEnableIntestazioneResoconto() {
    this.kuIntestazioneResoconto = !this.kuIntestazioneResoconto;
    this.formGroup
      .get(DOCUMENT_FIELD_TYPES.KU_INTESTAZIONE_RESOCONTO)
      .setValue(this.kuIntestazioneResoconto);
  }

  onClickEnablePrintFatturaSuConti() {
    this.kuAbilitaStampaFatturaSuConti = !this.kuAbilitaStampaFatturaSuConti;
    this.formGroup
      .get(DOCUMENT_FIELD_TYPES.KU_PRINT_FATTURA_SU_STAMPANTE_CONTI)
      .setValue(this.kuAbilitaStampaFatturaSuConti);
  }

  onClickEnablePrintPrecontoSuConti() {
    this.kuAbilitaStampaPrecontoSuConti = !this.kuAbilitaStampaPrecontoSuConti;
    this.formGroup
      .get(DOCUMENT_FIELD_TYPES.KU_PRINT_RESOCONTO_SU_STAMPANTE_CONTI)
      .setValue(this.kuAbilitaStampaPrecontoSuConti);
  }

  onClickEnablePrintProformaSuConti() {
    this.kuAbilitaStampaProformaSuConti = !this.kuAbilitaStampaProformaSuConti;
    this.formGroup
      .get(DOCUMENT_FIELD_TYPES.KU_PRINT_PROFORMA_SU_STAMPANTE_CONTI)
      .setValue(this.kuAbilitaStampaProformaSuConti);
  }

  onClickEnablePrintStatisticheDiChiusuraCassaSuConti() {
    this.kuAbilitaStampaStatisticheDiChiusuraCassaSuConti = !this.kuAbilitaStampaStatisticheDiChiusuraCassaSuConti;
    this.formGroup
      .get(DOCUMENT_FIELD_TYPES.KU_PRINT_STATS_SU_STAMPANTE_CONTI)
      .setValue(this.kuAbilitaStampaStatisticheDiChiusuraCassaSuConti);
  }

  onClickEnablePrintLogoOnProforma() {
    this.kuAbilitaStampaLogoSuPreconto = !this.kuAbilitaStampaLogoSuPreconto;
    this.formGroup
      .get(DOCUMENT_FIELD_TYPES.KU_PRINT_LOGO_SU_PRECONTO)
      .setValue(this.kuAbilitaStampaLogoSuPreconto);
  }

  onClickEnableResocontoPreRomana() {
    this.kuAbilitaResocontoPreRomana = !this.kuAbilitaResocontoPreRomana;
    this.formGroup
      .get(DOCUMENT_FIELD_TYPES.KU_RESOCONTO_PRE_ROMANA)
      .setValue(this.kuAbilitaResocontoPreRomana);
  }

  public openNumberDialog() {
    console.log("DOCUMENTS openNumberDialog = " + this.kuProgressivoFattura);
    const dataToDialog = {
      title: this.p2rTranslateService.get(
        this.translateService.instant(
          "DOCUMENTS.NUMERAZIONE_DOCUMENTI.PROGRESSIVO_FATTURE.TITLE"
        )
      ),
      number: this.kuProgressivoFattura,
      min: 0,
      max: 10000,
    };
    const alert = this.p2rDialogService.open(P2RDialogComponent, {
      id: 'number-dialog',
      hasHead: false,
      body: NumberDialogComponent,
      data: dataToDialog,
      size: SIZE.MEDIUM,
      actions: [
        {
          label: this.p2rTranslateService.get(
            this.translateService.instant("dialog_cancel")
          ),
          click: () => {
            alert.dismiss();
          },
          classes: "btn rounded-pill text-uppercase new-alert-button font-r_l fs18",
          data: {
            remove: true,
          },
          params: {},
        },
        {
          label: this.p2rTranslateService.get(
            this.translateService.instant("dialog_confirm")
          ),
          click: (data) => {
            console.log("DOCUMENTS NumberAlert data = " + JSON.stringify(data));
            if (
              dataToDialog.number == null ||
              dataToDialog.number < dataToDialog.min ||
              dataToDialog.number > dataToDialog.max
            ) {
              console.log("Invalid value:\n" + JSON.stringify(data));
            } else {
              alert.dismiss();
              this.kuProgressivoFattura = dataToDialog.number;
              this.formGroup
                .get("next_invoice")
                .setValue(this.kuProgressivoFattura);
            }
          },
          data: dataToDialog,
          classes: "btn rounded-pill text-uppercase new-alert-button font-r_l fs18",
          params: {
            submit: true,
          },
        },
      ],
    });
  }

  public changeSezionale() {
    console.log("DOCUMENTS changeSezionale = " + this.kuSezionaleFattura);
    const dataToDialog = {
      text: this.kuSezionaleFattura,
      input_title: this.p2rTranslateService.get(
        this.translateService.instant("DOCUMENTS.SEZIONALE_FATTURA.TITLE")
      ),
    };
    const alert = this.p2rDialogService.open(P2RDialogComponent, {
      id: 'change-text-dialog',
      hideCloseButton: true,
      title: this.p2rTranslateService.get(
        this.translateService.instant("DOCUMENTS.SEZIONALE_FATTURA.TITLE")
      ),
      titleClasses: "font-r_r fs24 lh-39 c11 text-uppercase",
      body: ChangeTextDialogComponent,
      data: dataToDialog,
      size: SIZE.MEDIUM,
      actions: [
        {
          label: this.p2rTranslateService.get(
            this.translateService.instant("dialog_cancel")
          ),
          click: () => {
            alert.dismiss();
          },
          classes: "btn rounded-pill text-uppercase new-alert-button font-r_l fs18",
          data: {
            remove: true,
          },
          params: {},
        },
        {
          label: this.p2rTranslateService.get(
            this.translateService.instant("dialog_confirm")
          ),
          click: (data) => {
            if (dataToDialog.text === undefined) {
              console.log("DOCUMENTS Invalid value:\n" + JSON.stringify(data));
            } else if (dataToDialog.text.length > 3) {
              // alert.dismiss();
              this.showErrorAlert(
                this.p2rTranslateService.get(
                  this.translateService.instant("sezionale_fattura_error")
                )
              );
            } else {
              alert.dismiss();
              console.log("DOCUMENTS update text = " + JSON.stringify(data));
              this.kuSezionaleFattura = dataToDialog.text;
              this.formGroup.get("sezionale").setValue(this.kuSezionaleFattura);
            }
          },
          data: dataToDialog,
          classes: "btn rounded-pill text-uppercase new-alert-button font-r_l fs18",
          params: {
            submit: true,
          },
        },
      ],
    });
  }

  public showErrorAlert(msg) {
    const alert = this.p2rDialogService.open(P2RDialogComponent, {
      id: 'show-error-dialog',
      body: ConfigurationAlertComponent,
      hasHead: false,
      data: {
        imgSrc: this.configurationService.getImg("icons/attenzione.svg"),
        title: this.p2rTranslateService.get(
          this.translateService.instant("attention")
        ),
        messages: [msg],
      },
      actions: [
        {
          label: this.p2rTranslateService.get(
            this.translateService.instant("close")
          ),
          click: () => {
            alert.dismiss();
          },
          classes: "btn rounded-pill text-uppercase new-alert-button",
          data: {
            remove: true,
          },
          params: {
            position: "right",
          },
        },
      ],
    });
  }

  private getLocalStorage() {
    let templateStoreLocalStorage = undefined;
    let fattureLocalStorage = undefined;

    const t = this.p2rLocalStorageService.getFromLocalStorage(
      this.TEMPLATE_STORE_LOCAL_STORAGE,
      [GROUP_TYPE.DOCUMENTS]
    );
    if (t !== undefined) {
      templateStoreLocalStorage = t.values;
    }
    const b = this.p2rLocalStorageService.getFromLocalStorage(
      this.FATTURE_LOCAL_STORAGE,
      [GROUP_TYPE.DOCUMENTS]
    );
    if (b !== undefined) {
      fattureLocalStorage = b.values;
    }

    return [templateStoreLocalStorage, fattureLocalStorage];
  }

  private compareValues(apiValue, formValue) {
    if (formValue === undefined) {
      return false;
    }
    if (apiValue != formValue) {
      return true;
    } else {
      return false;
    }
  }

  private enableSavePanel(thereIsChanges) {
    if (thereIsChanges === false) {
      this.configurationService.doCheck(
        UtilsShared.thereAreOtherChanges(this.p2rLocalStorageService)
      );
    } else {
      this.configurationService.doCheck(true);
    }
    if (thereIsChanges) {
      // aggiorno modifiedGroups
      this.configurationService.modifiedGroups[GROUP_TYPE.DOCUMENTS] = "CHANGE";
      // invio modifiedGroups dentro lo stream
      this.configurationService._modifiedGroups$.next(
        this.configurationService.modifiedGroups
      );
    } else {
      // cancello i dati relativi al gruppo dentro modifiedGroups
      delete this.configurationService.modifiedGroups[GROUP_TYPE.DOCUMENTS];
      // invio modifiedGroups dentro lo stream
      this.configurationService._modifiedGroups$.next(
        this.configurationService.modifiedGroups
      );
    }
  }

  private setVariables(tmplStore, fattureStore) {
    // Template Store
    this.currentStoreJsonTemplate = JSON.parse(
      this.currentTemplateStoreValues.jsontemplate
    );
    this.kuPreprintWithCard = tmplStore[DOCUMENT_FIELD_TYPES.KU_REPRINNT_CART];
    this.kuReprintCredit = tmplStore[DOCUMENT_FIELD_TYPES.KU_REPRINT_CREDIT];
    this.kuIntestazioneFattura =
      tmplStore[DOCUMENT_FIELD_TYPES.KU_INTESTAZIONE_FATTURA];
    this.kuIntestazioneProforma =
      tmplStore[DOCUMENT_FIELD_TYPES.KU_INTESTAZIONE_PROFORMA];
    this.kuIntestazioneResoconto =
      tmplStore[DOCUMENT_FIELD_TYPES.KU_INTESTAZIONE_RESOCONTO];
    this.kuAbilitaStampaFatturaSuConti =
      tmplStore[DOCUMENT_FIELD_TYPES.KU_PRINT_FATTURA_SU_STAMPANTE_CONTI];
    this.kuAbilitaStampaPrecontoSuConti =
      tmplStore[DOCUMENT_FIELD_TYPES.KU_PRINT_RESOCONTO_SU_STAMPANTE_CONTI];
    this.kuAbilitaStampaProformaSuConti =
      tmplStore[DOCUMENT_FIELD_TYPES.KU_PRINT_PROFORMA_SU_STAMPANTE_CONTI];
    this.kuAbilitaStampaStatisticheDiChiusuraCassaSuConti =
      tmplStore[DOCUMENT_FIELD_TYPES.KU_PRINT_STATS_SU_STAMPANTE_CONTI];
    this.kuAbilitaStampaLogoSuPreconto =
      tmplStore[DOCUMENT_FIELD_TYPES.KU_PRINT_LOGO_SU_PRECONTO];
    this.kuAbilitaResocontoPreRomana = tmplStore[DOCUMENT_FIELD_TYPES.KU_RESOCONTO_PRE_ROMANA];
    if (fattureStore) {
      // Fattura
      this.kuProgressivoFattura = fattureStore.next_invoice;
      this.kuSezionaleFattura = fattureStore.sezionale;
    }
  }

  private setAPI(change, value) {
    this.configurationService.saveAllList[GROUP_TYPE.DOCUMENTS] = [];
    if (change) {
      const storeTemplate = {};
      const fatturaTemplate = {};

      fatturaTemplate["next_invoice"] = value["next_invoice"];
      fatturaTemplate["sezionale"] = value["sezionale"];
      fatturaTemplate["store"] = this.apiStore;
      this.p2rLocalStorageService.addToLocalStorage(
        this.FATTURE_LOCAL_STORAGE,
        [GROUP_TYPE.DOCUMENTS, "values"],
        fatturaTemplate
      );
      this.configurationService.saveAllList[GROUP_TYPE.DOCUMENTS].push(
        this.p2rApiService.post(
          `${this.p2rApiService.apiCorePath}/fatture/update`,
          undefined,
          fatturaTemplate
        )
      );

      delete value["next_invoice"];
      delete value["sezionale"];

      storeTemplate["jsontemplate"] = JSON.stringify(value);
      storeTemplate["key_group"] = this.apiKeyGroup;
      if (storeTemplate !== undefined) {
        storeTemplate["store"] = this.apiStore;
        if (this.apiCode === 0) {
          storeTemplate["codice"] = "0";
          this.configurationService.saveAllList[GROUP_TYPE.DOCUMENTS].push(
            this.p2rApiService.post(
              `${this.p2rApiService.apiCorePath}/tmpstore/operatore`,
              undefined,
              storeTemplate
            )
          );
        } else {
          storeTemplate["codice"] = this.apiCode.toString();
          this.configurationService.saveAllList[GROUP_TYPE.DOCUMENTS].push(
            this.p2rApiService.put(
              `${this.p2rApiService.apiCorePath}/tmpstore/operatore`,
              undefined,
              storeTemplate
            )
          );
        }
        this.p2rLocalStorageService.addToLocalStorage(
          this.TEMPLATE_STORE_LOCAL_STORAGE,
          [GROUP_TYPE.DOCUMENTS, "values"],
          storeTemplate
        );
      }
    } else {
      if (
        this.configurationService.saveAllList[GROUP_TYPE.DOCUMENTS] !==
        undefined
      ) {
        this.configurationService.saveAllList[GROUP_TYPE.DOCUMENTS] = [];
      }
      this.p2rLocalStorageService.removeFromLocalStorage(
        this.TEMPLATE_STORE_LOCAL_STORAGE,
        [GROUP_TYPE.DOCUMENTS, "values"]
      );
      this.p2rLocalStorageService.removeFromLocalStorage(
        this.FATTURE_LOCAL_STORAGE,
        [GROUP_TYPE.DOCUMENTS, "values"]
      );
    }
  }

  private enableFormValuesChangeDetection() {
    this.formGroup.valueChanges.subscribe((value) => {
      let thereIsChanges = false;
      Object.keys(value).forEach((k) => {
        const m =
          this.compareValues(value[k], this.oldjson && this.oldjson[k]) ||
          this.compareValues(value[k], this.oldfatture && this.oldfatture[k]);
        if (m) {
          thereIsChanges = true;
        }
      });
      this.setAPI(thereIsChanges, value);
      this.enableSavePanel(thereIsChanges);
    });
  }

  private changeStoreComplete() {
    const currentPv = this.p2rLocalStorageService.getFromLocalStorage(
      GlobalVariable.SELECTED_PV_KEY_STORAGE,
      []
    );
    if (currentPv !== undefined && currentPv.codice !== undefined) {
      this.apiStore = currentPv.codice;
    }
    console.log("DOCUMENTS store = " + this.apiStore);
    this.ngxService.start();
    this.resolver
      .getValues(GROUP_TYPE.DOCUMENTS, this.apiStore)
      .subscribe((res) => {
        if (res) {
          console.log("DOCUMENTS resolver values = " + JSON.stringify(res));
          if (
            res !== undefined &&
            res.tmpl_store !== undefined &&
            res.tmpl_store.jsontemplate == "{}"
          ) {
            res.tmpl_store.jsontemplate = JSON.stringify(
              this.configurationService.apiValues[GROUP_TYPE.DOCUMENTS].values
            );
          }
          this.apiCode = res.tmpl_store.codice * 1;
          this.apiKeyGroup = res.tmpl_store.key_group;
          console.log("DOCUMENTS codice = " + this.apiCode);
          console.log("DOCUMENTS key group = " + this.apiKeyGroup);
          console.log("DOCUMENTS store = " + this.apiStore);
          this.currentStoreJsonTemplate = JSON.parse(
            res.tmpl_store.jsontemplate
          );
          this.oldjson = JSON.parse(res.tmpl_store.jsontemplate);
          this.oldfatture = res.fatture;
          this.currentTemplateStoreValues = res.tmpl_store;
          this.fattureApiValues = res.fatture;
          this.ngxService.stopAll();
          this.setAPI(false, undefined);
          this.currentStoreJsonTemplate = JSON.parse(
            this.currentTemplateStoreValues.jsontemplate
          );
          this.setVariables(
            JSON.parse(this.currentTemplateStoreValues.jsontemplate),
            this.fattureApiValues
          );
          const group: any = {};
          Object.keys(this.currentStoreJsonTemplate).forEach((key) => {
            group[key] = new FormControl(this.currentStoreJsonTemplate[key], {
              validators: [],
              asyncValidators: [],
              updateOn: "blur",
            });
          });
          group["next_invoice"] = new FormControl(this.kuProgressivoFattura, {
            validators: [],
            asyncValidators: [],
            updateOn: "blur",
          });
          group["sezionale"] = new FormControl(this.kuSezionaleFattura, {
            validators: [],
            asyncValidators: [],
            updateOn: "blur",
          });
          this.formGroup = this.formBuilder.group(group);
          this.enableFormValuesChangeDetection();
        }
      });
  }

  ngOnDestroy(): void {
    if (this.saveCompleteSubscription) {
      this.saveCompleteSubscription.unsubscribe()
    }
    if (this._changeStoreSubscription) {
      this._changeStoreSubscription.unsubscribe()
    }
  }

}
