import { Component, OnInit } from '@angular/core';
import { P2REnvironmentService } from '@p2r/env';
import { TranslateService } from '@ngx-translate/core';
import { P2RLocalStorageService, P2RDialogService, P2RDialogComponent, P2RTranslateService } from '@p2r/shared';
import { ConfigurationAlertComponent } from '../configuration/alert/alert.component';
import { GROUP_TYPE, PvDto } from '../configuration/configuration.model';
import { ConfigurationService } from '../configuration/configuration.service';
import { GlobalVariable } from '../global';
import { assetUrl } from 'src/single-spa/asset-url';
import { Subscription } from 'rxjs';
import { Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'configuration-store-panel',
  templateUrl: './store-panel.component.html',
  styleUrls: ['./store-panel.component.scss']
})
export class StorePanelComponent implements OnInit {

  private _assetsPath;

  pvList: PvDto[];
  selectedPv: PvDto;
  // selectedPvWidth: number;
  isStoreListExpanded: boolean;
  hasStoreModification: boolean;
  private _saveDataSubscription: Subscription;

  @Output() expandEvent = new EventEmitter<any>();

  constructor(
    public configurationService: ConfigurationService,
    private p2rEnvironmentService: P2REnvironmentService,
    private p2rLocalStorageService: P2RLocalStorageService,
    private p2rDialogService: P2RDialogService,
    private p2rTranslateService: P2RTranslateService,
    private translateService: TranslateService
  ) {
    this.p2rEnvironmentService.getEnvironmentStream().subscribe(env => {
      this._assetsPath = `${env.BASE_CONFIG_URL}`;
    });
  }

  getImg(imgSrc) {
    if (typeof imgSrc === 'function') {
      imgSrc = imgSrc();
    }
    return assetUrl(imgSrc, this._assetsPath);
  }

  ngOnInit() {

    this.configurationService.doCheck$().subscribe(val => {
      this.hasStoreModification = val;
    });
    this.pvList = this.p2rEnvironmentService.storeData.pv;
    console.log('STORE PANEL = ' + JSON.stringify(this.pvList));

    this.selectedPv = this.p2rLocalStorageService.getFromLocalStorage(GlobalVariable.SELECTED_PV_KEY_STORAGE, []);
    console.log('STORE PANEL SELECTED PV = ' + JSON.stringify(this.selectedPv));
  }

  openStoreList() {
    if (this.pvList.length === 1) {
      console.log('Single Store --> don\'t expand list');
      return;
    }

    if (!this.hasStoreModification) {
      this.isStoreListExpanded = true;
      this.sendExpandListEvent();
    } else {
      console.log('Store has modification --> MUST SAVE before selection of another store');
      const alert = this.p2rDialogService.open(P2RDialogComponent, {
        id: 'open-store-list-dialog',
        body: ConfigurationAlertComponent,
        hasHead: false,
        data: {
          imgSrc: this.getImg('icons/attenzione.svg'),
          title: this.p2rTranslateService.get(this.translateService.instant('attention')),
          messages: [
            this.p2rTranslateService.get(this.translateService.instant('pending_save')),
          ]
        },
        actions: [
          {
            label: this.p2rTranslateService.get(this.translateService.instant('dialog_cancel')),
            click: () => {
              alert.dismiss();
            },
            classes: 'btn rounded-pill text-uppercase new-alert-button',
            data: {
              remove: true
            },
            params: {
            }
          },
          {
            label: this.p2rTranslateService.get(this.translateService.instant('save')),
            click: () => {
              console.log('STORE PANEL --> Start saving...');
              this._saveDataSubscription = this.configurationService.saveLocalChanges().subscribe((resp: Array<boolean>) => {
                const successSaved = !resp.includes(false);
                if (successSaved) {
                  console.log('STORE PANEL --> Success save');
                  if (this._saveDataSubscription) {
                    this._saveDataSubscription.unsubscribe();
                  }
                  this.isStoreListExpanded = true;
                  this.sendExpandListEvent();
                  const currentSelectedGroup = this.configurationService.selectedGroup;
                  if (currentSelectedGroup === GROUP_TYPE.DOCUMENTS || currentSelectedGroup === GROUP_TYPE.OFFLINE_WORK || currentSelectedGroup === GROUP_TYPE.SERVIZI) {
                    console.log("STORE PANEL Call dedicated resolver for group = " + currentSelectedGroup);
                    this.configurationService.changeStoreCompleteSubject.next(true);
                  }
                } else {
                  console.log('STORE PANEL --> save error');
                  this.configurationService.showErrorAlert();
                }
                alert.dismiss();
              });
            },
            classes: 'btn rounded-pill text-uppercase new-alert-button',
            params: {
              submit: true
            }
          }
        ]
      });
    }
  }

  sendExpandListEvent() {
    this.expandEvent.emit(this.isStoreListExpanded);
  }

  onCloseStoreList() {
    this.isStoreListExpanded = false;
    this.sendExpandListEvent();
  }

  onSelectPv(pv) {
    console.log('SELECTED: ' + JSON.stringify(pv));
    this.selectedPv = pv;
    this.p2rLocalStorageService.addToLocalStorage(GlobalVariable.SELECTED_PV_KEY_STORAGE, [], this.selectedPv);
    this.isStoreListExpanded = false;
    this.sendExpandListEvent();
    // this.p2rLocalStorageService.removeFromLocalStorage('CONFIGURATION');
    const currentSelectedGroup = this.configurationService.selectedGroup;
    switch (currentSelectedGroup) {
      case GROUP_TYPE.DOCUMENTS:
      case GROUP_TYPE.OFFLINE_WORK:
      case GROUP_TYPE.SERVIZI:
        console.log("STORE PANEL Call dedicated resolver for group = " + currentSelectedGroup);
        this.configurationService.changeStoreCompleteSubject.next(true);
        break;

      default:
        console.log("STORE PANEL Call default reloadValues for group = " + currentSelectedGroup);
        this.configurationService.reloadValues(this.selectedPv.codice);
        break;
    }
  }

}
