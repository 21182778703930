import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ConfigurationEmptyRouteComponent } from './empty-route/configuration-empty-route.component';
import { EmptyRouteComponent } from './empty-route/empty-route.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { P2RApiService, P2rPlatformApiLibModule } from '@p2r/platform-api';
import { LocalStorageService } from 'ngx-webstorage';
import { SessionStorageService } from 'ngx-webstorage';
import { P2REnvironmentService } from '@p2r/env';
import { assetUrl } from '../single-spa/asset-url';
import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { MFECommunicationModule } from '@p2r/mfe';
import { SingleSpaPropsSubject } from '../single-spa/single-spa-props';
import { ConfigurationModule } from './configuration/configuration.module';
import { NgxUiLoaderModule, NgxUiLoaderRouterModule } from 'ngx-ui-loader';
import { P2RLocalStorageService } from '@p2r/shared';
import { GlobalVariable } from './global';

@NgModule({
  declarations: [
    AppComponent,
    ConfigurationEmptyRouteComponent,
    EmptyRouteComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ConfigurationModule,
    NgxWebstorageModule.forRoot(),
    NgxUiLoaderModule.forRoot({
      masterLoaderId: 'configuration-loader',
      minTime: 0,
      blur: 0
    }), // import NgxUiLoaderModule
    NgxUiLoaderRouterModule,
    P2rPlatformApiLibModule.forRoot(
      {
        LOCAL_STORAGE_SERVICE: LocalStorageService,
        SESSION_STORAGE_SERVICE: SessionStorageService,
        P2R_ENVIRONMENT_SERVICE: P2REnvironmentService
      }),
    MFECommunicationModule.forRoot(
      { channelName: 'configurationChannel', singleSPAPropsSubject: SingleSpaPropsSubject }
    )
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (
        envService: P2REnvironmentService,
        http: HttpClient,
        apiService: P2RApiService,
        localStorageService: P2RLocalStorageService) =>
        () => envService.loadEnvironment({
          config_url: assetUrl('config/config.json'),
          env_url: `${window[`app_base`] ? window[`app_base`] : ''}/assets/config/env.json`,
          microfrontend: 'configuration'
        })
          .pipe(
            tap(_ => {
              apiService.get(`${apiService.apiPath}/configuration/store/getStoreInfo`)
                // http.get(assetUrl('config/getStoreInfo.json'))
                .subscribe((data: any) => {
                  envService.storeData = data;
                  console.log('APP MODULE /getStoreInfo');
                  const selectedPv = localStorageService
                    .getFromLocalStorage(GlobalVariable.SELECTED_PV_KEY_STORAGE, []);
                  if (selectedPv === undefined) {
                    console.log('APP MODULE --> NO PV SELECTED');
                    localStorageService
                      .addToLocalStorage(GlobalVariable.SELECTED_PV_KEY_STORAGE, [], data.pv[0]);
                  } else {
                    console.log('APP MODULE --> PV: ' + JSON.stringify(selectedPv));
                  }
                });
              // http.get(assetUrl('config/menu.json')).subscribe((menu: any[]) => {
              //   if (menu && menu.length) {
              //     const message = {
              //       menu: [...menu]
              //     };
              //     mfeComunicationService.sendMessage('navAppChannel', `${JSON.stringify(message)}`);
              //   }
              // });
            })
          ).toPromise(),
      deps: [
        P2REnvironmentService,
        HttpClient,
        P2RApiService,
        P2RLocalStorageService
      ],
      multi: true
    },
    P2REnvironmentService,
    SingleSpaPropsSubject,
    { provide: 'MICROFRONTEND', useValue: 'configuration' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
