import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, of } from 'rxjs';
import { P2REnvironmentService } from '@p2r/env';
import { mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
}) export class FcicCatPaginationService {

  private FCIC: any;
  private totalItems = [];
  
  constructor(private http: HttpClient, private p2rEnvironmentService: P2REnvironmentService) {
    this.FCIC = this.p2rEnvironmentService.getEnvironment().FCIC || {};
  }

  getData(credenziali, currentPage) {
    // Azzero totalItems
    if(currentPage == 0) {
      this.totalItems = [];
    }

    // observable che emette i dati della paginazione corrente
    let pagination$ = of({
      page: currentPage
    });

    // chiamata API utilizzando l'operatore forkJoin per unire i risultati in un unico oggetto
    return pagination$.pipe(
      mergeMap((pagination) => {
        const pageNumber = pagination.page;
        return this.http.get(`${this.FCIC.API_URL}/categorie/all`, {
            headers: {
              [`X-ACCESS-TOKEN`]: credenziali.token
            },
            params: {
              pageNumber: pageNumber.toString()
            }
          }).pipe(
          mergeMap((data) => {
            // gestione errori
            if (!data || data['responses'] == null || data['responses'][0] == null || data['responses'][0].status != 200 ||  data['responses'][0].items.length == 0) {
                return of([]);
            } else {
                return of(data['responses'][0]);
            }
          }),
          mergeMap((data) => {
            // se l'array di dati restituito dalla chiamata API è vuoto, non ci sono altre pagine
            if(data != undefined && data.items != undefined && data.items.length > 0) {
                currentPage++;
                this.totalItems = this.totalItems.concat(data.items);
                if(currentPage < data.paging.totalPages) { 
                  return forkJoin([of(this.totalItems), this.getData(credenziali, currentPage)]);
                } else {
                  //return this.totalItems;
                  return of([this.totalItems]);
                }
            } else {
                return of([]);
            }
          })
        );
      })
    );
  }
}
