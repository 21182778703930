import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { P2RDialogComponent } from '@p2r/shared';
import { GROUP_TYPE, TAKEAWAY_FIELD_TYPES } from '../configuration.model';
import { TimeSlotAlertComponent } from '../time-slot-alert/time-slot-alert.component';
import { ORDER_PRINTING_TYPE } from './takeaway.model';
import * as _ from 'lodash';
import * as momentN from 'moment';
import { ChangeDescriptionDialogComponent } from '../change-description-dialog/change-description-dialog.component';
import { ChangeTextDialogComponent } from '../change-text-dialog/change-text-dialog.component';
import { ConfigurationAlertComponent } from '../alert/alert.component';
import { CustomizeDialogComponent } from '../../customize-dialog/customize-dialog.component';
import { CUSTOMIZE_TYPE } from '../../customize-dialog/customize-dialog.model';
import { GlobalVariable } from '../../global';
const moment = momentN;
export class TakeawayComponent {
    constructor(configurationService, formBuilder, p2rDialogService, p2rTranslateService, translateService, route, p2rLocalStorageService) {
        this.configurationService = configurationService;
        this.formBuilder = formBuilder;
        this.p2rDialogService = p2rDialogService;
        this.p2rTranslateService = p2rTranslateService;
        this.translateService = translateService;
        this.route = route;
        this.p2rLocalStorageService = p2rLocalStorageService;
        this.takeawayStart = 0;
        this.takeawayEnd = 0;
        this.timeSlots = [
            {
                slot: 5,
                selected: false
            },
            {
                slot: 10,
                selected: false
            },
            {
                slot: 15,
                selected: false
            },
            {
                slot: 20,
                selected: false
            },
            {
                slot: 30,
                selected: false
            },
            {
                slot: 60,
                selected: false
            },
        ];
        this.orderPrintingTypes = [
            {
                type: ORDER_PRINTING_TYPE.ON_ORDER,
                selected: false
            },
            {
                type: ORDER_PRINTING_TYPE.BEFORE_DELIVERY,
                selected: false
            },
        ];
        this.selectedOrderPrintingType = 0;
        this.ORDER_PRINTING_TYPE = ORDER_PRINTING_TYPE;
        this.askProceed = () => new Promise(res => {
            const proceedAlert = this.p2rDialogService.open(P2RDialogComponent, {
                id: 'ask-proceed-dialog',
                body: ConfigurationAlertComponent,
                hasHead: false,
                data: {
                    imgSrc: this.configurationService.getImg("icons/attenzione.svg"),
                    title: this.p2rTranslateService.get(this.translateService.instant("attention")),
                    messages: [
                        this.p2rTranslateService.get(this.translateService.instant("by_carrying_out_this_operation_some_of_the_orders_in_progress_may_no_longer_be_visible")),
                        this.p2rTranslateService.get(this.translateService.instant("are_you_sure_you_want_to_proceed_or_want_to_cancel_the_operation"))
                    ],
                },
                actions: [
                    {
                        label: this.p2rTranslateService.get(this.translateService.instant("proceed")),
                        click: () => {
                            proceedAlert.dismiss();
                            res(true);
                        },
                        classes: "btn rounded-pill text-uppercase new-alert-button",
                        data: {
                            remove: true,
                        },
                        params: {},
                    },
                    {
                        label: this.p2rTranslateService.get(this.translateService.instant("dialog_cancel")),
                        click: () => {
                            proceedAlert.dismiss();
                            res(false);
                        },
                        classes: "btn rounded-pill text-uppercase new-alert-button",
                        data: {
                            remove: true,
                        },
                        params: {},
                    },
                ],
            });
        });
        this.typegrop = GROUP_TYPE.TAKEAWAY;
        this.images = {
            add: this.configurationService.ICONS.ADD,
            remove: this.configurationService.ICONS.REMOVE
        };
    }
    ngOnInit() {
        this.configurationService.selectedGroup = GROUP_TYPE.TAKEAWAY;
        this.route.data.subscribe(({ data }) => {
            const [items, values, casse] = data;
            // this.items = items;
            this.casseAll = casse;
            this.filterCasseByStore();
            const group = {};
            Object.keys(values).forEach(key => {
                group[key] = new FormControl(values[key], {
                    validators: [],
                    asyncValidators: [],
                    updateOn: 'blur'
                });
            });
            this.initializeVariables(values);
            this.configurationService.formGroup = this.formBuilder.group(group);
        });
        this.configurationService.values$().subscribe(values => {
            values = values[GROUP_TYPE.TAKEAWAY];
            if (values !== undefined) {
                if (this.configurationService._selectedGroup === GROUP_TYPE.TAKEAWAY) {
                    this.initializeVariables(values);
                    this.filterCasseByStore();
                }
            }
        });
    }
    initializeVariables(values) {
        this.enableTakeaway = values[TAKEAWAY_FIELD_TYPES.KU_ASPORTO_ENABLED];
        this.takeawayStart = values[TAKEAWAY_FIELD_TYPES.KU_ASPORTO_START];
        this.takeawayEnd = values[TAKEAWAY_FIELD_TYPES.KU_ASPORTO_END];
        this.takeawayPeriods = _.cloneDeep(values[TAKEAWAY_FIELD_TYPES.KU_ASPORTO_PERIODS]);
        this.takeawayMainPrinterCash = values[TAKEAWAY_FIELD_TYPES.KU_ASPORTO_PRINTER_CASH];
        if (!this.takeawayMainPrinterCash || Array.isArray(this.takeawayMainPrinterCash)) {
            this.takeawayMainPrinterCash = this.casse[0].key;
        }
        if (!this.takeawayPeriods.length) {
            this.takeawayPeriods.push({
                START: 8 * 60,
                END: 24 * 59.98,
                DESCRIPTION: this.p2rTranslateService.get(this.translateService.instant('service_1'))
            });
        }
        this.timeSlots = this.timeSlots.map(ts => (Object.assign({}, ts, { selected: ts.slot === (values[TAKEAWAY_FIELD_TYPES.KU_ASPORTO_SLOT_TIME] || 60) })));
        this.orderPrintingTypes = this.orderPrintingTypes.map(op => (Object.assign({}, op, { selected: op.type === values[TAKEAWAY_FIELD_TYPES.KU_ASPORTO_PRINT_ORDER] ? true : false })));
        this.selectedOrderPrintingType = this.orderPrintingTypes.findIndex(op => op.selected);
        this.timeBeforePrintingOrder = values[TAKEAWAY_FIELD_TYPES.KU_ASPORTO_TIME_TO_PRINT_ORDER_BEFORE_DELIVERY];
    }
    onClickEnableTakeaway() {
        this.enableTakeaway = !this.enableTakeaway;
        this.configurationService.formGroup.get(TAKEAWAY_FIELD_TYPES.KU_ASPORTO_ENABLED).setValue(this.enableTakeaway);
    }
    openChangeTimeSlot(index) {
        const startTime = this.convertMinutesToHourMinutes(this.takeawayPeriods[index].START);
        const endTime = this.convertMinutesToHourMinutes(this.takeawayPeriods[index].END);
        let dataToDialog = {
            type: GROUP_TYPE.TAKEAWAY,
            startTime: {
                hour: this.pad(parseInt(startTime.split(':')[0], 10)),
                minutes: this.pad(parseInt(startTime.split(':')[1], 10))
            },
            endTime: {
                hour: this.pad(parseInt(endTime.split(':')[0], 10)),
                minutes: this.pad(parseInt(endTime.split(':')[1], 10))
            }
        };
        const alert = this.p2rDialogService.open(P2RDialogComponent, {
            id: 'takeaway-timeslot',
            hasHead: false,
            body: TimeSlotAlertComponent,
            data: dataToDialog,
            size: "lg" /* LARGE */,
            actions: [
                {
                    label: this.p2rTranslateService.get(this.translateService.instant('dialog_cancel')),
                    click: () => {
                        alert.dismiss();
                    },
                    classes: 'btn rounded-pill text-uppercase new-alert-button font-r_l fs18',
                    data: {
                        remove: true
                    },
                    params: {}
                },
                {
                    label: this.p2rTranslateService.get(this.translateService.instant('dialog_confirm')),
                    click: (data) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                        if (dataToDialog.startTime.hour == null
                            || dataToDialog.endTime.hour == null
                            || dataToDialog.startTime.hour < 0
                            || dataToDialog.startTime.hour > 23
                            || dataToDialog.endTime.hour < 0
                            || dataToDialog.endTime.hour > 23) {
                            this.configurationService.showErrorAlert([
                                this.p2rTranslateService.get(this.translateService.instant('you_entered_an_incorrect_time_format')),
                                this.p2rTranslateService.get(this.translateService.instant('the_hours_must_be_between_0_and_23')),
                            ]);
                        }
                        else if (dataToDialog.startTime.minutes == null
                            || dataToDialog.endTime.minutes == null
                            || dataToDialog.startTime.minutes < 0
                            || dataToDialog.startTime.minutes > 59
                            || dataToDialog.endTime.minutes < 0
                            || dataToDialog.endTime.minutes > 59) {
                            this.configurationService.showErrorAlert([
                                this.p2rTranslateService.get(this.translateService.instant('you_entered_an_incorrect_time_format')),
                                this.p2rTranslateService.get(this.translateService.instant('the_minutes_must_be_between_0_and_59')),
                            ]);
                        }
                        else {
                            const startTime = dataToDialog.startTime.hour + ':' + dataToDialog.startTime.minutes;
                            const endTime = dataToDialog.endTime.hour + ':' + dataToDialog.endTime.minutes;
                            const startTimeM = moment(startTime, 'HH:mm');
                            const endTimeM = moment(endTime, 'HH:mm');
                            const startDayM = moment().clone().startOf('day');
                            const startMinutes = startTimeM.diff(startDayM, 'minutes');
                            const endMinutes = endTimeM.diff(startDayM, 'minutes');
                            const tp = _.cloneDeep(this.takeawayPeriods);
                            tp[index].START = startMinutes;
                            tp[index].END = endMinutes;
                            if ((tp.length > 1
                                && tp[0].END > tp[1].START)
                                || (tp[index].END < tp[index].START)) {
                                this.configurationService.showErrorAlert([
                                    this.p2rTranslateService.get(this.translateService.instant('the_durations_of_the_two_service_periods_overlap')),
                                    this.p2rTranslateService.get(this.translateService.instant('change_the_start_or_end_time')),
                                ]);
                            }
                            else {
                                let proceed = true;
                                if ((this.takeawayPeriods[index].START !== startMinutes) || (this.takeawayPeriods[index].END !== endMinutes)) {
                                    proceed = yield this.askProceed();
                                }
                                if (proceed) {
                                    this.takeawayPeriods[index].START = startMinutes;
                                    this.takeawayPeriods[index].END = endMinutes;
                                    alert.dismiss();
                                    this.configurationService.formGroup.get(TAKEAWAY_FIELD_TYPES.KU_ASPORTO_PERIODS)
                                        .setValue(this.takeawayPeriods);
                                }
                            }
                        }
                    }),
                    data: dataToDialog,
                    classes: 'btn rounded-pill text-uppercase new-alert-button font-r_l fs18',
                    params: {
                        submit: true
                    }
                }
            ]
        });
    }
    openChangeTimeSlotDescription(index) {
        const selectedTimeSlotDesc = this.takeawayPeriods[index].DESCRIPTION;
        const dataToDialog = {
            description: selectedTimeSlotDesc
        };
        const alert = this.p2rDialogService.open(P2RDialogComponent, {
            id: 'change-timeslot-description-dialog',
            hideCloseButton: true,
            title: this.p2rTranslateService.get(this.translateService.instant('change_description_dialog_title')),
            titleClasses: 'font-r_r fs24 lh-39 c11 text-uppercase',
            body: ChangeDescriptionDialogComponent,
            data: dataToDialog,
            size: "md" /* MEDIUM */,
            actions: [
                {
                    label: this.p2rTranslateService.get(this.translateService.instant('dialog_cancel')),
                    click: () => {
                        alert.dismiss();
                    },
                    classes: 'btn rounded-pill text-uppercase new-alert-button font-r_l fs18',
                    data: {
                        remove: true
                    },
                    params: {}
                },
                {
                    label: this.p2rTranslateService.get(this.translateService.instant('dialog_confirm')),
                    click: (data) => {
                        if (dataToDialog.description === undefined || dataToDialog.description.length === 0) {
                            console.log('Invalid value:\n' + JSON.stringify(data));
                        }
                        else {
                            alert.dismiss();
                            console.log('UPDATE DESC:\n' + JSON.stringify(data));
                            this.takeawayPeriods[index].DESCRIPTION = dataToDialog.description;
                            alert.dismiss();
                            this.configurationService.formGroup.get(TAKEAWAY_FIELD_TYPES.KU_ASPORTO_PERIODS)
                                .setValue(this.takeawayPeriods);
                        }
                    },
                    data: dataToDialog,
                    classes: 'btn rounded-pill text-uppercase new-alert-button font-r_l fs18',
                    params: {
                        submit: true
                    }
                }
            ]
        });
    }
    addTimeSlot() {
        const firstPeriod = this.takeawayPeriods[0];
        if (!firstPeriod.END) {
            this.configurationService.showErrorAlert([
                this.p2rTranslateService.get(this.translateService.instant('before_adding_the_second_period_it_is_necessary_to_configure_the_first'))
            ]);
        }
        else {
            this.takeawayPeriods = this.takeawayPeriods.concat({
                START: firstPeriod.END,
                END: 24 * 59.98,
                DESCRIPTION: this.p2rTranslateService.get(this.translateService.instant('service_2'))
            });
            this.configurationService.formGroup.get(TAKEAWAY_FIELD_TYPES.KU_ASPORTO_PERIODS)
                .setValue(this.takeawayPeriods);
        }
    }
    removeTimeSlot(index) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const proceed = yield this.askProceed();
            if (proceed) {
                this.takeawayPeriods.splice(index, 1);
                this.configurationService.formGroup.get(TAKEAWAY_FIELD_TYPES.KU_ASPORTO_PERIODS)
                    .setValue(this.takeawayPeriods);
            }
        });
    }
    convertMinutesToHourMinutes(totalMinutes) {
        let h = Math.floor(totalMinutes / 60);
        let hours;
        if (h < 10) {
            hours = "0" + h;
        }
        else {
            hours = h * 1;
        }
        let m = Math.floor(totalMinutes % 60);
        let minutes;
        if (m == 0) {
            minutes = "00";
        }
        else if (m < 10) {
            minutes = "0" + m;
        }
        else {
            minutes = m * 1;
        }
        return hours + ":" + minutes;
    }
    changeTimeSlot(slot) {
        this.configurationService.showErrorAlert([
            {
                text: this.p2rTranslateService.get(this.translateService.instant('you_are_changing_the_duration_of_the_time_slots')),
                class: 'mb-2'
            },
            this.p2rTranslateService.get(this.translateService.instant('make_sure_that_in_the_ARCHIVES_TAKEAWAY_PROCESSING_CENTERS_section_the_capacities_are_adequately_sized'))
        ]);
        this.timeSlots = this.timeSlots.map(ts => (Object.assign({}, ts, { selected: ts.slot === slot ? true : false })));
        this.configurationService.formGroup.get(TAKEAWAY_FIELD_TYPES.KU_ASPORTO_SLOT_TIME)
            .setValue(slot);
    }
    changeOrderPrintingType(type) {
        this.orderPrintingTypes = this.orderPrintingTypes.map(op => (Object.assign({}, op, { selected: op.type === type ? true : false })));
        this.selectedOrderPrintingType = this.orderPrintingTypes.findIndex(op => op.selected);
        this.configurationService.formGroup.get(TAKEAWAY_FIELD_TYPES.KU_ASPORTO_PRINT_ORDER)
            .setValue(type);
    }
    getTranslate(key) {
        switch (key) {
            case ORDER_PRINTING_TYPE.ON_ORDER:
                return 'TAKEAWAY.ORDER_PRINTING.ON_ORDER';
            case ORDER_PRINTING_TYPE.BEFORE_DELIVERY:
                return 'TAKEAWAY.ORDER_PRINTING.BEFORE_DELIVERY';
            default:
                return '';
        }
    }
    getPrinterCashLabel() {
        return this.casse.find(c => c.key === this.takeawayMainPrinterCash).label;
    }
    changeTimeBeforeDelivery() {
        const dataToDialog = {
            text: this.timeBeforePrintingOrder,
            input_title: this.p2rTranslateService.get(this.translateService.instant("set_how_long_before_delivery_print_the_order_minutes")),
        };
        const alert = this.p2rDialogService.open(P2RDialogComponent, {
            id: 'change-time-before-delivery-dialog',
            hideCloseButton: true,
            title: this.p2rTranslateService.get(this.translateService.instant("TAKEAWAY.ORDER_PRINTING.TITLE")),
            titleClasses: "font-r_r fs24 lh-39 c11 text-uppercase",
            body: ChangeTextDialogComponent,
            data: dataToDialog,
            size: "md" /* MEDIUM */,
            actions: [
                {
                    label: this.p2rTranslateService.get(this.translateService.instant("dialog_cancel")),
                    click: () => {
                        alert.dismiss();
                    },
                    classes: "btn rounded-pill text-uppercase new-alert-button font-r_l fs18",
                    data: {
                        remove: true,
                    },
                    params: {},
                },
                {
                    label: this.p2rTranslateService.get(this.translateService.instant("dialog_confirm")),
                    click: (data) => {
                        if (!dataToDialog.text || isNaN(+dataToDialog.text)) {
                            this.configurationService.showErrorAlert(this.p2rTranslateService.get(this.translateService.instant("not_valid_input_error")));
                        }
                        else {
                            alert.dismiss();
                            this.timeBeforePrintingOrder = dataToDialog.text;
                            this.configurationService.formGroup.get(TAKEAWAY_FIELD_TYPES.KU_ASPORTO_TIME_TO_PRINT_ORDER_BEFORE_DELIVERY).setValue(+dataToDialog.text);
                        }
                    },
                    data: dataToDialog,
                    classes: "btn rounded-pill text-uppercase new-alert-button font-r_l fs18",
                    params: {
                        submit: true,
                    },
                },
            ],
        });
    }
    chooseCassa() {
        const dialog = this.p2rDialogService.open(P2RDialogComponent, {
            id: 'customize-dialog',
            body: CustomizeDialogComponent,
            hideCloseButton: true,
            title: this.p2rTranslateService.get(this.translateService.instant('TAKEAWAY.SENDING_ORDERS.TITLE')),
            titleClasses: 'text-uppercase font-r_r fs24 lh21 c11 mt-2',
            size: 'lg',
            /*      options: {
              id: 'customize-for-store-opt',
              titleClasses: 'customize-for-store-height'
            },*/
            data: {
                hasSearch: true,
                customizeType: CUSTOMIZE_TYPE.SELECT_CASHDESK,
                items: this.casse.map(s => (Object.assign({}, s, { value: this.takeawayMainPrinterCash === s.key ? 1 : 0 }))),
                close: (key) => {
                    if (key) {
                        this.takeawayMainPrinterCash = key;
                        this.configurationService.formGroup.get(TAKEAWAY_FIELD_TYPES.KU_ASPORTO_PRINTER_CASH)
                            .setValue(key);
                    }
                    dialog.dismiss();
                },
            }
        });
    }
    filterCasseByStore() {
        const selectedPv = this.p2rLocalStorageService.getFromLocalStorage(GlobalVariable.SELECTED_PV_KEY_STORAGE, []);
        let casseTemp = [];
        this.casseAll.forEach(cassa => {
            if (cassa.XSF03 == selectedPv.codice) {
                casseTemp.push(cassa);
            }
        });
        this.casse = casseTemp;
    }
    pad(value) {
        if (value === 0 && String(value).length === 1) {
            return '0' + value;
        }
        else {
            if (!String(value).startsWith('0')) {
                if (value < 10) {
                    return '0' + value;
                }
                else {
                    return value;
                }
            }
            else {
                return value;
            }
        }
    }
}
