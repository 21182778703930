import { Component } from '@angular/core';

@Component({
  selector: 'configuration-empty-route',
  templateUrl: './configuration-empty-route.component.html',
  styleUrls: ['./configuration-empty-route.component.scss']
})
export class ConfigurationEmptyRouteComponent {

}
