import { Component, OnDestroy, OnInit } from "@angular/core";
import { checkIfKeyIsPresent } from "../configuration.utils";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { GROUP_TYPE, OFFLINE_WORK_FIELD_TYPES } from "../configuration.model";
import { ActivatedRoute } from "@angular/router";
import { ConfigurationService } from "../configuration.service";
import {
  P2RDialogComponent,
  P2RDialogService,
  P2RLocalStorageService,
  P2RTranslateService,
} from "@p2r/shared";
import { SIZE } from "@p2r/shared/lib/shared/p2r-dialog/p2r-dialog.interface";
import { TranslateService } from "@ngx-translate/core";
import { NumberDialogComponent } from "src/app/number-dialog/number-dialog.component";
import { P2RApiService } from "@p2r/platform-api";
import { GlobalVariable } from "src/app/global";
import { ReloadResolveService } from "src/reload-resolve/reload-resolve.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ConfigurationResolverOfflineWork } from "../configuration.resolve";
import { ChangeHostIpDialogComponent } from "src/app/change-host-ip-dialog/change-host-ip-dialog.component";
import { ConfigurationAlertComponent } from "../alert/alert.component";
import { Subject, Subscription } from "rxjs";
import { CUSTOMIZE_TYPE } from 'src/app/customize-dialog/customize-dialog.model';
import { CustomizeDialogComponent } from 'src/app/customize-dialog/customize-dialog.component';
@Component({
  selector: "configuration-custom-ku-offline-work",
  templateUrl: "./custom-ku-offline-work.component.html",
  styleUrls: ["./custom-ku-offline-work.component.scss"],
})
export class CustomKuOfflineWorkComponent implements OnInit, OnDestroy {
  private TEMPLATE_STORE_LOCAL_STORAGE = "TEMPLATE_STORE";
  private BROKERS_LOCAL_STORAGE = "BROKERS";

  public items: any; // {"MQTT":{"TITLE":{},"DESCRIPTION":{}}}
  private formGroup: FormGroup;

  private apiCode: number;
  private apiKeyGroup: number;
  private apiStore: string;

  private templateStoreApiValues: any; // {"p2rsync['enabled']":false,"KA_FATT_CASSA_OFFLINE":0,"KU_NEW_MQTT_CASH_DESK_MASTER": "99999_001"}
  private currentTemplateStoreValues: any;
  public currentStoreJsonTemplate: any;
  public kuOfflineWorkEnabled: any;
  public kuTableFattOffline: number;

  public brokerStoreApiValues: any; // {"codice":"BROKERS","store":"99999","key_group":"18","broker_hosts":[{"host":"10.10.56.170"}]}
  private currentBrokersValues: any;
  public kuBrokerIpHost1: string;
  private saveCompleteSubscription: Subscription;

  public KuNewMqttEnabled: boolean;
  public KuEnableSwitchMaster: boolean;
  private KuNewMqttMasterCashDesk: any;
  private KuNewMqttMasterCashDeskKey: any;

  casseAll: any[]; // Contiene le casse di tutti gli store
  casse: any[]; // Contiene le casse dello store attualmente selezionato
  private _changeStoreSubscription: Subscription;

  constructor(
    public configurationService: ConfigurationService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private p2rDialogService: P2RDialogService,
    private translateService: TranslateService,
    private p2rTranslateService: P2RTranslateService,
    private p2rLocalStorageService: P2RLocalStorageService,
    private p2rApiService: P2RApiService,
    public reloader: ReloadResolveService,
    private ngxService: NgxUiLoaderService,
    private resolver: ConfigurationResolverOfflineWork
  ) { }

  ngOnInit() {
    this.configurationService.selectedGroup = GROUP_TYPE.OFFLINE_WORK;
    this.configurationService.restoreCompleteSubject[GROUP_TYPE.OFFLINE_WORK] =
      new Subject<any>();

    console.log(this.configurationService.apiValues[GROUP_TYPE.OFFLINE_WORK]);


    console.log(this.KuNewMqttEnabled)

    this.route.data.subscribe(({ data }) => {
      this.ngxService.stopAll();

      const [items, apiValues, casse] = data;
      console.log(data);
      const group: any = {};
      this.casseAll = casse;
      this.filterCasseByStore();
      this.items = items;
      if (
        apiValues !== undefined &&
        apiValues.tmpl_store !== undefined &&
        apiValues.tmpl_store.jsontemplate == "{}"
      ) {
        apiValues.tmpl_store.jsontemplate = JSON.stringify(
          this.configurationService.apiValues[GROUP_TYPE.OFFLINE_WORK].values
        );
      }
      console.log("OFFLINE WORK apiValues = " + JSON.stringify(apiValues));
      console.log(this.configurationService.apiValues[GROUP_TYPE.OFFLINE_WORK].values);

      this.templateStoreApiValues = apiValues.tmpl_store;
      this.brokerStoreApiValues = apiValues.brokers;

      // console.log(this.templateStoreApiValues)
      // console.log(this.brokerStoreApiValues)

      this.apiCode = apiValues.tmpl_store.codice * 1;
      this.apiKeyGroup = apiValues.tmpl_store.key_group;
      const currentPv = this.p2rLocalStorageService.getFromLocalStorage(
        GlobalVariable.SELECTED_PV_KEY_STORAGE,
        []
      );
      if (currentPv !== undefined && currentPv.codice !== undefined) {
        this.apiStore = currentPv.codice;
      }

      // console.log(
      //   "OFFLINE WORK tmp store = " +
      //   JSON.stringify(this.templateStoreApiValues)
      // );
      // console.log(
      //   "OFFLINE WORK brokers = " + JSON.stringify(this.brokerStoreApiValues)
      // );
      // console.log("OFFLINE WORK codice = " + this.apiCode);
      // console.log("OFFLINE WORK key group = " + this.apiKeyGroup);
      // console.log("OFFLINE WORK store = " + this.apiStore);

      let [templateStoreLocaLStorage, brokerStoreLocalStorage] =
        this.getLocalStorage();
      if (
        templateStoreLocaLStorage === undefined &&
        brokerStoreLocalStorage === undefined
      ) {
        this.currentTemplateStoreValues = this.templateStoreApiValues;
        this.currentBrokersValues = this.brokerStoreApiValues;
      } else {
        this.currentTemplateStoreValues = templateStoreLocaLStorage;
        this.currentBrokersValues = brokerStoreLocalStorage;
        this.enableSavePanel(true);
      }

      this.currentStoreJsonTemplate = JSON.parse(
        this.currentTemplateStoreValues.jsontemplate
      );
      console.log(this.currentStoreJsonTemplate);
      console.log(this.currentTemplateStoreValues);
      this.kuOfflineWorkEnabled =
        this.currentStoreJsonTemplate[
        OFFLINE_WORK_FIELD_TYPES.OFFLINE_WORK_ENABLE
        ];
      this.kuTableFattOffline =
        this.currentStoreJsonTemplate[
        OFFLINE_WORK_FIELD_TYPES.CASSA_FATT_OFFLINE
        ];
      this.KuNewMqttEnabled =
        this.currentStoreJsonTemplate[
        OFFLINE_WORK_FIELD_TYPES.KU_NEW_MQTT_ENABLED
        ];
      this.KuEnableSwitchMaster =
        this.currentStoreJsonTemplate[
        OFFLINE_WORK_FIELD_TYPES.KU_NEW_MQTT_ELEGGI_NUOVO_MASTER_ENABLED
        ];
      this.KuNewMqttMasterCashDesk = this.currentStoreJsonTemplate[OFFLINE_WORK_FIELD_TYPES.CASSA_MASTER];
      if (this.KuNewMqttMasterCashDesk == undefined) {
        this.KuNewMqttMasterCashDesk = this.apiStore + "_001";
        group[OFFLINE_WORK_FIELD_TYPES.CASSA_MASTER] = new FormControl(this.KuNewMqttMasterCashDesk, {
          validators: [],
          asyncValidators: [],
          updateOn: "blur",
        });
      }
      group[OFFLINE_WORK_FIELD_TYPES.KU_NEW_MQTT_ENABLED] = new FormControl(this.KuNewMqttEnabled, {
        validators: [],
        asyncValidators: [],
        updateOn: "blur",
      });
      group[OFFLINE_WORK_FIELD_TYPES.KU_NEW_MQTT_ELEGGI_NUOVO_MASTER_ENABLED] = new FormControl(this.KuEnableSwitchMaster, {
        validators: [],
        asyncValidators: [],
        updateOn: "blur",
      });
      try {
        this.KuNewMqttMasterCashDeskKey = this.KuNewMqttMasterCashDesk.split("_")[1].replace(/^0+/, '');
      } catch (e) {
        console.error(e);
      }
      if (this.currentBrokersValues.broker_hosts.length > 0) {
        this.kuBrokerIpHost1 = this.currentBrokersValues.broker_hosts[0].host;
      }

      console.log("OFFLINE WORK cassa master nuovo mqtt = " + this.KuNewMqttMasterCashDesk + " (" + this.KuNewMqttMasterCashDeskKey + ")");

      Object.keys(this.currentStoreJsonTemplate).forEach((key) => {
        group[key] = new FormControl(this.currentStoreJsonTemplate[key], {
          validators: [],
          asyncValidators: [],
          updateOn: "blur",
        });
      });
      group["host_0"] = new FormControl(this.kuBrokerIpHost1, {
        validators: [],
        asyncValidators: [],
        updateOn: "blur",
      });
      this.formGroup = this.formBuilder.group(group);
      this.enableFormValuesChangeDetection();

      this.saveCompleteSubscription = this.configurationService.saveCompleteSubject.subscribe((res) => {
        console.log("OFFLINE WORK save complete = " + res);
        if (res) {
          this.ngxService.stopAll();
          this.resolver
            .getValues(GROUP_TYPE.OFFLINE_WORK, this.apiStore)
            .subscribe((res) => {
              if (res) {
                // Template Store
                let tmplStore = JSON.parse(res.tmpl_store.jsontemplate);
                this.templateStoreApiValues = res.tmpl_store;
                this.currentStoreJsonTemplate = tmplStore;
                this.kuOfflineWorkEnabled =
                  tmplStore[OFFLINE_WORK_FIELD_TYPES.OFFLINE_WORK_ENABLE];
                this.kuTableFattOffline =
                  tmplStore[OFFLINE_WORK_FIELD_TYPES.CASSA_FATT_OFFLINE];
                this.KuNewMqttMasterCashDesk = tmplStore[OFFLINE_WORK_FIELD_TYPES.CASSA_MASTER];
                if (this.KuNewMqttMasterCashDesk == undefined) {
                  this.KuNewMqttMasterCashDesk = this.apiStore + "_001";
                }
                try {
                  this.KuNewMqttMasterCashDeskKey = this.KuNewMqttMasterCashDesk.split("_")[1].replace(/^0+/, '');
                } catch (e) {
                  console.error(e);
                }
                //

                // Brokers
                let brokerStore = res.brokers;
                if (brokerStore.broker_hosts.length > 0) {
                  this.kuBrokerIpHost1 = brokerStore.broker_hosts[0].host;
                } else {
                  this.kuBrokerIpHost1 = "";
                }
                this.brokerStoreApiValues = brokerStore;
                // Svuoto local storage
                this.setAPI(false);
              }
            });
        }
      });

      this.configurationService.restoreCompleteSubject[
        GROUP_TYPE.OFFLINE_WORK
      ].subscribe((r) => {
        console.log("OFFLINE WORK restore complete = " + r);
        if (r) {
          this.ngxService.start("offline-restore");
          this.resolver
            .getValues(GROUP_TYPE.OFFLINE_WORK, this.apiStore)
            .subscribe((res) => {
              if (res) {
                console.log(
                  "OFFLINE WORK resolver values = " + JSON.stringify(res)
                );
                this.templateStoreApiValues = res.tmpl_store;
                this.currentStoreJsonTemplate = JSON.parse(
                  this.templateStoreApiValues.jsontemplate
                );
                this.kuOfflineWorkEnabled =
                  this.currentStoreJsonTemplate[
                  OFFLINE_WORK_FIELD_TYPES.OFFLINE_WORK_ENABLE
                  ];
                this.kuTableFattOffline =
                  this.currentStoreJsonTemplate[
                  OFFLINE_WORK_FIELD_TYPES.CASSA_FATT_OFFLINE
                  ];
                this.KuNewMqttMasterCashDesk = this.currentStoreJsonTemplate[OFFLINE_WORK_FIELD_TYPES.CASSA_MASTER];
                this.KuEnableSwitchMaster = this.currentStoreJsonTemplate[OFFLINE_WORK_FIELD_TYPES.KU_NEW_MQTT_ELEGGI_NUOVO_MASTER_ENABLED];

                if (this.KuNewMqttMasterCashDesk == undefined) {
                  this.KuNewMqttMasterCashDesk = this.apiStore + "_001";
                }
                try {
                  this.KuNewMqttMasterCashDeskKey = this.KuNewMqttMasterCashDesk.split("_")[1].replace(/^0+/, '');
                } catch (e) {
                  console.error(e);
                }
                this.brokerStoreApiValues = res.brokers;
                if (this.brokerStoreApiValues.broker_hosts.length > 0) {
                  this.kuBrokerIpHost1 =
                    this.brokerStoreApiValues.broker_hosts[0].host;
                } else {
                  this.kuBrokerIpHost1 = "";
                }
                this.ngxService.stop("offline-restore");
                // Svuoto local storage
                this.setAPI(false);
              }
            });
        }
      });

      this._changeStoreSubscription = this.configurationService.changeStoreCompleteSubject.subscribe((r) => {
        console.log("OFFLINE WORK change store complete = " + r);
        if (r) {
          this.changeStoreComplete();
        }
      });
    });
  }

  private getLocalStorage() {
    let templateStoreLocalStorage = undefined;
    let brokerStoreLocalStorage = undefined;

    const t = this.p2rLocalStorageService.getFromLocalStorage(
      this.TEMPLATE_STORE_LOCAL_STORAGE,
      [GROUP_TYPE.OFFLINE_WORK]
    );
    if (t !== undefined) {
      templateStoreLocalStorage = t.values;
    }
    const b = this.p2rLocalStorageService.getFromLocalStorage(
      this.BROKERS_LOCAL_STORAGE,
      [GROUP_TYPE.OFFLINE_WORK]
    );
    if (b !== undefined) {
      brokerStoreLocalStorage = b.values;
    }

    return [templateStoreLocalStorage, brokerStoreLocalStorage];
  }

  private compareValues(apiValue, formValue) {
    if (formValue === undefined) {
      return false;
    }
    if (apiValue != formValue) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Check if at least one of other sections have changes.
   * @returns
   */
  private thereAreOtherChanges() {
    // Check Configuration
    const localConfValues =
      this.p2rLocalStorageService.getFromLocalStorage("CONFIGURATION");
    if (localConfValues !== undefined) {
      for (let [key, value] of Object.entries(localConfValues)) {
        if (value !== undefined && value["values"] !== undefined) {
          console.log("Found at least one category with changes: " + key);
          return true;
        }
      }
    }

    const localServiceValues = this.p2rLocalStorageService.getFromLocalStorage(
      "SERVICES",
      ["SERVIZI"]
    );
    if (localServiceValues !== undefined) {
      if (Object.keys(localServiceValues).length > 0) {
        console.log("SERVICES has changes");
        return true;
      }
    }

    const localFattureValues = this.p2rLocalStorageService.getFromLocalStorage(
      "FATTURE",
      ["DOCUMENTS"]
    );
    if (localFattureValues !== undefined) {
      if (Object.keys(localFattureValues).length > 0) {
        console.log("FATTURE has changes");
        return true;
      }
    }
    const localGeneraliValues = this.p2rLocalStorageService.getFromLocalStorage(
      "TEMPLATE_STORE_GENERALI",
      ["GENERALI"]
    );
    if (localGeneraliValues !== undefined) {
      if (Object.keys(localGeneraliValues).length > 0) {
        console.log("FATTURE has changes");
        return true;
      }
    }

    return false;
  }

  private enableSavePanel(thereIsChanges) {
    if (thereIsChanges === false) {
      this.configurationService.doCheck(this.thereAreOtherChanges());
    } else {
      this.configurationService.doCheck(true);
    }
    if (thereIsChanges) {
      // aggiorno modifiedGroups
      this.configurationService.modifiedGroups[GROUP_TYPE.OFFLINE_WORK] =
        "CHANGE";
      // invio modifiedGroups dentro lo stream
      this.configurationService._modifiedGroups$.next(
        this.configurationService.modifiedGroups
      );
    } else {
      // cancello i dati relativi al gruppo dentro modifiedGroups
      delete this.configurationService.modifiedGroups[GROUP_TYPE.OFFLINE_WORK];
      // invio modifiedGroups dentro lo stream
      this.configurationService._modifiedGroups$.next(
        this.configurationService.modifiedGroups
      );
    }
  }

  private setAPI(change) {
    this.configurationService.saveAllList[GROUP_TYPE.OFFLINE_WORK] = [];
    if (change) {
      const jsonTemplateToSend = {};
      jsonTemplateToSend[OFFLINE_WORK_FIELD_TYPES.OFFLINE_WORK_ENABLE] =
        this.kuOfflineWorkEnabled;
      jsonTemplateToSend[OFFLINE_WORK_FIELD_TYPES.CASSA_FATT_OFFLINE] =
        this.kuTableFattOffline;
      jsonTemplateToSend[OFFLINE_WORK_FIELD_TYPES.CASSA_MASTER] =
        this.KuNewMqttMasterCashDesk;
      jsonTemplateToSend[OFFLINE_WORK_FIELD_TYPES.KU_NEW_MQTT_ENABLED] =
        this.KuNewMqttEnabled;
      jsonTemplateToSend[OFFLINE_WORK_FIELD_TYPES.KU_NEW_MQTT_ELEGGI_NUOVO_MASTER_ENABLED] =
        this.KuEnableSwitchMaster;

      const storeTemplate = {};
      storeTemplate["jsontemplate"] = JSON.stringify(jsonTemplateToSend);
      storeTemplate["key_group"] = this.apiKeyGroup;
      if (storeTemplate !== undefined) {
        storeTemplate["store"] = this.apiStore;
        if (this.apiCode === 0) {
          storeTemplate["codice"] = "0";
          this.configurationService.saveAllList[GROUP_TYPE.OFFLINE_WORK].push(
            this.p2rApiService.post(
              `${this.p2rApiService.apiCorePath}/tmpstore/operatore`,
              undefined,
              storeTemplate
            )
          );
        } else {
          storeTemplate["codice"] = this.apiCode.toString();
          this.configurationService.saveAllList[GROUP_TYPE.OFFLINE_WORK].push(
            this.p2rApiService.put(
              `${this.p2rApiService.apiCorePath}/tmpstore/operatore`,
              undefined,
              storeTemplate
            )
          );
        }
        this.p2rLocalStorageService.addToLocalStorage(
          this.TEMPLATE_STORE_LOCAL_STORAGE,
          [GROUP_TYPE.OFFLINE_WORK, "values"],
          storeTemplate
        );
      }
      let brokerTemplate = {};
      brokerTemplate["broker_hosts"] = [];
      if (
        this.kuBrokerIpHost1 !== undefined &&
        this.kuBrokerIpHost1.length > 0
      ) {
        let m = { host: this.kuBrokerIpHost1 };
        brokerTemplate["broker_hosts"].push(m);
      }
      brokerTemplate["store"] = this.apiStore;
      this.p2rLocalStorageService.addToLocalStorage(
        this.BROKERS_LOCAL_STORAGE,
        [GROUP_TYPE.OFFLINE_WORK, "values"],
        brokerTemplate
      );
      this.configurationService.saveAllList[GROUP_TYPE.OFFLINE_WORK].push(
        this.p2rApiService.post(
          `${this.p2rApiService.apiCorePath}/brokers/update`,
          undefined,
          brokerTemplate
        )
      );
    } else {
      if (
        this.configurationService.saveAllList[GROUP_TYPE.OFFLINE_WORK] !==
        undefined
      ) {
        this.configurationService.saveAllList[GROUP_TYPE.OFFLINE_WORK] = [];
      }
      this.p2rLocalStorageService.removeFromLocalStorage(
        this.TEMPLATE_STORE_LOCAL_STORAGE,
        [GROUP_TYPE.OFFLINE_WORK, "values"]
      );
      this.p2rLocalStorageService.removeFromLocalStorage(
        this.BROKERS_LOCAL_STORAGE,
        [GROUP_TYPE.OFFLINE_WORK, "values"]
      );
    }
  }

  public checkIfKeyIsPresent(array, key) {
    const v = checkIfKeyIsPresent(array, key);
    return v;
  }

  /**
 * Filtra la lista casseAll creando una nuova lista con le sole casse dello
 * store corrente.
 */
  public filterCasseByStore() {
    const selectedPv = this.p2rLocalStorageService.getFromLocalStorage(
      GlobalVariable.SELECTED_PV_KEY_STORAGE,
      []
    );
    let casseTemp = [];
    this.casseAll.forEach(cassa => {
      if (cassa.XSF03 == selectedPv.codice) {
        casseTemp.push(cassa);
      }
    });
    this.casse = casseTemp;
  }

  public getMasterCashLabel(section: string) {
    if (section === 'KU_CASSA_FATTURE_OFFLINE') {
      if (this.kuTableFattOffline && this.kuTableFattOffline != 0) {
        const cassaFattureSelected = this.casse.find(c => c.key === this.kuTableFattOffline.toString());
        return cassaFattureSelected.label;
      } else {
        return '';
      }
    } else if (section === 'KU_NEW_MQTT_ELEGGI_NUOVO_MASTER_ENABLED') {
      if (this.KuNewMqttMasterCashDesk == undefined) {
        this.KuNewMqttMasterCashDesk = this.apiStore + "_001";
      }
      const codCassa = this.KuNewMqttMasterCashDesk.split("_");
      if (codCassa.length == 2) {
        const keyCassa = codCassa[1].replace(/^0+/, '');
        const cassaSelected = this.casse.find(c => c.key === keyCassa);
        return cassaSelected.label;
      }
    }
  }

  public getCashDeskLabel() {

  }

  public chooseCassaMaster(section: string) {

    const title = section === 'KU_NEW_MQTT_ELEGGI_NUOVO_MASTER_ENABLED'
      ? this.p2rTranslateService.get(this.translateService.instant('OFFLINE.CASSA_MASTER.TITLE'))
      : this.p2rTranslateService.get(this.translateService.instant('OFFLINE.TABLE_FATT.TITLE'))

    const dialog = this.p2rDialogService.open(P2RDialogComponent, {
      id: 'customize-dialog',
      body: CustomizeDialogComponent,
      hideCloseButton: true,
      title,
      titleClasses: 'text-uppercase font-r_r fs24 lh21 c11 mt-2',
      size: 'lg',
      data: {
        message: section === 'KU_NEW_MQTT_ELEGGI_NUOVO_MASTER_ENABLED' ? true : false,
        hasSearch: true,
        customizeType: CUSTOMIZE_TYPE.SELECT_CASHDESK,
        items: this.casse.map(s => ({
          ...s,
          value: section === 'KU_NEW_MQTT_ELEGGI_NUOVO_MASTER_ENABLED' && this.KuNewMqttMasterCashDeskKey === s.key ? 1 : section === 'KU_CASSA_FATTURE_OFFLINE' && this.kuTableFattOffline === s.key ? 1 : 0
        })),
        close: (key) => {
          if (key) {
            if (section === 'KU_CASSA_FATTURE_OFFLINE') {
              this.kuTableFattOffline = key;
              // this.KuNewMqttMasterCashDesk = this.apiStore + "_" + this.KuNewMqttMasterCashDeskKey.padStart(3, '0');
              this.formGroup.get(OFFLINE_WORK_FIELD_TYPES.CASSA_FATT_OFFLINE).setValue(this.kuTableFattOffline);
            } else if (section === 'KU_NEW_MQTT_ELEGGI_NUOVO_MASTER_ENABLED') {
              this.KuNewMqttMasterCashDeskKey = key;
              this.KuNewMqttMasterCashDesk = this.apiStore + "_" + this.KuNewMqttMasterCashDeskKey.padStart(3, '0');
              console.log("MQTT new master: " + this.KuNewMqttMasterCashDesk);
              this.formGroup.get(OFFLINE_WORK_FIELD_TYPES.CASSA_MASTER).setValue(this.KuNewMqttMasterCashDesk);
            }
          }
          dialog.dismiss();
        },
      }
    });
  }

  onClickAbilitaMqtt() {
    this.kuOfflineWorkEnabled = !this.kuOfflineWorkEnabled;
    this.formGroup
      .get(OFFLINE_WORK_FIELD_TYPES.OFFLINE_WORK_ENABLE)
      .setValue(this.kuOfflineWorkEnabled);
  }

  public openNumberDialog() {
    const dialog = this.p2rDialogService.open(P2RDialogComponent, {
      id: 'customize-dialog',
      body: CustomizeDialogComponent,
      hideCloseButton: true,
      title: this.p2rTranslateService.get(this.translateService.instant('OFFLINE.CASSA_MASTER.TITLE')),
      titleClasses: 'text-uppercase font-r_r fs24 lh21 c11 mt-2',
      size: 'lg',
      data: {
        hasSearch: true,
        customizeType: CUSTOMIZE_TYPE.SELECT_CASHDESK,
        items: this.casse.map(s => ({
          ...s,
          value: this.KuNewMqttMasterCashDeskKey === s.key ? 1 : 0
        })),
        close: (key) => {
          if (key) {
            this.kuTableFattOffline = key;
            // this.KuNewMqttMasterCashDesk = this.apiStore + "_" + this.KuNewMqttMasterCashDeskKey.padStart(3, '0');
            this.formGroup.get(OFFLINE_WORK_FIELD_TYPES.CASSA_FATT_OFFLINE).setValue(this.kuTableFattOffline);
          }
          dialog.dismiss();
        },
      },
    });
  }

  changeBrokerHost() {
    let ipAdress = this.kuBrokerIpHost1;
    const dataToDialog = {
      ip: ipAdress,
    };
    const alert = this.p2rDialogService.open(P2RDialogComponent, {
      id: 'change-host-ip-dialog',
      hideCloseButton: true,
      title: this.p2rTranslateService.get(
        this.translateService.instant("ip_host")
      ),
      titleClasses: "font-r_r fs24 lh-39 c11",
      body: ChangeHostIpDialogComponent,
      data: dataToDialog,
      size: SIZE.MEDIUM,
      actions: [
        {
          label: this.p2rTranslateService.get(
            this.translateService.instant("dialog_cancel")
          ),
          click: () => {
            alert.dismiss();
          },
          classes: "btn rounded-pill text-uppercase new-alert-button font-r_l fs18",
          data: {
            remove: true,
          },
          params: {},
        },
        {
          label: this.p2rTranslateService.get(
            this.translateService.instant("dialog_confirm")
          ),
          click: (data) => {
            if (this.validateIPaddress(dataToDialog.ip)) {
              alert.dismiss();
              console.log(
                "OFFLINE WORK openChangeBrokerHost = " + JSON.stringify(data)
              );
              this.kuBrokerIpHost1 =
                dataToDialog.ip !== undefined && dataToDialog.ip !== "-"
                  ? dataToDialog.ip
                  : "";
              this.formGroup.get("host_0").setValue(this.kuBrokerIpHost1);
            } else {
              this.showErrorAlert(
                this.p2rTranslateService.get(
                  this.translateService.instant("ip_not_valid_error")
                )
              );
            }
          },
          data: dataToDialog,
          classes: "btn rounded-pill text-uppercase new-alert-button font-r_l fs18",
          params: {
            submit: true,
          },
        },
      ],
    });
  }

  /**
   * Check if string is IP.
   * @param str
   * @returns true if string is a valid IP, false otherwise.
   */
  validateIPaddress(str) {
    // Regular expression to check if string is a IP address
    const regexExp =
      /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/gi;

    return regexExp.test(str);
  }

  public showErrorAlert(msg) {
    const alert = this.p2rDialogService.open(P2RDialogComponent, {
      id: 'show-error-dialog',
      body: ConfigurationAlertComponent,
      hasHead: false,
      data: {
        imgSrc: this.configurationService.getImg("icons/attenzione.svg"),
        title: this.p2rTranslateService.get(
          this.translateService.instant("attention")
        ),
        messages: [msg],
      },
      actions: [
        {
          label: this.p2rTranslateService.get(
            this.translateService.instant("close")
          ),
          click: () => {
            alert.dismiss();
          },
          classes: "btn rounded-pill text-uppercase new-alert-button",
          data: {
            remove: true,
          },
          params: {
            position: "right",
          },
        },
      ],
    });
  }

  private enableFormValuesChangeDetection() {
    this.formGroup.valueChanges.subscribe((value) => {
      // console.log('OFFLINE WORK broker obj = ' + JSON.stringify(this.brokerStoreApiValues));
      // console.log('OFFLINE WORK form value = ' + JSON.stringify(value));

      const currentKuOfflineWorkEnabled =
        value[OFFLINE_WORK_FIELD_TYPES.OFFLINE_WORK_ENABLE];
      let apiValue =
        this.currentStoreJsonTemplate[
        OFFLINE_WORK_FIELD_TYPES.OFFLINE_WORK_ENABLE
        ];
      const compareOfflineWorkValue = this.compareValues(
        apiValue,
        currentKuOfflineWorkEnabled
      );

      const currentKuTableFattOffline =
        value[OFFLINE_WORK_FIELD_TYPES.CASSA_FATT_OFFLINE];
      apiValue =
        this.currentStoreJsonTemplate[
        OFFLINE_WORK_FIELD_TYPES.CASSA_FATT_OFFLINE
        ];
      const compareKuTableFattOffline = this.compareValues(
        apiValue,
        currentKuTableFattOffline
      );

      const currentKuNewMqttMasterCash = value[OFFLINE_WORK_FIELD_TYPES.CASSA_MASTER];
      apiValue = this.currentStoreJsonTemplate[OFFLINE_WORK_FIELD_TYPES.CASSA_MASTER];
      const compareKuNewMqttMasterCash = this.compareValues(apiValue, currentKuNewMqttMasterCash);

      const currentKuNewMqttEnabled = value[OFFLINE_WORK_FIELD_TYPES.KU_NEW_MQTT_ENABLED];
      apiValue = this.currentStoreJsonTemplate[OFFLINE_WORK_FIELD_TYPES.KU_NEW_MQTT_ENABLED];
      const compareKuNewMqttEnabled = this.compareValues(apiValue, currentKuNewMqttEnabled);

      const currentKuEnableSwitchMaster = value[OFFLINE_WORK_FIELD_TYPES.KU_NEW_MQTT_ELEGGI_NUOVO_MASTER_ENABLED];
      apiValue = this.currentStoreJsonTemplate[OFFLINE_WORK_FIELD_TYPES.KU_NEW_MQTT_ELEGGI_NUOVO_MASTER_ENABLED];
      const compareKuEnableSwitchMaster = this.compareValues(apiValue, currentKuEnableSwitchMaster);

      let currentBrokerIp = value.host_0;
      if (currentBrokerIp === null) {
        currentBrokerIp = undefined;
      }
      apiValue = "";
      if (this.brokerStoreApiValues.broker_hosts.length > 0) {
        apiValue = this.brokerStoreApiValues.broker_hosts[0].host;
      }
      const compareBrokerIp = this.compareValues(apiValue, currentBrokerIp);

      const thereIsChanges =
        compareOfflineWorkValue || compareKuTableFattOffline || compareKuNewMqttMasterCash || compareKuNewMqttEnabled || compareKuEnableSwitchMaster || compareBrokerIp;
      this.setAPI(thereIsChanges);
      this.enableSavePanel(thereIsChanges);
    });
  }

  private changeStoreComplete() {
    const currentPv = this.p2rLocalStorageService.getFromLocalStorage(
      GlobalVariable.SELECTED_PV_KEY_STORAGE,
      []
    );
    if (currentPv !== undefined && currentPv.codice !== undefined) {
      this.apiStore = currentPv.codice;
    }
    console.log("OFFLINE WORK store = " + this.apiStore);
    this.ngxService.start();
    this.resolver
      .getValues(GROUP_TYPE.OFFLINE_WORK, this.apiStore)
      .subscribe((res) => {
        if (res) {
          console.log("OFFLINE WORK resolver values = " + JSON.stringify(res));
          if (
            res !== undefined &&
            res.tmpl_store !== undefined &&
            res.tmpl_store.jsontemplate == "{}"
          ) {
            res.tmpl_store.jsontemplate = JSON.stringify(
              this.configurationService.apiValues[GROUP_TYPE.OFFLINE_WORK]
                .values
            );
          }
          this.apiCode = res.tmpl_store.codice * 1;
          this.apiKeyGroup = res.tmpl_store.key_group;
          console.log("OFFLINE WORK codice = " + this.apiCode);
          console.log("OFFLINE WORK key group = " + this.apiKeyGroup);
          console.log("OFFLINE WORK store = " + this.apiStore);
          this.currentStoreJsonTemplate = JSON.parse(
            res.tmpl_store.jsontemplate
          );
          this.currentTemplateStoreValues = res.tmpl_store;
          this.brokerStoreApiValues = res.brokers;
          this.ngxService.stopAll();
          this.setAPI(false);
          this.kuOfflineWorkEnabled =
            this.currentStoreJsonTemplate[
            OFFLINE_WORK_FIELD_TYPES.OFFLINE_WORK_ENABLE
            ];
          this.kuTableFattOffline =
            this.currentStoreJsonTemplate[
            OFFLINE_WORK_FIELD_TYPES.CASSA_FATT_OFFLINE
            ];
          this.KuNewMqttMasterCashDesk = this.currentStoreJsonTemplate[OFFLINE_WORK_FIELD_TYPES.CASSA_MASTER];
          this.KuEnableSwitchMaster = this.currentStoreJsonTemplate[OFFLINE_WORK_FIELD_TYPES.KU_NEW_MQTT_ELEGGI_NUOVO_MASTER_ENABLED];
          if (this.KuNewMqttMasterCashDesk == undefined) {
            this.KuNewMqttMasterCashDesk = this.apiStore + "_001";
          }
          try {
            this.KuNewMqttMasterCashDeskKey = this.KuNewMqttMasterCashDesk.split("_")[1].replace(/^0+/, '');
          } catch (e) {
            console.error(e);
          }
          if (
            this.brokerStoreApiValues.broker_hosts !== undefined &&
            this.brokerStoreApiValues.broker_hosts.length > 0
          ) {
            this.kuBrokerIpHost1 =
              this.brokerStoreApiValues.broker_hosts[0].host;
          } else {
            this.kuBrokerIpHost1 = "";
          }
          const group: any = {};
          Object.keys(this.currentStoreJsonTemplate).forEach((key) => {
            group[key] = new FormControl(this.currentStoreJsonTemplate[key], {
              validators: [],
              asyncValidators: [],
              updateOn: "blur",
            });
          });
          group["host_0"] = new FormControl(this.kuBrokerIpHost1, {
            validators: [],
            asyncValidators: [],
            updateOn: "blur",
          });
          this.formGroup = this.formBuilder.group(group);
          this.enableFormValuesChangeDetection();
        }
      });
  }

  public onClickEnableNewMqtt() {
    this.KuNewMqttEnabled = !this.KuNewMqttEnabled;
    this.formGroup.get(OFFLINE_WORK_FIELD_TYPES.KU_NEW_MQTT_ENABLED).setValue(this.KuNewMqttEnabled);
  }

  public onClickEnableSwitchMaster() {
    this.KuEnableSwitchMaster = !this.KuEnableSwitchMaster;
    this.formGroup.get(OFFLINE_WORK_FIELD_TYPES.KU_NEW_MQTT_ELEGGI_NUOVO_MASTER_ENABLED).setValue(this.KuEnableSwitchMaster);
  }

  ngOnDestroy(): void {
    if (this.saveCompleteSubscription) {
      this.saveCompleteSubscription.unsubscribe()
    }
    if (this._changeStoreSubscription) {
      this._changeStoreSubscription.unsubscribe()
    }
  }
}
