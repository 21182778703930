import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { P2RDialogComponent, P2RDialogService, P2RTranslateService, P2R_FIELD_TYPE } from '@p2r/shared';
import { Subject, Subscription } from 'rxjs';
import { CustomizeDialogComponent } from 'src/app/customize-dialog/customize-dialog.component';
import { GROUP_TYPE, LANGUAGE_CURRENCY_FIELD_TYPES } from '../configuration.model';
import { ConfigurationService } from '../configuration.service';
import { TranslateService } from "@ngx-translate/core";
import { CUSTOMIZE_TYPE } from 'src/app/customize-dialog/customize-dialog.model';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';

@Component({
  selector: 'configuration-language-currency',
  templateUrl: './language-currency.component.html',
  styleUrls: ['./language-currency.component.scss']
})
export class LanguageCurrencyComponent implements OnInit {

  private apiValues: any;
  private values: any;
  private items: any;
  public kuUserLanguage: string;
  private formGroup: FormGroup;
  private configurationValuesSubscription: Subscription;
  public kuPrinterLanguage: string;
  public kuDefaultCurrency: string;
  private typegrop: GROUP_TYPE;

  constructor(
    public configurationService: ConfigurationService,
    private p2rDialogService: P2RDialogService,
    private translateService: TranslateService,
    private p2rTranslateService: P2RTranslateService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private localStorageService: LocalStorageService,
  ) {
    this.typegrop = GROUP_TYPE.LANGUAGE_CURRENCY
  }

  ngOnInit() {

    this.configurationService.selectedGroup = this.typegrop;

    this.apiValues = this.configurationService.apiValues[GROUP_TYPE.LANGUAGE_CURRENCY].values;

    this.route.data.subscribe(({ data }) => {
      const [items, values] = data;

      this.items = items;
      this.values = values;

      console.log(this.values)

      // Assegnazioni
      this.kuPrinterLanguage = this.values[LANGUAGE_CURRENCY_FIELD_TYPES.KU_PRINTER_LANGUAGE];
      this.kuDefaultCurrency = this.values[LANGUAGE_CURRENCY_FIELD_TYPES.KU_DEFAULT_CURRENCY];
      this.kuUserLanguage = this.convertLabel(this.localStorageService.retrieve('lingua'), true, true);

      // Creazione del form group
      const group: any = {};

      Object.keys(values).forEach(key => {
        group[key] = new FormControl(values[key], {
          validators: [],
          asyncValidators: [],
          updateOn: 'blur'
        });
      });

      this.configurationService.formGroup = this.formBuilder.group(group);
    });

    this.configurationService.groups$().subscribe(groups => {
      const found = groups.find(g => g.cod === GROUP_TYPE.LANGUAGE_CURRENCY);
      this.items = found && found.items || [];
    });

    this.configurationService.values$().subscribe(values => {
      values = values[GROUP_TYPE.LANGUAGE_CURRENCY];
      if (values !== undefined) {
        if (this.configurationService._selectedGroup === GROUP_TYPE.LANGUAGE_CURRENCY) {

          this.kuPrinterLanguage = values.KU_PRINTER_LANGUAGE;
          this.kuDefaultCurrency = values.KU_DEFAULT_CURRENCY;

        }
      }
    });

  }

  public openPrinterLanguageDialog() {

    var languageAvailable = [
      {
        key: '',
        label: this.p2rTranslateService.get(this.translateService.instant('DEFAULT_LANGUAGE')),
        note: this.p2rTranslateService.get(this.translateService.instant('default_label_description')),
        fieldType: P2R_FIELD_TYPE.CHECK,
        selected: false
      },
      {
        key: 'IT',
        label: this.p2rTranslateService.get(this.translateService.instant('IT')),
        fieldType: P2R_FIELD_TYPE.CHECK,
        selected: false
      },
      {
        key: 'EN',
        label: this.p2rTranslateService.get(this.translateService.instant('EN')),
        fieldType: P2R_FIELD_TYPE.CHECK,
        selected: false
      },
      {
        key: 'DE',
        label: this.p2rTranslateService.get(this.translateService.instant('DE')),
        fieldType: P2R_FIELD_TYPE.CHECK,
        selected: false
      }
    ]

    languageAvailable.reduce((acc, curr) => {
      curr.key == this.kuPrinterLanguage ? curr.selected = true : false
      acc.push(curr)
      return acc
    }, [])

    const dialogData = {
      hasSearch: false,
      customizeType: CUSTOMIZE_TYPE.PRINTER_LANGUAGE,
      items: languageAvailable
    }

    this.p2rDialogService.open(P2RDialogComponent, {
      id: 'lst-periodo-dialog',
      body: CustomizeDialogComponent,
      // hideCloseButton: true,
      title: this.p2rTranslateService.get(this.translateService.instant('LANGUAGE_CURRENCY.PRINTER_LANGUAGE.TITLE')),
      titleClasses: 'text-uppercase font-r_r fs24 lh21 c11 mt-2',
      size: 'lg',
      keyboard: false,
      closeOnOutsideClick: false,
      closeButton: {
        label: this.p2rTranslateService.get(this.translateService.instant('close')),
        buttonClass: 'btn btn-primary rounded-pill lp-bottom-btn',
        labelClass: 'text-uppercase font-r_l fs18 lh21',
        click: () => {
          const languageSelected = dialogData.items.filter((el) => el.selected).map((el) => el.key)
          this.kuPrinterLanguage = languageSelected[0]
          this.configurationService.formGroup.get(LANGUAGE_CURRENCY_FIELD_TYPES.KU_PRINTER_LANGUAGE).setValue(this.kuPrinterLanguage);
          this.cdRef.detectChanges();
        }
      },
      data: dialogData,
    });
  }

  public openDefaultCurrencyDialog() {
    var currencyAvailable = [
      {
        key: '',
        label: this.p2rTranslateService.get(this.translateService.instant('DEFAULT_CURRENCY')),
        note: this.p2rTranslateService.get(this.translateService.instant('default_label_description')),
        fieldType: P2R_FIELD_TYPE.CHECK,
        selected: false
      },
      {
        key: 'EUR',
        label: this.p2rTranslateService.get(this.translateService.instant('EUR')),
        selected: false
      },
      {
        key: 'USD',
        label: this.p2rTranslateService.get(this.translateService.instant('USD')),
        selected: false
      },
      {
        key: 'GBP',
        label: this.p2rTranslateService.get(this.translateService.instant('GBP')),
        selected: false
      }
    ]

    currencyAvailable.reduce((acc, curr) => {
      curr.key == this.kuDefaultCurrency ? curr.selected = true : false
      acc.push(curr)
      return acc
    }, [])

    const dialogData = {
      hasSearch: false,
      customizeType: CUSTOMIZE_TYPE.DEFAULT_CURRENCY,
      items: currencyAvailable
    }

    this.p2rDialogService.open(P2RDialogComponent, {
      id: 'lst-periodo-dialog',
      body: CustomizeDialogComponent,
      hideCloseButton: true,
      title: this.p2rTranslateService.get(this.translateService.instant('LANGUAGE_CURRENCY.DEFAULT_CURRENCY.TITLE')),
      titleClasses: 'text-uppercase font-r_r fs24 lh21 c11 mt-2',
      size: 'lg',
      closeOnOutsideClick: false,
      closeButton: {
        label: this.p2rTranslateService.get(this.translateService.instant('close')),
        buttonClass: 'btn btn-primary rounded-pill lp-bottom-btn',
        labelClass: 'text-uppercase font-r_l fs18 lh21',
        click: () => {
          const currencySelected = dialogData.items.filter((el) => el.selected).map((el) => el.key)
          this.kuDefaultCurrency = currencySelected[0]
          this.configurationService.formGroup.get(LANGUAGE_CURRENCY_FIELD_TYPES.KU_DEFAULT_CURRENCY).setValue(this.kuDefaultCurrency);
          this.cdRef.detectChanges();
        }
      },
      data: dialogData,
    });
  }

  public convertLabel(key, lang, op): string {
    let code_key: string = '';
    if (op) {
      code_key = lang && !key ? 'IT' : key;
    } else {
      code_key = lang && !key ? 'DEFAULT_LANGUAGE' : !lang && !key ? 'DEFAULT_CURRENCY' : key;
    }

    switch (code_key) {
      case code_key:
        return this.p2rTranslateService.get(this.translateService.instant(code_key));
      default:
        return this.p2rTranslateService.get(this.translateService.instant(code_key));
    }
  }

  ngOnDestroy(): void {
    if (this.configurationValuesSubscription) {
      this.configurationValuesSubscription.unsubscribe()
    }
  }

}
