import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ConfigurationService } from '../configuration.service';
import { Input } from '@angular/core';

@Component({
  selector: 'configuration-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  public isFullScreen: boolean;
  private _header: {
    label: string
  } = {
      label: 'preferences'
    }

  public screenImg: {
    fullscreen: string,
    windowscreen: string
  };

  private elem;

  constructor(
    public configurationService: ConfigurationService,
    @Inject(DOCUMENT) private doc: any,
  ) {

    this.screenImg = {
      fullscreen: this.configurationService.ICONS.FULL_SCREEN,
      windowscreen: this.configurationService.ICONS.WINDOW_SCREEN
    };

    this.elem = doc.documentElement;

    this.isFullScreen = document.fullscreenElement ? true : false;

    doc.onfullscreenchange = () => {
      setTimeout(() => {
        this.isFullScreen = !this.isFullScreen;
      }, 0);
    };
  }

  ngOnInit() {
    document.dispatchEvent(new CustomEvent('SETTINGS:OPEN'));
  }

  async openMenu(event) {
    event.stopPropagation();
    event.preventDefault();
    document.dispatchEvent(new CustomEvent('SETTINGS:OPEN_MENU'));
  }

  openFullscreen() {
    let isOpened: boolean;
    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen();
      isOpened = true;
    } else if (this.elem.mozRequestFullScreen) {
      /* Firefox */
      this.elem.mozRequestFullScreen();
      isOpened = true;
    } else if (this.elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      this.elem.webkitRequestFullscreen();
      isOpened = true;
    } else if (this.elem.msRequestFullscreen) {
      /* IE/Edge */
      this.elem.msRequestFullscreen();
      isOpened = true;
    }
    if (isOpened) {
      document.dispatchEvent(new CustomEvent('SETTINGS:OPEN_FULL_SCREEN'));
    }
  }

  /* Close fullscreen */
  closeFullscreen() {
    let isClosed: boolean;
    if (this.doc.exitFullscreen) {
      this.doc.exitFullscreen();
      isClosed = true;
    } else if (this.doc.mozCancelFullScreen) {
      /* Firefox */
      this.doc.mozCancelFullScreen();
      isClosed = true;
    } else if (this.doc.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      this.doc.webkitExitFullscreen();
      isClosed = true;
    } else if (this.doc.msExitFullscreen) {
      /* IE/Edge */
      this.doc.msExitFullscreen();
      isClosed = true;
    }
    if (isClosed) {
      document.dispatchEvent(new CustomEvent('SETTINGS:CLOSE_FULL_SCREEN'));
    }
  }

  ngOnDestroy() {

  }

  @Input() set header(header: any) {
    if (header) {
      this._header = header;
    }
  }

  get header(): any {
    return this._header;
  }

}
