/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./custom-articles.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./custom-articles.component";
import * as i5 from "../configuration/configuration.service";
import * as i6 from "@angular/router";
import * as i7 from "@p2r/shared";
import * as i8 from "./custom-articles.service";
import * as i9 from "@angular/forms";
var styles_CustomArticlesComponent = [i0.styles];
var RenderType_CustomArticlesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CustomArticlesComponent, data: {} });
export { RenderType_CustomArticlesComponent as RenderType_CustomArticlesComponent };
function View_CustomArticlesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["class", "align-items-center d-flex justify-content-between sub-section-container"], ["style", "margin-top:30px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 9, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "p", [["class", "section-title"], ["style", "text-transform: uppercase;"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 2, "p", [["class", "sub-section-description font-r_r fs16 lh16 c10"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(9, 0, null, null, 2, "p", [["class", "sub-section-description font-r_r fs14 lh16 c10 mt-2"], ["style", "font-weight: bold;"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(12, 0, null, null, 1, "div", [["class", "sub-section-checkbox"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 0, "img", [], [[8, "src", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickAbilitaStock() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 0, "hr", [], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("ARTICLES.KU_ABILITA_STOCK.TITLE")); _ck(_v, 4, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("ARTICLES.KU_ABILITA_STOCK.SUBTITLE")); _ck(_v, 7, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform("ARTICLES.KU_ABILITA_STOCK.NOTE")); _ck(_v, 10, 0, currVal_2); var currVal_3 = (_co.kuabilitastock ? _co.configurationService.getImg("/icons/switch-on.svg") : _co.configurationService.getImg("/icons/switch-off.svg")); _ck(_v, 13, 0, currVal_3); }); }
function View_CustomArticlesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomArticlesComponent_2)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.checkIfKeyIsPresent(_co.items, "KU_ABILITA_STOCK"); _ck(_v, 2, 0, currVal_0); }, null); }
function View_CustomArticlesComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 22, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 20, "div", [["class", "align-items-center d-flex justify-content-between sub-section-container"], ["style", "margin-right: 32px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 9, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["style", "display: flex;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "p", [["class", "section-title"], ["style", "text-transform: uppercase;"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "label_mobile"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" MOBILE "])), (_l()(), i1.ɵeld(9, 0, null, null, 2, "p", [["class", "sub-section-description font-r_r fs16 lh16 c10"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(12, 0, null, null, 9, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 8, "div", [["class", "d-flex align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "span", [["class", "sub-section-value mr-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(15, null, ["", ""])), (_l()(), i1.ɵeld(16, 0, null, null, 5, "button", [["class", "btn rounded-pill btn-block btn-primary text-uppercase pl-3 pr-3 text-center"], ["style", "height: 40px; width: 120px;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.chooseSecondRowOnButton() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 4, "span", [["class", "font-r_r fs18 c13 lh21"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(19, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(21, 1), (_l()(), i1.ɵeld(22, 0, null, null, 0, "hr", [], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("ARTICLES.KU_SECOND_ROW_ON_BUTTON.TITLE")); _ck(_v, 5, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform("ARTICLES.KU_SECOND_ROW_ON_BUTTON.SUBTITLE")); _ck(_v, 10, 0, currVal_1); var currVal_2 = _co.getSecondRowOnButtonCurrentLabel(); _ck(_v, 15, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 19, 0, _ck(_v, 21, 0, i1.ɵnov(_v.parent.parent, 0), i1.ɵunv(_v, 19, 0, i1.ɵnov(_v, 20).transform("choose")))); _ck(_v, 19, 0, currVal_3); }); }
function View_CustomArticlesComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomArticlesComponent_4)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.checkIfKeyIsPresent(_co.items, "KU_SECOND_ROW_ON_BUTTON"); _ck(_v, 2, 0, currVal_0); }, null); }
function View_CustomArticlesComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "align-items-center d-flex justify-content-between sub-section-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "p", [["class", "section-title"], ["style", "text-transform: uppercase;"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 2, "p", [["class", "sub-section-description font-r_r fs16 lh16 c10"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(9, 0, null, null, 1, "div", [["class", "sub-section-checkbox"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "img", [], [[8, "src", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickAbilitaVenditaCodice() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("ARTICLES.KU_BARCODE_ENABLED.TITLE")); _ck(_v, 4, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("ARTICLES.KU_BARCODE_ENABLED.SUBTITLE")); _ck(_v, 7, 0, currVal_1); var currVal_2 = (_co.kuBarcodeEnabled ? _co.configurationService.getImg("/icons/switch-on.svg") : _co.configurationService.getImg("/icons/switch-off.svg")); _ck(_v, 10, 0, currVal_2); }); }
function View_CustomArticlesComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomArticlesComponent_6)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 0, "hr", [], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.checkIfKeyIsPresent(_co.items, "KU_BARCODE_ENABLED"); _ck(_v, 2, 0, currVal_0); }, null); }
function View_CustomArticlesComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "td", [["class", "sub-section-label-value"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "td", [["class", "sub-section-container-label-variant"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["[", "]"])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.kuLabelDefaultModVariant; _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("variant")); _ck(_v, 4, 0, currVal_1); }); }
function View_CustomArticlesComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "td", [["class", "sub-section-label-value"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("no_prefix")); _ck(_v, 1, 0, currVal_0); }); }
function View_CustomArticlesComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "div", [["class", "sub-section-container-label"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "p", [["class", "section-title"], ["style", "text-transform: uppercase;"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 2, "p", [["class", "sub-section-description font-r_r fs16 lh16 c10"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(9, 0, null, null, 3, "div", [["class", "sub-section-label"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openChangePrefixDialog() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomArticlesComponent_9)), i1.ɵdid(11, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["elseBlock", 2]], null, 0, null, View_CustomArticlesComponent_10)), (_l()(), i1.ɵeld(13, 0, null, null, 0, "hr", [], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.kuLabelDefaultModVariant && (_co.kuLabelDefaultModVariant.length > 0)); var currVal_3 = i1.ɵnov(_v, 12); _ck(_v, 11, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("ARTICLES.KU_DEFAULT_LABEL_MODIFICATORE.TITLE")); _ck(_v, 4, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("ARTICLES.KU_DEFAULT_LABEL_MODIFICATORE.SUBTITLE")); _ck(_v, 7, 0, currVal_1); }); }
function View_CustomArticlesComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomArticlesComponent_8)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.checkIfKeyIsPresent(_co.items, "KU_DEFAULT_LABEL_MODIFICATORE"); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_CustomArticlesComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.UpperCasePipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomArticlesComponent_1)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomArticlesComponent_3)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomArticlesComponent_5)), i1.ɵdid(7, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomArticlesComponent_7)), i1.ɵdid(9, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.checkIfKeyIsPresent(_co.values, "KU_ABILITA_STOCK"); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.checkIfKeyIsPresent(_co.values, "KU_MOBILE_SHOW_PRICE_ON_BUTTONS"); _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.checkIfKeyIsPresent(_co.values, "KU_BARCODE_ENABLED"); _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.checkIfKeyIsPresent(_co.values, "KU_LABEL_DEFAULT_MOD_VARIANT"); _ck(_v, 9, 0, currVal_3); }, null); }
export function View_CustomArticlesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "configuration-custom-articles", [], null, null, null, View_CustomArticlesComponent_0, RenderType_CustomArticlesComponent)), i1.ɵdid(1, 114688, null, 0, i4.CustomArticlesComponent, [i5.ConfigurationService, i6.ActivatedRoute, i7.P2RTranslateService, i2.TranslateService, i7.P2RDialogService, i8.CustomArticlesService, i1.ChangeDetectorRef, i9.FormBuilder], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomArticlesComponentNgFactory = i1.ɵccf("configuration-custom-articles", i4.CustomArticlesComponent, View_CustomArticlesComponent_Host_0, {}, {}, []);
export { CustomArticlesComponentNgFactory as CustomArticlesComponentNgFactory };
