import * as tslib_1 from "tslib";
import { ChangeDetectorRef, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { checkIfKeyIsPresent } from "../configuration.utils";
import { P2RDialogComponent, P2R_FIELD_TYPE, } from "@p2r/shared";
import { Subject } from "rxjs";
import { GlobalVariable } from "src/app/global";
import { ARTICLES_FIELD_TYPES, GENERAL_FIELD_TYPES, GROUP_TYPE } from "../configuration.model";
import { UtilsShared } from "src/app/shared/utilsshared";
import * as _ from 'lodash';
import { CustomizeDialogComponent } from "src/app/customize-dialog/customize-dialog.component";
import { CUSTOMIZE_TYPE } from "src/app/customize-dialog/customize-dialog.model";
import { TimeSlotAlertComponent } from "../time-slot-alert/time-slot-alert.component";
import * as momentN from 'moment';
const moment = momentN;
export class CustomGeneraliComponent {
    constructor(configurationService, formBuilder, route, ngxService, p2rLocalStorageService, p2rApiService, p2rDialogService, p2rTranslateService, translateService, resolver, cdRef) {
        this.configurationService = configurationService;
        this.formBuilder = formBuilder;
        this.route = route;
        this.ngxService = ngxService;
        this.p2rLocalStorageService = p2rLocalStorageService;
        this.p2rApiService = p2rApiService;
        this.p2rDialogService = p2rDialogService;
        this.p2rTranslateService = p2rTranslateService;
        this.translateService = translateService;
        this.resolver = resolver;
        this.cdRef = cdRef;
        this.CONFIGURATION = `CONFIGURATION`;
        this.typegrop = GROUP_TYPE.GENERALI;
        this.images = {
            add: this.configurationService.ICONS.ADD,
            remove: this.configurationService.ICONS.REMOVE
        };
    }
    // 1439 minutes == 23h:59m
    get canAdd() {
        return this.listinoPeriods
            && this.listinoPeriods.length
            && this.listinoPeriods.length < 3
            && ((this.listinoPeriods[0].endTime && this.listinoPeriods[0].endTime < 1439)
                && (this.listinoPeriods.length == 2
                    ? this.listinoPeriods[1].endTime && this.listinoPeriods[1].endTime < 1439 : true));
    }
    ngOnInit() {
        this.configurationService.selectedGroup = this.typegrop;
        this.configurationService.restoreCompleteSubject[this.typegrop] =
            new Subject();
        this.setsubscribe();
        const currentPv = this.p2rLocalStorageService.getFromLocalStorage(GlobalVariable.SELECTED_PV_KEY_STORAGE, []);
        if (currentPv !== undefined && currentPv.codice !== undefined) {
            this.apiStore = currentPv.codice;
        }
        this.route.data.subscribe(({ data }) => {
            this.ngxService.stopAll();
            const [items, apiValues, listini] = data;
            this.items = items;
            this.listini = listini;
            this.setdatiform(apiValues, false);
        });
        this.configurationValuesSubscription = this.configurationService.values$().subscribe(values => {
            values = values[GROUP_TYPE.GENERALI];
            if (values !== undefined) {
                if (this.configurationService._selectedGroup === GROUP_TYPE.GENERALI) {
                    console.log("GENERALI reload values: " + JSON.stringify(values));
                    const selectedPv = this.p2rLocalStorageService.getFromLocalStorage(GlobalVariable.SELECTED_PV_KEY_STORAGE, []);
                    this.apiStore = selectedPv.codice;
                    this.setVariables(values);
                }
            }
        });
    }
    setsubscribe() {
        this.saveCompleteSubscription = this.configurationService.saveCompleteSubject.subscribe((res) => {
            console.log("GENERALI save complete = " + res);
            if (res) {
                this.ngxService.stopAll();
                this.resolver
                    .getValues(this.typegrop, this.apiStore)
                    .subscribe((res) => {
                    if (res) {
                        this.setdatiform(res, true);
                        // Svuoto local storage
                        this.setAPI(false, undefined);
                    }
                });
            }
        });
        this.configurationService.restoreCompleteSubject[this.typegrop].subscribe((r) => {
            console.log("GENERALI restore complete = " + r);
            if (r) {
                this.ngxService.start("GENERALI-restore");
                this.resolver
                    .getValues(this.typegrop, this.apiStore)
                    .subscribe((res) => {
                    if (res) {
                        this.ngxService.stopAll();
                        this.setdatiform(res, true);
                        // Svuoto local storage
                        this.setAPI(false, undefined);
                    }
                });
            }
        });
        this._changeStoreSubscription = this.configurationService.changeStoreCompleteSubject.subscribe((r) => {
            console.log("GENERALI change store complete = " + r);
            if (r) {
                if (this.configurationService.selectedGroup === this.typegrop) {
                    this.changeStoreComplete();
                }
                else {
                    console.log("GENERALI change store not visible");
                }
            }
        });
    }
    checkIfKeyIsPresent(array, key) {
        const v = checkIfKeyIsPresent(array, key);
        return v;
    }
    getLocalStorage() {
        let templateStoreLocalStorage = undefined;
        const t = this.p2rLocalStorageService.getFromLocalStorage(this.CONFIGURATION, [this.typegrop]);
        console.log(t);
        if (t !== undefined) {
            templateStoreLocalStorage = t.values;
        }
        return templateStoreLocalStorage;
    }
    enableSavePanel(thereIsChanges) {
        if (thereIsChanges) {
            // aggiorno modifiedGroups
            this.configurationService.modifiedGroups[this.typegrop] = "CHANGE";
            // invio modifiedGroups dentro lo stream
            this.configurationService._modifiedGroups$.next(this.configurationService.modifiedGroups);
        }
        else {
            // cancello i dati relativi al gruppo dentro modifiedGroups
            delete this.configurationService.modifiedGroups[this.typegrop];
            // invio modifiedGroups dentro lo stream
            this.configurationService._modifiedGroups$.next(this.configurationService.modifiedGroups);
        }
        if (thereIsChanges === false) {
            this.configurationService.doCheck(UtilsShared.thereAreOtherChanges(this.p2rLocalStorageService));
        }
        else {
            this.configurationService.doCheck(true);
        }
    }
    setVariables(tmplStore) {
        this.currentStoreJsonTemplate = tmplStore;
        this.listinoPeriods = _.cloneDeep(tmplStore[GENERAL_FIELD_TYPES.KU_LISTINO_PERIODS]);
        if (!this.listinoPeriods.length) {
            this.listinoPeriods.push({
                startTime: 0,
                endTime: 24 * 59.98,
                enabled: false,
                priorityOnRoom: false,
                codListino: 'XLST10'
            });
            if (!this.cdRef[`destroyed`]) {
                this.cdRef.detectChanges();
            }
            this.formGroup.get(GENERAL_FIELD_TYPES.KU_LISTINO_PERIODS)
                .setValue(this.listinoPeriods);
        }
        this.kuabilitastock = tmplStore["KU_ABILITA_STOCK"];
        this.kuabilitavenditacodice = tmplStore["KU_BARCODE_ENABLED"];
        this.kuexcludedesk = tmplStore["KU_EXCLUDE_DESK_FROM_TIMESLOT_LISTINO"];
        this.KuLabelDefaultModVariant = tmplStore[ARTICLES_FIELD_TYPES.KU_LABEL_DEFAULT_MOD_VARIANT];
        this.KuLabelDefaultModVariant = this.replaceAll(this.KuLabelDefaultModVariant, "\`", "\'"); // Solo per visualizzare meglio apostrofo
    }
    setAPI(change, value) {
        this.configurationService.saveAllList[this.typegrop] = [];
        if (change) {
            const storeTemplate = {};
            storeTemplate["jsontemplate"] = JSON.stringify(value);
            storeTemplate["key_group"] = this.apiKeyGroup;
            if (storeTemplate !== undefined) {
                storeTemplate["store"] = this.apiStore;
                if (this.apiCode === 0) {
                    storeTemplate["codice"] = "0";
                    this.configurationService.saveAllList[this.typegrop].push(this.p2rApiService.post(`${this.p2rApiService.apiCorePath}/tmpstore/operatore`, undefined, storeTemplate));
                }
                else {
                    storeTemplate["codice"] = this.apiCode.toString();
                    this.configurationService.saveAllList[this.typegrop].push(this.p2rApiService.put(`${this.p2rApiService.apiCorePath}/tmpstore/operatore`, undefined, storeTemplate));
                }
                this.p2rLocalStorageService.addToLocalStorage(this.CONFIGURATION, [this.typegrop, "values"], storeTemplate);
            }
        }
        else {
            if (this.configurationService.saveAllList[this.typegrop] !== undefined) {
                this.configurationService.saveAllList[this.typegrop] = [];
            }
            this.p2rLocalStorageService.removeFromLocalStorage(this.CONFIGURATION, [this.typegrop, "values"]);
            // this.p2rLocalStorageService.removeFromLocalStorage(this.FATTURE_LOCAL_STORAGE, [ this.typegrop 'values']);
        }
    }
    enableFormValuesChangeDetection() {
        this.formGroup.valueChanges.subscribe((value) => {
            let thereIsChanges = false;
            Object.keys(value).forEach((k) => {
                const m = UtilsShared.compareValues(value[k], this.oldjson && this.oldjson[k]);
                if (m) {
                    thereIsChanges = true;
                }
            });
            console.log(thereIsChanges);
            this.setAPI(thereIsChanges, value);
            this.enableSavePanel(thereIsChanges);
        });
    }
    changeStoreComplete() {
        const currentPv = this.p2rLocalStorageService.getFromLocalStorage(GlobalVariable.SELECTED_PV_KEY_STORAGE, []);
        if (currentPv !== undefined && currentPv.codice !== undefined) {
            this.apiStore = currentPv.codice;
        }
        this.ngxService.start();
        this.resolver.getValues(this.typegrop, this.apiStore).subscribe((res) => {
            if (res) {
                /*   if (res !== undefined && res.tmpl_store !== undefined && res.tmpl_store.jsontemplate == '{}') {
                  res.tmpl_store.jsontemplate = JSON.stringify(this.configurationService.apiValues[this.typegrop].values);
                } */
                this.ngxService.stopAll();
                this.setdatiform(res, true);
                this.setAPI(false, undefined);
                this.enableFormValuesChangeDetection();
            }
        });
    }
    onClickAbilitaStock() {
        this.kuabilitastock = !this.kuabilitastock;
        this.formGroup.get("KU_ABILITA_STOCK").setValue(this.kuabilitastock);
    }
    onClickAbilitaVenditaCodice() {
        this.kuabilitavenditacodice = !this.kuabilitavenditacodice;
        this.formGroup.get('KU_BARCODE_ENABLED').setValue(this.kuabilitavenditacodice);
    }
    onClickExcludeDesk() {
        this.kuexcludedesk = !this.kuexcludedesk;
        this.formGroup.get("KU_EXCLUDE_DESK_FROM_TIMESLOT_LISTINO").setValue(this.kuexcludedesk);
    }
    setdatiform(apiValues, force) {
        this.templateStoreApiValues = apiValues.tmpl_store;
        // qualcuno mi spiega perche tutta questa logica?
        this.apiCode = apiValues.tmpl_store.codice * 1;
        this.apiKeyGroup = apiValues.tmpl_store.key_group;
        this.oldjson = JSON.parse(apiValues.tmpl_store.jsontemplate);
        this.currentTemplateStoreValues = this.templateStoreApiValues;
        if (!force) {
            let templateStoreLocaLStorage = this.getLocalStorage();
            if (templateStoreLocaLStorage === undefined) {
                this.currentTemplateStoreValues = this.templateStoreApiValues;
            }
            else {
                this.currentTemplateStoreValues = templateStoreLocaLStorage;
                this.enableSavePanel(true);
            }
        }
        this.currentStoreJsonTemplate = JSON.parse(this.currentTemplateStoreValues.jsontemplate);
        let group = UtilsShared.setFormControlbayJson(this.currentStoreJsonTemplate);
        this.formGroup = this.formBuilder.group(group);
        // this.configurationService.formGroup = this.formGroup;
        this.setVariables(this.currentStoreJsonTemplate);
        this.enableFormValuesChangeDetection();
    }
    addTimeSlot() {
        const last = this.listinoPeriods[this.listinoPeriods.length - 1];
        if (!last.endTime) {
            this.configurationService.showErrorAlert([
                this.p2rTranslateService.get(this.translateService.instant('before_adding_the_second_period_it_is_necessary_to_configure_the_first'))
            ]);
        }
        else {
            this.listinoPeriods = this.listinoPeriods.concat({
                enabled: true,
                priorityOnRoom: false,
                codListino: 'XLST10',
                startTime: last.endTime,
                endTime: 24 * 59.98,
            });
            this.formGroup.get(GENERAL_FIELD_TYPES.KU_LISTINO_PERIODS)
                .setValue(this.listinoPeriods);
        }
    }
    removeTimeSlot(index) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.listinoPeriods.splice(index, 1);
            this.formGroup.get(GENERAL_FIELD_TYPES.KU_LISTINO_PERIODS)
                .setValue(this.listinoPeriods);
        });
    }
    configTimeSlot(index) {
        const listinoPeriod = this.listinoPeriods[index];
        const dialog = this.p2rDialogService.open(P2RDialogComponent, {
            id: 'lst-periodo-dialog',
            body: CustomizeDialogComponent,
            // hideCloseButton: true,
            title: this.p2rTranslateService.get(this.translateService.instant('TAKEAWAY.SENDING_ORDERS.TITLE')),
            titleClasses: 'text-uppercase font-r_r fs24 lh21 c11 mt-2',
            size: 'lg',
            keyboard: false,
            closeOnOutsideClick: false,
            closeButton: {
                label: this.p2rTranslateService.get(this.translateService.instant('close')),
                buttonClass: 'btn btn-primary rounded-pill lp-bottom-btn',
                labelClass: 'text-uppercase font-r_l fs18 lh21',
                click: () => {
                    this.listinoPeriods[index] = listinoPeriod;
                    this.formGroup.get(GENERAL_FIELD_TYPES.KU_LISTINO_PERIODS)
                        .setValue(this.listinoPeriods);
                }
            },
            data: {
                hasSearch: false,
                customizeType: CUSTOMIZE_TYPE.LISTINO_PERIOD,
                opt: {
                    listinoPeriod
                },
                items: [
                    {
                        key: 'enabled',
                        label: this.p2rTranslateService.get(this.translateService.instant('LST_PERIODO_ENABLED_LABEL')),
                        note: this.p2rTranslateService.get(this.translateService.instant('LST_PERIODO_ENABLED_NOTE')),
                        value: listinoPeriod.enabled,
                        fieldType: P2R_FIELD_TYPE.CHECK
                    },
                    {
                        key: 'priorityOnRoom',
                        label: this.p2rTranslateService.get(this.translateService.instant('LST_PRIORITY_ON_ROOM_LABEL')),
                        note: this.p2rTranslateService.get(this.translateService.instant('LST_PRIORITY_ON_ROOM_NOTE')),
                        value: listinoPeriod.priorityOnRoom,
                        fieldType: P2R_FIELD_TYPE.CHECK
                    },
                    {
                        label: this.p2rTranslateService.get(this.translateService.instant('LST_PERIODO_CHOOSE_LABEL')),
                    },
                    {
                        label: this.p2rTranslateService.get(this.translateService.instant('LST_BASE')),
                        key: 'XLST10',
                        value: listinoPeriod.codListino === 'XLST10' ? 1 : 0,
                        fieldType: P2R_FIELD_TYPE.RADIO
                    },
                    ...this.listini.map(s => (Object.assign({}, s, { value: listinoPeriod.codListino === s.key ? 1 : 0, fieldType: P2R_FIELD_TYPE.RADIO }))),
                ]
            }
        });
    }
    openChangeTimeSlot(index) {
        const startTime = this.convertMinutesToHourMinutes(this.listinoPeriods[index].startTime);
        const endTime = this.convertMinutesToHourMinutes(this.listinoPeriods[index].endTime);
        let dataToDialog = {
            type: GROUP_TYPE.TAKEAWAY,
            startTime: {
                hour: this.pad(parseInt(startTime.split(':')[0], 10)),
                minutes: this.pad(parseInt(startTime.split(':')[1], 10))
            },
            endTime: {
                hour: this.pad(parseInt(endTime.split(':')[0], 10)),
                minutes: this.pad(parseInt(endTime.split(':')[1], 10))
            }
        };
        const alert = this.p2rDialogService.open(P2RDialogComponent, {
            id: 'takeaway-timeslot',
            hasHead: false,
            body: TimeSlotAlertComponent,
            data: dataToDialog,
            size: "lg" /* LARGE */,
            actions: [
                {
                    label: this.p2rTranslateService.get(this.translateService.instant('dialog_cancel')),
                    click: () => {
                        alert.dismiss();
                    },
                    classes: 'btn rounded-pill text-uppercase new-alert-button',
                    data: {
                        remove: true
                    },
                    params: {}
                },
                {
                    label: this.p2rTranslateService.get(this.translateService.instant('dialog_confirm')),
                    click: (data) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                        if (dataToDialog.startTime.hour == null
                            || dataToDialog.endTime.hour == null
                            || dataToDialog.startTime.hour < 0
                            || dataToDialog.startTime.hour > 23
                            || dataToDialog.endTime.hour < 0
                            || dataToDialog.endTime.hour > 23) {
                            this.configurationService.showErrorAlert([
                                this.p2rTranslateService.get(this.translateService.instant('you_entered_an_incorrect_time_format')),
                                this.p2rTranslateService.get(this.translateService.instant('the_hours_must_be_between_0_and_23')),
                            ]);
                        }
                        else if (dataToDialog.startTime.minutes == null
                            || dataToDialog.endTime.minutes == null
                            || dataToDialog.startTime.minutes < 0
                            || dataToDialog.startTime.minutes > 59
                            || dataToDialog.endTime.minutes < 0
                            || dataToDialog.endTime.minutes > 59) {
                            this.configurationService.showErrorAlert([
                                this.p2rTranslateService.get(this.translateService.instant('you_entered_an_incorrect_time_format')),
                                this.p2rTranslateService.get(this.translateService.instant('the_minutes_must_be_between_0_and_59')),
                            ]);
                        }
                        else {
                            const startTime = dataToDialog.startTime.hour + ':' + dataToDialog.startTime.minutes;
                            const endTime = dataToDialog.endTime.hour + ':' + dataToDialog.endTime.minutes;
                            const startTimeM = moment(startTime, 'HH:mm');
                            const endTimeM = moment(endTime, 'HH:mm');
                            const startDayM = moment().clone().startOf('day');
                            const startMinutes = startTimeM.diff(startDayM, 'minutes');
                            const endMinutes = endTimeM.diff(startDayM, 'minutes');
                            const tp = _.cloneDeep(this.listinoPeriods);
                            tp[index].startTime = startMinutes;
                            tp[index].endTime = endMinutes;
                            if (this.servicePeriodsOverlap(tp, index)) {
                                this.configurationService.showErrorAlert([
                                    this.p2rTranslateService.get(this.translateService.instant('the_durations_of_the_two_service_periods_overlap')),
                                    this.p2rTranslateService.get(this.translateService.instant('change_the_start_or_end_time')),
                                ]);
                            }
                            else {
                                this.listinoPeriods[index].startTime = startMinutes;
                                this.listinoPeriods[index].endTime = endMinutes;
                                alert.dismiss();
                                this.formGroup.get(GENERAL_FIELD_TYPES.KU_LISTINO_PERIODS)
                                    .setValue(this.listinoPeriods);
                            }
                        }
                    }),
                    data: dataToDialog,
                    classes: 'btn rounded-pill text-uppercase new-alert-button',
                    params: {
                        submit: true
                    }
                }
            ]
        });
    }
    /**
     * KU-749
     * Ritorna true se i servizi di periodo si sovrappongono.
     * @param tp
     * @param index
     * @returns
     */
    servicePeriodsOverlap(tp, index) {
        let isOk = true;
        // 1. Controllo la fine e l'inizio della fascia oraria corrente (index)
        if (tp[index].endTime <= tp[index].startTime) {
            isOk = false;
        }
        else {
            // 2. Applico algoritmo che confronta la fascia oraria corrente (index)
            //    con tutte le altre fasce orarie (i)
            for (let i = 0; i < tp.length; ++i) {
                if (i == index) {
                    continue;
                }
                if (tp[index].endTime <= tp[i].startTime || tp[index].startTime >= tp[i].endTime) {
                }
                else {
                    isOk = false;
                }
            }
        }
        return !isOk;
    }
    convertMinutesToHourMinutes(totalMinutes) {
        let h = Math.floor(totalMinutes / 60);
        let hours;
        if (h < 10) {
            hours = "0" + h;
        }
        else {
            hours = h * 1;
        }
        let m = Math.floor(totalMinutes % 60);
        let minutes;
        if (m == 0) {
            minutes = "00";
        }
        else if (m < 10) {
            minutes = "0" + m;
        }
        else {
            minutes = m * 1;
        }
        return hours + ":" + minutes;
    }
    pad(value) {
        if (value === 0 && String(value).length === 1) {
            return '0' + value;
        }
        else {
            if (!String(value).startsWith('0')) {
                if (value < 10) {
                    return '0' + value;
                }
                else {
                    return value;
                }
            }
            else {
                return value;
            }
        }
    }
    /**
     * Se la stringa non è nulla, sostituisce tutte le occorrenze
     * della variabile search con replace.
     * @param string
     * @param search
     * @param replace
     * @returns
     */
    replaceAll(string, search, replace) {
        if (string != undefined && string.length > 0) {
            return string.split(search).join(replace);
        }
        else {
            return string;
        }
    }
    ngOnDestroy() {
        if (this.saveCompleteSubscription) {
            this.saveCompleteSubscription.unsubscribe();
        }
        if (this.configurationValuesSubscription) {
            this.configurationValuesSubscription.unsubscribe();
        }
        if (this._changeStoreSubscription) {
            this._changeStoreSubscription.unsubscribe();
        }
    }
}
