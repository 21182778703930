<div>
    <div>
        <div>
            <div class="sub-section-container">
                <div>
                    <p
                        class="section-title"
                        style="text-transform: uppercase;"
                    >
                        {{'PAYMENTS.SERVICE.TITLE' | translate}}
                    </p>
                    <p class="sub-section-description-2 font-r_r fs16 lh16 c10">
                        {{'PAYMENTS.SERVICE.DESCRIPTION' | translate}}
                    </p>
                </div>
                <div class="sub-section-checkbox">
                    <img
                        [src]="kuabilitavoceservizio ? configurationService.getImg('/icons/switch-on.svg') : configurationService.getImg('/icons/switch-off.svg')"
                        (click)="onClickAbilitaVoceServizio()"
                    >
                </div>
            </div>
            <hr>
            <div>
                <div class="sub-section-container pt-2 pb-2">
                    <div>
                        <p class="sub-section-title">
                            {{'PAYMENTS.SERVICE_PERCENTAGE.TITLE' | translate}}
                        </p>
                    </div>
                    <div
                        class="sub-section-variable"
                        style="min-width: 50px;"
                        (click)="openPercentageDialog()"
                    >
                        <div class="sub-section-label-value">{{ kuvoceserviziopercentage + '%' }}</div>
                    </div>
                </div>
                <hr>
            </div>
        </div>
    </div>
    <div>
        <div style="height:auto; display:flex; width: 100%; margin-bottom: 10px">
            <div>
                <p class="section-title-2 text-uppercase">
                    {{'PAYMENTS.CUSTOMIZED_PAYMENTS.TITLE' | translate}}
                </p>
                <p class="sub-section-description-2 font-r_r fs16 lh16 c10">
                    {{'PAYMENTS.CUSTOMIZED_PAYMENTS.DESCRIPTION' | translate}}
                </p>
            </div>
            <div>
                <img
                    [src]="configurationService.getImg('/icons/add.svg')"
                    (click)="addCustomizedPayment(paymentsService.allPaymentList)"
                    class="icon section-icon-2"
                    style="margin-left: 12px;"
                    width="40"
                    height="40"
                >
            </div>
        </div>
        <hr>
        <div *ngFor="let element of paymentsService?.allPaymentList; let i = index">
            <div class="sub-section-container">
                <div>
                    <p
                        class="sub-section-title"
                        style="margin: 8px 40px 0px;"
                    >
                        {{ element?.XCCARDFOOD05 }}
                    </p>
                </div>
                <div style="height:auto; display:flex; margin-left: auto">
                    <div class="time-slot">
                        <p
                            id="methodPaymentLabel"
                            class="sub-section-payment-details"
                        >
                            {{ paymentsService.getMethodLabelByCode(element?.XCCARDFOOD10) | translate }}
                        </p>
                    </div>
                    <div>
                        <img
                            [src]="configurationService.getImg('/icons/customize_blue.svg')"
                            class="icon"
                            style="margin-right: 10px;"
                            width="40"
                            height="40"
                            (click)="edit_customizedPayment(element)"
                        >
                    </div>
                    <div>
                        <img
                            [src]="configurationService.getImg('/icons/remove.svg')"
                            class="icon"
                            style="margin-right: 32px;"
                            width="40"
                            height="40"
                            (click)="remove_customizedPayment(element)"
                        >
                    </div>
                </div>
            </div>
            <hr>
        </div>
    </div>
    <div>
        <div style="height:auto; display:flex; width: 100%; margin-bottom: 10px">
            <div>
                <p class="section-title-2 text-uppercase">
                    {{'PAYMENTS.VASSOIO.TITLE' | translate}}
                </p>
                <p class="sub-section-description-2 font-r_r fs16 lh16 c10">
                    {{'PAYMENTS.VASSOIO.DESCRIPTION' | translate}}
                </p>
            </div>
            <div class="sub-section-checkbox">
                <img
                    [src]="kuAbilitaVassoio ? configurationService.getImg('/icons/switch-on.svg') : configurationService.getImg('/icons/switch-off.svg')"
                    (click)="onClickAbilitaVassoio()"
                >
            </div>
        </div>
        <hr>
        <div>
            <div class="sub-section-container pt-2 pb-2"
                [class.disabled]="kuAbilitaVassoio === false"
                style="margin-right: 32px;">
                <div>
                    <p class="sub-section-title">
                        {{'PAYMENTS.VASSOIO.CASSE.TITLE' | translate}}
                    </p>
                    <p class="sub-section-description font-r_r fs16 lh16 c10">
                        {{'PAYMENTS.VASSOIO.CASSE.DESCRIPTION' | translate}}
                    </p>
                </div>
                <div>
                    <div class="d-flex align-items-center">
                        <span class="sub-section-value mr-3">{{kuCasseVassoioLabel}}</span>
                        <button
                            class="btn rounded-pill btn-block btn-primary text-uppercase pl-3 pr-3 text-center"
                            style="height: 40px; width: 120px;"
                            (click)="openScegliCassePerVassoio()"
                        >
                            <span class="font-r_r fs18 c13 lh21">
                                <span>{{'choose' | translate | uppercase}}</span>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
            <hr>
        </div>
    </div>
</div>
