<div class="d-flex flex-column">
    <ng-container [ngSwitch]="type">
        <ng-container *ngSwitchCase="'TAKEAWAY'">
            <ng-container *ngTemplateOutlet="TAKEAWAY"></ng-container>
        </ng-container>
        <ng-container *ngSwitchDefault>
            <div
                class="text-center"
                style="padding: 16px;"
            >
                <div
                    id="title-container"
                    class="title-container"
                >
                    {{'hour_change_time_slot_alert' | translate}}
                </div>
                <div class="time-slot-container">
                    <div style="float: right; width: 152px; height: 112px;">
                        <div
                            class="leftbox"
                            [class.focused-time-slot]="isHourInputFocused"
                        >
                            <input
                                class="time-label"
                                #timeHourInput
                                type="number"
                                name="hour"
                                [(ngModel)]="data.hour"
                                customVirtualKeyboard
                                value="data.hour"
                                min="0"
                                max="23"
                                (focus)="onHourInputFocus()"
                                required
                            >
                        </div>
                        <!-- <span
                            style="color:red"
                            *ngIf="(data.hour < 0 || data.hour > 23)"
                        >
                            <span>{{'hour_error' | translate}}</span>
                        </span> -->
                    </div>
                    <div class="middlebox time-label">:</div>
                    <div style="float: right; width: 152px; height: 112px;">
                        <div
                            class="rightbox"
                            [class.focused-time-slot]="isMinutesInputFocued"
                        >
                            <input
                                class="time-label"
                                type="number"
                                name="minutes"
                                [(ngModel)]="data.minutes"
                                customVirtualKeyboard
                                min="0"
                                max="59"
                                (focus)="onMinutesInputFocus()"
                                required
                            >
                            <!-- <p class="time-label hour-minute-label">{{data.minutes}}</p> -->
                        </div>
                        <!-- <span
                            style="color:red"
                            *ngIf="(data.minutes < 0 || data.minutes > 59)"
                        >
                            <span>{{'minutes_error' | translate}}</span>
                        </span> -->
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>
</div>
<ng-template #TAKEAWAY>
    <div class="d-flex justify-content-between  ">
        <div>
            <div
                class="text-center"
                style="padding: 16px;"
            >
                <div
                    id="title-container"
                    class="title-container"
                >
                    {{'start_time' | translate}}
                </div>
                <div class="time-slot-container">
                    <div style="float: right; width: 152px; height: 112px;">
                        <div
                            class="leftbox"
                            [class.focused-time-slot]="isStartHourInputFocused"
                        >
                            <input
                                class="time-label"
                                #timeHourInput
                                type="number"
                                name="hour"
                                [(ngModel)]="data.startTime.hour"
                                customVirtualKeyboard
                                value="data.startTime.hour"
                                min="0"
                                max="23"
                                (focus)="onStartHourInputFocus()"
                                required
                            >
                        </div>
                        <!-- <span
                            style="color:red"
                            *ngIf="(data.startTime.hour < 0 || data.startTime.hour > 23)"
                        >
                            <span>{{'hour_error' | translate}}</span>
                        </span> -->
                    </div>
                    <div class="middlebox time-label">:</div>
                    <div style="float: right; width: 152px; height: 112px;">
                        <div
                            class="rightbox"
                            [class.focused-time-slot]="isStartMinutesInputFocued"
                        >
                            <input
                                class="time-label"
                                type="number"
                                name="minutes"
                                [(ngModel)]="data.startTime.minutes"
                                customVirtualKeyboard
                                min="0"
                                max="59"
                                (focus)="onStartMinutesInputFocus()"
                                required
                            >
                            <!-- <p class="time-label hour-minute-label">{{data.startTime.minutes}}</p> -->
                        </div>
                        <!-- <span
                            style="color:red"
                            *ngIf="(data.startTime.minutes < 0 || data.startTime.minutes > 59)"
                        >
                            <span>{{'minutes_error' | translate}}</span>
                        </span> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="border-left mb-2 mt-4"></div>
        <div>
            <div
                class="text-center"
                style="padding: 16px;"
            >
                <div
                    id="title-container"
                    class="title-container"
                >
                    {{'end_time' | translate}}
                </div>
                <div class="time-slot-container">
                    <div style="float: right; width: 152px; height: 112px;">
                        <div
                            class="leftbox"
                            [class.focused-time-slot]="isEndHourInputFocused"
                        >
                            <input
                                class="time-label"
                                #timeHourInput
                                type="number"
                                name="hour"
                                [(ngModel)]="data.endTime.hour"
                                customVirtualKeyboard
                                value="data.endTime.hour"
                                min="0"
                                max="23"
                                (focus)="onEndHourInputFocus()"
                                required
                            >
                        </div>
                        <!-- <span
                            style="color:red"
                            *ngIf="(data.endTime.hour < 0 || data.endTime.hour > 23)"
                        >
                            <span>{{'hour_error' | translate}}</span>
                        </span> -->
                    </div>
                    <div class="middlebox time-label">:</div>
                    <div style="float: right; width: 152px; height: 112px;">
                        <div
                            class="rightbox"
                            [class.focused-time-slot]="isEndMinutesInputFocued"
                        >
                            <input
                                class="time-label"
                                type="number"
                                name="minutes"
                                [(ngModel)]="data.endTime.minutes"
                                customVirtualKeyboard
                                min="0"
                                max="59"
                                (focus)="onEndMinutesInputFocus()"
                                required
                            >
                            <!-- <p class="time-label hour-minute-label">{{data.endTime.minutes}}</p> -->
                        </div>
                        <!-- <span
                            style="color:red"
                            *ngIf="(data.endTime.minutes < 0 || data.endTime.minutes > 59)"
                        >
                            <span>{{'minutes_error' | translate}}</span>
                        </span> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
