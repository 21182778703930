import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { P2RApiService } from '@p2r/platform-api';
import { P2RLocalStorageService, P2RDialogService, P2RTranslateService, P2RDialogComponent } from '@p2r/shared';
import { SIZE } from '@p2r/shared/lib/shared/p2r-dialog/p2r-dialog.interface';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subject, Subscription } from 'rxjs';
import { GlobalVariable } from 'src/app/global';
import { ChangeTextDialogComponent } from '../change-text-dialog/change-text-dialog.component';
import { GROUP_TYPE } from '../configuration.model';
import { ConfigurationResolverServices } from '../configuration.resolve';
import { ConfigurationService } from '../configuration.service';
import { TranslateService } from "@ngx-translate/core";
import { P2REnvironmentService } from '@p2r/env';
import { CustomizeDialogComponent } from 'src/app/customize-dialog/customize-dialog.component';
import { CUSTOMIZE_TYPE } from 'src/app/customize-dialog/customize-dialog.model';

@Component({
  selector: 'configuration-mycustom',
  templateUrl: './mycustom.component.html',
  styleUrls: ['./mycustom.component.scss']
})
export class MycustomComponent implements OnInit, OnDestroy {
  private SERVICES_LOCAL_STORAGE = "SERVICES";
  private USERNAME_KEY = "username";
  private PWD_KEY = "password";
  private URL_KEY = "url";

  public items: any;
  private formGroup: FormGroup;

  private apiStore: string;

  private servicesFattureApiValues: any; // {"store":"99999","url":"https://test.mycustom.it","username":"FS96001122","password":"12345678","readonly":false}
  public currentServicesValues: any;
  public kuServicesUsername: string;
  public kuServicesPwd: string;
  public kuServicesUrl: string;
  public isMultiStore: boolean;
  public selectedPv: any;
  private _changeStoreSubscription: Subscription;

  constructor(
    public configurationService: ConfigurationService,
    private route: ActivatedRoute,
    private ngxService: NgxUiLoaderService,
    private p2rLocalStorageService: P2RLocalStorageService,
    private formBuilder: FormBuilder,
    private p2rDialogService: P2RDialogService,
    private p2rTranslateService: P2RTranslateService,
    private translateService: TranslateService,
    private p2rApiService: P2RApiService,
    private resolver: ConfigurationResolverServices,
    private p2rEnvironmentService: P2REnvironmentService
  ) { }

  ngOnInit() {

    if (this.p2rEnvironmentService.storeData !== undefined) {
      this.isMultiStore = this.p2rEnvironmentService.storeData.multistore;
    } else {
      this.isMultiStore = false;
    }

    this.selectedPv = this.p2rLocalStorageService.getFromLocalStorage(GlobalVariable.SELECTED_PV_KEY_STORAGE, []);

    // this.configurationService.selectedGroup = GROUP_TYPE.SERVIZI;
    this.configurationService.restoreCompleteSubject[GROUP_TYPE.SERVIZI] =
      new Subject<any>();

    this.route.data.subscribe(({ data }) => {
      this.ngxService.stopAll();

      const [items, apiValues] = data;
      this.items = items;
      console.log("SERVICES apiValues = " + JSON.stringify(apiValues));

      this.servicesFattureApiValues = apiValues;
      this.apiStore = apiValues.store;

      console.log("SERVICES store = " + this.apiStore);

      let servicesLocalStorage = this.getLocalStorage();
      if (
        servicesLocalStorage === undefined &&
        servicesLocalStorage === undefined
      ) {
        this.currentServicesValues = this.servicesFattureApiValues;
      } else {
        this.currentServicesValues = servicesLocalStorage;
        this.enableSavePanel(true);
      }
      this.setVariables(this.currentServicesValues);

      const group: any = {};
      group[this.USERNAME_KEY] = new FormControl(this.kuServicesUsername, {
        validators: [],
        asyncValidators: [],
        updateOn: "blur",
      });
      group[this.PWD_KEY] = new FormControl(this.kuServicesPwd, {
        validators: [],
        asyncValidators: [],
        updateOn: "blur",
      });
      group[this.URL_KEY] = new FormControl(this.kuServicesUrl, {
        validators: [],
        asyncValidators: [],
        updateOn: "blur",
      });
      this.formGroup = this.formBuilder.group(group);

      this.enableFormValuesChangeDetection();

      this.configurationService.saveCompleteSubject.subscribe((r) => {
        console.log("SERVICES save complete = " + r);
        if (r) {
          this.ngxService.stopAll();
          this.resolver.getValues(this.apiStore).subscribe((res) => {
            if (res) {
              this.servicesFattureApiValues = res;
              this.currentServicesValues = this.servicesFattureApiValues;
              this.setVariables(this.servicesFattureApiValues);
              // Svuoto local storage
              this.setAPI(false, undefined);
            }
          });
        }
      });

      this.configurationService.restoreCompleteSubject[
        GROUP_TYPE.SERVIZI
      ].subscribe((r) => {
        console.log("SERVICES restore complete = " + r);
        if (r) {
          this.ngxService.start("service-restore");
          this.resolver.getValues(this.apiStore).subscribe((res) => {
            if (res) {
              console.log("SERVICES resolver values = " + JSON.stringify(res));
              this.servicesFattureApiValues = res;
              this.setVariables(this.servicesFattureApiValues);
              this.ngxService.stop("service-restore");
              // Svuoto local storage
              this.setAPI(false, undefined);
            }
          });
        }
      });

      this._changeStoreSubscription = this.configurationService.changeStoreCompleteSubject.subscribe((r) => {
        console.log("SERVICES change store complete = " + r);
        if (r) {
          this.changeStoreComplete();
        }
      });
      
    });
  }

  private getLocalStorage() {
    let servicesLocalStorage = undefined;
    const t = this.p2rLocalStorageService.getFromLocalStorage(
      this.SERVICES_LOCAL_STORAGE,
      [GROUP_TYPE.SERVIZI]
    );
    if (t !== undefined) {
      servicesLocalStorage = t.values;
    }
    return servicesLocalStorage;
  }

  /**
   * Check if at least one of other sections have changes.
   * @returns
   */
  private thereAreOtherChanges() {
    // Check Configuration
    const localConfValues =
      this.p2rLocalStorageService.getFromLocalStorage("CONFIGURATION");
    if (localConfValues !== undefined) {
      for (let [key, value] of Object.entries(localConfValues)) {
        if (value !== undefined && value["values"] !== undefined) {
          console.log("Found at least one category with changes: " + key);
          return true;
        }
      }
    }

    const localFattureValues = this.p2rLocalStorageService.getFromLocalStorage(
      "FATTURE",
      ["DOCUMENTS"]
    );
    if (localFattureValues !== undefined) {
      if (Object.keys(localFattureValues).length > 0) {
        console.log("FATTURE has changes");
        return true;
      }
    }

    const localOfflineValues = this.p2rLocalStorageService.getFromLocalStorage(
      "BROKERS",
      ["OFFLINE"]
    );
    if (localOfflineValues !== undefined) {
      if (Object.keys(localOfflineValues).length > 0) {
        console.log("OFFLINE has changes");
        return true;
      }
    }
    const localGeneraliValues = this.p2rLocalStorageService.getFromLocalStorage(
      "TEMPLATE_STORE_GENERALI",
      ["GENERALI"]
    );
    if (localGeneraliValues !== undefined) {
      if (Object.keys(localGeneraliValues).length > 0) {
        console.log("FATTURE has changes");
        return true;
      }
    }

    return false;
  }

  private enableSavePanel(thereIsChanges) {
    if (thereIsChanges === false) {
      this.configurationService.doCheck(this.thereAreOtherChanges());
    } else {
      this.configurationService.doCheck(true);
    }
    if (thereIsChanges) {
      // aggiorno modifiedGroups
      this.configurationService.modifiedGroups[GROUP_TYPE.SERVIZI] = "CHANGE";
      // invio modifiedGroups dentro lo stream
      this.configurationService._modifiedGroups$.next(
        this.configurationService.modifiedGroups
      );
    } else {
      // cancello i dati relativi al gruppo dentro modifiedGroups
      delete this.configurationService.modifiedGroups[GROUP_TYPE.SERVIZI];
      // invio modifiedGroups dentro lo stream
      this.configurationService._modifiedGroups$.next(
        this.configurationService.modifiedGroups
      );
    }
  }

  private setVariables(servicesStore) {
    this.kuServicesUsername = servicesStore.username;
    this.kuServicesPwd = servicesStore.password;
    this.kuServicesUrl = servicesStore.url;
  }

  public changeUsername() {
    console.log("SERVICES changeUsername = " + this.kuServicesUsername);
    const dataToDialog = {
      text: this.kuServicesUsername,
      input_title: this.p2rTranslateService.get(
        this.translateService.instant("SERVIZI.MY_CUSTOM.USERNAME")
      ),
    };
    const alert = this.p2rDialogService.open(P2RDialogComponent, {
      id: 'change-username-dialog',
      hideCloseButton: true,
      title: this.p2rTranslateService.get(
        this.translateService.instant("SERVIZI.MY_CUSTOM")
      ),
      titleClasses: "font-r_r fs24 lh-39 c11 text-uppercase",
      body: ChangeTextDialogComponent,
      data: dataToDialog,
      size: SIZE.MEDIUM,
      actions: [
        {
          label: this.p2rTranslateService.get(
            this.translateService.instant("dialog_cancel")
          ),
          click: () => {
            alert.dismiss();
          },
          classes: "btn rounded-pill text-uppercase new-alert-button font-r_l fs18",
          data: {
            remove: true,
          },
          params: {},
        },
        {
          label: this.p2rTranslateService.get(
            this.translateService.instant("dialog_confirm")
          ),
          click: (data) => {
            if (dataToDialog.text === undefined) {
              console.log("SERVICES Invalid value:\n" + JSON.stringify(data));
            } else {
              alert.dismiss();
              console.log("SERVICES update username = " + JSON.stringify(data));
              this.kuServicesUsername = dataToDialog.text;
              this.formGroup
                .get(this.USERNAME_KEY)
                .setValue(this.kuServicesUsername);
            }
          },
          data: dataToDialog,
          classes: "btn rounded-pill text-uppercase new-alert-button font-r_l fs18",
          params: {
            submit: true,
          },
        },
      ],
    });
  }

  public changePwd() {
    console.log("SERVICES changePwd = " + this.kuServicesPwd);
    const dataToDialog = {
      text: this.kuServicesPwd,
      input_title: this.p2rTranslateService.get(
        this.translateService.instant("SERVIZI.MY_CUSTOM.PWD")
      ),
    };
    const alert = this.p2rDialogService.open(P2RDialogComponent, {
      id: 'change-password-dialog',
      hideCloseButton: true,
      title: this.p2rTranslateService.get(
        this.translateService.instant("SERVIZI.MY_CUSTOM")
      ),
      titleClasses: "font-r_r fs24 lh-39 c11 text-uppercase",
      body: ChangeTextDialogComponent,
      data: dataToDialog,
      size: SIZE.MEDIUM,
      actions: [
        {
          label: this.p2rTranslateService.get(
            this.translateService.instant("dialog_cancel")
          ),
          click: () => {
            alert.dismiss();
          },
          classes: "btn rounded-pill text-uppercase new-alert-button font-r_l fs18",
          data: {
            remove: true,
          },
          params: {},
        },
        {
          label: this.p2rTranslateService.get(
            this.translateService.instant("dialog_confirm")
          ),
          click: (data) => {
            if (dataToDialog.text === undefined) {
              console.log("SERVICES Invalid value:\n" + JSON.stringify(data));
            } else {
              alert.dismiss();
              console.log("SERVICES update pwd = " + JSON.stringify(data));
              this.kuServicesPwd = dataToDialog.text;
              this.formGroup.get(this.PWD_KEY).setValue(this.kuServicesPwd);
            }
          },
          data: dataToDialog,
          classes: "btn rounded-pill text-uppercase new-alert-button font-r_l fs18",
          params: {
            submit: true,
          },
        },
      ],
    });
  }

  public changeUrl() {
    console.log("SERVICES changeUrl = " + this.kuServicesUrl);
    const dataToDialog = {
      text: this.kuServicesUrl,
      input_title: this.p2rTranslateService.get(
        this.translateService.instant("SERVIZI.MY_CUSTOM.URL")
      ),
    };
    const alert = this.p2rDialogService.open(P2RDialogComponent, {
      id: 'change-url-dialog',
      hideCloseButton: true,
      title: this.p2rTranslateService.get(
        this.translateService.instant("SERVIZI.MY_CUSTOM")
      ),
      titleClasses: "font-r_r fs24 lh-39 c11 text-uppercase",
      body: ChangeTextDialogComponent,
      data: dataToDialog,
      size: SIZE.MEDIUM,
      actions: [
        {
          label: this.p2rTranslateService.get(
            this.translateService.instant("dialog_cancel")
          ),
          click: () => {
            alert.dismiss();
          },
          classes: "btn rounded-pill text-uppercase new-alert-button font-r_l fs18",
          data: {
            remove: true,
          },
          params: {},
        },
        {
          label: this.p2rTranslateService.get(
            this.translateService.instant("dialog_confirm")
          ),
          click: (data) => {
            if (dataToDialog.text === undefined) {
              console.log("SERVICES Invalid value:\n" + JSON.stringify(data));
            } else {
              alert.dismiss();
              console.log("SERVICES update url = " + JSON.stringify(data));
              this.kuServicesUrl = dataToDialog.text;
              this.formGroup.get(this.URL_KEY).setValue(this.kuServicesUrl);
            }
          },
          data: dataToDialog,
          classes: "btn rounded-pill text-uppercase new-alert-button font-r_l fs18",
          params: {
            submit: true,
          },
        },
      ],
    });
  }

  private compareValues(apiValue, formValue) {
    if (formValue === undefined) {
      return false;
    }
    if (apiValue != formValue) {
      return true;
    } else {
      return false;
    }
  }

  private setAPI(change, value) {
    this.configurationService.saveAllList[GROUP_TYPE.SERVIZI] = [];
    if (change) {
      const updateServiceCredentialTemplate = {};
      updateServiceCredentialTemplate[this.USERNAME_KEY] = value["username"];
      updateServiceCredentialTemplate[this.PWD_KEY] = value[this.PWD_KEY];
      updateServiceCredentialTemplate[this.URL_KEY] = value[this.URL_KEY];
      updateServiceCredentialTemplate["store"] = this.apiStore;
      this.p2rLocalStorageService.addToLocalStorage(
        this.SERVICES_LOCAL_STORAGE,
        [GROUP_TYPE.SERVIZI, "values"],
        updateServiceCredentialTemplate
      );
      this.configurationService.saveAllList[GROUP_TYPE.SERVIZI].push(
        this.p2rApiService.post(
          `${this.p2rApiService.apiCorePath}/fatture/updatecredenziali`,
          undefined,
          updateServiceCredentialTemplate
        )
      );
    } else {
      if (
        this.configurationService.saveAllList[GROUP_TYPE.SERVIZI] !== undefined
      ) {
        this.configurationService.saveAllList[GROUP_TYPE.SERVIZI] = [];
      }
      this.p2rLocalStorageService.removeFromLocalStorage(
        this.SERVICES_LOCAL_STORAGE,
        [GROUP_TYPE.SERVIZI, "values"]
      );
    }
  }

  private enableFormValuesChangeDetection() {
    this.formGroup.valueChanges.subscribe((value) => {
      let thereIsChanges = false;
      Object.keys(value).forEach((k) => {
        const m = this.compareValues(value[k], this.currentServicesValues && this.currentServicesValues[k]);
        if (m) {
          thereIsChanges = true;
        }
      });
      this.setAPI(thereIsChanges, value);
      this.enableSavePanel(thereIsChanges);
    });
  }

  private changeStoreComplete() {
    const currentPv = this.p2rLocalStorageService.getFromLocalStorage(
      GlobalVariable.SELECTED_PV_KEY_STORAGE,
      []
    );
    if (currentPv !== undefined && currentPv.codice !== undefined) {
      this.apiStore = currentPv.codice;
    }
    console.log("SERVICES store = " + this.apiStore);
    this.ngxService.start();
    this.resolver.getValues(this.apiStore).subscribe((res) => {
      if (res) {
        this.selectedPv = currentPv;
        console.log("SERVICES resolver values = " + JSON.stringify(res));
        console.log("SERVICES store = " + this.apiStore);
        this.servicesFattureApiValues = res;
        this.setVariables(this.servicesFattureApiValues);
        this.ngxService.stopAll();
        // Svuoto local storage
        this.setAPI(false, undefined);
        const group: any = {};
        group[this.USERNAME_KEY] = new FormControl(this.kuServicesUsername, {
          validators: [],
          asyncValidators: [],
          updateOn: "blur",
        });
        group[this.PWD_KEY] = new FormControl(this.kuServicesPwd, {
          validators: [],
          asyncValidators: [],
          updateOn: "blur",
        });
        group[this.URL_KEY] = new FormControl(this.kuServicesUrl, {
          validators: [],
          asyncValidators: [],
          updateOn: "blur",
        });
        this.formGroup = this.formBuilder.group(group);
        this.enableFormValuesChangeDetection();
      }
    });
  }

  public selectStore() {

    const dialog = this.p2rDialogService.open(P2RDialogComponent, {
      id: 'customize-dialog',
      body: CustomizeDialogComponent,
      hideCloseButton: true,
      title: this.p2rTranslateService.get(this.translateService.instant('scegli_negozio')),
      titleClasses: 'text-uppercase font-r_r fs24 lh21 c11 mt-2',
      size: 'lg',
      /*      options: {
        id: 'customize-for-store-opt',
        titleClasses: 'customize-for-store-height'
      },*/
      data: {
        hasSearch: true,
        customizeType: CUSTOMIZE_TYPE.SELECT_STORE,
        items: (this.p2rEnvironmentService.storeData.pv || []).map(s => ({
          ...s,
          key: s.codice,
          label: s.nome,
          value: this.selectedPv.codice === s.codice ? 1 : 0
        })),
        close: () => {
          dialog.dismiss();
        },
      }
    });
  }

  ngOnDestroy(): void {
    if (this._changeStoreSubscription) {
      this._changeStoreSubscription.unsubscribe()
    }
  }
}
