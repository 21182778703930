import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { P2RDialogComponent, P2RDialogService, P2RTranslateService } from '@p2r/shared';
import { ConfigurationAlertComponent } from '../alert/alert.component';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { TEMPLATE_TYPE } from './importExport.model';
import * as FileSaver from 'file-saver';
import * as momentN from 'moment';
import { UtilsShared } from 'src/app/shared/utilsshared';
import { EXPORT_SALES_DATA_ERROR } from '../configuration.model';
import * as i0 from "@angular/core";
import * as i1 from "@p2r/platform-api";
import * as i2 from "../configuration.service";
import * as i3 from "@angular/common/http";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@p2r/shared";
const moment = momentN;
const EXCEL_TYPE = 'application/vnd.ms-excel;charset=UTF-8';
const EXCEL_EXTENSION = '.csv';
const CSV_EXTENSION = '.csv';
const CSV_TYPE = 'text/plain;charset=utf-8';
export class ImportExportService {
    constructor(p2rApiService, configurationService, http, translateService, p2rTranslateService, p2rDialogService) {
        this.p2rApiService = p2rApiService;
        this.configurationService = configurationService;
        this.http = http;
        this.translateService = translateService;
        this.p2rTranslateService = p2rTranslateService;
        this.p2rDialogService = p2rDialogService;
        this.apiPath = this.p2rApiService.apiCorePath;
    }
    importArt(file) {
        const httpCall = this.http.post(this.apiPath + '/art/createfromcsv?separator=semicolon&check_art=1&debug=1', file, {
            headers: {
                [`Content-type`]: 'text/csv'
            }
        });
        this.importDialog(httpCall, TEMPLATE_TYPE.ARTICLES);
    }
    importHallsTables(file, selectedPv) {
        const httpCall = this.http.post(this.apiPath + '/tavoli/importcsv?separator=semicolon&debug=1&store=' + selectedPv, file, {
            headers: {
                [`Content-type`]: 'text/csv'
            }
        });
        this.importDialog(httpCall, TEMPLATE_TYPE.HALLS_TABLES);
    }
    importClients(file) {
        const httpCall = this.http.post(this.apiPath + '/clienti/importcsv?separator=semicolon', file, {
            headers: {
                [`Content-type`]: 'text/csv'
            }
        });
        this.importDialog(httpCall, TEMPLATE_TYPE.CLIENTS);
    }
    exportClients() {
        this.p2rApiService.get(this.p2rApiService.apiCorePath + '/clienti/getclienticsv').subscribe((d) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            let contiuneExport = true;
            if (d.Errori && d.Errori.length) {
                contiuneExport = yield this.exportErrorDialog();
            }
            if (contiuneExport) {
                this.exportToCsv(d.csv, TEMPLATE_TYPE.CLIENTS, []);
            }
        }));
    }
    exportHallsTables(selectedPv) {
        this.p2rApiService.get(this.p2rApiService.apiCorePath + '/tavoli/getsalecsv?store=' + selectedPv).subscribe((d) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            let contiuneExport = true;
            if (d.Errori && d.Errori.length) {
                contiuneExport = yield this.exportErrorDialog();
            }
            if (contiuneExport) {
                this.exportToCsv(d.csv, TEMPLATE_TYPE.HALLS_TABLES, []);
            }
        }));
    }
    exportArt() {
        this.p2rApiService.get(this.p2rApiService.apiCorePath + '/art/getcsvart').subscribe((d) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            let contiuneExport = true;
            if (d.Errori && d.Errori.length) {
                contiuneExport = yield this.exportErrorDialog();
            }
            if (contiuneExport) {
                this.exportToCsv(d.csv, TEMPLATE_TYPE.ARTICLES, []);
            }
        }));
    }
    /*
    private mockDownloadSalesDataFile() {
      return this.http.get('http://localhost:3000/', {
        responseType: 'arraybuffer'
      });
    }
    */
    exportSalesData(store, dateTimeFrom, dateTimeTo, decimalSeparator, callback) {
        console.log("ExportSalesData\nFrom: " + dateTimeFrom + "\nTo: " + dateTimeTo + "\nStore: " + store + "\nSeparator: " + decimalSeparator);
        this.http.get(this.p2rApiService.apiCorePath + '/sales/get-data?plain_data=0&data_start=' + dateTimeFrom + '&data_end=' + dateTimeTo + '&store=' + store + '&separatore=' + decimalSeparator, { responseType: 'arraybuffer' }).subscribe(data => {
            //console.log(data);
            this.saveAsFile(data, "export_sales_data_" + UtilsShared.getCurrDateInCustomFormat() + ".zip", 'application/zip');
            if (callback) {
                callback(true);
            }
        }, err => {
            let errorType = EXPORT_SALES_DATA_ERROR.GENERIC;
            let maxDays = "93";
            console.error(err);
            try {
                let errorDecoded = (new TextDecoder("utf-8")).decode(err.error);
                errorDecoded = JSON.parse(errorDecoded);
                console.error(errorDecoded);
                const errorMessage = errorDecoded['message'];
                if (errorMessage.includes(EXPORT_SALES_DATA_ERROR.MAX_DAYS)) {
                    errorType = EXPORT_SALES_DATA_ERROR.MAX_DAYS;
                    if (errorMessage.split("#").length == 2) {
                        maxDays = errorMessage.split("#")[1];
                    }
                }
            }
            catch (e) {
                console.error("Error during decoding message error: " + e);
            }
            if (callback) {
                callback(false, errorType, maxDays);
            }
        });
    }
    saveAsFile(buffer, fileName, fileType) {
        const data = new Blob([buffer], { type: fileType });
        FileSaver.saveAs(data, fileName);
    }
    exportToCsv(list, fileName, columns) {
        const [csvHeader, ...csvBody] = list;
        let rows = [];
        const splittedHeader = csvHeader.split(";");
        for (let i = 0; i < csvBody.length; i++) {
            const content = csvBody[i];
            const splittedContent = content.split(";");
            const obj = {};
            for (let j = 0; j < splittedHeader.length; j++) {
                const header = splittedHeader[j];
                obj[header] = splittedContent[j];
            }
            rows = rows.concat(obj);
        }
        if (!rows || !rows.length) {
            const obj = {};
            for (let j = 0; j < splittedHeader.length; j++) {
                const header = splittedHeader[j];
                obj[header] = "";
            }
            rows = rows.concat(obj);
        }
        const separator = ';';
        const keys = Object.keys(rows[0]).filter(k => {
            if (columns.length) {
                return columns.includes(k);
            }
            else {
                return true;
            }
        });
        const csvContent = keys.join(separator) +
            '\n' +
            rows.map(row => {
                return keys.map(k => {
                    let cell = row[k] === null || row[k] === undefined ? '' : row[k];
                    cell = cell instanceof Date
                        ? cell.toLocaleString()
                        : cell.toString().replace(/"/g, '""');
                    if (cell.search(/("|;|\n)/g) >= 0) {
                        cell = `"${cell}"`;
                    }
                    return cell;
                }).join(separator);
            }).join('\n');
        this.saveAsFile(csvContent, `${fileName}${CSV_EXTENSION}`, CSV_TYPE);
    }
    importDialog(httpCall, templateType) {
        const progressCb = (p) => {
            document.getElementById('progress-bar').style.width = `${p}%`;
            return p;
        };
        let progress = 0;
        const alertOptions = {
            id: 'sync-art-fcic',
            body: ConfigurationAlertComponent,
            hasHead: false,
            data: {
                imgSrc: this.configurationService.getImg('icons/import_popup.svg'),
                title: `${this.p2rTranslateService.get(this.translateService.instant('import_in_progress'))} ...`,
                titleClasses: 'text-inherit font-r_r fs24 lh21 c11 mt-2',
                progressBar: {},
                messages: [
                    this.p2rTranslateService.get(this.translateService.instant('this_may_take_a_few_minutes')),
                    this.p2rTranslateService.get(this.translateService.instant('this_window_will_close_automatically_when_the_operation_is_complete'))
                ],
            }
        };
        let alert = this.p2rDialogService.open(P2RDialogComponent, alertOptions);
        progressCb(0);
        const progressInterval = setInterval(() => {
            if (progress <= 90) {
                progress += 5;
                progressCb(progress);
            }
        }, 1000);
        const exportDetails = (messages, detailMessages) => {
            const file = new Blob([`${this.p2rTranslateService.get(this.translateService.instant('import_completed'))}\n${messages.join('\n')}\n\n${detailMessages.join('\n')}`], { type: '.txt' });
            const link = document.createElement('a');
            const url = URL.createObjectURL(file);
            link.download = `${templateType}_${moment().format('YYYY-MM-DD_HH-mm')}`;
            link.href = url;
            link.click();
            setTimeout(function () {
                window.URL.revokeObjectURL(url);
            }, 0);
        };
        httpCall.pipe(tap(d => {
            clearInterval(progressInterval);
        })).subscribe(res => {
            clearInterval(progressInterval);
            alert.dismiss();
            console.log(res);
            const { messages, detailMessages } = this.prepareMessages(res, templateType);
            alert = this.p2rDialogService.open(P2RDialogComponent, Object.assign({}, alertOptions, { data: {
                    imgSrc: this.configurationService.getImg('icons/confirm.svg'),
                    title: this.p2rTranslateService.get(this.translateService.instant('import_completed')),
                    messages,
                    detailMessages
                }, actions: [
                    {
                        label: this.p2rTranslateService.get(this.translateService.instant('close')),
                        click: () => {
                            alert.dismiss();
                        },
                        classes: 'btn btn-block rounded-pill text-uppercase new-alert-button font-r_m fs14 lh22',
                        params: {}
                    },
                    {
                        label: this.p2rTranslateService.get(this.translateService.instant('save_details')),
                        click: (data) => {
                            exportDetails(data.data.messages, data.data.detailMessages);
                            alert.dismiss();
                        },
                        classes: 'btn btn-block rounded-pill text-uppercase new-alert-button font-r_m fs14 lh22',
                        params: {
                            submit: true
                        },
                        data: {
                            messages,
                            detailMessages
                        },
                    }
                ] }));
        }, err => {
            clearInterval(progressInterval);
            alert.dismiss();
            alert = this.p2rDialogService.open(P2RDialogComponent, Object.assign({}, alertOptions, { data: {
                    imgSrc: this.configurationService.getImg('icons/attenzione.svg'),
                    title: this.p2rTranslateService.get(this.translateService.instant('attention')),
                    messages: [
                        this.p2rTranslateService.get(this.translateService.instant('import_failed')),
                        err.error && err.error.message
                    ],
                }, actions: [
                    {
                        label: this.p2rTranslateService.get(this.translateService.instant('close')),
                        click: () => {
                            alert.dismiss();
                        },
                        classes: 'btn btn-block rounded-pill text-uppercase new-alert-button font-r_m fs14 lh22',
                        params: {}
                    }
                ] }));
        });
    }
    exportErrorDialog() {
        return new Promise((resolve, reject) => {
            const alert = this.p2rDialogService.open(P2RDialogComponent, {
                id: 'export-error-dialog',
                body: ConfigurationAlertComponent,
                hasHead: false,
                data: {
                    imgSrc: this.configurationService.getImg('icons/attenzione.svg'),
                    title: this.p2rTranslateService.get(this.translateService.instant('attention')),
                    messages: [
                        this.p2rTranslateService.get(this.translateService.instant('empty_archive')),
                        this.p2rTranslateService.get(this.translateService.instant('export_empty_archive'))
                    ]
                },
                actions: [
                    {
                        label: this.p2rTranslateService.get(this.translateService.instant('dialog_cancel')),
                        click: () => {
                            alert.dismiss();
                            resolve(false);
                        },
                        classes: 'btn rounded-pill text-uppercase new-alert-button',
                        data: {
                            remove: true
                        },
                        params: {}
                    },
                    {
                        label: this.p2rTranslateService.get(this.translateService.instant('export')),
                        click: () => {
                            alert.dismiss();
                            resolve(true);
                        },
                        classes: 'btn rounded-pill text-uppercase new-alert-button',
                        params: {}
                    }
                ]
            });
        });
    }
    prepareMessages(res, templateType) {
        const messages = [];
        const detailMessages = [];
        let Errori;
        switch (templateType) {
            case TEMPLATE_TYPE.ARTICLES:
                const articoli_creati = [].concat(res.articoli_creati || []);
                const reparti_creati = [].concat(res.reparti_creati || []);
                const cprod_creati = [].concat(res.cprod_creati || []);
                const art_skippati = [].concat(res.art_skippati || []);
                const cprod_skippati = [].concat(res.cprod_skippati || []);
                const reparti_skippati = [].concat(res.reparti_skippati || []);
                Errori = [].concat(res.Errori || []);
                if (articoli_creati.length) {
                    if (articoli_creati.length == 1) {
                        messages.push(articoli_creati.length + " " + this.p2rTranslateService.get(this.translateService.instant('article_imported_successfully')));
                    }
                    else if (articoli_creati.length > 1) {
                        messages.push(articoli_creati.length + " " + this.p2rTranslateService.get(this.translateService.instant('articles_imported_successfully')));
                    }
                }
                if (reparti_creati.length) {
                    if (reparti_creati.length == 1) {
                        messages.push(reparti_creati.length + " " + this.p2rTranslateService.get(this.translateService.instant('depfood_created_successfully')));
                    }
                    else if (reparti_creati.length > 1) {
                        messages.push(reparti_creati.length + " " + this.p2rTranslateService.get(this.translateService.instant('depfoods_created_successfully')));
                    }
                }
                if (cprod_creati.length) {
                    if (cprod_creati.length == 1) {
                        messages.push(cprod_creati.length + " " + this.p2rTranslateService.get(this.translateService.instant('cprod_created_successfully')));
                    }
                    else if (cprod_creati.length > 1) {
                        messages.push(cprod_creati.length + " " + this.p2rTranslateService.get(this.translateService.instant('cprods_created_successfully')));
                    }
                }
                if (art_skippati.length) {
                    if (art_skippati.length == 1) {
                        messages.push(art_skippati.length + " " + this.p2rTranslateService.get(this.translateService.instant('art_skipped')));
                    }
                    else if (art_skippati.length > 1) {
                        messages.push(art_skippati.length + " " + this.p2rTranslateService.get(this.translateService.instant('arts_skipped')));
                    }
                }
                if (cprod_skippati.length) {
                    if (cprod_skippati.length == 1) {
                        messages.push(cprod_skippati.length + " " + this.p2rTranslateService.get(this.translateService.instant('cprod_skipped')));
                    }
                    else if (cprod_skippati.length > 1) {
                        messages.push(cprod_skippati.length + " " + this.p2rTranslateService.get(this.translateService.instant('cprods_skipped')));
                    }
                }
                if (reparti_skippati.length) {
                    if (reparti_skippati.length == 1) {
                        messages.push(reparti_skippati.length + " " + this.p2rTranslateService.get(this.translateService.instant('depfood_skipped')));
                    }
                    else if (reparti_skippati.length > 1) {
                        messages.push(reparti_skippati.length + " " + this.p2rTranslateService.get(this.translateService.instant('depfoods_skipped')));
                    }
                }
                if (Errori.length) {
                    if (Errori.length == 1) {
                        messages.push(Errori.length + " " + this.p2rTranslateService.get(this.translateService.instant('ERROR')));
                    }
                    else if (Errori.length > 1) {
                        messages.push(Errori.length + " " + this.p2rTranslateService.get(this.translateService.instant('ERRORs')));
                    }
                }
                if (articoli_creati.length) {
                    for (let ni = 0; ni < articoli_creati.length; ni++) {
                        const articolo_creato = articoli_creati[ni];
                        detailMessages.push(`${this.p2rTranslateService.get(this.translateService.instant('article'))} ${this.p2rTranslateService.get(this.translateService.instant('imported')).toLowerCase()}: ${articolo_creato.description}`);
                    }
                    detailMessages.push(`-------------------------------------------------------`);
                }
                if (reparti_creati.length) {
                    for (let ni = 0; ni < reparti_creati.length; ni++) {
                        const reparto_creato = reparti_creati[ni];
                        detailMessages.push(`${this.p2rTranslateService.get(this.translateService.instant('depfood'))} ${this.p2rTranslateService.get(this.translateService.instant('created')).toLowerCase()}: ${reparto_creato.description}`);
                    }
                    detailMessages.push(`-------------------------------------------------------`);
                }
                if (cprod_creati.length) {
                    for (let ni = 0; ni < cprod_creati.length; ni++) {
                        const cprod_creato = cprod_creati[ni];
                        detailMessages.push(`${this.p2rTranslateService.get(this.translateService.instant('cprodfood'))} ${this.p2rTranslateService.get(this.translateService.instant('created')).toLowerCase()}: ${cprod_creato.description}`);
                    }
                    detailMessages.push(`-------------------------------------------------------`);
                }
                if (art_skippati.length) {
                    for (let ni = 0; ni < art_skippati.length; ni++) {
                        const art_skippato = art_skippati[ni];
                        let codeText = this.getMessage(art_skippato);
                        detailMessages.push(`${this.p2rTranslateService.get(this.translateService.instant('article'))} ${this.p2rTranslateService.get(this.translateService.instant('not')).toUpperCase()} ${this.p2rTranslateService.get(this.translateService.instant('imported')).toLowerCase()}: ${art_skippato.description} - ${codeText}`);
                    }
                    detailMessages.push(`-------------------------------------------------------`);
                }
                if (cprod_skippati.length) {
                    for (let ni = 0; ni < cprod_skippati.length; ni++) {
                        const cprod_skippato = cprod_skippati[ni];
                        let codeText = this.getMessage(cprod_skippato);
                        detailMessages.push(`${this.p2rTranslateService.get(this.translateService.instant('cprodfood'))} ${this.p2rTranslateService.get(this.translateService.instant('not')).toUpperCase()} ${this.p2rTranslateService.get(this.translateService.instant('created')).toLowerCase()}: ${cprod_skippato.description} - ${codeText}`);
                    }
                    detailMessages.push(`-------------------------------------------------------`);
                }
                if (reparti_skippati.length) {
                    for (let ni = 0; ni < reparti_skippati.length; ni++) {
                        const reparto_skippato = reparti_skippati[ni];
                        let codeText = this.getMessage(reparto_skippato);
                        detailMessages.push(`${this.p2rTranslateService.get(this.translateService.instant('depfood'))} ${this.p2rTranslateService.get(this.translateService.instant('not')).toUpperCase()} ${this.p2rTranslateService.get(this.translateService.instant('created')).toLowerCase()}: ${reparto_skippato.description} - ${codeText}`);
                    }
                    detailMessages.push(`-------------------------------------------------------`);
                }
                if (Errori.length) {
                    for (let ni = 0; ni < Errori.length; ni++) {
                        const errore = Errori[ni];
                        let codeText = this.getMessage(errore);
                        detailMessages.push(`${this.p2rTranslateService.get(this.translateService.instant('GENERIC_ERROR'))}: ${codeText}`);
                    }
                    detailMessages.push(`-------------------------------------------------------`);
                }
                break;
            case TEMPLATE_TYPE.CLIENTS:
                const clienti_creati = [].concat(res.clienti_creati || []);
                const clienti_skippati = [].concat(res.clienti_skippati || []);
                Errori = [].concat(res.Errori || []);
                if (clienti_creati.length) {
                    if (clienti_creati.length == 1) {
                        messages.push(clienti_creati.length + " " + this.p2rTranslateService.get(this.translateService.instant('client_imported_successfully')));
                    }
                    else if (clienti_creati.length > 1) {
                        messages.push(clienti_creati.length + " " + this.p2rTranslateService.get(this.translateService.instant('clients_imported_successfully')));
                    }
                }
                if (clienti_skippati.length) {
                    if (clienti_skippati.length == 1) {
                        messages.push(clienti_skippati.length + " " + this.p2rTranslateService.get(this.translateService.instant('client_skipped')));
                    }
                    else if (clienti_skippati.length > 1) {
                        messages.push(clienti_skippati.length + " " + this.p2rTranslateService.get(this.translateService.instant('clients_skipped')));
                    }
                }
                if (Errori.length) {
                    if (Errori.length == 1) {
                        messages.push(Errori.length + " " + this.p2rTranslateService.get(this.translateService.instant('ERROR')));
                    }
                    else if (Errori.length > 1) {
                        messages.push(Errori.length + " " + this.p2rTranslateService.get(this.translateService.instant('ERRORs')));
                    }
                }
                if (clienti_creati.length) {
                    for (let ni = 0; ni < clienti_creati.length; ni++) {
                        const cliente_creato = clienti_creati[ni];
                        detailMessages.push(`${this.p2rTranslateService.get(this.translateService.instant('client'))} ${this.p2rTranslateService.get(this.translateService.instant('imported')).toLowerCase()}: ${cliente_creato.description}`);
                    }
                    detailMessages.push(`-------------------------------------------------------`);
                }
                if (clienti_skippati.length) {
                    for (let ni = 0; ni < clienti_skippati.length; ni++) {
                        const client_skippato = clienti_skippati[ni];
                        let codeText = this.getMessage(client_skippato);
                        detailMessages.push(`${this.p2rTranslateService.get(this.translateService.instant('client'))} ${this.p2rTranslateService.get(this.translateService.instant('not')).toUpperCase()} ${this.p2rTranslateService.get(this.translateService.instant('imported')).toLowerCase()}: ${client_skippato.description} - ${codeText}`);
                    }
                    detailMessages.push(`-------------------------------------------------------`);
                }
                if (Errori.length) {
                    for (let ni = 0; ni < Errori.length; ni++) {
                        const errore = Errori[ni];
                        let codeText = this.getMessage(errore);
                        detailMessages.push(`${this.p2rTranslateService.get(this.translateService.instant('GENERIC_ERROR'))}: ${codeText}`);
                    }
                    detailMessages.push(`-------------------------------------------------------`);
                }
                break;
            case TEMPLATE_TYPE.HALLS_TABLES:
                const tavoli_creati = [].concat(res.tavoli_creati || []);
                const sale_creati = [].concat(res.sale_creati || []);
                const tavoli_skippati = [].concat(res.tavoli_skippati || []);
                const sale_skippati = [].concat(res.sale_skippati || []);
                Errori = [].concat(res.Errori || []);
                if (tavoli_creati.length) {
                    if (tavoli_creati.length == 1) {
                        messages.push(tavoli_creati.length + " " + this.p2rTranslateService.get(this.translateService.instant('table_imported_successfully')));
                    }
                    else if (tavoli_creati.length > 1) {
                        messages.push(tavoli_creati.length + " " + this.p2rTranslateService.get(this.translateService.instant('tables_imported_successfully')));
                    }
                }
                if (sale_creati.length) {
                    if (sale_creati.length == 1) {
                        messages.push(sale_creati.length + " " + this.p2rTranslateService.get(this.translateService.instant('hall_created_successfully')));
                    }
                    else if (sale_creati.length > 1) {
                        messages.push(sale_creati.length + " " + this.p2rTranslateService.get(this.translateService.instant('halls_created_successfully')));
                    }
                }
                if (tavoli_skippati.length) {
                    if (tavoli_skippati.length == 1) {
                        messages.push(tavoli_skippati.length + " " + this.p2rTranslateService.get(this.translateService.instant('table_skipped')));
                    }
                    else if (tavoli_skippati.length > 1) {
                        messages.push(tavoli_skippati.length + " " + this.p2rTranslateService.get(this.translateService.instant('tables_skipped')));
                    }
                }
                if (sale_skippati.length) {
                    if (sale_skippati.length == 1) {
                        messages.push(sale_skippati.length + " " + this.p2rTranslateService.get(this.translateService.instant('hall_skipped')));
                    }
                    else if (sale_skippati.length > 1) {
                        messages.push(sale_skippati.length + " " + this.p2rTranslateService.get(this.translateService.instant('halls_skipped')));
                    }
                }
                if (Errori.length) {
                    if (Errori.length == 1) {
                        messages.push(Errori.length + " " + this.p2rTranslateService.get(this.translateService.instant('ERROR')));
                    }
                    else if (Errori.length > 1) {
                        messages.push(Errori.length + " " + this.p2rTranslateService.get(this.translateService.instant('ERRORs')));
                    }
                }
                if (tavoli_creati.length) {
                    for (let ni = 0; ni < tavoli_creati.length; ni++) {
                        const tavolo_creato = tavoli_creati[ni];
                        detailMessages.push(`${this.p2rTranslateService.get(this.translateService.instant('table'))} ${this.p2rTranslateService.get(this.translateService.instant('imported')).toLowerCase()}: ${tavolo_creato.description}`);
                    }
                    detailMessages.push(`-------------------------------------------------------`);
                }
                if (sale_creati.length) {
                    for (let ni = 0; ni < sale_creati.length; ni++) {
                        const sala_creato = sale_creati[ni];
                        detailMessages.push(`${this.p2rTranslateService.get(this.translateService.instant('hall'))} ${this.p2rTranslateService.get(this.translateService.instant('imported')).toLowerCase()}: ${sala_creato.description}`);
                    }
                    detailMessages.push(`-------------------------------------------------------`);
                }
                if (tavoli_skippati.length) {
                    for (let ni = 0; ni < tavoli_skippati.length; ni++) {
                        const tavolo_skippato = tavoli_skippati[ni];
                        let codeText = this.getMessage(tavolo_skippato);
                        detailMessages.push(`${this.p2rTranslateService.get(this.translateService.instant('table'))} ${this.p2rTranslateService.get(this.translateService.instant('not')).toUpperCase()} ${this.p2rTranslateService.get(this.translateService.instant('imported')).toLowerCase()}: ${tavolo_skippato.description} - ${codeText}`);
                    }
                    detailMessages.push(`-------------------------------------------------------`);
                }
                if (sale_skippati.length) {
                    for (let ni = 0; ni < sale_skippati.length; ni++) {
                        const sala_skippata = sale_skippati[ni];
                        let codeText = this.getMessage(sala_skippata);
                        detailMessages.push(`${this.p2rTranslateService.get(this.translateService.instant('hall'))} ${this.p2rTranslateService.get(this.translateService.instant('not')).toUpperCase()} ${this.p2rTranslateService.get(this.translateService.instant('imported')).toLowerCase()}: ${sala_skippata.description} - ${codeText}`);
                    }
                    detailMessages.push(`-------------------------------------------------------`);
                }
                if (Errori.length) {
                    for (let ni = 0; ni < Errori.length; ni++) {
                        const errore = Errori[ni];
                        let codeText = this.getMessage(errore);
                        detailMessages.push(`${this.p2rTranslateService.get(this.translateService.instant('GENERIC_ERROR'))}: ${codeText}`);
                    }
                    detailMessages.push(`-------------------------------------------------------`);
                }
                break;
            default:
                break;
        }
        return {
            messages,
            detailMessages
        };
    }
    getMessage(opt) {
        const { code, description, details } = opt;
        switch (code) {
            case 'EXIST':
                return this.p2rTranslateService.get(this.translateService.instant('EXIST'));
            case 'MISSING_FIELD':
            case 'ERR_FORMAT':
                return details;
            case 'GENERIC_ERROR':
            default:
                return description;
        }
    }
}
ImportExportService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ImportExportService_Factory() { return new ImportExportService(i0.ɵɵinject(i1.P2RApiService), i0.ɵɵinject(i2.ConfigurationService), i0.ɵɵinject(i3.HttpClient), i0.ɵɵinject(i4.TranslateService), i0.ɵɵinject(i5.P2RTranslateService), i0.ɵɵinject(i5.P2RDialogService)); }, token: ImportExportService, providedIn: "root" });
