import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { P2RDialogComponent, P2RDialogService, P2RLocalStorageService, P2RTranslateService } from '@p2r/shared';
import { GROUP_TYPE, TAKEAWAY_FIELD_TYPES } from '../configuration.model';
import { ConfigurationService } from '../configuration.service';
import { TimeSlotAlertComponent } from '../time-slot-alert/time-slot-alert.component';
import { SIZE } from "@p2r/shared/lib/shared/p2r-dialog/p2r-dialog.interface";
import { TranslateService } from "@ngx-translate/core";
import { ORDER_PRINTING_TYPE, TakeawayPeriod } from './takeaway.model';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import * as momentN from 'moment';
import { ChangeDescriptionDialogComponent } from '../change-description-dialog/change-description-dialog.component';
import { ChangeTextDialogComponent } from '../change-text-dialog/change-text-dialog.component';
import { ConfigurationAlertComponent } from '../alert/alert.component';
import { CustomizeDialogComponent } from '../../customize-dialog/customize-dialog.component';
import { CUSTOMIZE_TYPE } from '../../customize-dialog/customize-dialog.model';
import { GlobalVariable } from '../../global';
const moment = momentN;

@Component({
  selector: 'app-takeaway',
  templateUrl: './takeaway.component.html',
  styleUrls: ['./takeaway.component.scss']
})
export class TakeawayComponent implements OnInit {

  typegrop: GROUP_TYPE;
  enableTakeaway: boolean;

  takeawayStart: number = 0;
  takeawayEnd: number = 0;

  public images: {
    add: string,
    remove: string
  };

  casse: any[];
  casseAll: any[];

  timeSlots: any[] = [
    {
      slot: 5,
      selected: false
    },
    {
      slot: 10,
      selected: false
    },
    {
      slot: 15,
      selected: false
    },
    {
      slot: 20,
      selected: false
    },
    {
      slot: 30,
      selected: false
    },
    {
      slot: 60,
      selected: false
    },
  ]

  orderPrintingTypes: any[] = [
    {
      type: ORDER_PRINTING_TYPE.ON_ORDER,
      selected: false
    },
    {
      type: ORDER_PRINTING_TYPE.BEFORE_DELIVERY,
      selected: false
    },
  ]

  selectedOrderPrintingType: number = 0;
  timeBeforePrintingOrder: number;
  takeawayMainPrinterCash: string;

  formGroup: FormGroup;
  takeawayPeriods: TakeawayPeriod[];

  ORDER_PRINTING_TYPE = ORDER_PRINTING_TYPE;

  constructor(
    public configurationService: ConfigurationService,
    private formBuilder: FormBuilder,
    private p2rDialogService: P2RDialogService,
    private p2rTranslateService: P2RTranslateService,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private p2rLocalStorageService: P2RLocalStorageService
  ) {
    this.typegrop = GROUP_TYPE.TAKEAWAY;
    this.images = {
      add: this.configurationService.ICONS.ADD,
      remove: this.configurationService.ICONS.REMOVE
    };
  }

  ngOnInit() {

    this.configurationService.selectedGroup = GROUP_TYPE.TAKEAWAY;

    this.route.data.subscribe(({ data }) => {
      const [items, values, casse] = data;

      // this.items = items;
      this.casseAll = casse;
      this.filterCasseByStore();

      const group: any = {};

      Object.keys(values).forEach(key => {
        group[key] = new FormControl(values[key], {
          validators: [],
          asyncValidators: [],
          updateOn: 'blur'
        });
      });

      this.initializeVariables(values);
      this.configurationService.formGroup = this.formBuilder.group(group);
    });

    this.configurationService.values$().subscribe(values => {
      values = values[GROUP_TYPE.TAKEAWAY];
      if (values !== undefined) {
        if (this.configurationService._selectedGroup === GROUP_TYPE.TAKEAWAY) {
          this.initializeVariables(values);

          this.filterCasseByStore();
        }
      }
    });
  }

  public initializeVariables(values: any) {
    this.enableTakeaway = values[TAKEAWAY_FIELD_TYPES.KU_ASPORTO_ENABLED];
    this.takeawayStart = values[TAKEAWAY_FIELD_TYPES.KU_ASPORTO_START];
    this.takeawayEnd = values[TAKEAWAY_FIELD_TYPES.KU_ASPORTO_END];
    this.takeawayPeriods = _.cloneDeep(values[TAKEAWAY_FIELD_TYPES.KU_ASPORTO_PERIODS])
    this.takeawayMainPrinterCash = values[TAKEAWAY_FIELD_TYPES.KU_ASPORTO_PRINTER_CASH]

    if (!this.takeawayMainPrinterCash || Array.isArray(this.takeawayMainPrinterCash)) {
      this.takeawayMainPrinterCash = this.casse[0].key;
    }

    if (!this.takeawayPeriods.length) {
      this.takeawayPeriods.push({
        START: 8 * 60,
        END: 24 * 59.98,
        DESCRIPTION: this.p2rTranslateService.get(this.translateService.instant('service_1'))
      })
    }

    this.timeSlots = this.timeSlots.map(ts => ({
      ...ts,
      selected: ts.slot === (values[TAKEAWAY_FIELD_TYPES.KU_ASPORTO_SLOT_TIME] || 60)
    }))

    this.orderPrintingTypes = this.orderPrintingTypes.map(op => ({
      ...op,
      selected: op.type === values[TAKEAWAY_FIELD_TYPES.KU_ASPORTO_PRINT_ORDER] ? true : false
    }))

    this.selectedOrderPrintingType = this.orderPrintingTypes.findIndex(op => op.selected);

    this.timeBeforePrintingOrder = values[TAKEAWAY_FIELD_TYPES.KU_ASPORTO_TIME_TO_PRINT_ORDER_BEFORE_DELIVERY]
  }

  public onClickEnableTakeaway() {
    this.enableTakeaway = !this.enableTakeaway;
    this.configurationService.formGroup.get(TAKEAWAY_FIELD_TYPES.KU_ASPORTO_ENABLED).setValue(this.enableTakeaway);
  }

  public openChangeTimeSlot(index) {

    const startTime = this.convertMinutesToHourMinutes(this.takeawayPeriods[index].START);
    const endTime = this.convertMinutesToHourMinutes(this.takeawayPeriods[index].END);

    let dataToDialog: any = {
      type: GROUP_TYPE.TAKEAWAY,
      startTime: {
        hour: this.pad(parseInt(startTime.split(':')[0], 10)),
        minutes: this.pad(parseInt(startTime.split(':')[1], 10))
      },
      endTime: {
        hour: this.pad(parseInt(endTime.split(':')[0], 10)),
        minutes: this.pad(parseInt(endTime.split(':')[1], 10))
      }
    }
    const alert = this.p2rDialogService.open(P2RDialogComponent, {
      id: 'takeaway-timeslot',
      hasHead: false,
      body: TimeSlotAlertComponent,
      data: dataToDialog,
      size: SIZE.LARGE,
      actions: [
        {
          label: this.p2rTranslateService.get(this.translateService.instant('dialog_cancel')),
          click: () => {
            alert.dismiss();
          },
          classes: 'btn rounded-pill text-uppercase new-alert-button font-r_l fs18',
          data: {
            remove: true
          },
          params: {
          }
        },
        {
          label: this.p2rTranslateService.get(this.translateService.instant('dialog_confirm')),
          click: async (data) => {
            if (dataToDialog.startTime.hour == null
              || dataToDialog.endTime.hour == null
              || dataToDialog.startTime.hour < 0
              || dataToDialog.startTime.hour > 23
              || dataToDialog.endTime.hour < 0
              || dataToDialog.endTime.hour > 23) {
              this.configurationService.showErrorAlert([
                this.p2rTranslateService.get(this.translateService.instant('you_entered_an_incorrect_time_format')),
                this.p2rTranslateService.get(this.translateService.instant('the_hours_must_be_between_0_and_23')),
              ])
            } else if (dataToDialog.startTime.minutes == null
              || dataToDialog.endTime.minutes == null
              || dataToDialog.startTime.minutes < 0
              || dataToDialog.startTime.minutes > 59
              || dataToDialog.endTime.minutes < 0
              || dataToDialog.endTime.minutes > 59) {
              this.configurationService.showErrorAlert([
                this.p2rTranslateService.get(this.translateService.instant('you_entered_an_incorrect_time_format')),
                this.p2rTranslateService.get(this.translateService.instant('the_minutes_must_be_between_0_and_59')),
              ])
            } else {
              const startTime = dataToDialog.startTime.hour + ':' + dataToDialog.startTime.minutes;
              const endTime = dataToDialog.endTime.hour + ':' + dataToDialog.endTime.minutes;
              const startTimeM = moment(startTime, 'HH:mm');
              const endTimeM = moment(endTime, 'HH:mm');
              const startDayM = moment().clone().startOf('day');

              const startMinutes = startTimeM.diff(startDayM, 'minutes');
              const endMinutes = endTimeM.diff(startDayM, 'minutes');

              const tp = _.cloneDeep(this.takeawayPeriods);

              tp[index].START = startMinutes;
              tp[index].END = endMinutes;

              if ((tp.length > 1
                && tp[0].END > tp[1].START)
                || (tp[index].END < tp[index].START)) {
                this.configurationService.showErrorAlert([
                  this.p2rTranslateService.get(this.translateService.instant('the_durations_of_the_two_service_periods_overlap')),
                  this.p2rTranslateService.get(this.translateService.instant('change_the_start_or_end_time')),
                ])
              } else {
                let proceed: boolean = true;

                if ((this.takeawayPeriods[index].START !== startMinutes) || (this.takeawayPeriods[index].END !== endMinutes)) {
                  proceed = await this.askProceed();
                }

                if (proceed) {
                  this.takeawayPeriods[index].START = startMinutes;
                  this.takeawayPeriods[index].END = endMinutes;
                  alert.dismiss();

                  this.configurationService.formGroup.get(TAKEAWAY_FIELD_TYPES.KU_ASPORTO_PERIODS)
                    .setValue(this.takeawayPeriods);
                }
              }
            }
          },
          data: dataToDialog,
          classes: 'btn rounded-pill text-uppercase new-alert-button font-r_l fs18',
          params: {
            submit: true
          }
        }
      ]
    });

  }

  public askProceed = () => new Promise<boolean>(res => {
    const proceedAlert = this.p2rDialogService.open(P2RDialogComponent, {
      id: 'ask-proceed-dialog',
      body: ConfigurationAlertComponent,
      hasHead: false,
      data: {
        imgSrc: this.configurationService.getImg("icons/attenzione.svg"),
        title: this.p2rTranslateService.get(
          this.translateService.instant("attention")
        ),
        messages: [
          this.p2rTranslateService.get(
            this.translateService.instant("by_carrying_out_this_operation_some_of_the_orders_in_progress_may_no_longer_be_visible")
          ),
          this.p2rTranslateService.get(
            this.translateService.instant("are_you_sure_you_want_to_proceed_or_want_to_cancel_the_operation")
          )
        ],
      },
      actions: [
        {
          label: this.p2rTranslateService.get(
            this.translateService.instant("proceed")
          ),
          click: () => {
            proceedAlert.dismiss();
            res(true);
          },
          classes: "btn rounded-pill text-uppercase new-alert-button",
          data: {
            remove: true,
          },
          params: {
          },
        },
        {
          label: this.p2rTranslateService.get(
            this.translateService.instant("dialog_cancel")
          ),
          click: () => {
            proceedAlert.dismiss();
            res(false);
          },
          classes: "btn rounded-pill text-uppercase new-alert-button",
          data: {
            remove: true,
          },
          params: {
          },
        },
      ],
    });
  })

  public openChangeTimeSlotDescription(index: number) {
    const selectedTimeSlotDesc = this.takeawayPeriods[index].DESCRIPTION;
    const dataToDialog = {
      description: selectedTimeSlotDesc
    };
    const alert = this.p2rDialogService.open(P2RDialogComponent, {
      id: 'change-timeslot-description-dialog',
      hideCloseButton: true,
      title: this.p2rTranslateService.get(this.translateService.instant('change_description_dialog_title')),
      titleClasses: 'font-r_r fs24 lh-39 c11 text-uppercase',
      body: ChangeDescriptionDialogComponent,
      data: dataToDialog,
      size: SIZE.MEDIUM,
      actions: [
        {
          label: this.p2rTranslateService.get(this.translateService.instant('dialog_cancel')),
          click: () => {
            alert.dismiss();
          },
          classes: 'btn rounded-pill text-uppercase new-alert-button font-r_l fs18',
          data: {
            remove: true
          },
          params: {
          }
        },
        {
          label: this.p2rTranslateService.get(this.translateService.instant('dialog_confirm')),
          click: (data) => {
            if (dataToDialog.description === undefined || dataToDialog.description.length === 0) {
              console.log('Invalid value:\n' + JSON.stringify(data));
            } else {
              alert.dismiss();
              console.log('UPDATE DESC:\n' + JSON.stringify(data));

              this.takeawayPeriods[index].DESCRIPTION = dataToDialog.description;

              alert.dismiss();

              this.configurationService.formGroup.get(TAKEAWAY_FIELD_TYPES.KU_ASPORTO_PERIODS)
                .setValue(this.takeawayPeriods);
            }
          },
          data: dataToDialog,
          classes: 'btn rounded-pill text-uppercase new-alert-button font-r_l fs18',
          params: {
            submit: true
          }
        }
      ]
    });
  }

  public addTimeSlot() {
    const firstPeriod = this.takeawayPeriods[0];

    if (!firstPeriod.END) {
      this.configurationService.showErrorAlert([
        this.p2rTranslateService.get(this.translateService.instant('before_adding_the_second_period_it_is_necessary_to_configure_the_first'))
      ])
    } else {
      this.takeawayPeriods = this.takeawayPeriods.concat({
        START: firstPeriod.END,
        END: 24 * 59.98,
        DESCRIPTION: this.p2rTranslateService.get(this.translateService.instant('service_2'))
      })

      this.configurationService.formGroup.get(TAKEAWAY_FIELD_TYPES.KU_ASPORTO_PERIODS)
        .setValue(this.takeawayPeriods);
    }
  }

  public async removeTimeSlot(index) {

    const proceed = await this.askProceed();

    if (proceed) {
      this.takeawayPeriods.splice(index, 1);

      this.configurationService.formGroup.get(TAKEAWAY_FIELD_TYPES.KU_ASPORTO_PERIODS)
        .setValue(this.takeawayPeriods);
    }
  }

  public convertMinutesToHourMinutes(totalMinutes) {
    let h = Math.floor(totalMinutes / 60);
    let hours;
    if (h < 10) {
      hours = "0" + h;
    } else {
      hours = h * 1;
    }

    let m = Math.floor(totalMinutes % 60);
    let minutes;
    if (m == 0) {
      minutes = "00";
    } else if (m < 10) {
      minutes = "0" + m;
    } else {
      minutes = m * 1;
    }

    return hours + ":" + minutes;
  }

  public changeTimeSlot(slot) {

    this.configurationService.showErrorAlert([
      {
        text: this.p2rTranslateService.get(this.translateService.instant('you_are_changing_the_duration_of_the_time_slots')),
        class: 'mb-2'
      },
      this.p2rTranslateService.get(this.translateService.instant('make_sure_that_in_the_ARCHIVES_TAKEAWAY_PROCESSING_CENTERS_section_the_capacities_are_adequately_sized'))
    ])

    this.timeSlots = this.timeSlots.map(ts => ({
      ...ts,
      selected: ts.slot === slot ? true : false
    }))

    this.configurationService.formGroup.get(TAKEAWAY_FIELD_TYPES.KU_ASPORTO_SLOT_TIME)
      .setValue(slot);
  }

  public changeOrderPrintingType(type) {
    this.orderPrintingTypes = this.orderPrintingTypes.map(op => ({
      ...op,
      selected: op.type === type ? true : false
    }))

    this.selectedOrderPrintingType = this.orderPrintingTypes.findIndex(op => op.selected);

    this.configurationService.formGroup.get(TAKEAWAY_FIELD_TYPES.KU_ASPORTO_PRINT_ORDER)
      .setValue(type);
  }

  public getTranslate(key) {
    switch (key) {
      case ORDER_PRINTING_TYPE.ON_ORDER:
        return 'TAKEAWAY.ORDER_PRINTING.ON_ORDER'
      case ORDER_PRINTING_TYPE.BEFORE_DELIVERY:
        return 'TAKEAWAY.ORDER_PRINTING.BEFORE_DELIVERY'
      default:
        return '';
    }
  }

  public getPrinterCashLabel() {
    return this.casse.find(c => c.key === this.takeawayMainPrinterCash).label;
  }

  public changeTimeBeforeDelivery() {
    const dataToDialog = {
      text: this.timeBeforePrintingOrder,
      input_title: this.p2rTranslateService.get(
        this.translateService.instant("set_how_long_before_delivery_print_the_order_minutes")
      ),
    };
    const alert = this.p2rDialogService.open(P2RDialogComponent, {
      id: 'change-time-before-delivery-dialog',
      hideCloseButton: true,
      title: this.p2rTranslateService.get(
        this.translateService.instant("TAKEAWAY.ORDER_PRINTING.TITLE")
      ),
      titleClasses: "font-r_r fs24 lh-39 c11 text-uppercase",
      body: ChangeTextDialogComponent,
      data: dataToDialog,
      size: SIZE.MEDIUM,
      actions: [
        {
          label: this.p2rTranslateService.get(
            this.translateService.instant("dialog_cancel")
          ),
          click: () => {
            alert.dismiss();
          },
          classes: "btn rounded-pill text-uppercase new-alert-button font-r_l fs18",
          data: {
            remove: true,
          },
          params: {},
        },
        {
          label: this.p2rTranslateService.get(
            this.translateService.instant("dialog_confirm")
          ),
          click: (data) => {
            if (!dataToDialog.text || isNaN(+dataToDialog.text)) {
              this.configurationService.showErrorAlert(
                this.p2rTranslateService.get(
                  this.translateService.instant("not_valid_input_error")
                )
              );
            } else {
              alert.dismiss();
              this.timeBeforePrintingOrder = dataToDialog.text;
              this.configurationService.formGroup.get(TAKEAWAY_FIELD_TYPES.KU_ASPORTO_TIME_TO_PRINT_ORDER_BEFORE_DELIVERY).setValue(+dataToDialog.text);
            }
          },
          data: dataToDialog,
          classes: "btn rounded-pill text-uppercase new-alert-button font-r_l fs18",
          params: {
            submit: true,
          },
        },
      ],
    });
  }

  public chooseCassa() {

    const dialog = this.p2rDialogService.open(P2RDialogComponent, {
      id: 'customize-dialog',
      body: CustomizeDialogComponent,
      hideCloseButton: true,
      title: this.p2rTranslateService.get(this.translateService.instant('TAKEAWAY.SENDING_ORDERS.TITLE')),
      titleClasses: 'text-uppercase font-r_r fs24 lh21 c11 mt-2',
      size: 'lg',
      /*      options: {
        id: 'customize-for-store-opt',
        titleClasses: 'customize-for-store-height'
      },*/
      data: {
        hasSearch: true,
        customizeType: CUSTOMIZE_TYPE.SELECT_CASHDESK,
        items: this.casse.map(s => ({
          ...s,
          value: this.takeawayMainPrinterCash === s.key ? 1 : 0
        })),
        close: (key) => {
          if (key) {
            this.takeawayMainPrinterCash = key;
            this.configurationService.formGroup.get(TAKEAWAY_FIELD_TYPES.KU_ASPORTO_PRINTER_CASH)
              .setValue(key);
          }

          dialog.dismiss();
        },
      }
    });
  }

  public filterCasseByStore() {
    const selectedPv = this.p2rLocalStorageService.getFromLocalStorage(
      GlobalVariable.SELECTED_PV_KEY_STORAGE,
      []
    );
    let casseTemp = [];
    this.casseAll.forEach(cassa => {
      if (cassa.XSF03 == selectedPv.codice) {
        casseTemp.push(cassa);
      }
    });
    this.casse = casseTemp;
  }

  private pad(value: number) {
    if (value === 0 && String(value).length === 1) {
      return '0' + value;
    } else {
      if (!String(value).startsWith('0')) {
        if (value < 10) {
          return '0' + value;
        } else {
          return value;
        }
      } else {
        return value;
      }
    }
  }

}
