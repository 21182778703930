<div>
    <div class="sub-section-container">
        <div>
            <p
                class="section-title"
                style="text-transform: uppercase;"
            >
                {{'TAKEAWAY' | translate}}
            </p>
            <p class="sub-section-description-2 font-r_r fs16 lh16 c10">{{'TAKEAWAY.SUBTITLE' | translate}}</p>
        </div>
        <div class="sub-section-checkbox-2">
            <img
                [src]="enableTakeaway ? configurationService.getImg('/icons/switch-on.svg') :
          configurationService.getImg('/icons/switch-off.svg')"
                (click)="onClickEnableTakeaway()"
            >
        </div>
    </div>
    <hr>
    <div
        class="sub-section-container"
        [class.disabled]="!enableTakeaway"
    >
        <div class="d-flex">
            <p
                class="section-title-2"
                style="text-transform: uppercase;"
            >
                {{'TAKEAWAY.SERVICE_PERIOD.TITLE' | translate}}
            </p>
            <ng-container *ngIf="takeawayPeriods.length < 2 && (takeawayPeriods[0].END && takeawayPeriods[0].END < 1380)">
                <img
                    [src]="configurationService.getImg(images.add)"
                    class="icon"
                    style="margin-left: 12px;"
                    width="40"
                    height="40"
                    (click)="addTimeSlot()"
                >
            </ng-container>
        </div>
    </div>
    <hr>
    <div
        *ngFor="let takeawayPeriod of takeawayPeriods; index as i"
        [class.disabled]="!enableTakeaway"
    >
        <div class="align-items-center d-flex sub-section-container">
            <div (click)="openChangeTimeSlotDescription(i)">
                <p class="sub-section-title">{{takeawayPeriod.DESCRIPTION}}</p>
            </div>
            <div class="sub-section-number align-items-center d-flex">
                <div (click)="openChangeTimeSlot(i)">
                    <div
                        *ngIf="takeawayPeriod.START + takeawayPeriod.END; else noBrokerIp"
                        class="mr-1"
                    >
                        {{convertMinutesToHourMinutes(takeawayPeriod.START)}} - {{convertMinutesToHourMinutes(takeawayPeriod.END)}}
                    </div>
                    <ng-template #noBrokerIp>00:00 - 23:59</ng-template>
                </div>
                <ng-container *ngIf="i == 1; else noImg">
                    <img
                        [src]="configurationService.getImg(images.remove)"
                        class="icon"
                        style="margin-left: 12px;"
                        width="40"
                        height="40"
                        (click)="removeTimeSlot(i)"
                    >
                </ng-container>
                <ng-template #noImg>
                    <div style="width: 40px; height: 40px; margin-left: 12px;"></div>
                </ng-template>
            </div>
        </div>
        <hr>
    </div>
    <div
        class="sub-section-container"
        [class.disabled]="!enableTakeaway"
    >
        <div>
            <p
                class="section-title-2"
                style="text-transform: uppercase;"
            >
                {{'TAKEAWAY.TIME_SLOTS.TITLE' | translate}}
            </p>
            <p class="sub-section-description-2 font-r_r fs16 lh16 c10">
                {{'TAKEAWAY.TIME_SLOTS.SUBTITLE' | translate}}
            </p>
        </div>
    </div>
    <hr>
    <div
        *ngFor="let timeSlot of timeSlots;"
        [class.disabled]="!enableTakeaway"
    >
        <div class="align-items-center d-flex sub-section-container">
            <div>
                <p class="sub-section-title">
                    {{timeSlot.slot}} {{'TAKEAWAY.TIME_SLOTS.MINUTES' | translate}}
                </p>
            </div>
            <div class="sub-section-checkbox">
                <img
                    style="width: 40px; height: 40px"
                    [src]="timeSlot.selected ? configurationService.getImg('/icons/checkbox-selected.svg') : configurationService.getImg('/icons/checkbox-not_selected.svg')"
                    (click)="changeTimeSlot(timeSlot.slot)"
                >
            </div>
        </div>
        <hr>
    </div>
    <div
        class="sub-section-container"
        [class.disabled]="!enableTakeaway"
    >
        <div>
            <p
                class="section-title-2"
                style="text-transform: uppercase;"
            >
                {{'TAKEAWAY.ORDER_PRINTING.TITLE' | translate}}
            </p>
            <p class="sub-section-description-2 font-r_r fs16 lh16 c10">
                {{'TAKEAWAY.ORDER_PRINTING.SUBTITLE' | translate}}
            </p>
        </div>
    </div>
    <hr>
    <div
        *ngFor="let orderPrintingType of orderPrintingTypes;"
        [class.disabled]="!enableTakeaway"
    >
        <div class="align-items-center d-flex sub-section-container justify-content-between">
            <div>
                <p class="sub-section-title">
                    {{getTranslate(orderPrintingType.type) | translate}}
                </p>
            </div>
            <div class="align-items-center d-flex">
                <div
                    class="sub-section-number mr-3"
                    (click)="changeTimeBeforeDelivery()"
                    *ngIf="(selectedOrderPrintingType === ORDER_PRINTING_TYPE.BEFORE_DELIVERY) && (orderPrintingType.type === ORDER_PRINTING_TYPE.BEFORE_DELIVERY)"
                >
                    {{timeBeforePrintingOrder}} {{ 'minutes' | translate}}
                </div>
                <div class="sub-section-checkbox">
                    <img
                        style="width: 40px; height: 40px"
                        [src]="orderPrintingType.selected ? configurationService.getImg('/icons/checkbox-selected.svg') : configurationService.getImg('/icons/checkbox-not_selected.svg')"
                        (click)="changeOrderPrintingType(orderPrintingType.type)"
                    >
                </div>
            </div>
        </div>
        <hr>
    </div>
    <div
        class="align-items-center d-flex justify-content-between sub-section-container"
        style="margin-right: 32px;"
        [class.disabled]="!enableTakeaway || !(selectedOrderPrintingType === ORDER_PRINTING_TYPE.BEFORE_DELIVERY)"
    >
        <div>
            <p
                class="section-title-2"
                style="text-transform: uppercase;"
            >
                {{'TAKEAWAY.SENDING_ORDERS.TITLE' | translate}}
            </p>
            <p class="sub-section-description-2 font-r_r fs16 lh16 c10">
                {{'TAKEAWAY.SENDING_ORDERS.SUBTITLE' | translate}}
            </p>
        </div>
        <div>
            <div class="d-flex align-items-center">
                <span class="sub-section-number mr-3">{{getPrinterCashLabel()}}</span>
                <button
                    class="btn rounded-pill btn-block btn-primary text-uppercase pl-3 pr-3"
                    style="height: 40px;"
                    (click)="chooseCassa()"
                >
                    <span class="font-r_r fs18 c13 lh21">
                        <span>{{'choose' | translate | uppercase}}</span>
                    </span>
                </button>
            </div>
        </div>
    </div>
    <hr>
</div>
