import * as _ from 'lodash';
import { getChangedKeys } from '../configuration.utils';
import { P2R_FIELD_TYPE } from '@p2r/shared';
import { ReplaySubject } from 'rxjs';
import { P2R_CENTER_DETT } from '@p2r/platform-api';
import { CONFIGURATION_SOURCE } from '../configuration.model';
import { map, tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@p2r/shared";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@p2r/platform-api";
export class PaymentsService {
    constructor(p2rTranslateService, translateService, apiService) {
        this.p2rTranslateService = p2rTranslateService;
        this.translateService = translateService;
        this.apiService = apiService;
        this._doCheck = new ReplaySubject(1);
        this.METHOD_PAYMENT_TYPE = [
            {
                key: 'cash',
                label: this.p2rTranslateService.get(this.translateService.instant('METHOD_PAYMENT.CASH')),
                value: 1,
                fieldType: P2R_FIELD_TYPE.CHECK
            },
            {
                key: 'electronic_payment',
                label: this.p2rTranslateService.get(this.translateService.instant('METHOD_PAYMENT.ELECTRONIC_PAYMENT')),
                value: 2,
                fieldType: P2R_FIELD_TYPE.CHECK
            },
            {
                key: 'checks',
                label: this.p2rTranslateService.get(this.translateService.instant('METHOD_PAYMENT.CHECKS')),
                value: 3,
                fieldType: P2R_FIELD_TYPE.CHECK
            },
            {
                key: 'credit',
                label: this.p2rTranslateService.get(this.translateService.instant('METHOD_PAYMENT.CREDIT')),
                value: 4,
                fieldType: P2R_FIELD_TYPE.CHECK
            },
            {
                key: 'debit',
                label: this.p2rTranslateService.get(this.translateService.instant('METHOD_PAYMENT.DEBIT')),
                value: 5,
                fieldType: P2R_FIELD_TYPE.CHECK
            },
            {
                key: 'meal_voucher',
                label: this.p2rTranslateService.get(this.translateService.instant('METHOD_PAYMENT.MEAL_VOUCHER')),
                value: 6,
                fieldType: P2R_FIELD_TYPE.CHECK
            },
        ];
    }
    doCheck(data) {
        this._doCheck.next(data);
    }
    doCheck$() {
        return this._doCheck.asObservable();
    }
    set paymentApiList(list) {
        this._paymentApiList = list;
    }
    get paymentApiList() {
        return this._paymentApiList || [];
    }
    set paymentLocalList(list) {
        this._paymentLocalList = list;
    }
    get paymentLocalList() {
        return this._paymentLocalList || [];
    }
    set paymentDeletedList(list) {
        this._paymentDeletedList = list;
    }
    get paymentDeletedList() {
        return this._paymentDeletedList || [];
    }
    set newPaymentList(list) {
        this._newPaymentList = list;
    }
    get newPaymentList() {
        return this._newPaymentList || [];
    }
    get allPaymentList() {
        return _.cloneDeep([].concat(this.paymentApiList.filter(ap => !this.paymentLocalList
            .filter(lp => lp.CCARDFOOD_ID)
            .map(lp => lp.CCARDFOOD_ID)
            .includes(ap.CCARDFOOD_ID)).filter(ap => !this.paymentDeletedList
            .filter(lp => lp.CCARDFOOD_ID)
            .map(lp => lp.CCARDFOOD_ID)
            .includes(ap.CCARDFOOD_ID)), this.paymentLocalList));
    }
    changesManager(localPayment, action_delete) {
        if (localPayment.CCARDFOOD_ID) {
            const apiPayment = this.paymentApiList.find(ap => ap.CCARDFOOD_ID === localPayment.CCARDFOOD_ID);
            let isEqual = getChangedKeys(apiPayment, localPayment);
            if (action_delete) {
                return this.paymentDeletedList = _.cloneDeep([].concat(this.paymentDeletedList, localPayment));
            }
            else {
                if (isEqual.length) {
                    if (this.paymentLocalList.filter(lp => lp.CCARDFOOD_ID === localPayment.CCARDFOOD_ID).length > 0) {
                        const foundIndex = this.paymentLocalList.findIndex(lp => lp.CCARDFOOD_ID === localPayment.CCARDFOOD_ID);
                        if (foundIndex >= 0) {
                            return this.paymentLocalList[foundIndex] = localPayment;
                        }
                    }
                    else {
                        return this.paymentLocalList = _.cloneDeep([].concat(this.paymentLocalList, localPayment));
                    }
                }
                else {
                    if (this.paymentLocalList.filter(lp => lp.CCARDFOOD_ID === localPayment.CCARDFOOD_ID).length > 0) {
                        this.paymentLocalList.splice(this.paymentLocalList.indexOf(this.paymentLocalList.find(lp => lp.CCARDFOOD_ID === localPayment.CCARDFOOD_ID)), 1);
                    }
                }
            }
        }
        else if (localPayment.CC_ID) {
            const foundIndex = this.paymentLocalList.findIndex(lp => lp.CC_ID === localPayment.CC_ID);
            if (action_delete) {
                const newPaymentListIndex = this.newPaymentList.findIndex(np => np.CC_ID === localPayment.CC_ID);
                this.newPaymentList.splice(newPaymentListIndex, 1);
                return this.paymentLocalList.splice(foundIndex, 1);
            }
            else {
                if (foundIndex == -1) {
                    this.newPaymentList = _.cloneDeep([].concat(this.newPaymentList, localPayment));
                    return this.paymentLocalList = _.cloneDeep([].concat(this.paymentLocalList, localPayment));
                }
                else {
                    return this.paymentLocalList[foundIndex] = localPayment;
                }
            }
        }
        // this.checkPaymentChanges();
    }
    // Search payment method label by XCCARD04
    getMethodLabelByCode(code) {
        const methodObj = this.METHOD_PAYMENT_TYPE.find(el => el.value === code);
        return methodObj.label;
    }
    checkPaymentChanges() {
        const modifiedPayment = this.paymentLocalList;
        const deletedPayment = this.paymentDeletedList;
        if (modifiedPayment.length || deletedPayment.length) {
            this.doCheck(true);
        }
        else if (modifiedPayment.length == 0 && deletedPayment.length == 0) {
            this.doCheck(false);
        }
    }
    getApiValues() {
        return this.apiService.get(`${this.apiService.apiPath}/configuration/CCARDFOOD`, {
            center_dett: P2R_CENTER_DETT.ALL_VIEWS,
            source: CONFIGURATION_SOURCE.PREFERENCES,
            offset: `all`,
        }).pipe(map((ccards) => {
            ccards.reverse();
            return ccards;
        }), tap(ccards => {
            this.paymentApiList = ccards;
        }));
    }
}
PaymentsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PaymentsService_Factory() { return new PaymentsService(i0.ɵɵinject(i1.P2RTranslateService), i0.ɵɵinject(i2.TranslateService), i0.ɵɵinject(i3.P2RApiService)); }, token: PaymentsService, providedIn: "root" });
