/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./save-panel.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./save-panel.component";
import * as i5 from "../configuration.service";
import * as i6 from "@p2r/env";
import * as i7 from "ngx-ui-loader";
import * as i8 from "../payments/payments.service";
var styles_SavePanelComponent = [i0.styles];
var RenderType_SavePanelComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SavePanelComponent, data: {} });
export { RenderType_SavePanelComponent as RenderType_SavePanelComponent };
function View_SavePanelComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["(", ")"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.changesNumber; _ck(_v, 1, 0, currVal_0); }); }
function View_SavePanelComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "w-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["class", "d-flex flex-grow-1 p-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "mr-1 w-100"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.save() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "button", [["class", "btn rounded-pill p-2 btn-block btn-primary text-uppercase pl-3 pr-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "span", [["class", "font-r_r fs18 c13 lh21"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SavePanelComponent_2)), i1.ɵdid(8, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 4, "div", [["class", "ml-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 3, "button", [["class", "btn rounded-pill p-2 btn-block btn-primary text-uppercase pl-3 pr-3"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.restore() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "span", [["class", "font-r_r fs18 c13 lh21"]], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.changesNumber; _ck(_v, 8, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("save")); _ck(_v, 5, 0, currVal_0); var currVal_2 = i1.ɵunv(_v, 12, 0, i1.ɵnov(_v, 13).transform("restore")); _ck(_v, 12, 0, currVal_2); }); }
function View_SavePanelComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "w-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "d-flex flex-grow-1 p-2 position-relative"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "button", [["class", "btn rounded-pill btn-block btn-primary text-uppercase pl-3 pr-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "span", [["class", "font-r_r fs18 c13 lh21"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(7, 0, null, null, 0, "img", [["height", "28"], ["style", "position: absolute;right: 14px;width: 28px;height: 28px;top: 12px;"], ["width", "28"]], [[8, "src", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeSavePanel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("configuration_saved")); _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.configurationService.getImg("icons/close_white.svg"); _ck(_v, 7, 0, currVal_1); }); }
export function View_SavePanelComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_SavePanelComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SavePanelComponent_3)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hasChanges; _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.successSaved && !_co.hasChanges); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_SavePanelComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "configuration-save-panel", [], null, null, null, View_SavePanelComponent_0, RenderType_SavePanelComponent)), i1.ɵdid(1, 245760, null, 0, i4.SavePanelComponent, [i5.ConfigurationService, i6.P2REnvironmentService, i1.ChangeDetectorRef, i7.NgxUiLoaderService, i8.PaymentsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SavePanelComponentNgFactory = i1.ɵccf("configuration-save-panel", i4.SavePanelComponent, View_SavePanelComponent_Host_0, { hasChanges: "hasChanges" }, {}, []);
export { SavePanelComponentNgFactory as SavePanelComponentNgFactory };
