import * as tslib_1 from "tslib";
import { HttpErrorResponse } from '@angular/common/http';
import { P2R_CENTER_DETT } from '@p2r/platform-api';
import { P2RDialogComponent } from '@p2r/shared';
import * as _ from 'lodash';
import { forkJoin, Observable, of, ReplaySubject, Subject, Subscription, throwError } from 'rxjs';
import { catchError, debounceTime, map, mergeMap, startWith, tap } from 'rxjs/operators';
import { assetUrl } from 'src/single-spa/asset-url';
import { ConfigurationAlertComponent } from './alert/alert.component';
import { CONFIGURATION_SOURCE, CONFIGURATION_TYPE, GROUP_TYPE, IMPORT_EXPORT_TYPE, PRE_SAVE_CHECK, SERVICE_TYPE } from './configuration.model';
import { getChangedKeys } from './configuration.utils';
import { GlobalVariable } from '../global';
import { CUSTOMIZE_TYPE } from '../customize-dialog/customize-dialog.model';
import { CustomizeDialogComponent } from '../customize-dialog/customize-dialog.component';
import * as momentN from 'moment';
import { UtilsShared } from '../shared/utilsshared';
import * as i0 from "@angular/core";
import * as i1 from "@p2r/env";
import * as i2 from "@p2r/platform-api";
import * as i3 from "@ngx-translate/core";
import * as i4 from "@p2r/shared";
import * as i5 from "ngx-ui-loader";
import * as i6 from "@angular/common/http";
import * as i7 from "./payments/payments.service";
import * as i8 from "./fcic-cat-pagination.service";
import * as i9 from "@angular/router";
const moment = momentN;
const IVA_MAPPING_KEEPUP_FICC = {
    '22': 1,
    '10': 2,
    '4': 4,
    'F4': 3,
    'F5': 3,
    'ES': 8,
    'EE': 3,
    'NS': 7,
    'NI': 14,
    'RM': 3,
    'AL': 12,
    'N2.1': 16,
    'N2.2': 18,
    'N3.1': 19,
    'N3.2': 20,
    'N3.3': 21,
    'N3.4': 21,
    'N3.5': 23,
    'N3.6': 24,
    'N6.1': 25,
    'N6.2': 26,
    'N6.3': 27,
    'N6.4': 28,
    'N6.5': 29,
    'N6.6': 30
};
export var CONFIGURATION_SERVICES_ORDER;
(function (CONFIGURATION_SERVICES_ORDER) {
    CONFIGURATION_SERVICES_ORDER[CONFIGURATION_SERVICES_ORDER["MYCUSTOM"] = 0] = "MYCUSTOM";
    CONFIGURATION_SERVICES_ORDER[CONFIGURATION_SERVICES_ORDER["FCIC"] = 1] = "FCIC";
})(CONFIGURATION_SERVICES_ORDER || (CONFIGURATION_SERVICES_ORDER = {}));
export class ConfigurationService {
    constructor(p2rEnvironmentService, p2rApiService, translateService, p2rLocalStorageService, loaderService, p2rDialogService, p2rTranslateService, http, paymentsService, fcicCatPaginationService, router) {
        this.p2rEnvironmentService = p2rEnvironmentService;
        this.p2rApiService = p2rApiService;
        this.translateService = translateService;
        this.p2rLocalStorageService = p2rLocalStorageService;
        this.loaderService = loaderService;
        this.p2rDialogService = p2rDialogService;
        this.p2rTranslateService = p2rTranslateService;
        this.http = http;
        this.paymentsService = paymentsService;
        this.fcicCatPaginationService = fcicCatPaginationService;
        this.router = router;
        this.apiValues = {};
        this.CONFIGURATION = `CONFIGURATION`;
        this.CONFIGURATION_TYPE = CONFIGURATION_TYPE.CONFIGURATION;
        // Usiamo questa variabile come observer.
        // Ogni volta che c'è una modifica su allGroups la propaghiamo a chi è in ascolto con next().
        this._groups$ = new ReplaySubject(1);
        this._values$ = new Subject();
        this._modifiedGroups$ = new ReplaySubject();
        this._doCheck = new ReplaySubject();
        this._selectedGroup$ = new Subject();
        /*
        Usato per la comunicazione tra save-panel e operator component.
        il save-panel chiama il metodo setOpenChangeTimeSlotSubject
        che fa il next sul subject.
        L'operator panel fa il subscribe e quando riceve un messaggio
        chiama il metodo openChangeTimeSlotAlert.
        */
        this._openChangeTimeSlotSubject$ = new Subject();
        // KU-421: Questa lista la usiamo nei vari componenti dove
        // gestiamo in maniera customizzata le varie preferenze (ad esempio, OFFLINE).
        this.saveAllList = [];
        /*
        Usati per la comunicazione tra il save-panel e i nuovi componenti custom.
        Il primo viene utilizzato quando si effettua il salvataggio con sucesso,
        il secondo quando si clicca su RIPRISTINA, il terzo quando si cambia store.
        */
        this.saveCompleteSubject = new Subject();
        this.restoreCompleteSubject = new Array();
        this.changeStoreCompleteSubject = new Subject();
        /* END KU-421 */
        this.repartiSubject = new ReplaySubject();
        this.categorieSubject = new ReplaySubject();
        this.linkRepartiCategorieSubject = new ReplaySubject();
        this.linkRepartiCategorieSubject2 = new ReplaySubject();
        this.FCIC_ID_AZIENDA = '';
        this.user_grant_type = "-1";
        this._icons = this.p2rEnvironmentService.getEnvironment().ICONS || {};
        this.FCIC = this.p2rEnvironmentService.getEnvironment().FCIC || {};
        this.p2rEnvironmentService.getEnvironmentStream().subscribe(env => {
            this._assetsPath = `${env.BASE_CONFIG_URL}`;
        });
        this.apiPath = this.p2rApiService.apiCorePath;
        this.modifiedGroups = {};
    }
    getImg(imgSrc) {
        if (typeof imgSrc === 'function') {
            imgSrc = imgSrc();
        }
        return assetUrl(imgSrc, this._assetsPath);
    }
    get ICONS() {
        return this._icons;
    }
    getValues(groupType, store) {
        if (groupType !== undefined) {
            const groupKey = this.allGroups.find(g => g.cod === groupType).key;
            switch (groupType) {
                case GROUP_TYPE.OPERATOR:
                case GROUP_TYPE.HALLS_TABLES:
                case GROUP_TYPE.OFFLINE_WORK:
                case GROUP_TYPE.DOCUMENTS:
                case GROUP_TYPE.CLOSURE:
                case GROUP_TYPE.ORDER_PRINTERS:
                case GROUP_TYPE.TAKEAWAY:
                case GROUP_TYPE.GENERALI:
                case GROUP_TYPE.PAYMENTS:
                case GROUP_TYPE.LANGUAGE_CURRENCY:
                case GROUP_TYPE.ARTICLES:
                case GROUP_TYPE.ORDERS:
                    return this.p2rApiService.get(this.apiPath + '/tmpstore/operatore/findByStore', {
                        Store: store,
                        Group: groupKey
                    }).pipe(catchError(error => of({})), map((v) => {
                        console.log('CONFIGURATION SERVICE --> findByStore: ' + JSON.stringify(v));
                        // setto i apiValues con i valori che arrivano dal API
                        this.apiValues[groupType] = Object.assign({}, this.apiValues[groupType], v);
                        // se c'e il jsontemplate allora lo parso e setto di nuovo i apiValues
                        if (v.jsontemplate) {
                            v.values = JSON.parse(v.jsontemplate);
                            this.apiValues[groupType] = Object.assign({}, this.apiValues[groupType], { values: Object.assign({}, this.apiValues[groupType].values, v.values) });
                            this.manageValues(groupType, this.apiValues[groupType].values);
                        }
                        // prelevo i valori salvati localmente
                        const localValues = this.p2rLocalStorageService.getFromLocalStorage(this.CONFIGURATION, [
                            groupType,
                            'values'
                        ]);
                        // preparo i values
                        const values = Object.assign({}, this.apiValues[groupType].values, localValues);
                        return values;
                    }));
                case SERVICE_TYPE.FCIC:
                    return this.loadFCICInitialData();
                default:
                    return of({});
            }
        }
        else {
            return of({});
        }
    }
    values$() {
        return this._values$.asObservable();
    }
    getGroups(opt = {}) {
        const { type } = opt;
        let path = '';
        switch (type) {
            case CONFIGURATION_TYPE.SERVICE:
                this.CONFIGURATION_TYPE = CONFIGURATION_TYPE.SERVICE;
                path = this.apiPath + '/group-prop-service';
                break;
            case CONFIGURATION_TYPE.IMPORT_EXPORT:
                this.CONFIGURATION_TYPE = CONFIGURATION_TYPE.IMPORT_EXPORT;
                this._groups$.next([
                    {
                        cod: IMPORT_EXPORT_TYPE.IMPORT_ARCHIVES,
                        key: IMPORT_EXPORT_TYPE.IMPORT_ARCHIVES,
                        isSelected: true
                    },
                    {
                        cod: IMPORT_EXPORT_TYPE.EXPORT_ARCHIVES,
                        key: IMPORT_EXPORT_TYPE.EXPORT_ARCHIVES
                    },
                    {
                        cod: IMPORT_EXPORT_TYPE.EXPORT_SALE_DATA,
                        key: IMPORT_EXPORT_TYPE.EXPORT_SALE_DATA
                    },
                ]);
                return;
            default:
                this.CONFIGURATION_TYPE = CONFIGURATION_TYPE.CONFIGURATION;
                path = this.apiPath + '/group-prop-store';
                break;
        }
        // faccio la chiamata ai gruppi e le traduzioni
        return forkJoin([
            this.p2rApiService.get(path),
            this.translateService.getTranslation(this.translateService.defaultLang)
        ]).pipe(
        /*
        catchError(error => {
          alert(error.message)
          return forkJoin(of([]), of({}))
        }),
        */
        map(([groups, translations]) => {
            if (type == CONFIGURATION_TYPE.SERVICE) {
                groups.sort((a, b) => (CONFIGURATION_SERVICES_ORDER[`${a.cod}`] > CONFIGURATION_SERVICES_ORDER[`${b.cod}`]) ? 1 : -1);
            }
            else {
                groups.sort((a, b) => (a.key > b.key) ? 1 : -1);
            }
            // Salvo il tipo utente
            let user_grant_type = "-1";
            try {
                user_grant_type = groups[0].value[0].grant_type;
            }
            catch (ex) {
                console.error(ex);
            }
            console.log("USER GRANT TYPE = " + user_grant_type);
            if (Number(user_grant_type) > 0) {
                this.user_grant_type = user_grant_type;
            }
            // per ogni gruppo
            const grps = (groups || []).map(g => {
                // costruisco i items
                const translateItems = this.translateItems(g.cod, translations, true);
                // setto i items in base alle traduzioni
                const items = translateItems[g.cod];
                return Object.assign({}, g, { items, label: translations[g.cod] });
            });
            // setto i apiValues con i valori di default di ogni gruppo
            groups.forEach(g => {
                const values = g.value || [];
                this.apiValues[g.cod] = {
                    key_group: g.key,
                    store: this.p2rEnvironmentService.storeData && this.p2rEnvironmentService.storeData.pv[0].codice
                };
                values.forEach(value => {
                    this.apiValues[g.cod].values = Object.assign({}, this.apiValues[g.cod].values, { [value.key]: JSON.parse(value.default) });
                });
            });
            return grps;
        }), tap(groups => {
            this.allGroups = groups;
            for (const g of groups) {
                const values = this.p2rLocalStorageService.getFromLocalStorage('CONFIGURATION', [
                    g.cod,
                    'values'
                ]);
                if (values) {
                    g.isModified = true;
                    this.doCheck(true);
                }
                else {
                    // console.log('CONFIGURATION SERVICE --> Save in localStorage ' + g.cod);
                    const av = {};
                    if (g.value) {
                        for (const k of g.value) {
                            av[k.key] = k.default;
                        }
                        this.p2rLocalStorageService.addToLocalStorage('CONFIGURATION', [g.cod, 'apiValues'], av);
                    }
                }
            }
            this._groups$.next(groups);
        }));
    }
    search(s) {
        this.translateService.getTranslation(this.translateService.defaultLang).pipe(map(translations => {
            const translateItems = this.translateItems(s, translations);
            return translateItems;
        })).subscribe(translateItems => {
            let groups = this.allGroups.reduce((acc, curr) => {
                if (Object.keys(translateItems).includes(curr.cod)) {
                    curr.items = translateItems[curr.cod];
                    acc.push(curr);
                }
                return acc;
            }, []);
            groups = groups.map(p => (Object.assign({}, p, { isModified: this.modifiedGroups[p.cod] ? true : false, isSelected: p.cod === this._selectedGroup ? true : false })));
            this._groups$.next(groups);
        });
        if (!s) {
            return of(this.allGroups);
        }
        return of([]);
    }
    translateItems(search, translations, byKey = false) {
        // trovo tutte le chiavi la cui traduzione matcha
        let translateMatchKeys = [];
        if (byKey) {
            translateMatchKeys = Object.keys(translations).filter(k => k.startsWith(search));
        }
        else {
            translateMatchKeys = Object.keys(translations)
                .filter(k => (translations[k] || '')
                .toLocaleLowerCase()
                .includes(search.toLocaleLowerCase()));
        }
        let items = {};
        // per ogni chiave
        translateMatchKeys.forEach(tKey => {
            // rimuovo la prima parte della chiave delineata dal ('.')
            const splitted = tKey.split('.');
            // splitted.shift();
            // se la chiave ha un figlio la aggiungo ai items
            if (splitted.length) {
                tKey = splitted.join('.');
                const obj = {};
                _.set(obj, tKey, {});
                items = _.merge(items, obj);
            }
        });
        return items;
    }
    // Torniamo l'observer su _groups.
    groups$() {
        return this._groups$.asObservable();
    }
    modifiedGroups$() {
        return this._modifiedGroups$.asObservable();
    }
    set selectedGroup(group) {
        this._selectedGroup = group;
        this._selectedGroup$.next(this._selectedGroup);
    }
    get selectedGroup() {
        return this._selectedGroup;
    }
    selectedGroup$() {
        return this._selectedGroup$.asObservable();
    }
    setOpenChangeTimeSlotSubject() {
        this._openChangeTimeSlotSubject$.next(true);
    }
    openChangeTimeSlotSubject$() {
        return this._openChangeTimeSlotSubject$.asObservable();
    }
    set formGroup(form) {
        // setto il form
        this._formGroup = form;
        // mi metto in ascolto sulle modifiche del form
        this._formValueChangesSubscription = form.valueChanges.pipe(startWith(this._formGroup.value), debounceTime(300)).subscribe(changes => {
            // prendo i valori correnti del form
            const formValues = form.getRawValue();
            // chiamo manageValues per verificare le modifiche fatte
            this.manageValues(this.selectedGroup, this.apiValues[this.selectedGroup].values, formValues);
        });
    }
    get formGroup() {
        return this._formGroup;
    }
    loadFCICData(credenziali) {
        credenziali.token = credenziali.token;
        this.FCIC_ID_AZIENDA = credenziali.idAzienda;
        return forkJoin([
            this.p2rApiService.get(this.p2rApiService.apiPath + '/settings/DEPFOOD', {
                source: CONFIGURATION_SOURCE.DEPFOOD,
                center_dett: P2R_CENTER_DETT.ALL_VIEWS,
                forceListCost: 'XDEPFOOD03,XDEPFOOD04',
                offset: 'all'
            }, {
                microservice: 'mssettings'
            }),
            this.p2rApiService.get(this.p2rApiService.apiPath + '/configuration/LINKDEPCATFCICFOOD', {
                source: CONFIGURATION_SOURCE.SERVICE,
                center_dett: P2R_CENTER_DETT.ALL_VIEWS,
                offset: 'all'
            }),
            this.p2rApiService.get(this.p2rApiService.apiPath + '/settings/ART', {
                source: CONFIGURATION_SOURCE.ART,
                center_dett: P2R_CENTER_DETT.ALL_VIEWS,
                offset: 'all',
                forceListCost: 'XART03',
            }, {
                microservice: 'mssettings'
            }),
            this.p2rApiService.get(this.p2rApiService.apiPath + '/configuration/LINKARTFCICFOOD', {
                source: CONFIGURATION_SOURCE.SERVICE,
                center_dett: P2R_CENTER_DETT.ALL_VIEWS,
                offset: 'all'
            }),
            this.fcicCatPaginationService.getData(credenziali, 0).pipe(catchError((err) => {
                console.log(err);
                this.showErrorAlert([
                    this.p2rTranslateService.get(this.translateService.instant('error_fcic_categories'))
                ]);
                return of(undefined);
            }), map((d) => {
                if (d != undefined) {
                    // d è un array dove nel primo elemento c'è la risposta alla prima chiamata della paginazione
                    // mentre nel secondo elemento c'è un altro array che contiene la concatenazione tra la risposta 
                    // alla seconda chiamata e la risposta alla prima chiamata della paginazione e un altro array se ci sono altre pagine.
                    // Ciclo dentro d fino a quando non ho solo l'array che contiene la concantenazione di tutte le risposte
                    // alla chiamata della paginazione.
                    let tot = d[d.length - 1];
                    while (tot[1] != undefined && tot[1].length != 0) {
                        tot = tot[tot.length - 1];
                    }
                    tot = tot[0];
                    console.log("FCIC CATEGORIES: " + tot.length);
                    return tot;
                }
                else {
                    console.log("FCIC CATEGORIES ERROR");
                    return [];
                }
            })),
            this.p2rApiService.post(this.apiPath + '/fcic/print_status').pipe(map((statusResp) => {
                const statusData = JSON.parse(statusResp.error);
                return statusData
                    && statusData.errors
                    && statusData.errors.ResponseBody
                    || {};
            }))
        ]).pipe(tap(([DEPs, linkDEP_CAT_FCIC, ARTs, linkART_FCIC, CATs_FCIC]) => {
            this.repartiSubject.next(DEPs);
            this.repartiSubject.complete();
            this.categorieSubject.next(CATs_FCIC);
            this.categorieSubject.complete();
            this.linkRepartiCategorieSubject.next(linkDEP_CAT_FCIC);
            this.linkRepartiCategorieSubject2.next(linkDEP_CAT_FCIC);
            this.linkRepartiCategorieSubject.complete();
        }), mergeMap(([DEPs, linkDEP_CAT_FCIC, ARTs, linkART_FCIC, CATs_FCIC, status]) => of({
            credenziali,
            reparti: DEPs,
            linkDEPCAT: linkDEP_CAT_FCIC,
            articoli: ARTs,
            linkART: linkART_FCIC,
            categorieFCIC: CATs_FCIC,
            status
        })));
    }
    manageValues(group, apiValues, formValues, key) {
        if (key === undefined) {
            key = this.CONFIGURATION;
        }
        // se non ci sono dei formValues (dati che arrivano dal form)
        if (apiValues && !formValues) {
            // aggiungo i apiValues in localStorage
            this.p2rLocalStorageService.addToLocalStorage(key, [
                group,
                'apiValues'
            ], Object.assign({}, apiValues));
        }
        else {
            // altrimenti, se ho i dati dal form
            // faccio un check delle chiavi modificate mettendo a confronto entrambi oggetti
            const changedKeys = getChangedKeys(apiValues, formValues);
            // se ci sono chiavi modificate
            if (changedKeys.length) {
                // creo l'oggetto values con le nuove modifiche
                const values = Object.assign({}, this.apiValues[group].values, formValues);
                // salvo values in localStorage
                this.p2rLocalStorageService.addToLocalStorage(key, [
                    group,
                    'values'
                ], values);
                // aggiorno modifiedGroups
                this.modifiedGroups[group] = values;
                // invio modifiedGroups dentro lo stream
                this._modifiedGroups$.next(this.modifiedGroups);
            }
            else {
                // se non ci sono alcune chiavi cambiate
                this.p2rLocalStorageService.removeFromLocalStorage(key, [
                    group,
                    'values'
                ]);
                // cancello i dati relativi al gruppo dentro modifiedGroups
                delete this.modifiedGroups[group];
                // invio modifiedGroups dentro lo stream
                this._modifiedGroups$.next(this.modifiedGroups);
            }
        }
        if (Object.keys(this.modifiedGroups).length) {
            // se c'e alcun gruppo modificato allora invia il valore true nello stream doCheck
            // questo comporta la comparsa del panello di salva / ripristina
            this.doCheck(true);
            return true;
        }
        else {
            // se non c'e alcun gruppo modificato allora invia il valore false nello stream doCheck
            // questo comporta la scomparsa del panello di salva / ripristina
            this.doCheck(false);
            return false;
        }
    }
    doCheck(data) {
        this._doCheck.next(data);
    }
    doCheck$() {
        return this._doCheck.asObservable();
    }
    restoreLocalChanges() {
        // cancella tutti i dati relativi a "CONFIGURATION" dal localStorage
        var searchLocal;
        if (this.CONFIGURATION_TYPE === 'SERVICE') {
            searchLocal = 'SERVICES';
        }
        else {
            searchLocal = 'CONFIGURATION';
        }
        this.p2rLocalStorageService.removeFromLocalStorage(searchLocal);
        this.paymentsService.paymentLocalList = [];
        this.paymentsService.newPaymentList = [];
        this.paymentsService.paymentDeletedList = [];
        this.paymentsService.checkPaymentChanges();
        // setta modifiedGroups a oggetto vuoto
        this.modifiedGroups = {};
        // invia un oggetto vuoto dentro lo stream di modifiedGroups
        this._modifiedGroups$.next({});
        // invia i valori che arrivano da BE nello stream dei values
        const s = {};
        for (const key of Object.keys(this.apiValues)) {
            const value = this.apiValues[key];
            const z = 'values'; // Per non generare lint "object access via string literals is disallowed"
            s[key] = value[z];
        }
        this._values$.next(s);
        this.doCheck(false);
    }
    preSave() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => {
                // preleva tutti i dati relativi alla chiave CONFIGURATION da localStorage
                var searchLocal;
                if (this.CONFIGURATION_TYPE === 'SERVICE') {
                    searchLocal = 'SERVICES';
                }
                else {
                    searchLocal = 'CONFIGURATION';
                }
                const localSaved = this.p2rLocalStorageService.getFromLocalStorage(searchLocal) || {};
                let preSaveCheck = PRE_SAVE_CHECK.EVERYTHING_OK;
                // per ogni chiave di localSaved
                Object.keys(localSaved).forEach(key => {
                    switch (key) {
                        case GROUP_TYPE.OPERATOR:
                            // prendo tutti i dati precedentemente salvati nei apiValues relativi al gruppo OPERATOR
                            const toSave = Object.assign({}, this.apiValues[key]);
                            // cancello l'oggetto values
                            delete toSave.values;
                            const operatorType = localSaved[key].values && localSaved[key].values.KA_OPERATOR_TYPE;
                            if (operatorType !== undefined && operatorType === 2) {
                                const timeSlots = localSaved[key].values.KA_OPERATOR_TIME_SLOT;
                                if (timeSlots === undefined || timeSlots.length === 0) {
                                    preSaveCheck = PRE_SAVE_CHECK.NO_TIME_SLOT;
                                }
                            }
                            break;
                        default:
                            break;
                    }
                });
                switch (preSaveCheck) {
                    case PRE_SAVE_CHECK.NO_TIME_SLOT:
                        const alert = this.p2rDialogService.open(P2RDialogComponent, {
                            id: 'presave-alert',
                            body: ConfigurationAlertComponent,
                            hasHead: false,
                            data: {
                                imgSrc: this.getImg('icons/attenzione.svg'),
                                title: this.p2rTranslateService.get(this.translateService.instant('attention')),
                                messages: [
                                    this.p2rTranslateService.get(this.translateService.instant('no_time_slot_selected_1')),
                                    this.p2rTranslateService.get(this.translateService.instant('no_time_slot_selected_2'))
                                ]
                            },
                            actions: [
                                {
                                    label: this.p2rTranslateService.get(this.translateService.instant('save')),
                                    click: () => {
                                        alert.dismiss();
                                        resolve(true);
                                    },
                                    classes: 'btn rounded-pill text-uppercase new-alert-button',
                                    data: {
                                        remove: true
                                    },
                                    params: {}
                                },
                                {
                                    label: this.p2rTranslateService.get(this.translateService.instant('create_shift_change')),
                                    click: () => {
                                        alert.dismiss();
                                        resolve(false);
                                    },
                                    classes: 'btn rounded-pill text-uppercase new-alert-button',
                                    params: {}
                                }
                            ]
                        });
                        return of(false);
                    case PRE_SAVE_CHECK.EVERYTHING_OK:
                        resolve(true);
                        break;
                }
            });
        });
    }
    saveLocalChanges() {
        // preleva tutti i dati relativi alla chiave CONFIGURATION da localStorage
        var searchLocal;
        if (this.CONFIGURATION_TYPE === 'SERVICE') {
            searchLocal = 'SERVICES';
        }
        else {
            searchLocal = 'CONFIGURATION';
        }
        let localSaved = this.p2rLocalStorageService.getFromLocalStorage(searchLocal) || {};
        const paymentsToSave = [
            ...this.paymentsService.paymentDeletedList,
            ...this.paymentsService.paymentLocalList
        ];
        if (paymentsToSave.length) {
            localSaved = Object.assign({}, localSaved, { [GROUP_TYPE.PAYMENTS]: {} });
        }
        // creo un array dentro il quale aggiungo tutti i stream di salvataggio
        const toSaveList = [];
        const getValuesList = [];
        // per ogni chiave di localSaved
        Object.keys(localSaved).forEach(key => {
            switch (key) {
                case GROUP_TYPE.OPERATOR:
                case GROUP_TYPE.HALLS_TABLES:
                case GROUP_TYPE.DOCUMENTS:
                case GROUP_TYPE.CLOSURE:
                case GROUP_TYPE.ORDER_PRINTERS:
                case GROUP_TYPE.TAKEAWAY:
                case GROUP_TYPE.PAYMENTS:
                case GROUP_TYPE.LANGUAGE_CURRENCY:
                case GROUP_TYPE.ARTICLES:
                case GROUP_TYPE.ORDERS:
                    // prendo tutti i dati precedentemente salvati nei apiValues relativi al gruppo OPERATOR
                    const toSave = Object.assign({}, this.apiValues[key]);
                    // cancello l'oggetto values
                    delete toSave.values;
                    const currentPv = this.p2rLocalStorageService
                        .getFromLocalStorage(GlobalVariable.SELECTED_PV_KEY_STORAGE, []);
                    if (currentPv !== undefined && currentPv.codice !== undefined) {
                        toSave.store = currentPv.codice;
                    }
                    if (localSaved[key].values !== undefined) {
                        // sovrascrivo il jsontemplate con le modifiche locali
                        toSave.jsontemplate = JSON.stringify(localSaved[key].values);
                        getValuesList.push(new Observable(observer => {
                            this.getValues(key, toSave.store).pipe(catchError(error => of(error))).subscribe(values => {
                                this.loaderService.stop();
                                if (values instanceof HttpErrorResponse) {
                                    observer.next(false);
                                    observer.complete();
                                }
                                else {
                                    this.p2rLocalStorageService.removeFromLocalStorage('CONFIGURATION', [key, 'values']);
                                    delete this.modifiedGroups[key];
                                    const s = {};
                                    s[key] = values;
                                    this._values$.next(s);
                                    this._modifiedGroups$.next({});
                                    this.doCheck(false);
                                    observer.next(true);
                                    observer.complete();
                                }
                            });
                        }));
                        // nel caso in cui il campo codice di apiValues non è presente allora dobbiamo fare una POST
                        if (!toSave.codice) {
                            // settiamo il codice a "0"
                            toSave.codice = '0';
                            // pushiamo la nostra chiamata post nel array che rappresenta la lista di chiamate da fare
                            toSaveList.push(this.p2rApiService.post(`${this.apiPath}/tmpstore/operatore`, undefined, toSave));
                        }
                        else {
                            // pushiamo la nostra chiamata put nel array che rappresenta la lista di chiamate da fare
                            toSaveList.push(this.p2rApiService.put(`${this.apiPath}/tmpstore/operatore`, undefined, toSave));
                        }
                    }
                    if (key == GROUP_TYPE.PAYMENTS) {
                        // prelevare i dati che devi salvare o cancellare dal db
                        // fare le chiamate api per tutti i dati che si devono cancellare
                        let toDelete = this.paymentsService.paymentDeletedList;
                        toDelete.forEach(td => {
                            toSaveList.push(this.p2rApiService.delete(`${this.apiPath}/${'CCARDFOOD'}/${td.CCARDFOOD_ID}`, {
                                source: `${CONFIGURATION_SOURCE.PREFERENCES}`,
                                debug: true,
                            }));
                        });
                        let toPost = this.paymentsService.paymentLocalList.filter(np => np.CC_ID);
                        // fare le chiamate api per tutti i dati che si devono aggiungere
                        // in toPost
                        if (toPost.length) {
                            toPost = toPost.map(tp => {
                                delete tp.CC_ID;
                                tp.XCCARDFOOD03 = 1;
                                // tp.XCCARD16 = 1;
                                tp.counter = 'XCCARDFOOD03';
                                tp.debug = true;
                                tp.source = `${CONFIGURATION_SOURCE.PREFERENCES}`;
                                return tp;
                            });
                            toSaveList.push(this.p2rApiService.post(`${this.apiPath}/${'CCARDFOOD'}`, {
                                source: `${CONFIGURATION_SOURCE.PREFERENCES}`,
                                debug: true,
                            }, toPost));
                        }
                        let toPut = this.paymentsService.paymentLocalList.filter(p => p.CCARDFOOD_ID);
                        // fare le chiamate api per tutti i dati che si devono aggiornare
                        // in toPut
                        if (toPut.length) {
                            toPut = toPut.map(tp => {
                                tp.debug = true;
                                tp.source = `${CONFIGURATION_SOURCE.PREFERENCES}`;
                                return tp;
                            });
                            toPut.forEach(tp => {
                                toSaveList.push(this.p2rApiService.put(`${this.apiPath}/${'CCARDFOOD'}/${tp.CCARDFOOD_ID}`, {
                                    source: `${CONFIGURATION_SOURCE.PREFERENCES}`,
                                    debug: true,
                                }, tp));
                            });
                        }
                        getValuesList.push(this.paymentsService.getApiValues());
                    }
                    break;
                default:
                    break;
            }
        });
        // Ogni elemento dell'array lo aggiungiamo alla
        // lista da mandare al server
        Object.keys(this.saveAllList).forEach(v => {
            if (this.saveAllList[v] !== undefined) {
                // Con i tre punti ti fa il push di tutto l'array
                toSaveList.push(...this.saveAllList[v]);
                getValuesList.push(new Observable(observer => {
                    delete this.modifiedGroups[v];
                    this.doCheck(false);
                    observer.next(true);
                    observer.complete();
                }));
            }
        });
        if (toSaveList && toSaveList.length) {
            this.loaderService.start();
            // inviamo tutti i dati al API
            return forkJoin(toSaveList).pipe(catchError(error => of(error)), mergeMap(saveResp => {
                if (saveResp instanceof HttpErrorResponse) {
                    this.loaderService.stop();
                    console.log(saveResp.message);
                    this.showErrorAlert();
                    return of(false);
                }
                else {
                    this.loaderService.stop();
                    this.paymentsService.paymentLocalList = [];
                    this.paymentsService.paymentDeletedList = [];
                    this.paymentsService.newPaymentList = [];
                    this.paymentsService.checkPaymentChanges();
                    this.allGroups = this.allGroups.map(g => (Object.assign({}, g, { isModified: false })));
                    this._groups$.next(this.allGroups);
                    this._selectedGroup$.next(this._selectedGroup);
                    return forkJoin(getValuesList);
                }
            }));
        }
        else {
            this.paymentsService.paymentLocalList = [];
            this.paymentsService.paymentDeletedList = [];
            this.paymentsService.newPaymentList = [];
            this.paymentsService.checkPaymentChanges();
            this.allGroups = this.allGroups.map(g => (Object.assign({}, g, { isModified: false })));
            this._groups$.next(this.allGroups);
            this._selectedGroup$.next(this._selectedGroup);
            return forkJoin(getValuesList);
        }
    }
    reloadValues(pvCode) {
        console.log('CONFIGURATION SERVICE reloadValues --> ' + pvCode);
        this.loaderService.start();
        this.getGroups().pipe(catchError(error => of(error))).subscribe(v => {
            this.getValues(this._selectedGroup, pvCode).pipe(catchError(error => of(error))).subscribe(values => {
                this.loaderService.stop();
                console.log('RELOAD VALUES --> ', values);
                console.log('SELECTED GROUP --> ' + this._selectedGroup);
                const s = {};
                s[this._selectedGroup] = values;
                this._values$.next(s);
                this._modifiedGroups$.next({});
                this.doCheck(false);
                this._selectedGroup$.next(this._selectedGroup);
                if (this.formGroup !== undefined) {
                    // Aggiungo questo controllo per evitare un errore in console
                    // quando sono in generali
                    if (this._selectedGroup == GROUP_TYPE.GENERALI) {
                        Object.keys(values).forEach(key => {
                            if (this.formGroup.get(key) != null) {
                                this.formGroup.get(key).setValue(values[key]);
                            }
                        });
                    }
                    else {
                        this.formGroup.setValue(values);
                    }
                }
            });
        });
    }
    showErrorAlert(messages = []) {
        const alert = this.p2rDialogService.open(P2RDialogComponent, {
            id: 'save-error-alert',
            body: ConfigurationAlertComponent,
            hasHead: false,
            data: {
                imgSrc: this.getImg('icons/attenzione.svg'),
                title: this.p2rTranslateService.get(this.translateService.instant('attention')),
                messages: messages || [
                    this.p2rTranslateService.get(this.translateService.instant('save_error_message_1')),
                    this.p2rTranslateService.get(this.translateService.instant('save_error_message_2'))
                ]
            },
            actions: [
                {
                    label: this.p2rTranslateService.get(this.translateService.instant('close')),
                    click: () => {
                        alert.dismiss();
                    },
                    classes: 'btn rounded-pill text-uppercase new-alert-button font-r_l fs18',
                    data: {
                        remove: true
                    },
                    params: {
                        position: 'right',
                    }
                }
            ]
        });
    }
    getLinkRepartiCategorie$() {
        return this.linkRepartiCategorieSubject.asObservable();
    }
    getLinkRepartiCategorie2$() {
        return this.linkRepartiCategorieSubject2.asObservable();
    }
    getReparti$() {
        return this.repartiSubject.asObservable();
    }
    getCategorie$() {
        return this.categorieSubject.asObservable();
    }
    linkDepCatFCIC(opt) {
        const { rifDEP, objCATFCIC, linkDEPCAT } = opt;
        this.loaderService.start();
        return new Observable(observer => {
            if (linkDEPCAT) {
                const toSave = Object.assign({}, linkDEPCAT, { XLINKDEPCATFCICFOOD04: rifDEP, XLINKDEPCATFCICFOOD05: objCATFCIC.key, XLINKDEPCATFCICFOOD06: objCATFCIC.data && objCATFCIC.data.sigla, XLINKDEPCATFCICFOOD08: "UPDATED", source: CONFIGURATION_SOURCE.SERVICE });
                this.p2rApiService.put(`${this.apiPath}/LINKDEPCATFCICFOOD/${linkDEPCAT.LINKDEPCATFCICFOOD_ID}`, undefined, toSave).pipe(mergeMap(d => this.p2rApiService.get(this.p2rApiService.apiPath + '/configuration/LINKDEPCATFCICFOOD', {
                    source: CONFIGURATION_SOURCE.SERVICE,
                    center_dett: P2R_CENTER_DETT.ALL_VIEWS,
                    offset: 'all'
                })), tap(data => {
                    this.linkRepartiCategorieSubject.next(data);
                    this.linkRepartiCategorieSubject2.next(data);
                    this.linkRepartiCategorieSubject.complete();
                })).subscribe(data => {
                    console.log(data);
                    observer.next(true);
                    observer.complete();
                    this.loaderService.stop();
                }, error => {
                    observer.next(false);
                    observer.complete();
                    this.loaderService.stop();
                });
            }
            else {
                const toSave = {
                    // ...linkDEPCAT,
                    XLINKDEPCATFCICFOOD03: 1,
                    XLINKDEPCATFCICFOOD04: `${rifDEP}`,
                    XLINKDEPCATFCICFOOD05: objCATFCIC.key,
                    XLINKDEPCATFCICFOOD06: objCATFCIC.data && objCATFCIC.data.sigla,
                    counter: 'XLINKDEPCATFCICFOOD03'
                };
                this.p2rApiService.post(`${this.apiPath}/LINKDEPCATFCICFOOD`, undefined, [toSave]).pipe(mergeMap(d => this.p2rApiService.get(this.p2rApiService.apiPath + '/configuration/LINKDEPCATFCICFOOD', {
                    source: CONFIGURATION_SOURCE.SERVICE,
                    center_dett: P2R_CENTER_DETT.ALL_VIEWS,
                    offset: 'all'
                })), tap(data => {
                    this.linkRepartiCategorieSubject.next(data);
                    this.linkRepartiCategorieSubject2.next(data);
                    this.linkRepartiCategorieSubject.complete();
                })).subscribe(data => {
                    console.log(data);
                    observer.next(true);
                    observer.complete();
                    this.loaderService.stop();
                }, error => {
                    observer.next(false);
                    observer.complete();
                    this.loaderService.stop();
                });
            }
        });
    }
    unlinkDepCatFCIC(opt) {
        const { linkDEPCAT } = opt;
        this.loaderService.start();
        return new Observable(observer => {
            this.p2rApiService.delete(`${this.apiPath}/LINKDEPCATFCICFOOD/${linkDEPCAT.LINKDEPCATFCICFOOD_ID}`, {
                source: CONFIGURATION_TYPE.SERVICE,
                debug: true
            }).pipe(mergeMap(d => this.p2rApiService.get(this.p2rApiService.apiPath + '/configuration/LINKDEPCATFCICFOOD', {
                source: CONFIGURATION_SOURCE.SERVICE,
                center_dett: P2R_CENTER_DETT.ALL_VIEWS,
                offset: 'all'
            })), tap(data => {
                this.linkRepartiCategorieSubject.next(data);
                this.linkRepartiCategorieSubject2.next(data);
                this.linkRepartiCategorieSubject.complete();
            })).subscribe(data => {
                console.log(data);
                observer.next(true);
                observer.complete();
                this.loaderService.stop();
            }, error => {
                observer.next(false);
                observer.complete();
                this.loaderService.stop();
            });
        });
    }
    associateDEPCATFCIC() {
        forkJoin(this.getReparti$(), this.getCategorie$(), this.getLinkRepartiCategorie$()).subscribe(([reparti, categorie, linkRepartiCategorie]) => {
            if (this.associateDEPCATFCICSubscription) {
                this.associateDEPCATFCICSubscription.unsubscribe();
            }
            const dialog = this.p2rDialogService.open(P2RDialogComponent, {
                id: 'customize-dialog',
                body: CustomizeDialogComponent,
                hideCloseButton: true,
                title: this.p2rTranslateService.get(this.translateService.instant('SERVIZI.FCIC.LINK_DEP_CAT')),
                titleClasses: 'text-uppercase font-r_r fs24 lh21 c11 mt-2',
                size: 'lg',
                /*      options: {
                  id: 'customize-for-store-opt',
                  titleClasses: 'customize-for-store-height'
                },*/
                data: {
                    hasSearch: true,
                    customizeType: CUSTOMIZE_TYPE.LINK_DEP_FCIC,
                    items: (reparti || []).map(r => (Object.assign({}, r, { key: r.XDEPFOOD03, label: r.XDEPFOOD04, 
                        // tslint:disable-next-line: triple-equals
                        value: linkRepartiCategorie.find(ra => ra.XLINKDEPCATFCICFOOD04 == r.XDEPFOOD03) ? 1 : 0 }))),
                    opt: {
                        categorieFCIC: categorie,
                        linkDEPCAT: linkRepartiCategorie
                    },
                    close: () => {
                        dialog.dismiss();
                    },
                }
            });
        });
    }
    /**
     * A sua volta chiama {@link loadFCICData}.
     *
     * @returns
     */
    loadFCICInitialData() {
        return forkJoin([
            this.p2rApiService.get(this.apiPath + '/FCICFOOD', {
                source: 'configuration-2',
                center_dett: P2R_CENTER_DETT.ALL_VIEWS,
                offset: 'all'
            }),
            this.p2rApiService.get(this.apiPath + '/LINKSTORETOKENCLIFCICFOOD', {
                source: 'configuration-2',
                center_dett: P2R_CENTER_DETT.ALL_VIEWS,
                offset: 'all'
            })
        ]).pipe(catchError(error => of({})), mergeMap(([fcicfood, linkstoretokenclifcicfood]) => {
            const credenziali = fcicfood[0] && {
                idAzienda: fcicfood[0].XFCICFOOD03,
                token: linkstoretokenclifcicfood[0].XLINKSTORETOKENCLIFCICFOOD05,
                activationDate: moment(fcicfood[0].CDATA, 'YYYYMMDD').format('DD.MM.YYYY'),
                active: fcicfood[0].XFCICFOOD04,
                fcicfoodcredentials: Object.assign({}, fcicfood[0])
            };
            if (credenziali) {
                return this.loadFCICData(credenziali);
            }
            else {
                return of({});
            }
        }));
    }
    activate(opt) {
        const { idAzienda } = opt;
        // Show progress bar dialog
        const alertTitle = `${this.p2rTranslateService.get(this.translateService.instant('activating'))} ...`;
        const alertMessages = [this.p2rTranslateService.get(this.translateService.instant('this_may_take_a_few_minutes')), this.p2rTranslateService.get(this.translateService.instant('this_window_will_close_automatically_when_the_operation_is_complete'))];
        let alertDialog = UtilsShared.showProgressBarDialog(ConfigurationAlertComponent, this.getImg('icons/licenza_popup.svg'), alertTitle, alertMessages, P2RDialogComponent, this.p2rDialogService);
        const alert = alertDialog[0];
        const progressInterval = alertDialog[1];
        //
        return this.http.get(`${this.FCIC.API_URL}/attivaLicenza/${this.FCIC.TOKEN_CUSTOM}/${idAzienda}`).pipe(catchError(err => {
            UtilsShared.dismissProgressBarDialog(alert, progressInterval);
            console.warn("FCIC error during activation: " + JSON.stringify(err));
            return of(err);
        }), map(data => {
            if (data instanceof HttpErrorResponse) {
                return data;
            }
            else {
                return data.responses[0].items || [];
            }
        }), mergeMap(d => {
            if (d instanceof HttpErrorResponse) {
                return of(d);
            }
            else {
                let fcicData = d;
                let partiteIva = [];
                d.forEach(d => {
                    partiteIva.push(d.piva);
                });
                console.log("FCIC check P.Iva: " + partiteIva);
                return forkJoin([this.p2rApiService.get(this.p2rApiService.apiPath + '/configuration/fcic/check_piva', {
                        idAzienda: idAzienda,
                        tokenPartner: this.FCIC.TOKEN_CUSTOM,
                        piva: partiteIva.join('|')
                    }, {
                        microservice: 'mssettings'
                    }).pipe(catchError(err => {
                        UtilsShared.dismissProgressBarDialog(alert, progressInterval);
                        console.warn("FCIC error during check_piva: " + JSON.stringify(err));
                        return of(err);
                    }), mergeMap((data) => {
                        if (data instanceof HttpErrorResponse) {
                            return of(data);
                        }
                        else {
                            console.log("FCIC check P.Iva SUCCESS!");
                            return forkJoin([
                                this.p2rApiService.post(this.apiPath + '/FCICFOOD', undefined, [{
                                        XFCICFOOD03: idAzienda,
                                    }]),
                                this.p2rApiService.get(this.p2rApiService.apiPath + '/settings/PV', {
                                    source: CONFIGURATION_SOURCE.PV,
                                    center_dett: P2R_CENTER_DETT.ALL_VIEWS,
                                    offset: 'all'
                                }, {
                                    microservice: 'mssettings'
                                }).pipe(catchError(err => {
                                    UtilsShared.dismissProgressBarDialog(alert, progressInterval);
                                    console.warn("FCIC error during activation: " + JSON.stringify(err));
                                    return of(err);
                                }), mergeMap((p) => {
                                    const tokensToSave = (fcicData || []).map((d, i) => ({
                                        XLINKSTORETOKENCLIFCICFOOD03: 0,
                                        XLINKSTORETOKENCLIFCICFOOD04: p[i] && p[i].XPV03 || '',
                                        XLINKSTORETOKENCLIFCICFOOD05: d.token,
                                        counter: 'XLINKSTORETOKENCLIFCICFOOD03',
                                        source: CONFIGURATION_SOURCE.SERVICE,
                                        debug: true
                                    }));
                                    return this.p2rApiService.post(this.apiPath + '/LINKSTORETOKENCLIFCICFOOD', undefined, tokensToSave);
                                })),
                                this.http.get(`${this.FCIC.API_URL}/iva/all`, {
                                    headers: {
                                        [`X-ACCESS-TOKEN`]: fcicData[0].token
                                    },
                                    params: {
                                        pageNumber: '0'
                                    }
                                }).pipe(map((d) => {
                                    return d.responses && d.responses.length && d.responses[0] && d.responses[0].items;
                                }), mergeMap((IVA_FCICs) => {
                                    const IVA_FCIC_toSave = (IVA_FCICs || []).map(ivaFcic => ({
                                        XIVAFCICFOOD03: 1,
                                        XIVAFCICFOOD04: ivaFcic.id,
                                        XIVAFCICFOOD05: ivaFcic.codice,
                                        XIVAFCICFOOD06: ivaFcic.descrizione,
                                        XIVAFCICFOOD07: ivaFcic.iva,
                                        XIVAFCICFOOD08: ivaFcic.note,
                                        XIVAFCICFOOD09: ivaFcic.reparto,
                                        XIVAFCICFOOD10: ivaFcic.tipo,
                                        // tslint:disable-next-line: triple-equals
                                        XIVAFCICFOOD11: Object.keys(IVA_MAPPING_KEEPUP_FICC).find(ivaKeepUp => IVA_MAPPING_KEEPUP_FICC[ivaKeepUp] == ivaFcic.id),
                                        counter: 'XIVAFCICFOOD03',
                                        source: CONFIGURATION_SOURCE.SERVICE,
                                        debug: true
                                    }));
                                    return this.p2rApiService.post(this.p2rApiService.apiPath + '/settings/IVAFCICFOOD', undefined, IVA_FCIC_toSave);
                                }), mergeMap(d => this.loadFCICInitialData()), tap(d => {
                                    UtilsShared.dismissProgressBarDialog(alert, progressInterval);
                                })),
                            ]);
                        }
                    }))
                ]);
            }
        }));
    }
    deActivateFCICLicense() {
        var subject = new Subject();
        this.loaderService.start();
        this.p2rApiService.delete(this.p2rApiService.apiPath + '/configuration/fcic/disassocia/' + this.FCIC.TOKEN_CUSTOM + '/' + this.FCIC_ID_AZIENDA, {}, {
            microservice: 'mssettings'
        }).subscribe((data) => {
            console.log("FCIC disattivazione SUCCESS");
            this.loaderService.stopAll();
            subject.next(true);
            //this.router.navigate(['services/mycustom'], { queryParams: { goToFcic: true} });
            // window.location.reload(); Non funziona in dev, ma solo su okteto
        }, (error) => {
            console.log("FCIC disattivazione ERROR: " + error);
            this.loaderService.stopAll();
            subject.next(false);
        });
        return subject.asObservable();
    }
    updateFCICSync(opt) {
        const { active, fcicfoodcredentials } = opt;
        this.loaderService.start();
        return this.p2rApiService.put(this.apiPath + '/FCICFOOD/' + fcicfoodcredentials.FCICFOOD_ID, undefined, Object.assign({}, fcicfoodcredentials, { XFCICFOOD04: active, debug: true, source: CONFIGURATION_SOURCE.SERVICE })).pipe(catchError(err => {
            this.loaderService.stop();
            return throwError(err);
        }), tap(d => {
            this.loaderService.stop();
        }));
    }
    syncronize() {
        const progressCb = (p) => {
            document.getElementById('progress-bar').style.width = `${p}%`;
            return p;
        };
        let progress = 0;
        const alertOptions = {
            id: 'sync-art-fcic',
            body: ConfigurationAlertComponent,
            hasHead: false,
            data: {
                imgSrc: this.getImg('icons/sync.svg'),
                title: `${this.p2rTranslateService.get(this.translateService.instant('synchronization_in_progress'))} ...`,
                titleClasses: 'text-inherit font-r_r fs24 lh21 c11 mt-2',
                progressBar: {},
                messages: [
                    this.p2rTranslateService.get(this.translateService.instant('this_may_take_a_few_minutes')),
                    this.p2rTranslateService.get(this.translateService.instant('this_window_will_close_automatically_when_the_operation_is_complete'))
                ],
            }
        };
        let alert = this.p2rDialogService.open(P2RDialogComponent, alertOptions);
        progressCb(0);
        const progressInterval = setInterval(() => {
            if (progress <= 90) {
                progress += 5;
                progressCb(progress);
            }
        }, 1000);
        this.p2rApiService.post(this.apiPath + '/fcic/cron_env').pipe(tap(d => {
            clearInterval(progressInterval);
        })).subscribe((res) => {
            clearInterval(progressInterval);
            alert.dismiss();
            const newSynchronizedArts = [].concat(res.NewArts || []);
            const updateSynchronizedArts = [].concat((res.SynchronizedArts || []), (res.SynchronizedUpdateArts || []));
            const notSynchronizedArts = [].concat((res.NewArtsFailed || []), (res.NotSynchronizedArts || []));
            const messages = [];
            if (newSynchronizedArts.length) {
                if (newSynchronizedArts.length == 1) {
                    messages.push(newSynchronizedArts.length + " " + this.p2rTranslateService.get(this.translateService.instant('new_article_syncronized')));
                }
                else if (newSynchronizedArts.length > 1) {
                    messages.push(newSynchronizedArts.length + " " + this.p2rTranslateService.get(this.translateService.instant('new_articles_syncronized')));
                }
            }
            if (updateSynchronizedArts.length) {
                if (updateSynchronizedArts.length == 1) {
                    messages.push(updateSynchronizedArts.length + " " + this.p2rTranslateService.get(this.translateService.instant('article_syncronized_successfully')));
                }
                else if (updateSynchronizedArts.length > 1) {
                    messages.push(updateSynchronizedArts.length + " " + this.p2rTranslateService.get(this.translateService.instant('articles_syncronized_successfully')));
                }
            }
            if (notSynchronizedArts.length) {
                if (notSynchronizedArts.length == 1) {
                    messages.push(notSynchronizedArts.length + " " + this.p2rTranslateService.get(this.translateService.instant('article_not_syncronized')));
                }
                else if (notSynchronizedArts.length > 1) {
                    messages.push(notSynchronizedArts.length + " " + this.p2rTranslateService.get(this.translateService.instant('articles_not_syncronized')));
                }
            }
            const detailMessages = [];
            if (newSynchronizedArts.length) {
                for (let ni = 0; ni < newSynchronizedArts.length; ni++) {
                    const newSynchronizedArt = newSynchronizedArts[ni];
                    detailMessages.push(`${this.p2rTranslateService.get(this.translateService.instant('new_article'))}: ${newSynchronizedArt}`);
                }
                detailMessages.push(`-------------------------------------------------------`);
            }
            if (updateSynchronizedArts.length) {
                for (let ni = 0; ni < updateSynchronizedArts.length; ni++) {
                    const updateSynchronizedArt = updateSynchronizedArts[ni];
                    detailMessages.push(`${this.p2rTranslateService.get(this.translateService.instant('syncronized'))}: ${updateSynchronizedArt}`);
                }
                detailMessages.push(`-------------------------------------------------------`);
            }
            if (notSynchronizedArts.length) {
                for (let ni = 0; ni < notSynchronizedArts.length; ni++) {
                    const notSynchronizedArt = notSynchronizedArts[ni];
                    detailMessages.push(`${this.p2rTranslateService.get(this.translateService.instant('not')).toUpperCase()} ${this.p2rTranslateService.get(this.translateService.instant('syncronized')).toLowerCase()}: ${notSynchronizedArt}`);
                }
                detailMessages.push(`-------------------------------------------------------`);
            }
            alert = this.p2rDialogService.open(P2RDialogComponent, Object.assign({}, alertOptions, { data: {
                    imgSrc: this.getImg('icons/confirm.svg'),
                    title: this.p2rTranslateService.get(this.translateService.instant('synchronization_completed')),
                    messages,
                    detailMessages
                }, actions: [
                    {
                        label: this.p2rTranslateService.get(this.translateService.instant('close')),
                        click: () => {
                            alert.dismiss();
                        },
                        classes: 'btn btn-block rounded-pill text-uppercase new-alert-button font-r_m fs14 lh22',
                        params: {}
                    }
                ] }));
        }, err => {
            clearInterval(progressInterval);
            alert.dismiss();
            alert = this.p2rDialogService.open(P2RDialogComponent, Object.assign({}, alertOptions, { data: {
                    imgSrc: this.getImg('icons/attenzione.svg'),
                    title: this.p2rTranslateService.get(this.translateService.instant('attention')),
                    messages: [
                        this.p2rTranslateService.get(this.translateService.instant('synchronization_failed'))
                    ],
                }, actions: [
                    {
                        label: this.p2rTranslateService.get(this.translateService.instant('close')),
                        click: () => {
                            alert.dismiss();
                        },
                        classes: 'btn btn-block rounded-pill text-uppercase new-alert-button font-r_m fs14 lh22',
                        params: {}
                    }
                ] }));
        });
    }
    send() {
        const progressCb = (p) => {
            document.getElementById('progress-bar').style.width = `${p}%`;
            return p;
        };
        let progress = 0;
        const alertOptions = {
            id: 'sync-art-fcic',
            body: ConfigurationAlertComponent,
            hasHead: false,
            data: {
                imgSrc: this.getImg('icons/sync.svg'),
                title: `${this.p2rTranslateService.get(this.translateService.instant('synchronization_in_progress'))} ...`,
                titleClasses: 'text-inherit font-r_r fs24 lh21 c11 mt-2',
                progressBar: {},
                messages: [
                    this.p2rTranslateService.get(this.translateService.instant('this_may_take_a_few_minutes')),
                    this.p2rTranslateService.get(this.translateService.instant('this_window_will_close_automatically_when_the_operation_is_complete'))
                ],
            }
        };
        let alert = this.p2rDialogService.open(P2RDialogComponent, alertOptions);
        progressCb(0);
        const progressInterval = setInterval(() => {
            if (progress <= 90) {
                progress += 5;
                progressCb(progress);
            }
        }, 1000);
        this.p2rApiService.post(this.apiPath + '/fcic/cron_move_corrispettivi').pipe(tap(d => {
            clearInterval(progressInterval);
        })).subscribe(res => {
            clearInterval(progressInterval);
            alert.dismiss();
            alert = this.p2rDialogService.open(P2RDialogComponent, Object.assign({}, alertOptions, { data: {
                    imgSrc: this.getImg('icons/confirm.svg'),
                    title: this.p2rTranslateService.get(this.translateService.instant('synchronization_completed')),
                    messages: [
                        this.p2rTranslateService.get(this.translateService.instant('the_Food_Cost_in_Cloud_warehouse_has_been_correctly_updated_based_on_Keep_Up_Pro_sales_data'))
                    ]
                }, actions: [
                    {
                        label: this.p2rTranslateService.get(this.translateService.instant('close')),
                        click: () => {
                            alert.dismiss();
                        },
                        classes: 'btn btn-block rounded-pill text-uppercase new-alert-button font-r_m fs14 lh22',
                        params: {}
                    }
                ] }));
        }, err => {
            clearInterval(progressInterval);
            alert.dismiss();
            alert = this.p2rDialogService.open(P2RDialogComponent, Object.assign({}, alertOptions, { data: {
                    imgSrc: this.getImg('icons/attenzione.svg'),
                    title: this.p2rTranslateService.get(this.translateService.instant('attention')),
                    messages: [
                        this.p2rTranslateService.get(this.translateService.instant('synchronization_failed'))
                    ],
                }, actions: [
                    {
                        label: this.p2rTranslateService.get(this.translateService.instant('close')),
                        click: () => {
                            alert.dismiss();
                        },
                        classes: 'btn btn-block rounded-pill text-uppercase new-alert-button font-r_m fs14 lh22',
                        params: {}
                    }
                ] }));
        });
    }
    verify() {
        const progressCb = (p) => {
            document.getElementById('progress-bar').style.width = `${p}%`;
            return p;
        };
        let progress = 0;
        const alertOptions = {
            id: 'sync-art-fcic',
            body: ConfigurationAlertComponent,
            hasHead: false,
            data: {
                imgSrc: this.getImg('icons/sync.svg'),
                title: `${this.p2rTranslateService.get(this.translateService.instant('verification_in_progress'))} ...`,
                titleClasses: 'text-inherit font-r_r fs24 lh21 c11 mt-2',
                progressBar: {},
                messages: [
                    this.p2rTranslateService.get(this.translateService.instant('this_may_take_a_few_minutes')),
                    this.p2rTranslateService.get(this.translateService.instant('this_window_will_close_automatically_when_the_operation_is_complete'))
                ],
            }
        };
        let alert = this.p2rDialogService.open(P2RDialogComponent, alertOptions);
        progressCb(0);
        const progressInterval = setInterval(() => {
            if (progress <= 90) {
                progress += 5;
                progressCb(progress);
            }
        }, 500);
        this.p2rApiService.post(this.apiPath + '/fcic/print_status').pipe(tap(d => {
            clearInterval(progressInterval);
        })).subscribe((res) => {
            const statusData = JSON.parse(res.error);
            const errorBody = statusData
                && statusData.errors
                && statusData.errors.ResponseBody
                || {};
            clearInterval(progressInterval);
            alert.dismiss();
            clearInterval(progressInterval);
            alert.dismiss();
            const ArtPendingList = [].concat(errorBody.ArtPendingList || []);
            const ArtErrorList = [].concat(errorBody.ArtErrorList || []);
            const CorrispettiviUnsyncPending = [].concat(errorBody.CorrispettiviUnsyncPending || []);
            const CorrispettiviUnsyncError = [].concat(errorBody.CorrispettiviUnsyncError || []);
            const messages = [];
            if (ArtPendingList.length) {
                if (ArtPendingList.length == 1) {
                    messages.push(ArtPendingList.length + " " + this.p2rTranslateService.get(this.translateService.instant('article_syncronization_anomaly')));
                }
                else if (ArtPendingList.length > 1) {
                    messages.push(ArtPendingList.length + " " + this.p2rTranslateService.get(this.translateService.instant('articles_syncronization_anomaly')));
                }
            }
            if (ArtErrorList.length) {
                if (ArtErrorList.length == 1) {
                    messages.push(ArtErrorList.length + " " + this.p2rTranslateService.get(this.translateService.instant('article_syncronization_error')));
                }
                else if (ArtErrorList.length > 1) {
                    messages.push(ArtErrorList.length + " " + this.p2rTranslateService.get(this.translateService.instant('articles_syncronization_error')));
                }
            }
            if (CorrispettiviUnsyncPending.length) {
                if (CorrispettiviUnsyncPending.length == 1) {
                    messages.push(CorrispettiviUnsyncPending.length + " " + this.p2rTranslateService.get(this.translateService.instant('fee_syncronization_anomaly')));
                }
                else if (CorrispettiviUnsyncPending.length > 1) {
                    messages.push(CorrispettiviUnsyncPending.length + " " + this.p2rTranslateService.get(this.translateService.instant('fees_syncronization_anomaly')));
                }
            }
            if (CorrispettiviUnsyncError.length) {
                if (CorrispettiviUnsyncError.length == 1) {
                    messages.push(CorrispettiviUnsyncError.length + " " + this.p2rTranslateService.get(this.translateService.instant('fee_syncronization_error')));
                }
                else if (CorrispettiviUnsyncError.length > 1) {
                    messages.push(CorrispettiviUnsyncError.length + " " + this.p2rTranslateService.get(this.translateService.instant('fees_syncronization_error')));
                }
            }
            const detailMessages = [];
            let counter = 0;
            if (ArtPendingList.length) {
                for (let ni = 0; ni < ArtPendingList.length; ni++) {
                    const pendingArt = ArtPendingList[ni];
                    counter += 1;
                    detailMessages.push(`${counter}. ${this.p2rTranslateService.get(this.translateService.instant('article_NOT_syncronized'))}: ${pendingArt}`);
                }
                detailMessages.push(`-------------------------------------------------------`);
            }
            if (ArtErrorList.length) {
                for (let ni = 0; ni < ArtErrorList.length; ni++) {
                    const errorArt = ArtErrorList[ni];
                    counter += 1;
                    detailMessages.push(`${counter}. ${this.p2rTranslateService.get(this.translateService.instant('article_syncronization_error'))}: ${errorArt}`);
                }
                detailMessages.push(`-------------------------------------------------------`);
            }
            if (CorrispettiviUnsyncPending.length) {
                for (let ni = 0; ni < CorrispettiviUnsyncPending.length; ni++) {
                    const notSynchronizedArt = CorrispettiviUnsyncPending[ni];
                    counter += 1;
                    detailMessages.push(`${counter}. ${this.p2rTranslateService.get(this.translateService.instant('fee_NOT_syncronized')).toUpperCase()}: ${notSynchronizedArt}`);
                }
                detailMessages.push(`-------------------------------------------------------`);
            }
            if (CorrispettiviUnsyncError.length) {
                for (let ni = 0; ni < CorrispettiviUnsyncError.length; ni++) {
                    const notSynchronizedArt = CorrispettiviUnsyncError[ni];
                    counter += 1;
                    detailMessages.push(`${counter}. ${this.p2rTranslateService.get(this.translateService.instant('fee_syncronization_error')).toUpperCase()}: ${notSynchronizedArt}`);
                }
                detailMessages.push(`-------------------------------------------------------`);
            }
            alert = this.p2rDialogService.open(P2RDialogComponent, Object.assign({}, alertOptions, { data: {
                    imgSrc: this.getImg('icons/confirm.svg'),
                    title: this.p2rTranslateService.get(this.translateService.instant('verification_completed')),
                    messages,
                    detailMessages
                }, actions: [
                    {
                        label: this.p2rTranslateService.get(this.translateService.instant('close')),
                        click: () => {
                            alert.dismiss();
                        },
                        classes: 'btn btn-block rounded-pill text-uppercase new-alert-button font-r_m fs14 lh22',
                        params: {}
                    }
                ] }));
        });
    }
}
ConfigurationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ConfigurationService_Factory() { return new ConfigurationService(i0.ɵɵinject(i1.P2REnvironmentService), i0.ɵɵinject(i2.P2RApiService), i0.ɵɵinject(i3.TranslateService), i0.ɵɵinject(i4.P2RLocalStorageService), i0.ɵɵinject(i5.NgxUiLoaderService), i0.ɵɵinject(i4.P2RDialogService), i0.ɵɵinject(i4.P2RTranslateService), i0.ɵɵinject(i6.HttpClient), i0.ɵɵinject(i7.PaymentsService), i0.ɵɵinject(i8.FcicCatPaginationService), i0.ɵɵinject(i9.Router)); }, token: ConfigurationService, providedIn: "root" });
