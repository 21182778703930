import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'configuration-change-description-dialog',
  templateUrl: './change-description-dialog.component.html',
  styleUrls: ['./change-description-dialog.component.scss']
})
export class ChangeDescriptionDialogComponent implements OnInit {


  private _data: any;
  private _title: any;
  private _messages: any;

  constructor() { }

  ngOnInit() {
  }

  @Input() set data(data: any) {
    this._data = data;
  }

  get data(): any {
    return this._data;
  }

  @Input() set title(title: any) {
    this._title = title;
  }

  get title(): any {
    return this._title || this._data.title;
  }

  @Input() set messages(messages: any[]) {
    this._messages = messages;
  }

  get messages(): any[] {
    return this._messages || this._data.messages;
  }

  public onChangeInput(event) {
    console.log((event));
  }

  public dismiss() {

  }

}
