<div class="d-flex flex-column">
    <div
        class="text-center"
        style="padding: 16px;"
    >
        <div
            id="title-container"
            class="title-container"
        >
            {{data.title}}
        </div>
        <div class="d-flex justify-content-center mb-2 mt-4">
            <div style="width: 152px">
                <div class="focused-slot">
                    <input
                        class="number-label"
                        #numberInput
                        type="number"
                        name="number"
                        [(ngModel)]="data.number"
                        customVirtualKeyboard
                        value="data.number"
                        min="0"
                        max="99"
                        (focus)=true
                        required
                    >
                </div>
                <span
                    style="color:red"
                    *ngIf="(data.number < data.min || data.number > data.max)"
                >
                    <span>{{data.warning}}</span>
                </span>
            </div>
        </div>
    </div>
</div>
