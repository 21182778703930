import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { ConfigurationService } from './configuration.service';
import { CONFIGURATION_TYPE, GroupStorePropertyUI, GROUP_TYPE, IMPORT_EXPORT_TYPE, PvDto, SERVICE_TYPE } from './configuration.model';
import { ActivatedRoute, Router } from '@angular/router';
import { of, Subscription } from 'rxjs';
import { P2REnvironmentService } from '@p2r/env';
import { P2RLocalStorageService, P2RTranslateService } from '@p2r/shared';
import { TranslateService } from '@ngx-translate/core';
import { PaymentsService } from './payments/payments.service';
import { GlobalVariable } from '../global';

export enum CONFIGURATION_PROPERTIES_ORDER {
  GENERALI,
  OPERATOR,
  FISCAL_PRINTER,
  HALLS_TABLES,
  ARTICLES,
  PAYMENTS,
  KEEP_UP_PRO_OPTIONS,
  CLOSURE,
  ORDER_PRINTERS,
  DOCUMENTS,
  ORDERS,
  OFFLINE,
  SERVIZI,
  TAKEAWAY,
  LANGUAGE_CURRENCY
}

@Component({
  selector: 'configuration-app',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss'],
})
export class ConfigurationComponent implements OnInit, OnDestroy, AfterViewInit {
  public images: {
    search: string;
    clean: string;
  };

  @ViewChildren("properties", { read: ElementRef }) propertyElements: QueryList<ElementRef>

  public search: string;
  public showSearch: boolean = true;

  properties: GroupStorePropertyUI[];
  selectedProperty?: GroupStorePropertyUI;

  selectedPv?: PvDto;
  isMultiStore;

  isStoreListExpanded: boolean;
  header: any;

  public CONFIGURATION_TYPE = CONFIGURATION_TYPE;
  private selectedSubscription: Subscription;
  private modifiedSubscription: Subscription;
  private groupsSubscription: Subscription;
  type: CONFIGURATION_TYPE;

  constructor(
    public configurationService: ConfigurationService,
    public paymentsService: PaymentsService,
    private p2rEnvironmentService: P2REnvironmentService,
    private translateService: TranslateService,
    private p2rTranslateService: P2RTranslateService,
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private p2rLocalStorageService: P2RLocalStorageService
  ) {
    this.images = {
      search: this.configurationService.ICONS.SEARCH,
      clean: 'icons/close_black.svg',
    };

    this.search = '';
  }

  ngOnInit() {

    //
    this.p2rLocalStorageService.addToLocalStorage(GlobalVariable.N_GET_GROUP_PROP_STORE_REQUEST, [], 0);
    //

    this.route.data.subscribe(({ header, data, type }) => {
      this.header = header;
      this.type = type;
    });

    this.groupsSubscription = this.configurationService.groups$().subscribe((groups) => {

      switch (this.configurationService.CONFIGURATION_TYPE) {
        case CONFIGURATION_TYPE.SERVICE:
          this.showSearch = true;
          this.header = {
            ...this.header,
            label: 'services'
          }
          break;
        case CONFIGURATION_TYPE.CONFIGURATION:
          this.showSearch = true;
          this.header = {
            ...this.header,
            label: 'preferences'
          }
          this.properties = groups.sort((a, b) =>  (CONFIGURATION_PROPERTIES_ORDER[`${a.cod}`] > CONFIGURATION_PROPERTIES_ORDER[`${b.cod}`]) ? 1 : -1)
          return;
        case CONFIGURATION_TYPE.IMPORT_EXPORT:
          setTimeout(() => {
            groups = groups.map(g => ({
              ...g,
              label: this.p2rTranslateService.get(this.translateService.instant(g.key))
            }))
            this.properties = groups;
          }, 250);
          this.showSearch = false;
          this.header = {
            ...this.header,
            label: 'import_export'
          }
          break;
        default:
          break;
      }

      this.properties = groups;
    });

    console.log(this.properties);

    this.modifiedSubscription = this.configurationService.modifiedGroups$().subscribe((mGroups) => {
      // console.log(mGroups);
      // console.log(this.properties);
      this.properties = this.properties.map((p) => ({
        ...p,
        isModified: mGroups[p.cod] ? true : false,
      }));

      if (!this.cdRef[`destroyed`]) {
        this.cdRef.detectChanges();
      }
    });

    this.selectedSubscription = this.configurationService.selectedGroup$().subscribe((group) => {
      this.properties = this.properties.map((p) => ({
        ...p,
        isSelected: p.cod === group ? true : false,
      }));
    });

    if (this.p2rEnvironmentService.storeData !== undefined) {
      this.isMultiStore = this.p2rEnvironmentService.storeData.multistore;
    } else {
      this.isMultiStore = false;
    }
    console.log('ConfigurationComponent: isMultiStore = ' + this.isMultiStore);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.propertyElements.length) {
        this.propertyElements.first.nativeElement.click();
      }
    }, 0);
  }

  expandedStoreList(event) {
    // console.log('expandedStoreList --> ' + event);
    this.isStoreListExpanded = event;
  }

  onSearch() {
    of(this.search)
      .pipe(
        tap((v) => console.log(v)),
        debounceTime(300),
        // distinctUntilChanged(),
        map((v) => (v || '').trim()),
        switchMap((v) => this.configurationService.search(v))
      )
      .subscribe();
  }

  onClean() {
    this.search = '';
  }

  onSelectProperty(property: GroupStorePropertyUI): void {
    this.properties = this.properties.map((p) => ({
      ...p,
      isSelected: p.cod === property.cod ? true : false,
    }));

    switch (property.cod) {
      case GROUP_TYPE.OPERATOR:
        this.router.navigateByUrl('/operators');
        break;
      case GROUP_TYPE.HALLS_TABLES:
        this.router.navigateByUrl('/halls-tables');
        break;
      case GROUP_TYPE.OFFLINE_WORK:
        this.router.navigateByUrl('offline-work');
        break;
      case GROUP_TYPE.DOCUMENTS:
        this.router.navigateByUrl('ku-documents');
        break;
      case GROUP_TYPE.CLOSURE:
        this.router.navigateByUrl('ku-closure');
        break;
      case GROUP_TYPE.ORDER_PRINTERS:
        this.router.navigateByUrl('ku-order-printers');
        break;
      case GROUP_TYPE.SERVIZI:
        this.router.navigateByUrl('services');
        break;
      case GROUP_TYPE.GENERALI:
        this.router.navigateByUrl('generali');
        break;
      case GROUP_TYPE.TAKEAWAY:
        this.router.navigateByUrl('takeaway');
        break;
      case GROUP_TYPE.LANGUAGE_CURRENCY:
        this.router.navigateByUrl('language-currency');
        break;
      case GROUP_TYPE.PAYMENTS:
        this.router.navigateByUrl('payments');
        break;
      case SERVICE_TYPE.FCIC:
        this.router.navigateByUrl('services/fcic');
        break;
      case SERVICE_TYPE.CUSTOM4U:
        this.router.navigateByUrl('services/custom4u');
        break;
      case SERVICE_TYPE.MYCUSTOM:
        this.router.navigateByUrl('services/mycustom');
        break;
      case IMPORT_EXPORT_TYPE.IMPORT_ARCHIVES:
        this.router.navigateByUrl('importexport/archives-import');
        break;
      case IMPORT_EXPORT_TYPE.EXPORT_ARCHIVES:
        this.router.navigateByUrl('importexport/archives-export');
        break;
      case IMPORT_EXPORT_TYPE.IMPORT_ASPECT:
        this.router.navigateByUrl('importexport/aspect-import');
        break;
      case IMPORT_EXPORT_TYPE.EXPORT_ASPECT:
        this.router.navigateByUrl('importexport/aspect-export');
        break;
      case IMPORT_EXPORT_TYPE.EXPORT_SALE_DATA:
        this.router.navigateByUrl('importexport/export-sale-data');
        break;
      case GROUP_TYPE.ARTICLES:
        this.router.navigateByUrl('articles');
        break;
      case GROUP_TYPE.ORDERS:
        this.router.navigateByUrl('orders');
        break;
      // Se serve, aggiungere le altre rotte
      default:
        this.router.navigateByUrl('');
        break;
    }
  }

  ngOnDestroy() {
    if (this.selectedSubscription) {
      this.selectedSubscription.unsubscribe();
    }
    if (this.modifiedSubscription) {
      this.modifiedSubscription.unsubscribe();
    }
    if (this.groupsSubscription) {
      this.groupsSubscription.unsubscribe();
    }
  }
}
