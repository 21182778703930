import * as tslib_1 from "tslib";
import { P2R_CENTER_DETT } from '@p2r/platform-api';
import { P2RDialogComponent } from '@p2r/shared';
import { EMPTY, forkJoin, Observable, of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { CONFIGURATION_SOURCE, CONFIGURATION_TYPE, GROUP_TYPE, SERVICE_TYPE } from './configuration.model';
import { GlobalVariable } from '../global';
import { ConfigurationAlertComponent } from './alert/alert.component';
import { assetUrl } from 'src/single-spa/asset-url';
import * as i0 from "@angular/core";
import * as i1 from "./configuration.service";
import * as i2 from "@p2r/shared";
import * as i3 from "@p2r/platform-api";
import * as i4 from "@p2r/env";
import * as i5 from "ngx-ui-loader";
import * as i6 from "@ngx-translate/core";
export class ConfigurationResolver {
    constructor(configurationService, p2rLocalStorageService, apiService, envService, p2rDialogService, p2rEnvironmentService, ngxService) {
        this.configurationService = configurationService;
        this.p2rLocalStorageService = p2rLocalStorageService;
        this.apiService = apiService;
        this.envService = envService;
        this.p2rDialogService = p2rDialogService;
        this.p2rEnvironmentService = p2rEnvironmentService;
        this.ngxService = ngxService;
        this.p2rEnvironmentService.getEnvironmentStream().subscribe(env => {
            this._assetsPath = `${env.BASE_CONFIG_URL}`;
        });
    }
    resolve(route) {
        const group = route.data && route.data.group;
        const selectedPv = this.p2rLocalStorageService.getFromLocalStorage(GlobalVariable.SELECTED_PV_KEY_STORAGE, []);
        console.log('CONFIGURATION RESOLVE: group ' + group);
        switch (group) {
            case GROUP_TYPE.OPERATOR:
            case GROUP_TYPE.HALLS_TABLES:
            case GROUP_TYPE.OFFLINE_WORK:
            case GROUP_TYPE.DOCUMENTS:
            case GROUP_TYPE.CLOSURE:
            case GROUP_TYPE.ORDER_PRINTERS:
            case GROUP_TYPE.TAKEAWAY:
            case GROUP_TYPE.PAYMENTS:
            case GROUP_TYPE.LANGUAGE_CURRENCY:
            case GROUP_TYPE.ARTICLES:
            case GROUP_TYPE.ORDERS:
                const items$ = new Observable((observer) => {
                    this.configurationService.groups$().subscribe((groups) => {
                        this.p2rLocalStorageService.addToLocalStorage(GlobalVariable.N_GET_GROUP_PROP_STORE_REQUEST, [], 0);
                        const grp = groups.find((g) => g.cod === group);
                        const items = (grp && grp.items) || [];
                        observer.next(items);
                        observer.complete();
                    });
                });
                return forkJoin([
                    items$,
                    selectedPv
                        ? this.configurationService.getValues(group, selectedPv.codice)
                        : this.apiService
                            .get(`${this.apiService.apiPath}/configuration/store/getStoreInfo`)
                            .pipe(mergeMap((data) => {
                            this.envService.storeData = data;
                            this.p2rLocalStorageService.addToLocalStorage(GlobalVariable.SELECTED_PV_KEY_STORAGE, [], data.pv[0]);
                            return this.configurationService.getValues(group, data.pv[0].codice);
                        })),
                ]);
            default:
                //return this.configurationService.getGroups();
                let nOfRequest = this.p2rLocalStorageService.getFromLocalStorage(GlobalVariable.N_GET_GROUP_PROP_STORE_REQUEST, []);
                if (nOfRequest == undefined) {
                    nOfRequest = 0;
                }
                if (nOfRequest < GlobalVariable.N_GET_GROUP_PROP_STORE_MAX_RETRY) {
                    this.p2rLocalStorageService.addToLocalStorage(GlobalVariable.N_GET_GROUP_PROP_STORE_REQUEST, [], nOfRequest + 1);
                    if (nOfRequest > 0) {
                        this.waitOneSecond();
                    }
                    return this.configurationService.getGroups();
                }
                else {
                    console.error("*** TOO MUCH REQUEST /group-prop-store ***");
                    this.p2rLocalStorageService.addToLocalStorage(GlobalVariable.N_GET_GROUP_PROP_STORE_REQUEST, [], 0);
                    this.showGetGroupPropStoreErrorMessage();
                    return EMPTY;
                }
        }
    }
    waitOneSecond() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield new Promise(f => setTimeout(f, GlobalVariable.N_GET_GROUP_PROP_STORE_THREESHOLD));
        });
    }
    getImg(imgSrc) {
        if (typeof imgSrc === 'function') {
            imgSrc = imgSrc();
        }
        return assetUrl(imgSrc, this._assetsPath);
    }
    showGetGroupPropStoreErrorMessage() {
        let alertTitle = "Warning";
        let msg1 = "Page does not respond.";
        let msg2 = "Try to reload.";
        const lang = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;
        if (lang.includes("it") || lang.includes("IT")) {
            alertTitle = "Attenzione";
            msg1 = "La pagina non risponde.";
            msg2 = "Provare a ricaricare la pagina.";
        }
        this.p2rDialogService.open(P2RDialogComponent, {
            id: 'group-prop-store-error-alert',
            body: ConfigurationAlertComponent,
            hasHead: false,
            data: {
                title: alertTitle,
                messages: [msg1, msg2]
            },
            actions: []
        });
        this.ngxService.stopAll();
    }
}
ConfigurationResolver.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ConfigurationResolver_Factory() { return new ConfigurationResolver(i0.ɵɵinject(i1.ConfigurationService), i0.ɵɵinject(i2.P2RLocalStorageService), i0.ɵɵinject(i3.P2RApiService), i0.ɵɵinject(i4.P2REnvironmentService), i0.ɵɵinject(i2.P2RDialogService), i0.ɵɵinject(i4.P2REnvironmentService), i0.ɵɵinject(i5.NgxUiLoaderService)); }, token: ConfigurationResolver, providedIn: "root" });
export class ServicesResolver {
    constructor(configurationService, p2rLocalStorageService, apiService, envService) {
        this.configurationService = configurationService;
        this.p2rLocalStorageService = p2rLocalStorageService;
        this.apiService = apiService;
        this.envService = envService;
    }
    resolve(route) {
        const group = route.data && route.data.group;
        const selectedPv = this.p2rLocalStorageService.getFromLocalStorage(GlobalVariable.SELECTED_PV_KEY_STORAGE, []);
        console.log('SERVICE RESOLVE: group ' + group);
        switch (group) {
            case SERVICE_TYPE.FCIC:
            case SERVICE_TYPE.MYCUSTOM:
            case SERVICE_TYPE.CUSTOM4U:
                const items$ = new Observable((observer) => {
                    this.configurationService.groups$().subscribe((groups) => {
                        const grp = groups.find((g) => g.cod === group);
                        const items = (grp && grp.items) || [];
                        observer.next(items);
                        observer.complete();
                    });
                });
                return forkJoin([
                    items$,
                    selectedPv
                        ? this.configurationService.getValues(group, selectedPv.codice)
                        : this.apiService
                            .get(`${this.apiService.apiPath}/configuration/store/getStoreInfo`)
                            .pipe(mergeMap((data) => {
                            this.envService.storeData = data;
                            this.p2rLocalStorageService.addToLocalStorage(GlobalVariable.SELECTED_PV_KEY_STORAGE, [], data.pv[0]);
                            return this.configurationService.getValues(group, data.pv[0].codice);
                        })),
                ]);
            default:
                return this.configurationService.getGroups({ type: CONFIGURATION_TYPE.SERVICE });
        }
    }
}
ServicesResolver.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ServicesResolver_Factory() { return new ServicesResolver(i0.ɵɵinject(i1.ConfigurationService), i0.ɵɵinject(i2.P2RLocalStorageService), i0.ɵɵinject(i3.P2RApiService), i0.ɵɵinject(i4.P2REnvironmentService)); }, token: ServicesResolver, providedIn: "root" });
/**
 * Custom configuration resolver used only by "LAVORO OFFLINE" section.
 */
export class ConfigurationResolverOfflineWork {
    constructor(configurationService, p2rLocalStorageService, apiService, envService, ngxService, p2rTranslateService, translateService) {
        this.configurationService = configurationService;
        this.p2rLocalStorageService = p2rLocalStorageService;
        this.apiService = apiService;
        this.envService = envService;
        this.ngxService = ngxService;
        this.p2rTranslateService = p2rTranslateService;
        this.translateService = translateService;
    }
    resolve(route) {
        const group = route.data && route.data.group;
        const selectedPv = this.p2rLocalStorageService.getFromLocalStorage(GlobalVariable.SELECTED_PV_KEY_STORAGE, []);
        const cashTrans = this.p2rTranslateService.get(this.translateService.instant('cashdesk'));
        console.log('CONFIGURATION OFFLINE_WORK RESOLVE: group ' + group);
        const items$ = new Observable((observer) => {
            this.configurationService.groups$().subscribe((groups) => {
                const grp = groups.find((g) => g.cod === group);
                const items = (grp && grp.items) || [];
                observer.next(items);
                observer.complete();
            });
        });
        return forkJoin([
            items$,
            selectedPv
                ? this.getValues(group, selectedPv.codice)
                : this.apiService
                    .get(`${this.apiService.apiPath}/configuration/store/getStoreInfo`)
                    .pipe(mergeMap((data) => {
                    this.envService.storeData = data;
                    this.p2rLocalStorageService.addToLocalStorage(GlobalVariable.SELECTED_PV_KEY_STORAGE, [], data.pv[0]);
                    return this.getValues(group, data.pv[0].codice);
                })),
            this.apiService.get(`${this.apiService.apiPath}/settings/SFFOOD`, {
                center_dett: P2R_CENTER_DETT.VISUALIZZA,
                source: CONFIGURATION_SOURCE.SFFOOD,
                offset: `all`
            }, {
                microservice: 'mssettings'
            }).pipe(map((sffoods) => sffoods
                .map(sffood => (Object.assign({}, sffood, { key: sffood.XSF04, label: sffood.XSFFOOD05 || cashTrans + ' ' + sffood.XSF04 })))
                .reverse()))
        ]);
    }
    getValues(groupType, store) {
        this.ngxService.start();
        const groupKey = this.configurationService.allGroups.find((g) => g.cod === groupType).key;
        return this.apiService
            .get(this.apiService.apiCorePath + '/tmpstore/operatore/findByStore', {
            Store: store,
            Group: groupKey,
            Extend: 1,
        })
            .pipe(catchError((error) => of(error)), map((v) => {
            this.ngxService.stop();
            if (v.status !== undefined && v.status !== 200) {
                return v.error;
            }
            else {
                return v;
            }
        }));
    }
}
ConfigurationResolverOfflineWork.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ConfigurationResolverOfflineWork_Factory() { return new ConfigurationResolverOfflineWork(i0.ɵɵinject(i1.ConfigurationService), i0.ɵɵinject(i2.P2RLocalStorageService), i0.ɵɵinject(i3.P2RApiService), i0.ɵɵinject(i4.P2REnvironmentService), i0.ɵɵinject(i5.NgxUiLoaderService), i0.ɵɵinject(i2.P2RTranslateService), i0.ɵɵinject(i6.TranslateService)); }, token: ConfigurationResolverOfflineWork, providedIn: "root" });
/**
 * Custom configuration resolver used only by "DOCUMENTI" section.
 */
export class ConfigurationResolverDocuments {
    constructor(configurationService, p2rLocalStorageService, apiService, envService, ngxService) {
        this.configurationService = configurationService;
        this.p2rLocalStorageService = p2rLocalStorageService;
        this.apiService = apiService;
        this.envService = envService;
        this.ngxService = ngxService;
    }
    resolve(route) {
        const group = route.data && route.data.group;
        const selectedPv = this.p2rLocalStorageService.getFromLocalStorage(GlobalVariable.SELECTED_PV_KEY_STORAGE, []);
        console.log('CONFIGURATION DOCUMENTS RESOLVE: group ' + group);
        const items$ = new Observable((observer) => {
            this.configurationService.groups$().subscribe((groups) => {
                const grp = groups.find((g) => g.cod === group);
                const items = (grp && grp.items) || [];
                observer.next(items);
                observer.complete();
            });
        });
        return forkJoin([
            items$,
            selectedPv
                ? this.getValues(group, selectedPv.codice)
                : this.apiService
                    .get(`${this.apiService.apiPath}/configuration/store/getStoreInfo`)
                    .pipe(mergeMap((data) => {
                    this.envService.storeData = data;
                    this.p2rLocalStorageService.addToLocalStorage(GlobalVariable.SELECTED_PV_KEY_STORAGE, [], data.pv[0]);
                    return this.getValues(group, data.pv[0].codice);
                })),
        ]);
    }
    getValues(groupType, store) {
        this.ngxService.start();
        const groupKey = this.configurationService.allGroups.find((g) => g.cod === groupType).key;
        return this.apiService
            .get(this.apiService.apiCorePath + '/tmpstore/operatore/findByStore', {
            Store: store,
            Group: groupKey,
            Extend: 1,
        })
            .pipe(catchError((error) => of(error)), map((v) => {
            this.ngxService.stop();
            let apiValues = {};
            apiValues.tmpl_store = {};
            if (v.status !== undefined && v.status !== 200) {
                const apiValues = {};
                apiValues.tmpl_store = {};
                apiValues.tmpl_store.jsontemplate = JSON.stringify(this.configurationService.apiValues[groupType].values);
                apiValues.tmpl_store.codice = 0;
                apiValues.tmpl_store.key_group = this.configurationService.apiValues[groupType].key_group;
                if (v.error.fatture !== undefined) {
                    apiValues.fatture = v.error.fatture;
                }
                return apiValues;
            }
            else {
                const values = JSON.parse(v.tmpl_store.jsontemplate);
                const defaultValues = this.configurationService.apiValues[groupType].values;
                const merged = Object.assign({}, defaultValues, values);
                v.tmpl_store.jsontemplate = JSON.stringify(merged);
                apiValues = v;
                return apiValues;
            }
        }));
    }
}
ConfigurationResolverDocuments.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ConfigurationResolverDocuments_Factory() { return new ConfigurationResolverDocuments(i0.ɵɵinject(i1.ConfigurationService), i0.ɵɵinject(i2.P2RLocalStorageService), i0.ɵɵinject(i3.P2RApiService), i0.ɵɵinject(i4.P2REnvironmentService), i0.ɵɵinject(i5.NgxUiLoaderService)); }, token: ConfigurationResolverDocuments, providedIn: "root" });
/**
 * Custom configuration resolver used only by "SERVICES" section.
 */
export class ConfigurationResolverServices {
    constructor(configurationService, p2rLocalStorageService, apiService, envService, ngxService) {
        this.configurationService = configurationService;
        this.p2rLocalStorageService = p2rLocalStorageService;
        this.apiService = apiService;
        this.envService = envService;
        this.ngxService = ngxService;
    }
    resolve(route) {
        const group = route.data && route.data.group;
        const selectedPv = this.p2rLocalStorageService.getFromLocalStorage(GlobalVariable.SELECTED_PV_KEY_STORAGE, []);
        console.log('CONFIGURATION SERVICES RESOLVE: group ' + group);
        const items$ = new Observable((observer) => {
            this.configurationService.groups$().subscribe((groups) => {
                const grp = groups.find((g) => g.cod === group);
                const items = (grp && grp.items) || [];
                observer.next(items);
                observer.complete();
            });
        });
        return forkJoin([
            items$,
            selectedPv
                ? this.getValues(selectedPv.codice)
                : this.apiService
                    .get(`${this.apiService.apiPath}/configuration/store/getStoreInfo`)
                    .pipe(mergeMap((data) => {
                    this.envService.storeData = data;
                    this.p2rLocalStorageService.addToLocalStorage(GlobalVariable.SELECTED_PV_KEY_STORAGE, [], data.pv[0]);
                    return this.getValues(data.pv[0].codice);
                })),
        ]);
    }
    getValues(store) {
        return this.apiService
            .get(this.apiService.apiCorePath + '/fatture/getcredenziali', {
            Store: store,
        })
            .pipe(map((v) => {
            return v;
        }));
    }
}
ConfigurationResolverServices.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ConfigurationResolverServices_Factory() { return new ConfigurationResolverServices(i0.ɵɵinject(i1.ConfigurationService), i0.ɵɵinject(i2.P2RLocalStorageService), i0.ɵɵinject(i3.P2RApiService), i0.ɵɵinject(i4.P2REnvironmentService), i0.ɵɵinject(i5.NgxUiLoaderService)); }, token: ConfigurationResolverServices, providedIn: "root" });
/**
 * Custom configuration resolver used only by "Generali" section.
 */
export class ConfigurationResolverGenerali {
    constructor(configurationService, p2rLocalStorageService, apiService, envService, ngxService) {
        this.configurationService = configurationService;
        this.p2rLocalStorageService = p2rLocalStorageService;
        this.apiService = apiService;
        this.envService = envService;
        this.ngxService = ngxService;
    }
    resolve(route) {
        const group = route.data && route.data.group;
        const selectedPv = this.p2rLocalStorageService.getFromLocalStorage(GlobalVariable.SELECTED_PV_KEY_STORAGE, []);
        console.log('CONFIGURATION DOCUMENTS RESOLVE: group ' + group);
        const items$ = new Observable((observer) => {
            this.configurationService.groups$().subscribe((groups) => {
                this.p2rLocalStorageService.addToLocalStorage(GlobalVariable.N_GET_GROUP_PROP_STORE_REQUEST, [], 0);
                const grp = groups.find((g) => g.cod === group);
                const items = (grp && grp.items) || [];
                observer.next(items);
                observer.complete();
            });
        });
        const listini$ = this.apiService.get(`${this.apiService.apiPath}/settings/LSTPERIODO`, {
            center_dett: P2R_CENTER_DETT.VISUALIZZA,
            source: CONFIGURATION_SOURCE.ART,
            offset: `all`
        }, {
            microservice: 'mssettings'
        }).pipe(map((lsts) => lsts
            .map(lst => (Object.assign({}, lst, { key: lst.XLSTPERIODO04, label: lst.XLSTPERIODO03 })))
            .reverse()));
        return forkJoin([
            items$,
            selectedPv
                ? this.getValues(group, selectedPv.codice)
                : this.apiService
                    .get(`${this.apiService.apiPath}/configuration/store/getStoreInfo`)
                    .pipe(mergeMap((data) => {
                    this.envService.storeData = data;
                    this.p2rLocalStorageService.addToLocalStorage(GlobalVariable.SELECTED_PV_KEY_STORAGE, [], data.pv[0]);
                    return this.getValues(group, data.pv[0].codice);
                })),
            listini$
        ]);
    }
    getValues(groupType, store) {
        this.ngxService.start();
        const groupKey = this.configurationService.allGroups.find((g) => g.cod === groupType).key;
        return this.apiService
            .get(this.apiService.apiCorePath + '/tmpstore/operatore/findByStore', {
            Store: store,
            Group: groupKey,
            Extend: 1,
        })
            .pipe(catchError((error) => of(error)), map((v) => {
            this.ngxService.stop();
            const apiValues = {};
            apiValues.tmpl_store = {};
            if (v.status !== undefined && v.status !== 200) {
                const apiValues = {};
                apiValues.tmpl_store = {};
                apiValues.tmpl_store.jsontemplate = JSON.stringify(this.configurationService.apiValues[groupType].values);
                apiValues.tmpl_store.codice = 0;
                apiValues.tmpl_store.key_group =
                    this.configurationService.apiValues[groupType].key_group;
                return apiValues;
            }
            else {
                const values = JSON.parse(v.jsontemplate);
                const defaultValues = this.configurationService.apiValues[groupType].values;
                const merged = Object.assign({}, defaultValues, values);
                v.jsontemplate = JSON.stringify(merged);
                apiValues.tmpl_store = v;
                return apiValues;
            }
        }));
    }
}
ConfigurationResolverGenerali.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ConfigurationResolverGenerali_Factory() { return new ConfigurationResolverGenerali(i0.ɵɵinject(i1.ConfigurationService), i0.ɵɵinject(i2.P2RLocalStorageService), i0.ɵɵinject(i3.P2RApiService), i0.ɵɵinject(i4.P2REnvironmentService), i0.ɵɵinject(i5.NgxUiLoaderService)); }, token: ConfigurationResolverGenerali, providedIn: "root" });
export class ConfigurationResolverClosure {
    constructor(configurationService, p2rLocalStorageService, apiService, envService, p2rTranslateService, translateService) {
        this.configurationService = configurationService;
        this.p2rLocalStorageService = p2rLocalStorageService;
        this.apiService = apiService;
        this.envService = envService;
        this.p2rTranslateService = p2rTranslateService;
        this.translateService = translateService;
    }
    resolve(route) {
        const group = route.data && route.data.group;
        const selectedPv = this.p2rLocalStorageService.getFromLocalStorage(GlobalVariable.SELECTED_PV_KEY_STORAGE, []);
        const cashTrans = this.p2rTranslateService.get(this.translateService.instant('cashdesk'));
        const items$ = new Observable((observer) => {
            this.configurationService.groups$().subscribe((groups) => {
                const grp = groups.find((g) => g.cod === group);
                const items = (grp && grp.items) || [];
                observer.next(items);
                observer.complete();
            });
        });
        return forkJoin([
            items$,
            selectedPv
                ? this.configurationService.getValues(group, selectedPv.codice)
                : this.apiService
                    .get(`${this.apiService.apiPath}/configuration/store/getStoreInfo`)
                    .pipe(mergeMap((data) => {
                    this.envService.storeData = data;
                    this.p2rLocalStorageService.addToLocalStorage(GlobalVariable.SELECTED_PV_KEY_STORAGE, [], data.pv[0]);
                    return this.configurationService.getValues(group, data.pv[0].codice);
                })),
            this.apiService.get(`${this.apiService.apiPath}/settings/SFFOOD`, {
                center_dett: P2R_CENTER_DETT.VISUALIZZA,
                source: CONFIGURATION_SOURCE.SFFOOD,
                offset: `all`
            }, {
                microservice: 'mssettings'
            }).pipe(map((sffoods) => sffoods
                .map(sffood => (Object.assign({}, sffood, { key: sffood.XSF04, label: sffood.XSFFOOD05 || cashTrans + ' ' + sffood.XSF04 })))
                .reverse()))
        ]);
    }
}
ConfigurationResolverClosure.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ConfigurationResolverClosure_Factory() { return new ConfigurationResolverClosure(i0.ɵɵinject(i1.ConfigurationService), i0.ɵɵinject(i2.P2RLocalStorageService), i0.ɵɵinject(i3.P2RApiService), i0.ɵɵinject(i4.P2REnvironmentService), i0.ɵɵinject(i2.P2RTranslateService), i0.ɵɵinject(i6.TranslateService)); }, token: ConfigurationResolverClosure, providedIn: "root" });
export class ConfigurationResolverTakeaway {
    constructor(configurationService, p2rLocalStorageService, apiService, envService, p2rTranslateService, translateService) {
        this.configurationService = configurationService;
        this.p2rLocalStorageService = p2rLocalStorageService;
        this.apiService = apiService;
        this.envService = envService;
        this.p2rTranslateService = p2rTranslateService;
        this.translateService = translateService;
    }
    resolve(route) {
        const group = route.data && route.data.group;
        const selectedPv = this.p2rLocalStorageService.getFromLocalStorage(GlobalVariable.SELECTED_PV_KEY_STORAGE, []);
        const cashTrans = this.p2rTranslateService.get(this.translateService.instant('cashdesk'));
        const items$ = new Observable((observer) => {
            this.configurationService.groups$().subscribe((groups) => {
                const grp = groups.find((g) => g.cod === group);
                const items = (grp && grp.items) || [];
                observer.next(items);
                observer.complete();
            });
        });
        return forkJoin([
            items$,
            selectedPv
                ? this.configurationService.getValues(group, selectedPv.codice)
                : this.apiService
                    .get(`${this.apiService.apiPath}/configuration/store/getStoreInfo`)
                    .pipe(mergeMap((data) => {
                    this.envService.storeData = data;
                    this.p2rLocalStorageService.addToLocalStorage(GlobalVariable.SELECTED_PV_KEY_STORAGE, [], data.pv[0]);
                    return this.configurationService.getValues(group, data.pv[0].codice);
                })),
            this.apiService.get(`${this.apiService.apiPath}/settings/SFFOOD`, {
                center_dett: P2R_CENTER_DETT.VISUALIZZA,
                source: CONFIGURATION_SOURCE.SFFOOD,
                offset: `all`
            }, {
                microservice: 'mssettings'
            }).pipe(map((sffoods) => sffoods
                .map(sffood => (Object.assign({}, sffood, { key: sffood.XSF04, label: sffood.XSFFOOD05 || cashTrans + ' ' + sffood.XSF04 })))
                .reverse()))
        ]);
    }
}
ConfigurationResolverTakeaway.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ConfigurationResolverTakeaway_Factory() { return new ConfigurationResolverTakeaway(i0.ɵɵinject(i1.ConfigurationService), i0.ɵɵinject(i2.P2RLocalStorageService), i0.ɵɵinject(i3.P2RApiService), i0.ɵɵinject(i4.P2REnvironmentService), i0.ɵɵinject(i2.P2RTranslateService), i0.ɵɵinject(i6.TranslateService)); }, token: ConfigurationResolverTakeaway, providedIn: "root" });
export class ConfigurationResolverPayments {
    constructor(configurationService, p2rLocalStorageService, apiService, envService, p2rTranslateService, translateService) {
        this.configurationService = configurationService;
        this.p2rLocalStorageService = p2rLocalStorageService;
        this.apiService = apiService;
        this.envService = envService;
        this.p2rTranslateService = p2rTranslateService;
        this.translateService = translateService;
    }
    resolve(route) {
        const group = route.data && route.data.group;
        const selectedPv = this.p2rLocalStorageService.getFromLocalStorage(GlobalVariable.SELECTED_PV_KEY_STORAGE, []);
        const cashTrans = this.p2rTranslateService.get(this.translateService.instant('cashdesk'));
        const items$ = new Observable((observer) => {
            this.configurationService.groups$().subscribe((groups) => {
                const grp = groups.find((g) => g.cod === group);
                const items = (grp && grp.items) || [];
                observer.next(items);
                observer.complete();
            });
        });
        return forkJoin([
            items$,
            selectedPv
                ? this.configurationService.getValues(group, selectedPv.codice)
                : this.apiService
                    .get(`${this.apiService.apiPath}/configuration/store/getStoreInfo`)
                    .pipe(mergeMap((data) => {
                    this.envService.storeData = data;
                    this.p2rLocalStorageService.addToLocalStorage(GlobalVariable.SELECTED_PV_KEY_STORAGE, [], data.pv[0]);
                    return this.configurationService.getValues(group, data.pv[0].codice);
                })),
            this.apiService.get(`${this.apiService.apiPath}/configuration/CCARDFOOD`, {
                center_dett: P2R_CENTER_DETT.ALL_VIEWS,
                source: CONFIGURATION_SOURCE.PREFERENCES,
                offset: `all`,
            }).pipe(map((ccards) => {
                ccards.reverse();
                return ccards;
            })),
            this.apiService.get(`${this.apiService.apiPath}/settings/SFFOOD`, {
                center_dett: P2R_CENTER_DETT.VISUALIZZA,
                source: CONFIGURATION_SOURCE.SFFOOD,
                offset: `all`
            }, {
                microservice: 'mssettings'
            }).pipe(map((sffoods) => sffoods
                .map(sffood => (Object.assign({}, sffood, { key: sffood.XSF04, label: sffood.XSFFOOD05 || cashTrans + ' ' + sffood.XSF04 })))
                .reverse()))
        ]);
    }
}
ConfigurationResolverPayments.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ConfigurationResolverPayments_Factory() { return new ConfigurationResolverPayments(i0.ɵɵinject(i1.ConfigurationService), i0.ɵɵinject(i2.P2RLocalStorageService), i0.ɵɵinject(i3.P2RApiService), i0.ɵɵinject(i4.P2REnvironmentService), i0.ɵɵinject(i2.P2RTranslateService), i0.ɵɵinject(i6.TranslateService)); }, token: ConfigurationResolverPayments, providedIn: "root" });
export class ImportExportResolver {
    constructor(configurationService) {
        this.configurationService = configurationService;
    }
    resolve(route) {
        return this.configurationService.getGroups({ type: CONFIGURATION_TYPE.IMPORT_EXPORT });
    }
}
ImportExportResolver.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ImportExportResolver_Factory() { return new ImportExportResolver(i0.ɵɵinject(i1.ConfigurationService)); }, token: ImportExportResolver, providedIn: "root" });
export class ConfigurationResolverLanguageAndCurrency {
    constructor(configurationService, p2rLocalStorageService, apiService, envService) {
        this.configurationService = configurationService;
        this.p2rLocalStorageService = p2rLocalStorageService;
        this.apiService = apiService;
        this.envService = envService;
    }
    resolve(route) {
        const group = route.data && route.data.group;
        const selectedPv = this.p2rLocalStorageService.getFromLocalStorage(GlobalVariable.SELECTED_PV_KEY_STORAGE, []);
        const items$ = new Observable((observer) => {
            this.configurationService.groups$().subscribe((groups) => {
                const grp = groups.find((g) => g.cod === group);
                const items = (grp && grp.items) || [];
                observer.next(items);
                observer.complete();
            });
        });
        return forkJoin([
            items$,
            selectedPv
                ? this.configurationService.getValues(group, selectedPv.codice)
                : this.apiService
                    .get(`${this.apiService.apiPath}/configuration/store/getStoreInfo`)
                    .pipe(mergeMap((data) => {
                    this.envService.storeData = data;
                    this.p2rLocalStorageService.addToLocalStorage(GlobalVariable.SELECTED_PV_KEY_STORAGE, [], data.pv[0]);
                    return this.configurationService.getValues(group, data.pv[0].codice);
                }))
        ]);
    }
}
ConfigurationResolverLanguageAndCurrency.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ConfigurationResolverLanguageAndCurrency_Factory() { return new ConfigurationResolverLanguageAndCurrency(i0.ɵɵinject(i1.ConfigurationService), i0.ɵɵinject(i2.P2RLocalStorageService), i0.ɵɵinject(i3.P2RApiService), i0.ɵɵinject(i4.P2REnvironmentService)); }, token: ConfigurationResolverLanguageAndCurrency, providedIn: "root" });
