export interface GroupPropStoreItem {
  key: string;
  cod: string;
  value?: PropStore[];
}

export interface PropStore {
  key: string;
  default: string;
}

export interface Tmpstore {
  codice: string;
  key_group: string;
  store: string;
  jsontemplate: string;
  values: any;
}

export enum GROUP_TYPE {
  OPERATOR = 'OPERATOR',
  FISCAL_PRINTER = 'FISCAL_PRINTER',
  HALLS_TABLES = 'HALLS_TABLES',
  KEEP_UP_PRO_OPTIONS = 'KEEP_UP_PRO_OPTIONS',
  OFFLINE_WORK = 'OFFLINE',
  DOCUMENTS = 'DOCUMENTS',
  CLOSURE = 'CLOSURE',
  ORDER_PRINTERS = 'ORDER_PRINTERS',
  SERVIZI = 'SERVIZI',
  GENERALI = 'GENERALI',
  TAKEAWAY = 'TAKEAWAY',
  PAYMENTS = 'PAYMENTS',
  LANGUAGE_CURRENCY = 'LANGUAGE_CURRENCY',
  ARTICLES = 'ARTICLES',
  ORDERS = 'ORDERS'
}

export enum SERVICE_TYPE {
  FCIC = 'FCIC',
  MYCUSTOM = 'MYCUSTOM',
  CUSTOM4U = 'CUSTOM4U'
}
export enum IMPORT_EXPORT_TYPE {
  IMPORT_ARCHIVES = 'IMPORT_ARCHIVES',
  EXPORT_ARCHIVES = 'EXPORT_ARCHIVES',
  IMPORT_ASPECT = 'IMPORT_ASPECT',
  EXPORT_ASPECT = 'EXPORT_ASPECT',
  EXPORT_SALE_DATA = 'EXPORT_SALE_DATA'
}

export enum CONFIGURATION_SOURCE {
  PREFERENCES = 'configuration-1',
  SERVICE = 'configuration-2',
  DEPFOOD = 'settings-3',
  ART = 'settings-2',
  PV = 'settings-26',
  SFFOOD = 'settings-25',
}

export interface GroupStorePropertyUI {
  key?: string;
  cod?: string;
  label?: string;
  isSelected?: boolean;
  isModified?: boolean;
}

export enum OPERATOR_FIELD_TYPES {
  KA_OPERATOR_DESCRIPTIONS_TIME_SLOT = 'KA_OPERATOR_DESCRIPTIONS_TIME_SLOT',
  KA_OPERATOR_TIME_SLOT = 'KA_OPERATOR_TIME_SLOT',
  KA_OPERATOR_TYPE = 'KA_OPERATOR_TYPE',
  KU_ASK_PWD_STORNO_CANCELLA_ORDINE = 'KU_ASK_PWD_STORNO_CANCELLA_ORDINE'
}

export enum PAYMENTS_FIELD_TYPES {
  KU_VOCE_SERVIZIO_ENABLED = 'KU_VOCE_SERVIZIO_ENABLED',
  KU_VOCE_SERVIZIO_PERCENTAGE = 'KU_VOCE_SERVIZIO_PERCENTAGE',
  KU_VASSOIO_ENABLED = 'KU_VASSOIO_ENABLED',
  KU_VASSOIO_CASSE = 'KU_VASSOIO_CASSE'
}

export enum HALLS_TABLES_FIELD_TYPES {
  KETCHAPP_ABILITA_COPERTI = 'KETCHAPP_ABILITA_COPERTI',
  KETCHAPP_FORCE_FIRST_PAGE_MENU = 'KETCHAPP_FORCE_FIRST_PAGE_MENU',
  RETURN2HOME_AFTER_RSC = 'RETURN2HOME_AFTER_RSC',
  KU_VISUALIZZA_TOTALE_TAVOLI = 'KU_SHOW_TABLE_TOTAL_NOT_PAX'
}

export enum OFFLINE_WORK_FIELD_TYPES {
  OFFLINE_WORK_ENABLE = 'p2rsync[\'enabled\']',
  CASSA_FATT_OFFLINE = 'KA_FATT_CASSA_OFFLINE',
  KU_NEW_MQTT_ENABLED = 'KU_NEW_MQTT_ENABLED',
  CASSA_MASTER = 'KU_NEW_MQTT_CASH_DESK_MASTER',
  KU_NEW_MQTT_ELEGGI_NUOVO_MASTER_ENABLED = 'KU_NEW_MQTT_ELEGGI_NUOVO_MASTER_ENABLED'
}

export enum DOCUMENT_FIELD_TYPES {
  KU_REPRINNT_CART = 'KA_reprint_carte',
  KU_REPRINT_CREDIT = 'KA_reprint_credito',
  KU_INTESTAZIONE_FATTURA = 'KU_INTESTAZIONE_FATTURA',
  KU_INTESTAZIONE_PROFORMA = 'KU_INTESTAZIONE_PROFORMA',
  KU_INTESTAZIONE_RESOCONTO = 'KU_INTESTAZIONE_RESOCONTO',
  KU_PRINT_FATTURA_SU_STAMPANTE_CONTI = 'KU_PRINT_FATTURA_SU_STAMPANTE_CONTI',
  KU_PRINT_RESOCONTO_SU_STAMPANTE_CONTI = 'KU_PRINT_RESOCONTO_SU_STAMPANTE_CONTI',
  KU_PRINT_PROFORMA_SU_STAMPANTE_CONTI = 'KU_PRINT_PROFORMA_SU_STAMPANTE_CONTI',
  KU_PRINT_STATS_SU_STAMPANTE_CONTI = 'KU_PRINT_STATS_SU_STAMPANTE_CONTI',
  KU_PRINT_LOGO_SU_PRECONTO = 'KU_PRINT_LOGO_SU_PRECONTO',
  KU_PRINT_STORE_DATA_SU_PRECONTO = 'KU_PRINT_STORE_DATA_SU_PRECONTO',
  KU_RESOCONTO_PRE_ROMANA = 'KU_RESOCONTO_PRE_ROMANA'
}

export enum CLOSURE_FIELD_TYPES {
  STAMPA_REPORT_FINANZIARIO_IN_CHIUSURA = 'STAMPA_REPORT_FINANZIARIO_IN_CHIUSURA',
  KETCHAPP_APERTURA_CHIUSURA_VELOCE = 'KETCHAPP_APERTURA_CHIUSURA_VELOCE',
  KU_ASK_FISCAL_CLOSURE = 'KU_ASK_FISCAL_CLOSURE',
  //
  KU_SCORECARDS_CLOSURE_EMAIL_ADDRESS = 'KU_SCORECARDS_CLOSURE_EMAIL_ADDRESS',
  //
  KU_STATS_CLOSING_PRINTER = 'KU_STATS_CLOSING_PRINTER',
  KU_PRINT_STATS_GIACENZA_CASSA = 'KU_PRINT_STATS_GIACENZA_CASSA',
  KU_PRINT_STATS_RISCOSSO = 'KU_PRINT_STATS_RISCOSSO',
  KU_PRINT_STATS_VENDUTO_DA_RISCUOTERE = 'KU_PRINT_STATS_VENDUTO_DA_RISCUOTERE',
  KU_PRINT_STATS_SCONTI = 'KU_PRINT_STATS_SCONTI',
  KU_PRINT_STATS_MAGGIORAZIONI = 'KU_PRINT_STATS_MAGGIORAZIONI',
  KU_PRINT_STATS_VENDUTO_CLIENTE = 'KU_PRINT_STATS_VENDUTO_CLIENTE',
  KU_PRINT_STATS_VENDUTO_PER_REPARTO = 'KU_PRINT_STATS_VENDUTO_PER_REPARTO',
  KU_PRINT_STATS_VENDUTO_PER_CASSIERE = 'KU_PRINT_STATS_VENDUTO_PER_CASSIERE',
  KU_PRINT_STATS_VENDUTO_TOTALE_DOCUMENTI = 'KU_PRINT_STATS_VENDUTO_TOTALE_DOCUMENTI',
  KU_PRINT_STATS_VENDUTO_PER_ARTICOLO = 'KU_PRINT_STATS_VENDUTO_PER_ARTICOLO'
}

export enum ORDER_PRINTERS_FIELD_TYPES {
  KA_AVVISO_ACUSTICO_CASSETTO = 'KA_AVVISO_ACUSTICO_CASSETTO',
  KA_BUZZER_COMANDE = 'KA_BUZZER_COMANDE',
  KA_PRINTER_COMANDE_SOCKET_HOST1 = 'KA_PRINTER_COMANDE_SOCKET_HOST1',
  KA_PRINTER_COMANDE_SOCKET_HOST2 = 'KA_PRINTER_COMANDE_SOCKET_HOST2',
  KA_PRINTER_COMANDE_SOCKET_HOST3 = 'KA_PRINTER_COMANDE_SOCKET_HOST3',
}

export enum ARTICLES_FIELD_TYPES {
  KU_ABILITA_STOCK = 'KU_ABILITA_STOCK',
  KU_BARCODE_ENABLED = 'KU_BARCODE_ENABLED',
  KU_MOBILE_SHOW_PRICE_ON_BUTTONS = 'KU_MOBILE_SHOW_PRICE_ON_BUTTONS',
  KU_MOBILE_SHOW_STOCK_ON_BUTTONS = 'KU_MOBILE_SHOW_STOCK_ON_BUTTONS',
  KU_LABEL_DEFAULT_MOD_VARIANT = 'KU_LABEL_DEFAULT_MOD_VARIANT'
}

export enum ORDERS_FIELD_TYPES {
  KU_ABILITA_COMANDA_POSTICIPATA = 'KU_ABILITA_COMANDA_POSTICIPATA'
}

export enum PRE_SAVE_CHECK {
  EVERYTHING_OK = 'EVERYTHING_OK',
  NO_TIME_SLOT = 'NO_TIME_SLOT',
  // Aggiungere qui altri casi in cui il metodo pre save
  // deve mostrare un popup di alert
}

export interface PvDto {
  codice: string;
  nome: string;
  provincia: string;
  citta: string;
  via: string;
}

export enum CONFIGURATION_TYPE {
  CONFIGURATION = 'CONFIGURATION',
  SERVICE = 'SERVICE',
  IMPORT_EXPORT = 'IMPORT_EXPORT',
}

export enum TAKEAWAY_FIELD_TYPES {
  KU_ASPORTO_ENABLED = 'KU_ASPORTO_ENABLED',
  KU_ASPORTO_START = 'KU_ASPORTO_START',
  KU_ASPORTO_END = 'KU_ASPORTO_END',
  KU_ASPORTO_SLOT_TIME = 'KU_ASPORTO_SLOT_TIME',
  KU_ASPORTO_PERIODS = 'KU_ASPORTO_PERIODS',
  KU_ASPORTO_PRINT_ORDER = 'KU_ASPORTO_PRINT_ORDER',
  KU_ASPORTO_TIME_TO_PRINT_ORDER_BEFORE_DELIVERY = 'KU_ASPORTO_TIME_TO_PRINT_ORDER_BEFORE_DELIVERY',
  KU_ASPORTO_PRINTER_CASH = 'KU_ASPORTO_PRINTER_CASH',
}

export enum GENERAL_FIELD_TYPES {
  KU_LISTINO_PERIODS = 'KU_LISTINO_PERIODS',
  KU_EXCLUDE_DESK_FROM_TIMESLOT_LISTINO = 'KU_EXCLUDE_DESK_FROM_TIMESLOT_LISTINO'
}

export enum LANGUAGE_CURRENCY_FIELD_TYPES {
  KU_PRINTER_LANGUAGE = 'KU_PRINTER_LANGUAGE',
  KU_DEFAULT_CURRENCY = 'KU_DEFAULT_CURRENCY'
}

export enum DO_CHECK_TYPES {
  CONFIGURATIONS = 'CONFIGURATIONS',
  PAYMENTS = 'PAYMENTS',
}

export enum DECIMAL_SEPARATOR {
  COMMA = 'comma',
  DOT = 'dot'
}

export enum EXPORT_SALES_DATA_ERROR {
  GENERIC = 'GENERIC',
  MAX_DAYS = 'MAX_DAYS'
}

export enum FCIC_CHECK_PIVA_ERRORS {
  NO_PIVA = 'NO_PIVA',
  FCIC_DUPLICATO = 'FCIC_DUPLICATO'
}