<div class="d-flex flex-grow-1 w-100 steps text-center export-sale-data">

    <div style="min-width: 300px;" class="mt-3 w-33 border-right">
        <div class="text-center border-end d-flex flex-column" style="height: 100%;">
            <div class="font-regular" style="flex: 0.5">
                <div class="numstep">1</div>
                <div class="label-step font-regular">
                    <label>{{'choose_the_store' | translate}}</label>
                </div>
            </div>
            <div style="flex: 1; margin-top: 40px;" class="step-action">
                <ng-select
                    [(ngModel)]="storeSelected"
                    class="select_item"
                    [clearable]="false"
                    style="margin-top: 20px; width: 300px; margin-left: auto; margin-right: auto;"
                    [searchable]="false"
                    name="store_selector"
                    [items]="this.storeList"
                    bindLabel="nome"
                    bindValue="codice">
                    <ng-template
                        ng-option-tmp
                        let-item="item"
                        let-item$="item$"
                        let-index="index">
                        <div class="d-flex">
                            <div class="div-select d-flex">
                                <img
                                    *ngIf="item$.selected"
                                    [src]="configurationService.getImg('/icons/option_selected.svg')"
                                    style="margin: auto;"
                                >
                            </div>
                            <div class="select_item">{{item.nome}}</div>
                        </div>
                    </ng-template>
                </ng-select>
                <div class="step-action">
                    <button
                        id="submit"
                        type="submit"
                        style="margin-top: 210px;"
                        (click)="confirmStore()"
                        class="btn btn-custom pl-5 pr-5 btm-216 text-uppercase"
                        [disabled]="storeSelected==''"
                    >
                        {{'dialog_confirm' | translate}}
                    </button>
                </div>
            </div>
            <div class="mb-5 mt-auto">
                <img [src]="configurationService.getImg('/icons/store_icon.svg')">
            </div>
        </div>
    </div>

    <div style="min-width: 300px;" class="mt-3 w-33 border-right" [ngClass]="{'disabled': !this.storeSelectedConfirmClicked}">
        <div class="text-center border-end d-flex flex-column" style="height: 100%;">
            <div class="font-regular" style="flex: 0.5;">
                <div class="numstep">2</div>
                <div class="label-step font-regular">
                    <label>{{'select_time_slot' | translate}}</label>
                </div>
            </div>
            <div style="flex: 1; margin-top: 40px;">
                <div style="height: 210px;">
                    <div style="margin-top: -20px;">
                        <div class="datatime_div_title_label">{{'start' | translate}}</div>
                        <input
                            type="datetime-local"
                            id="data_time_from" 
                            [(ngModel)]="dateTimeSrcFrom"
                            (ngModelChange)="dateTimeSrcChanged()"
                            class="form-control datatime_input"
                        />
                    </div>
                    <div style="margin-top: 80px;"> 
                        <div class="datatime_div_title_label">{{'end' | translate}}</div>
                        <input
                            type="datetime-local"
                            id="data_time_to" 
                            [(ngModel)]="dateTimeSrcTo"
                            (ngModelChange)="dateTimeSrcChanged()"
                            class="form-control datatime_input"
                        />
                    </div>
                </div>
                <div style="margin-top: 70px;">
                    <button
                        (click)="confirmTimeRange()"
                        class="btn btn-custom pl-5 pr-5 btm-216 text-uppercase"
                        style="width: 230px;"
                        [disabled]="!isDateToAfterDateFrom()"
                    >
                        {{'dialog_confirm' | translate | uppercase}}
                    </button>
                </div>
            </div>
            <div class="mb-5 mt-auto">
                <img [src]="configurationService.getImg('/icons/export_calendar.svg')">
            </div>
        </div>
    </div>

    <div style="min-width: 300px;" class="mt-3 w-33 border-right"  [ngClass]="{'disabled': !this.dateRangeConfirmClicked || !this.storeSelectedConfirmClicked}">
        <div class="text-center border-end d-flex flex-column" style="height: 100%;">
            <div class="font-regular" style="flex: 0.5">
                <div class="numstep">3</div>
                <div class="label-step font-regular">
                    <label>{{'select_decimal_separator' | translate}}</label>
                </div>
            </div>
            <div style="flex: 1; margin-top: 40px;" class="step-action">
                <ng-select
                    [(ngModel)]="decimalSeparatorSelected"
                    class="select_item"
                    [clearable]="false"
                    style="margin-top: 20px; width: 300px; margin-left: auto; margin-right: auto;"
                    [searchable]="false"
                    name="decimal_separator"
                    [items]="this.decimalSeparatorList"
                    bindLabel="label"
                    bindValue="key">
                    <ng-template
                        ng-option-tmp
                        let-item="item"
                        let-item$="item$"
                        let-index="index">
                        <div class="d-flex">
                            <div class="div-select d-flex">
                                <img
                                    *ngIf="item$.selected"
                                    [src]="configurationService.getImg('/icons/option_selected.svg')"
                                    style="margin: auto;"
                                >
                            </div>
                            <div class="select_item">{{item.label}}</div>
                        </div>
                    </ng-template>
                </ng-select>
                <div class="step-action">
                    <button
                        id="submit"
                        type="submit"
                        style="margin-top: 210px;"
                        (click)="exportSalesData()"
                        class="btn btn-custom pl-5 pr-5 btm-216 text-uppercase"
                        [disabled]="decimalSeparatorSelected==''"
                    >
                        {{'export' | translate}}
                    </button>
                </div>
            </div>
            <div class="mb-5 mt-auto">
                <img [src]="configurationService.getImg('/icons/export.svg')">
            </div>
        </div>
    </div>

</div>
