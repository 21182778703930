<div class="d-flex flex-grow-1 h-100 justify-content-center import-export">
    <div class="d-flex flex-column justify-content-between">
        <div>
            <div class="m-5">
                <span class="fs24 lh32 font_r-r c11">
                    {{'choose_the_archive_to_export'| translate}}
                </span>
            </div>
            <div class="ml-5 mr-5">
                <div>
                    <ng-select
                        [(ngModel)]="templateSelected"
                        name="template"
                        [clearable]="false"
                        [searchable]="false"
                    >
                        <ng-option
                            *ngFor="let template of templateList"
                            [value]="template"
                        >
                            <span class="font_r-r fs18 lh22 c11">{{template.name}}</span>
                        </ng-option>
                    </ng-select>
                </div>
                <ng-container *ngIf="templateSelected && templateSelected.templateType === TEMPLATE_TYPE.HALLS_TABLES && isMultiStore">
                    <div class="mt-4">
                        <ng-select
                            [(ngModel)]="selectedPv"
                            name="store"
                            [clearable]="false"
                            [searchable]="false"
                            [compareWith]="storeCmp"
                        >
                            <ng-option
                                *ngFor="let store of storeList"
                                [value]="store"
                            >
                                <span class="font_r-r fs18 lh22 c11">{{store.nome}}</span>
                            </ng-option>
                        </ng-select>
                    </div>
                </ng-container>
                <div class="mt-4 d-flex justify-content-center">
                    <button
                        (click)="export()"
                        class="btn btn-custom pl-5 pr-5"
                        style="width: 230px;"
                        [disabled]="!exportEnabled"
                    >
                        {{'export' | translate | uppercase}}
                    </button>
                </div>
            </div>
        </div>
        <div class="mb-5 d-flex justify-content-center">
            <img [src]="configurationService.getImg('/icons/export.svg')">
        </div>
    </div>
</div>
