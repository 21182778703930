<div>
    <div
        *ngIf="this.currentServicesValues !== undefined"
        [class.disabled]="this.currentServicesValues.readonly === true"
    >
        <ng-container *ngIf="isMultiStore">
            <div class="align-items-center d-flex justify-content-between ml-3 mr-4 mt-4 mb-4">
                <div>
                    <span class="section-title margin-auto">{{'selected_store' | translate}}</span>
                </div>
                <div class="d-flex">
                    <span
                        class="sub-section-variable section-title margin-auto mr-3"
                        style="white-space: nowrap;"
                    >
                        {{selectedPv.nome.toUpperCase()}}
                    </span>
                    <button
                        class="btn rounded-pill btn-block btn-primary text-uppercase pl-3 pr-3"
                        (click)="selectStore()"
                    >
                        <span class="font-r_r fs18 c11 lh21">
                            <span>
                                {{'scegli_negozio' | translate | uppercase}}
                            </span>
                        </span>
                    </button>
                </div>
            </div>
            <hr>
        </ng-container>
        <ng-container *ngIf="!isMultiStore">
            <div class="mb-4"></div>
        </ng-container>
        <div>
            <div class="sub-section-container mt-4 mb-4">
                <div>
                    <p [ngClass]="isMultiStore ? 'sub-section-title' : 'sub-section-title ml-3'">
                        {{'SERVIZI.MY_CUSTOM.USERNAME' | translate | uppercase}}
                    </p>

                </div>
                <div
                    class="pl-5 sub-section-variable"
                    (click)="changeUsername()"
                >
                    <div *ngIf="this.kuServicesUsername; else noUsername">{{kuServicesUsername}}</div>
                    <ng-template #noUsername>-</ng-template>
                </div>
            </div>
            <hr>
            <div class="sub-section-container mt-4 mb-4">
                <div>
                    <p [ngClass]="isMultiStore ? 'sub-section-title' : 'sub-section-title ml-3'">
                        {{'SERVIZI.MY_CUSTOM.PWD' | translate | uppercase}}
                    </p>

                </div>
                <div
                    class="pl-5 sub-section-variable"
                    (click)="changePwd()"
                >
                    <div *ngIf="this.kuServicesPwd; else noPwd">{{kuServicesPwd}}</div>
                    <ng-template #noPwd>-</ng-template>
                </div>
            </div>
            <hr>
            <div class="sub-section-container mt-4 mb-4">
                <div>
                    <p [ngClass]="isMultiStore ? 'sub-section-title' : 'sub-section-title ml-3'">
                        {{'SERVIZI.MY_CUSTOM.URL' | translate | uppercase}}
                    </p>

                </div>
                <div
                    class="pl-5 sub-section-variable"
                    (click)="changeUrl()"
                >
                    <div *ngIf="this.kuServicesUrl; else noUrl">{{kuServicesUrl}}</div>
                    <ng-template #noUrl>-</ng-template>
                </div>
            </div>
        </div>
        <hr>
    </div>
</div>
