<div>
    <div *ngIf="checkIfKeyIsPresent(values, 'KU_ASK_FISCAL_CLOSURE')">
        <div *ngIf="checkIfKeyIsPresent(items, 'ASK_FISCAL_CLOSURE')">
            <div
                class="sub-section-container"
                style="margin-top: 10px;"
            >
                <div>
                    <p
                        class="section-title-2"
                        style="text-transform: uppercase;"
                    >
                        {{'CLOSURE.ASK_FISCAL_CLOSURE.TITLE' | translate}}
                    </p>
                    <p class="sub-section-description-2 font-r_r fs16 lh16 c10">
                        {{'CLOSURE.ASK_FISCAL_CLOSURE.DESCRIPTION' | translate}}
                    </p>
                </div>
                <div class="sub-section-checkbox-2">
                    <img
                        [src]="kuAskFiscalClosure ? configurationService.getImg('/icons/switch-on.svg') : configurationService.getImg('/icons/switch-off.svg')"
                        (click)="onClinkEnableFiscalClosure()"
                    >
                </div>
            </div>
            <hr>
        </div>
    </div>
    <div *ngIf="checkIfKeyIsPresent(values, 'KETCHAPP_APERTURA_CHIUSURA_VELOCE')">
        <div *ngIf="checkIfKeyIsPresent(items, 'APERTURA_CHIUSURA_VELOCE')">
            <div class="sub-section-container">
                <div>
                    <p
                        class="section-title-2"
                        style="text-transform: uppercase;"
                    >
                        {{'CLOSURE.APERTURA_CHIUSURA_VELOCE.TITLE' | translate}}
                    </p>
                    <p class="sub-section-description-2 font-r_r fs16 lh16 c10">
                        {{'CLOSURE.APERTURA_CHIUSURA_VELOCE.DESCRIPTION' | translate}}
                    </p>
                </div>
                <div class="sub-section-checkbox-2">
                    <img
                        [src]="kuAperturaChiusuraVeloce ? configurationService.getImg('/icons/switch-on.svg') : configurationService.getImg('/icons/switch-off.svg')"
                        (click)="onClickEnableAperturaChiusuraVeloce()"
                    >
                </div>
            </div>
            <hr>
        </div>
    </div>
    <!-- <div *ngIf="checkIfKeyIsPresent(values, 'STAMPA_REPORT_FINANZIARIO_IN_CHIUSURA')">
        <div *ngIf="checkIfKeyIsPresent(items, 'REPORTO_FINANZIARIO')">
            <div class="align-items-center d-flex justify-content-between sub-section-container"
                style="margin-right: 32px;">
                <div>
                    <p class="section-title-2" style="text-transform: uppercase;">
                        {{'CLOSURE.REPORTO_FINANZIARIO.TITLE' | translate}}
                    </p>
                    <p class="sub-section-description-2 font-r_r fs16 lh16 c10">
                        {{'CLOSURE.REPORTO_FINANZIARIO.DESCRIPTION' | translate}}
                    </p>
                </div> -->
    <!-- <div class="sub-section-checkbox-2">
                    <img [src]="kuReportInChiusura ? configurationService.getImg('/icons/switch-on.svg') : configurationService.getImg('/icons/switch-off.svg')"
                        (click)="onClickEnableReportInChiusura()">
                </div> -->
    <!-- <div>
                    <button class="btn rounded-pill btn-block btn-primary text-uppercase pl-3 pr-3"
                        style="height: 40px;" (click)="openPopupReportChiusura()">
                        <span class="font-r_r fs18 c13 lh21">
                            <span>Seleziona</span>
                        </span>
                    </button>
                </div>
            </div>
            <hr>
        </div>
    </div> -->
    <div *ngIf="checkIfKeyIsPresent(values, 'KU_STATS_CLOSING_PRINTER')">
        <div *ngIf="checkIfKeyIsPresent(items, 'KU_STATS_CLOSING_PRINTER')">
            <div
                class="align-items-center d-flex justify-content-between sub-section-container"
                style="margin-right: 32px;"
            >
                <div>
                    <p
                        class="section-title-2"
                        style="text-transform: uppercase;"
                    >
                        {{'CLOSURE.KU_STATS_CLOSING_PRINTER.TITLE' | translate}}
                    </p>
                    <p class="sub-section-description-2 font-r_r fs16 lh16 c10">
                        {{'CLOSURE.KU_STATS_CLOSING_PRINTER.DESCRIPTION' | translate}}
                    </p>
                </div>
                <div>
                    <div class="d-flex align-items-center">
                        <span class="sub-section-number mr-3">{{getPrinterStatsLabel()}}</span>
                        <button
                            class="btn rounded-pill btn-block btn-primary text-uppercase pl-3 pr-3 text-center"
                            style="height: 40px; width: 120px;"
                            (click)="chooseCassa()"
                        >
                            <span class="font-r_r fs18 c13 lh21">
                                <span>{{'choose' | translate | uppercase}}</span>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
            <hr>
        </div>
    </div>
    <!-- Email for stats -->
    <div
        *ngIf="checkIfKeyIsPresent(values, 'KU_SCORECARDS_CLOSURE_EMAIL_ADDRESS')"
        [ngClass]="{'disabled': (kuStatsPrinter == undefined) || (kuStatsPrinter.length == 0)}"
    >
        <div *ngIf="checkIfKeyIsPresent(items, 'KU_SCORECARDS_CLOSURE_EMAIL_ADDRESS')">
            <div
                class="align-items-center d-flex justify-content-between sub-section-container"
                style="margin-right: 32px;"
            >
                <div>
                    <p
                        class="section-title-2"
                        style="text-transform: uppercase;"
                    >
                        {{'CLOSURE.KU_SCORECARDS_CLOSURE_EMAIL_ADDRESS.TITLE' | translate}}
                    </p>
                    <p class="sub-section-description-2 font-r_r fs16 lh16 c10">
                        {{'CLOSURE.KU_SCORECARDS_CLOSURE_EMAIL_ADDRESS.DESCRIPTION' | translate}}
                    </p>
                </div>
                <div>
                    <div class="d-flex align-items-center">
                        <span class="sub-section-number mr-3">{{ setEmailFieldMessage() }}</span>
                        <button
                            class="btn rounded-pill btn-block btn-primary text-uppercase pl-3 pr-3 text-center"
                            style="height: 40px; width: 120px;"
                            (click)="setUpMail()"
                        >
                            <span class="font-r_r fs18 c13 lh21">
                                <span>{{'set_up' | translate | uppercase}}</span>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
            <hr>
        </div>
    </div>
    <div
        *ngIf="checkIfKeyIsPresent(values, 'STAMPA_REPORT_FINANZIARIO_IN_CHIUSURA')"
        [ngClass]="{'disabled': (kuStatsPrinter == undefined) || (kuStatsPrinter.length == 0)}"
    >
        <!-- && kuScorecardsClosureEmailAddress.length == 0) -->
        <div
            class="align-items-center d-flex justify-content-between sub-section-container"
            style="margin-right: 32px;"
        >
            <div>
                <p
                    class="section-title-2"
                    style="text-transform: uppercase;"
                >
                    {{'CLOSURE.REPORTO_FINANZIARIO.TITLE' | translate}}
                </p>
                <p class="sub-section-description-2 font-r_r fs16 lh16 c10">
                    {{'CLOSURE.REPORTO_FINANZIARIO.DESCRIPTION' | translate}}
                </p>
            </div>
            <div>
                <div class="d-flex align-items-center">
                    <span class="sub-section-number mr-3">{{kuStatisticsCounter}}</span>
                    <button
                        class="btn rounded-pill btn-block btn-primary text-uppercase pl-3 pr-3 text-center"
                        style="height: 40px; width: 120px;"
                        (click)="openPopupReportChiusura()"
                    >
                        <span class="font-r_r fs18 c13 lh21">
                            <span>{{'choose' | translate | uppercase}}</span>
                        </span>
                    </button>
                </div>
            </div>
        </div>
        <hr>
    </div>
</div>
