import { FormControl } from "@angular/forms";
import { P2RLocalStorageService } from "@p2r/shared";
import { isArray } from "util";
import * as _ from 'lodash';

export class UtilsShared {
  public static compareValues(apiValue, formValue) {
    if (formValue === undefined) {
      return false;
    }
    if (isArray(apiValue) && isArray(formValue)) {
      return _.differenceWith(formValue, apiValue, _.isEqual).length + _.differenceWith(apiValue, formValue, _.isEqual).length;
    }
    if (apiValue != formValue) {
      return true;
    } else {
      return false;
    }
  }
  public static thereAreOtherChanges(
    p2rLocalStorageService: P2RLocalStorageService
  ) {
    // Check Configuration
    const localConfValues =
      p2rLocalStorageService.getFromLocalStorage("CONFIGURATION");
    if (localConfValues !== undefined) {
      for (let [key, value] of Object.entries(localConfValues)) {
        if (value !== undefined && value["values"] !== undefined) {
          console.log("Found at least one category with changes: " + key);
          return true;
        }
      }
    }

    const localServiceValues = p2rLocalStorageService.getFromLocalStorage(
      "SERVICES",
      ["SERVIZI"]
    );
    if (localServiceValues !== undefined) {
      if (Object.keys(localServiceValues).length > 0) {
        console.log("SERVICES has changes");
        return true;
      }
    }

    const localOfflineValues = p2rLocalStorageService.getFromLocalStorage(
      "BROKERS",
      ["OFFLINE"]
    );
    if (localOfflineValues !== undefined) {
      if (Object.keys(localOfflineValues).length > 0) {
        console.log("OFFLINE has changes");
        return true;
      }
    }
    const localOfflineValues2 = p2rLocalStorageService.getFromLocalStorage(
      "FATTURE",
      ["DOCUMENTS"]
    );
    if (localOfflineValues2 !== undefined) {
      if (Object.keys(localOfflineValues2).length > 0) {
        console.log("OFFLINE has changes");
        return true;
      }
    }
    const localOfflineValues3 = p2rLocalStorageService.getFromLocalStorage(
      "TEMPLATE_STORE",
      ["DOCUMENTS"]
    );
    if (localOfflineValues3 !== undefined) {
      if (Object.keys(localOfflineValues3).length > 0) {
        console.log("OFFLINE has changes");
        return true;
      }
    }
    const localGeneraliValues = p2rLocalStorageService.getFromLocalStorage(
      "TEMPLATE_STORE_GENERALI",
      ["GENERALI"]
    );
    if (localGeneraliValues !== undefined) {
      if (Object.keys(localGeneraliValues).length > 0) {
        console.log("GENERALI has changes");
        return true;
      }
    }

    return false;
  }
  public static setFormControlbayJson(currentStoreJsonTemplate: any) {
    const group: any = {};
    Object.keys(currentStoreJsonTemplate).forEach((key) => {
      group[key] = new FormControl(currentStoreJsonTemplate[key], {
        validators: [],
        asyncValidators: [],
        updateOn: "blur",
      });
    });
    return group;
  }

  public static getCurrDateInCustomFormat() {
    var data = new Date();
    return this.convertDateInCustomFormat(data);
  }

  public static convertDateInCustomFormat(data) {
    var aaaa = String(data.getFullYear());
    var mese = String(data.getMonth() + 1);
    var gg = String(data.getDate());
    var ora = String(data.getHours());
    var min = String(data.getMinutes());
    var sec = String(data.getSeconds());
    if (mese.length == 1) mese = "0" + mese;
    if (gg.length == 1) gg = "0" + gg;
    if (ora.length == 1) ora = "0" + ora;
    if (min.length == 1) min = "0" + min;
    if (sec.length == 1) sec = "0" + sec;
    var today = aaaa + mese + gg + ora + min + sec;
    return today;
  }

  /**
   * Mostra un alert con all'interno una progress bar che si carica automaticamente ogni 1000 ms.
   * 
   * @param body 
   * @param img 
   * @param title 
   * @param messages 
   * @param P2RDialogComponent 
   * @param p2rDialogService 
   * @returns progress bar dialog + progress interval.
   */
  public static showProgressBarDialog(body, img, title, messages, P2RDialogComponent, p2rDialogService) {
    const progressCb = (p) => {
      if(document.getElementById('progress-bar')) {
        document.getElementById('progress-bar').style.width = `${p}%`;
      }
      return p;
    };

    let progress = 0;

    const alertOptions = {
      id: 'sync-art-fcic',
      body: body,
      hasHead: false,
      data: {
        imgSrc: img,
        title:title,
        titleClasses: 'text-inherit font-r_r fs24 lh21 c11 mt-2',
        progressBar: {
        },
        messages: messages,
      }
    };

    let alert = p2rDialogService.open(P2RDialogComponent, alertOptions);

    progressCb(0);

    const progressInterval = setInterval(() => {
      if (progress <= 90) {
        progress += 5;
        progressCb(progress);
      }
    }, 1000);

    return [alert, progressInterval];
  }

  public static dismissProgressBarDialog(alert, progressInterval) {
    clearInterval(progressInterval);
    alert.dismiss();
  }

}
