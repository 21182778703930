import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'configuration-app-time-slot-alert',
  templateUrl: './time-slot-alert.component.html',
  styleUrls: ['./time-slot-alert.component.scss']
})
export class TimeSlotAlertComponent implements OnInit {

  private _data: any;
  private _type: any;
  private _title: any;
  private _messages: any;

  public isHourInputFocused: boolean;
  public isMinutesInputFocued: boolean;
  public isStartHourInputFocused: boolean;
  public isStartMinutesInputFocued: boolean;
  public isEndHourInputFocused: boolean;
  public isEndMinutesInputFocued: boolean;

  @ViewChild('timeHourInput', { static: false }) timeHourInput: ElementRef;

  constructor() { }

  ngOnInit() {
    this.onHourInputFocus();
    setTimeout(() => { // this will make the execution after the above boolean has changed
      this.timeHourInput.nativeElement.focus();
    }, 0);
  }

  @Input() set data(data: any) {
    this._data = data;
  }

  get data(): any {
    return this._data;
  }

  @Input() set title(title: any) {
    this._title = title;
  }

  get title(): any {
    return this._title || this._data.title;
  }

  @Input() set type(type: any) {
    this._type = type;
  }

  get type(): any {
    return this._type || this._data.type;
  }

  @Input() set messages(messages: any[]) {
    this._messages = messages;
  }

  get messages(): any[] {
    return this._messages || this._data.messages;
  }

  public onChangeInput(event) {
    console.log((event));
  }

  public dismiss() {

  }

  public onHourInputFocus() {
    this.isHourInputFocused = true;
    this.isMinutesInputFocued = false;
  }

  public onMinutesInputFocus() {
    this.isHourInputFocused = false;
    this.isMinutesInputFocued = true;
  }

  public onStartHourInputFocus() {
    this.isStartHourInputFocused = true;
    this.isStartMinutesInputFocued = false;
    this.isEndHourInputFocused = false;
    this.isEndMinutesInputFocued = false;
  }

  public onStartMinutesInputFocus() {
    this.isStartHourInputFocused = false;
    this.isStartMinutesInputFocued = true;
    this.isEndHourInputFocused = false;
    this.isEndMinutesInputFocued = false;
  }
  public onEndHourInputFocus() {
    this.isStartHourInputFocused = false;
    this.isStartMinutesInputFocued = false;
    this.isEndHourInputFocused = true;
    this.isEndMinutesInputFocued = false;
  }

  public onEndMinutesInputFocus() {
    this.isStartHourInputFocused = false;
    this.isStartMinutesInputFocued = false;
    this.isEndHourInputFocused = false;
    this.isEndMinutesInputFocued = true;
  }
}
