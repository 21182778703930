import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ConfigurationComponent } from "./configuration.component";
import { ConfigurationService } from "./configuration.service";
import { OperatorComponent } from "./operator/operator.component";
import { RouterModule } from "@angular/router";
import { HttpClientModule } from "@angular/common/http";
import { HeaderComponent } from "./header/header.component";
import { P2rSharedLibModule, P2RTranslateModule } from "@p2r/shared";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TimeSlotAlertComponent } from "./time-slot-alert/time-slot-alert.component";
import { SavePanelComponent } from "./save-panel/save-panel.component";
import {
  ConfigurationResolver,
  ConfigurationResolverGenerali,
  ConfigurationResolverOfflineWork,
  ServicesResolver
} from "./configuration.resolve";
import { ChangeDescriptionDialogComponent } from "./change-description-dialog/change-description-dialog.component";
import { ConfigurationAlertComponent } from "./alert/alert.component";
import { HallsTablesComponent } from "../halls-tables/halls-tables.component";
import { NumberDialogComponent } from "../number-dialog/number-dialog.component";
import { KuClosureComponent } from "../ku-closure/ku-closure.component";
import { KuOrderPrintersComponent } from "../ku-order-printers/ku-order-printers.component";
import { ChangeHostIpDialogComponent } from "../change-host-ip-dialog/change-host-ip-dialog.component";
import { StorePanelComponent } from "../store-panel/store-panel.component";
import { CustomKuOfflineWorkComponent } from "./custom-ku-offline-work/custom-ku-offline-work.component";
import { ReloadResolveModule } from "src/reload-resolve/reload-resolve.module";
import { CustomKuDocumentsComponent } from "./custom-ku-documents/custom-ku-documents.component";
import { CustomKuServicesComponent } from "./custom-ku-services/custom-ku-services.component";
import { ChangeTextDialogComponent } from "./change-text-dialog/change-text-dialog.component";
import { CustomGeneraliComponent } from "./custom-generali/custom-generali.component";
import { FcicComponent } from "./fcic/fcic.component";
import { MycustomComponent } from "./mycustom/mycustom.component";
import { CustomizeDialogComponent } from "../customize-dialog/customize-dialog.component";
import { Custom4uComponent } from "./custom4u/custom4u.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { TakeawayComponent } from "./takeaway/takeaway.component";
import { ImportExportModule } from "./ImportExport/importExport.module";
import { SetEmailDialogComponent } from './set-email-dialog/set-email-dialog.component';
import { ChangeServicePercentageDialogComponent } from './change-service-percentage-dialog/change-service-percentage-dialog.component';
import { DragScrollModule } from "ngx-drag-scroll";
import { PaymentsComponent } from './payments/payments.component';
import { LanguageCurrencyComponent } from './language-currency/language-currency.component';
import { CustomArticlesComponent } from "../custom-articles/custom-articles.component";
import { CustomOrderComponent } from "../custom-order/custom-order.component";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    P2RTranslateModule,
    ReactiveFormsModule,
    FormsModule,
    P2rSharedLibModule,
    ReloadResolveModule,
    NgSelectModule,
    ImportExportModule,
    DragScrollModule
  ],
  declarations: [
    CustomKuOfflineWorkComponent,
    ConfigurationComponent,
    HeaderComponent,
    OperatorComponent,
    FcicComponent,
    Custom4uComponent,
    MycustomComponent,
    TimeSlotAlertComponent,
    SavePanelComponent,
    ChangeDescriptionDialogComponent,
    ConfigurationAlertComponent,
    HallsTablesComponent,
    NumberDialogComponent,
    KuClosureComponent,
    KuOrderPrintersComponent,
    ChangeHostIpDialogComponent,
    CustomizeDialogComponent,
    StorePanelComponent,
    CustomKuDocumentsComponent,
    CustomKuServicesComponent,
    ChangeTextDialogComponent,
    CustomGeneraliComponent,
    TakeawayComponent,
    SetEmailDialogComponent,
    ChangeServicePercentageDialogComponent,
    PaymentsComponent,
    LanguageCurrencyComponent,
    CustomArticlesComponent,
    CustomOrderComponent
  ],
  providers: [
    ConfigurationService,
    ConfigurationResolver,
    ConfigurationResolverOfflineWork,
    ConfigurationResolverGenerali,
    ServicesResolver
  ],
  entryComponents: [
    TimeSlotAlertComponent,
    ChangeDescriptionDialogComponent,
    ChangeTextDialogComponent,
    ConfigurationAlertComponent,
    NumberDialogComponent,
    ChangeHostIpDialogComponent,
    CustomizeDialogComponent,
    SetEmailDialogComponent,
    ChangeServicePercentageDialogComponent
  ],
})
export class ConfigurationModule { }
