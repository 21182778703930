import { enableProdMode, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from './environments/environment';
import singleSpaAngular from 'single-spa-angular';
import { SingleSpaPropsSubject } from './single-spa/single-spa-props';
import * as __NgCli_bootstrap_1 from "./app/app.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
if (environment.production) {
    enableProdMode();
}
const ɵ0 = singleSpaProps => {
    SingleSpaPropsSubject.singleSpaPropsSubject.next(singleSpaProps);
    return __NgCli_bootstrap_2.platformBrowser().bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory);
}, ɵ1 = () => {
    let dom = document.getElementsByClassName('app-container').item(0);
    if (dom) {
    }
    else {
        dom = document.getElementById('app-container');
    }
    return dom;
};
const lifecycles = singleSpaAngular({
    bootstrapFunction: ɵ0,
    template: '<configuration-root />',
    domElementGetter: ɵ1,
    Router,
    NgZone
});
export const bootstrap = lifecycles.bootstrap;
export const mount = lifecycles.mount;
export const unmount = lifecycles.unmount;
export { ɵ0, ɵ1 };
