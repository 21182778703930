<div>
    <div *ngIf="checkIfKeyIsPresent(values, 'RETURN2HOME_AFTER_RSC')">
        <div *ngIf="checkIfKeyIsPresent(items, 'HALLS')">
            <p class="section-title">{{'HALLS_TABLES.HALLS' | translate}}</p>
            <hr>
            <div *ngIf="items.HALLS.hasOwnProperty('RETURN_TO_HOME')">
                <div class="sub-section-container">
                    <div>
                        <p
                            class="sub-section-title"
                            style="text-transform: uppercase;"
                        >
                            {{'HALLS_TABLES.HALLS.RETURN_TO_HOME.TITLE' | translate}}
                        </p>
                        <p class="sub-section-description font-r_r fs16 lh16 c10">
                            {{'HALLS_TABLES.HALLS.RETURN_TO_HOME.DESCRIPTION' | translate}}
                        </p>
                    </div>
                    <div class="sub-section-checkbox">
                        <img
                            [src]="kuReturnToHomeAfterRsc ? configurationService.getImg('/icons/switch-on.svg') : configurationService.getImg('/icons/switch-off.svg')"
                            (click)="onClickReturn2Home()"
                        >
                    </div>
                </div>
                <hr>
            </div>
            <div *ngIf="items.HALLS.hasOwnProperty('SHOW_LAST_FOLDER')">
                <div class="sub-section-container">
                    <div>
                        <p
                            class="sub-section-title"
                            style="text-transform: uppercase;"
                        >
                            {{'HALLS_TABLES.HALLS.SHOW_LAST_FOLDER.TITLE' | translate}}
                        </p>
                        <p class="sub-section-description font-r_r fs16 lh16 c10">
                            {{'HALLS_TABLES.HALLS.SHOW_LAST_FOLDER.DESCRIPTION' | translate}}
                        </p>
                    </div>
                    <div class="sub-section-checkbox">
                        <img
                            [src]="kuForceFirstPageMenu ? configurationService.getImg('/icons/switch-on.svg') : configurationService.getImg('/icons/switch-off.svg')"
                            (click)="onClickForceFirstPageMenu()"
                        >
                    </div>
                </div>
                <hr>
            </div>
        </div>
    </div>
    <div
        *ngIf="checkIfKeyIsPresent(values, 'KETCHAPP_ABILITA_COPERTI')"
        class="mt-2"
    >
        <div *ngIf="checkIfKeyIsPresent(items, 'TABLES')">
            <p class="section-title">{{'HALLS_TABLES.TABLES' | translate}}</p>
            <hr>
            <div *ngIf="items.TABLES.hasOwnProperty('COVERED')">
                <div class="sub-section-container">
                    <div>
                        <p
                            class="sub-section-title"
                            style="text-transform: uppercase;"
                        >
                            {{'HALLS_TABLES.TABLES.COVERED.TITLE' | translate}}
                        </p>
                        <p class="sub-section-description font-r_r fs16 lh16 c10">
                            {{'HALLS_TABLES.TABLES.COVERED.DESCRIPTION' | translate}}
                        </p>
                    </div>
                    <div class="sub-section-checkbox">
                        <img
                            [src]="kuAbilitaCoperti ? configurationService.getImg('/icons/switch-on.svg') : configurationService.getImg('/icons/switch-off.svg')"
                            (click)="onClickAbilitaCoperti()"
                        >
                    </div>
                </div>
                <hr>
            </div>
            <div *ngIf="items.TABLES.hasOwnProperty('SHOW_TABLE_TOTAL')">
                <div class="sub-section-container">
                    <div>
                        <p
                            class="sub-section-title"
                            style="text-transform: uppercase;"
                        >
                            {{'HALLS_TABLES.TABLES.SHOW_TABLE_TOTAL.TITLE' | translate}}
                        </p>
                        <p class="sub-section-description font-r_r fs16 lh16 c10">
                            {{'HALLS_TABLES.TABLES.SHOW_TABLE_TOTAL.DESCRIPTION' | translate}}
                        </p>
                    </div>
                    <div class="sub-section-checkbox">
                        <img
                            [src]="kuVisualizzaTotaleTavolo ? configurationService.getImg('/icons/switch-on.svg') : configurationService.getImg('/icons/switch-off.svg')"
                            (click)="onClickAbilitaVisualizzaTotaleTavolo()"
                        >
                    </div>
                </div>
            </div>
        </div>
        <hr>
    </div>
    <!-- <div>
        <div>
            <div class="sub-section-container">
                <div>
                    <p
                        class="section-title-2"
                        style="text-transform: uppercase;"
                    >
                        {{'HALLS_TABLES.TABLES.SERVICE.TITLE' | translate}}
                    </p>
                    <p class="sub-section-description-2 font-r_r fs16 lh16 c10">
                        {{'HALLS_TABLES.TABLES.SERVICE.DESCRIPTION' | translate}}
                    </p>
                </div>
                <div class="sub-section-checkbox">
                    <img
                        [src]="kuabilitavoceservizio ? configurationService.getImg('/icons/switch-on.svg') : configurationService.getImg('/icons/switch-off.svg')"
                        (click)="onClickAbilitaVoceServizio()"
                    >
                </div>
            </div>
            <hr>
            <div>
                <div class="sub-section-container pt-2 pb-2">
                    <div>
                        <p class="sub-section-title">
                            {{'HALLS_TABLES.TABLES.SERVICE_PERCENTAGE.TITLE' | translate}}
                        </p>
                    </div>
                    <div
                        class="sub-section-variable"
                        style="min-width: 50px;"
                        (click)="openPercentageDialog()"
                    >
                        <div class="sub-section-label-value">{{ kuvoceserviziopercentage + '%' }}</div>
                    </div>
                </div>
                <hr>
            </div>
        </div>
    </div> -->
</div>
